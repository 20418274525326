import React, { Component } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import {
  faFileAlt,
  faFileExcel,
  faFileImage
} from '@fortawesome/free-regular-svg-icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

const muiStyles = (theme) => ({
  btRoot: {
    color: '#3A404D',
    textTransform: 'none',
    padding: '0.8rem 2.4rem 0.8rem 1.6rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
    borderRadius: '2.2rem',
    marginRight: '1.6rem',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.19)',
    border: '1px solid #13AE57',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#f2f2f2',
      boxShadow: 'inset 0rem 0.1rem 0.3rem 0rem rgba(0,0,0,0.19)'
    }
  },
  btSelected: {
    boxShadow: 'inset 0rem 0.1rem 0.3rem 0rem rgba(0,0,0,0.19)',
    backgroundColor: '#f2f2f2'
  },
  btText: {
    //marginRight: '1rem',
    //justifyContent: 'flex-start',
    maxWidth: '11rem',
    overflowX: 'hidden',
    whiteSpace: 'nowrap'
  },
  box: {
    boxShadow: '2px 4px 14px 0px rgba(178,178,189,0.33)',
    borderRadius: '13px',
    padding: '0rem 0rem',
    position: 'absolute',
    right: 10,
    backgroundColor: '#fff',
    marginTop: '0.2rem',
    minWidth: '19rem'
  },
  menuListItem: {
    padding: '0.4rem 1.6rem',
    //marginBottom: '1rem',
    width: 'fit-content',
    fontSize: '1.3rem',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#13AE57'
    },
    '&&selected': {
      backgroundColor: 'transparent'
    }
  },
  menuListItemSelected: {
    backgroundColor: 'transparent !important'
  },
  menuListItemIcon: {
    fontSize: '1.5rem',
    minWidth: '2.6rem'
  },
  menuListItemIconSelected: {
    fontSize: '1.7rem',
    minWidth: '4.3rem'
  },
  menuListItemText: {
    fontSize: '1.3rem'
  }
});

class DownloadList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  handleSelect(event, selectedIndex) {
    event.stopPropagation();
    this.securSetState({
      open: false
    });
  }
  handleClickAway(event) {
    this.securSetState({
      open: false
    });
  }
  handleToggle(event) {
    event.stopPropagation();
    this.securSetState({
      open: !this.state.open
    });
  }

  render() {
    const { classes } = this.props;

    const listItems = [
      { id: 'csv', icon: faFileAlt, label: 'Download Data .CSV' },
      { id: 'xlsx', icon: faFileExcel, label: 'Download Data .XLSX' },
      { id: 'image', icon: faFileImage, label: 'Download Image' }
    ];

    return (
      <ClickAwayListener
        onClickAway={(event) => {
          this.handleClickAway(event);
        }}
      >
        <div>
          <Button
            variant='outlined'
            type='button'
            disableElevation
            disableRipple
            size='small'
            classes={{
              root: clsx(classes.btRoot, {
                [classes.btSelected]: this.state.open
              }),
              label: classes.btText
            }}
            onClick={(event) => {
              this.handleToggle(event);
            }}
          >
            <FontAwesomeIcon
              icon={faDownload}
              style={{ marginRight: '1rem' }}
            />
            Download
          </Button>
          {this.state.open && (
            <Box className={classes.box}>
              <List component='nav' aria-label='main mailbox folders'>
                {listItems.map((item, index) => {
                  return (
                    <div key={index}>
                      <ListItem
                        button
                        disableRipple
                        disableGutters
                        classes={{
                          root: classes.menuListItem,
                          selected: classes.menuListItemSelected
                        }}
                        onClick={(event) => {
                          this.handleSelect(event, index);
                        }}
                      >
                        <ListItemIcon
                          classes={{ root: classes.menuListItemIcon }}
                        >
                          <FontAwesomeIcon icon={item.icon} />
                        </ListItemIcon>
                        <ListItemText
                          primary={item.label}
                          classes={{ primary: classes.menuListItemText }}
                        />
                      </ListItem>
                      {index < listItems.length - 1 && (
                        <Divider style={{ marginLeft: '4rem', opacity: 0.5 }} />
                      )}
                    </div>
                  );
                })}
              </List>
            </Box>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

export default withTranslation('monitoring')(
  withStyles(muiStyles)(DownloadList)
);
