import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AppContext, { AppProvider } from 'deep/contexts/AppProvider';
import { FiltersProvider } from './contexts/FiltersProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from 'utils/Routes';
import Wrapper from './../components/Wrapper';
import Filters from './components/Filters';

class AppMonitoringNotifications extends Component {
  render() {
    return (
      <AppProvider>
        <AppContext.Consumer>
          {(appContext) => (
            <MuiThemeProvider theme={appContext.theme}>
              <CssBaseline />
              <FiltersProvider>
                <Wrapper me={appContext.me}>
                  <Filters />
                  <Routes />
                </Wrapper>
              </FiltersProvider>
            </MuiThemeProvider>
          )}
        </AppContext.Consumer>
      </AppProvider>
    );
  }
}

export default AppMonitoringNotifications;
