import { BadgesSrc, LevelsSrc } from 'demo/ImagesSrc';

function formatTrans(trans) {
  if (trans) {
    let labels = {};
    trans.forEach((lbl) => {
      labels[lbl.lang] = lbl.text;
    });
    return labels;
  } else {
    return null;
  }
}

export function badge(item) {
  let dataTranslated = {
    ...item,
    name_trans: formatTrans(item.name_trans?.items),
    description_trans: formatTrans(item.description_trans?.items)
  };
  if (
    item &&
    item.image &&
    (item.image.indexOf('localhost') > 0 || item.image.indexOf('http') < 0)
  ) {
    let file = item.image;
    let splits = file.split('/');
    const fileFullName = splits[splits.length - 1];
    splits = fileFullName.split('.');
    const fileExt = splits[splits.length - 1];
    const fileName = splits[0];
    splits = fileName.split('_');
    const fileSuf = splits[splits.length - 1];
    const fileRealName = fileName.replace('_' + fileSuf, '') + '.' + fileExt;
    const filePath = '/uploads/assets/badges/' + fileRealName;
    dataTranslated.image = BadgesSrc[filePath];
  }

  return dataTranslated;
}

export function level(item) {
  const dataTranslated = {
    ...item,
    name_trans: formatTrans(item.name_trans?.items),
    description_trans: formatTrans(item.description_trans?.items)
  };
  if (
    item &&
    item.image &&
    (item.image.indexOf('localhost') > 0 || item.image.indexOf('http') < 0)
  ) {
    let file = item.image;
    let splits = file.split('/');
    const fileFullName = splits[splits.length - 1];
    splits = fileFullName.split('.');
    const fileExt = splits[splits.length - 1];
    const fileName = splits[0];
    splits = fileName.split('_');
    const fileSuf = splits[splits.length - 1];
    const fileRealName = fileName.replace('_' + fileSuf, '') + '.' + fileExt;
    const filePath = '/uploads/assets/levels/' + fileRealName;
    dataTranslated.image = LevelsSrc[filePath];
  }
  return dataTranslated;
}

export function badges(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(badge(item));
  });
  return dataTranslated;
}

export function levels(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(level(item));
  });
  return dataTranslated;
}
