import { postRequest, getRequest, patchRequest, deleteRequest } from './utils';
import * as MwHistory from 'utils/MwHistory';
import * as FormatData from './formatdata';
import * as FormatTrans from './formatTransModels';

export function okta_login(access_token) {
  const endpoint = 'cco/okta/login';

  return new Promise(function (resolve, reject) {
    const data = {
      access_token: access_token
    };
    
    postRequest(endpoint, data, false)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 401:
          case 404:
            reject('okta_login.err-signin-failed');
            break;
          default:
            reject('error.server');
            break;
        }
      });
  });
}

export function login(email, password) {
  const endpoint = 'user/login';

  return new Promise(function (resolve, reject) {
    const data = {
      username: email,
      password: password
    };

    postRequest(endpoint, data, false)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 401:
          case 404:
            reject('login.err-signin-failed');
            break;
          default:
            reject('error.server');
            break;
        }
      });
  });
}

// TODO: logout function

export function getMe() {
  const endpoint = 'cco/persona/me';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        resolve(FormatTrans.persona(result));
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
          case 404:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getPersona(personaId) {
  const endpoint = 'cco/persona/' + personaId;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const res = FormatTrans.persona(result);
        resolve(res);
      })
      .catch((error) => {
        switch (error.status) {
          case 404:
            reject('error.persona-404');
            break;
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getPersonas() {
  const endpoint = 'cco/persona';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.personas(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject(error.status);
            MwHistory.replace('/404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getChannels() {
  const endpoint = 'cco/channel';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.channels(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getPersonaTypes() {
  const endpoint = 'cco/personatype';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.personaTypes(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function createUser(email, firstname, lastname, password) {
  const endpoint = 'user';

  return new Promise(function (resolve, reject) {
    const data = {
      email: email,
      username: email,
      first_name: firstname,
      last_name: lastname,
      password: password
    };

    postRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 400:
            if (error.message.hasOwnProperty('email')) {
              reject('error.user-400-email');
            } else {
              reject('error.user-400-generic');
            }
            break;
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function deleteUser(userId) {
  const endpoint = 'user/' + userId;

  return new Promise(function (resolve, reject) {
    deleteRequest(endpoint)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function createPersona(userId, typeId, channelId, nickname) {
  const endpoint = 'cco/persona';

  return new Promise(function (resolve, reject) {
    const data = {
      user: userId,
      type: typeId,
      channel: channelId,
      nickname: nickname.trim()
    };

    postRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 400:
            reject('error.user-400-generic');
            break;
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function patchPersona(personaId, typeId, channelId, nickname) {
  const endpoint = 'cco/persona/' + personaId;

  return new Promise(function (resolve, reject) {
    const data = {
      type: typeId,
      channel: channelId,
      nickname: nickname
    };
    if (!typeId) delete data.type;
    if (!channelId) delete data.channel;
    if (!nickname) delete data.nickname;

    patchRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject('error.persona-404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function patchUser(userId, email, firstname, lastname) {
  const endpoint = 'user/' + userId;

  return new Promise(function (resolve, reject) {
    const data = {
      email: email,
      username: email,
      first_name: firstname,
      last_name: lastname
    };
    if (!email) {
      delete data.email;
      delete data.username;
    }
    if (!firstname) delete data.first_name;
    if (!lastname) delete data.last_name;

    patchRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 400:
            if (error.message.hasOwnProperty('email')) {
              reject('error.user-400-email');
            } else {
              reject('error.user-400-generic');
            }
            break;
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject('error.user-404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function setUserPassword(userId, password) {
  const endpoint = 'user/' + userId + '/chpass';

  return new Promise(function (resolve, reject) {
    const data = {
      new_password: password
    };

    postRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject('error.user-404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function patchUserAvatar(userId, avatarId) {
  //patch user avatar
  const endpoint = 'user/' + userId;

  return new Promise(function (resolve, reject) {
    const data = {
      avatar: avatarId
    };

    patchRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 400:
            reject('error.user-400-generic');
            break;
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject('error.user-404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function patchRemoveAvatar(userId) {
  //patch user avatar
  const endpoint = 'user/' + userId;

  return new Promise(function (resolve, reject) {
    const data = {
      avatar: null
    };

    patchRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 400:
            reject('error.user-400-generic');
            break;
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject('error.user-404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getAvatar(avatarId) {
  const endpoint = 'avatar/' + avatarId;

  return new Promise(function (resolve, reject) {
    if (!avatarId) {
      const res = FormatTrans.avatar(null);
      resolve(res);
      return;
    }

    getRequest(endpoint)
      .then((result) => {
        const res = FormatTrans.avatar(result);
        resolve(res);
      })
      .catch((error) => {
        switch (error.status) {
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getAvatars() {
  const endpoint = 'avatar';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.avatars(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}
