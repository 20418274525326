import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Resizable } from 're-resizable';

import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import DragHandleRoundedIcon from '@material-ui/icons/DragHandleRounded';

class ResizableCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      childrenRender: false,
      childrenReady: false,
      resized: false,
      expanded: this.props.expanded,
      innerContentHeight: null,
      refHeight: 0,
      minHeight: 73
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
  }
  componentDidUpdate() {
    if (this.state.childrenRender !== this.props.childrenRender) {
      this.securSetState({
        childrenRender: this.props.childrenRender,
        childrenReady: true
      });
      if (this.props.childrenRender) {
        this.setInnerContentHeight();
      }
    }
  }
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.myInterval);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  setInnerContentHeight() {
    const content_id = document.getElementById(this.props.contentId);
    const innerContentHeight = content_id.clientHeight;
    this.securSetState({ innerContentHeight: innerContentHeight });
  }

  toggle(event) {
    if (event) {
      event.preventDefault();
    }
    clearInterval(this.myInterval);

    if (this.state.resized) {
      if (this.state.expanded) {
        // Collapsing
        let height = this.state.refHeight;
        const end = this.state.minHeight;
        this.myInterval = setInterval(() => {
          this.resizable.updateSize({ width: '100%', height: height });
          height = height - 5;
          if (height <= end) {
            this.resizable.updateSize({ width: '100%', height: end });
            clearInterval(this.myInterval);
          }
        }, 5);
      } else {
        // Expanding
        let height = this.state.minHeight;
        let end = this.state.minHeight + this.state.innerContentHeight;
        this.myInterval = setInterval(() => {
          this.resizable.updateSize({ width: '100%', height: height });
          height = height + 5;
          if (height >= end) {
            this.resizable.updateSize({ width: '100%', height: end });
            clearInterval(this.myInterval);
          }
        }, 5);
      }
    }

    this.securSetState({ expanded: !this.state.expanded });
  }

  onStopDrag(refHeight) {
    this.securSetState({
      refHeight: refHeight,
      resized: true
    });
    if (this.props.collapsable && refHeight === this.state.minHeight) {
      this.toggle(null);
    }
  }

  render() {
    const styles = {
      resizable: {
        //background: "#f0f0f0",
        marginBottom: '1rem',
        position: 'relative'
      },
      box: {
        background: '#fff',
        //background: "red",
        height: 'calc(100% - 6px)',
        boxShadow: '0px 0px 10px -7px rgba(0,0,0,0.5)',
        borderRadius: 6,
        padding: '1.6rem 2.4rem',
        overflow: 'hidden'
      },
      content: {
        //backgroundColor: 'orange',
        //paddingTop: this.props.featureTitle ? '2rem' : '0rem'
        paddingTop: '2rem',
        paddingBottom: '2rem'
      },
      spacer: {
        height: 6
        //background: "yellow"
      },
      drag: {
        opacity: '0.5',
        textAlign: 'center',
        width: '50%',
        height: '0.9rem',
        position: 'absolute',
        bottom: '0.2rem',
        left: '50%',
        transform: 'TranslateX(-50%)'
      },
      btTitle: {
        //backgroundColor: 'lime',
        color: '#4C4C4C',
        fontWeight: 600,
        fontSize: '1.8rem',
        padding: '0px',
        textTransform: 'none',
        backgroundColor: 'transparent',
        textAlign: 'left'
      },
      arrows: {
        fontSize: '2.6rem'
      }
    };

    const resizeEnability = {
      top: false,
      right: false,
      bottom:
        this.state.expanded && this.props.resizable && this.state.childrenReady,
      left: false,
      topRight: false,
      bottomRight: false,
      bottomLeft: false,
      topLeft: false
    };

    const ButtonStyled = withStyles((theme) => ({
      label: {
        justifyContent: 'space-between'
      }
    }))(Button);

    return (
      <Resizable
        style={styles.resizable}
        defaultSize={{
          width: '100%',
          height: 'inherit'
        }}
        minWidth='100%'
        minHeight={this.state.minHeight}
        maxHeight={this.state.innerContentHeight + 150}
        enable={resizeEnability}
        ref={(c) => {
          this.resizable = c;
        }}
        onResizeStop={(e, direction, ref, d) => {
          this.onStopDrag(ref.clientHeight);
        }}
      >
        {this.props.outer}
        <div style={styles.box}>
          {this.props.featureTitle && (
            <ButtonStyled
              variant='text'
              disableElevation
              disableRipple
              fullWidth
              endIcon={
                !this.props.collapsable || !this.state.childrenReady ? (
                  ''
                ) : this.state.expanded ? (
                  <ExpandMoreIcon style={styles.arrows} />
                ) : (
                  <ChevronRightIcon style={styles.arrows} />
                )
              }
              onClick={(event) => {
                this.toggle(event);
              }}
              style={styles.btTitle}
              disabled={!this.props.collapsable}
            >
              {this.props.featureTitle}&nbsp;
            </ButtonStyled>
          )}
          <Collapse
            in={
              this.state.childrenReady &&
              (this.state.expanded || this.state.resized)
            }
          >
            <div id={this.props.contentId} style={styles.content}>
              {this.props.children}
            </div>
          </Collapse>
        </div>
        <div style={styles.spacer} />
        {this.state.childrenReady &&
          this.state.expanded &&
          this.props.resizable && (
            <div style={styles.drag}>
              <DragHandleRoundedIcon />
            </div>
          )}
      </Resizable>
    );
  }
}

export default withTranslation('cco')(ResizableCard);
