import { getRequest, postRequest, deleteRequest } from 'deep/api/utils';
import * as FormatData from './formatdata';
import * as DeepFormatData from 'deep/api/formatdata';
import * as MwHistory from 'utils/MwHistory';

export function getBadge(code) {
  const endpoint = 'gamification/badge?code=' + code;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        if (result.count < 1) {
          resolve(null);
        } else {
          const output = FormatData.badge(result.results[0]);
          resolve(output);
        }
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject(error.status);
            MwHistory.replace('/404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getBadges() {
  const endpoint = 'gamification/badge?active=true';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.badges(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject(error.status);
            MwHistory.replace('/404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getWonBadges(userId) {
  const endpoint =
    'gamification/badge/user_badges?user=' + userId + '&active=true';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.badges(result);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject(error.status);
            MwHistory.replace('/404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getBadgeWinners(badgeId) {
  const endpoint = 'gamification/badge/' + badgeId + '/winners';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = DeepFormatData.users(result.results);

        const output = {
          count: result.count,
          results: results
        };
        resolve(output);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject(error.status);
            MwHistory.replace('/404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getMyKeyInfo() {
  const endpoint = 'gamification/event/myrank';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        let finalResponse = {
          current_level: FormatData.level(result.current_level),
          next_level: FormatData.level(result.next_level),
          points: result.points,
          rank: result.rank,
          badges: result.badges_count
        };
        resolve(finalResponse);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
          case 404:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getLevels() {
  const endpoint = 'gamification/level?ordering=order&active=true';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.levels(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject(error.status);
            MwHistory.replace('/404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getRanking(levelId, limit = 5) {
  const endpoint =
    'gamification/event/ranks?limit=' + limit + '&offset=0&level=' + levelId;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = DeepFormatData.users(result);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          case 404:
            reject(error.status);
            MwHistory.replace('/404');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function postBadge(badge) {
  const endpoint = 'gamification/event';

  return new Promise(function (resolve, reject) {
    const data = {
      badge: badge.id,
      points: badge.points
    };

    postRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 401:
          case 404:
            reject('login.err-signin-failed');
            break;
          default:
            reject('error.server');
            break;
        }
      });
  });
}

export function getEvents(userId, limit = 100) {
  const endpoint =
    'gamification/event?user=' + userId + '&ordering=-date&limit=' + limit;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        resolve(result.results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
          case 404:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function resetEvents(userId) {
  const endpoint = 'gamification/event/reset?user=' + userId;

  return new Promise(function (resolve, reject) {
    deleteRequest(endpoint)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}
