import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BaseAutocomplete from '@material-ui/lab/Autocomplete';

export default function Autocomplete(props) {
  const change = (event, value) => {
    event.stopPropagation();
    event.preventDefault();
    props.onChange(event, value);
  };
  const StyledAutocomplete = withStyles((theme) => ({
    inputRoot: {
      borderRadius: '10em'
    }
    //
  }))(BaseAutocomplete);

  return <StyledAutocomplete {...props} onChange={change} />;
}
