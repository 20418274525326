import React, { Component } from 'react';
import * as Api from './../api/endpoints';
import moment from 'moment';

export const FILTERS_TIMELINE = {
  WEEK: { id: 'week', label: 'Last 7 Days', interval: 86400 },
  MONTH: { id: 'month', label: 'Last 30 Days', interval: 604800 },
  WEEKTODATE: { id: 'weektodate', label: 'Week to Date', interval: 86400 },
  MONTHTODATE: { id: 'monthtodate', label: 'Month to Date', interval: 604800 },
  YEARTODATE: { id: 'yeartodate', label: 'Year to Date', interval: 2728000 }
};
export const FILTERS_KPIS = [
  { id: 'logins', label: '# Logins' },
  { id: 'success', label: 'Successful actions' },
  { id: 'rate', label: 'Success rate' },
  { id: 'actions', label: 'Actions on tool' },
  { id: 'comparison', label: 'Comparison with average' },
  { id: 'average', label: 'Average Handling time' }
];
export const FILTERS_STATUS = {
  ALL: { id: 'all', label: 'All Status' },
  UPCOMING: { id: 'upcoming', label: 'Upcoming' },
  ONGOING: { id: 'ongoing', label: 'Ongoing' },
  ARCHIVES: { id: 'archives', label: 'Archives' }
};

const FiltersContext = React.createContext();

class FiltersProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outcomeFilter: null,
      outcomes: null,
      channelFilter: null,
      channels: null,
      multiChannelsFilter: null,
      multiProductsFilter: null,
      singleProductFilter: null,
      products: null,
      multiCampaignTypesFilter: null,
      singleCampaignTypeFilter: null,
      campaignTypes: null,
      timelineFilter: null,
      kpisFilter: null,
      statusFilter: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.loadOutcomes();
    this.loadChannels();
    this.loadProducts();
    this.loadCampaignTypes();
    this.setTimelineFilter(FILTERS_TIMELINE.WEEK);
    this.setKpisFilter([
      FILTERS_KPIS[0].id,
      FILTERS_KPIS[1].id,
      FILTERS_KPIS[2].id,
      FILTERS_KPIS[3].id
    ]);
    this.setStatusFilter(FILTERS_STATUS.ALL);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  async loadOutcomes() {
    const result = await Api.getOutcomes();
    this.formatOutcomes(result);
  }

  formatOutcomes(outcomes) {
    let formattedOutcomes = [];
    outcomes.forEach((outcome) => {
      formattedOutcomes.push({
        id: outcome.id,
        label: outcome.name_trans['en'],
        code: outcome.code
      });
    });
    this.securSetState({
      outcomes: formattedOutcomes
    });
    this.setOutcomeFilter(formattedOutcomes[0]);
  }

  async loadChannels() {
    const result = await Api.getChannels();
    this.formatChannels(result);
  }

  formatChannels(channels) {
    const formattedChannels = channels.map((channel) => ({
      id: channel.id,
      label: channel.name_trans['en'],
      parent: channel?.parent?.name_trans['en']
    }));

    this.securSetState({
      channels: formattedChannels
    });
    this.setChannelFilter(formattedChannels[0]);
    this.setMultiChannelsFilter(formattedChannels);
  }

  async loadProducts() {
    const result = await Api.getProducts();
    this.formatProducts(result);
  }

  formatProducts(products) {
    let formattedProducts = products.map((product) => ({
      id: product.id,
      label: product.name_trans['en']
    }));
    let multiProductsFilter = formattedProducts.map((p) => p.id);
    this.securSetState({
      products: formattedProducts
    });
    this.setMultiProductsFilter(multiProductsFilter);
    this.setSingleProductFilter(formattedProducts[0]);
  }

  async loadCampaignTypes() {
    const result = await Api.getCampaignTypes();
    this.formatCampaignTypes(result);
  }

  formatCampaignTypes(campaignTypes) {
    let formattedCampaignTypes = [];
    let multiCampaignTypesFilter = [];
    campaignTypes.forEach((campaignType) => {
      formattedCampaignTypes.push({
        id: campaignType.id,
        label: campaignType.name_trans['en']
      });
      multiCampaignTypesFilter.push(campaignType.id);
    });
    this.securSetState({
      campaignTypes: formattedCampaignTypes
    });
    this.setMultiCampaignTypesFilter(multiCampaignTypesFilter);
    this.setSingleCampaignTypeFilter(formattedCampaignTypes[0]);
  }

  setOutcomeFilter = (filter) => {
    this.securSetState({ outcomeFilter: filter });
  };

  setMultiChannelsFilter = (filter) => {
    this.securSetState({ multiChannelsFilter: filter });
  };
  setChannelFilter = (filter) => {
    this.securSetState({ channelFilter: filter });
  };
  setMultiProductsFilter = (products) => {
    this.securSetState({ multiProductsFilter: products });
  };
  setSingleProductFilter = (filter) => {
    this.securSetState({ singleProductFilter: filter });
  };
  setMultiCampaignTypesFilter = (campaignTypes) => {
    this.securSetState({ multiCampaignTypesFilter: campaignTypes });
  };
  setSingleCampaignTypeFilter = (filter) => {
    this.securSetState({ singleCampaignTypeFilter: filter });
  };
  setTimelineFilter = (filterBase) => {
    let filter = {
      id: filterBase.id,
      label: filterBase.label,
      interval: filterBase.interval,
      begin: null,
      end: moment().add(1, 'days').startOf('day')
    };
    switch (filterBase.id) {
      case FILTERS_TIMELINE.WEEK.id:
        filter.begin = moment().subtract(6, 'days').startOf('day');
        break;
      case FILTERS_TIMELINE.MONTH.id:
        filter.begin = moment().subtract(1, 'months').startOf('day');
        break;
      case FILTERS_TIMELINE.WEEKTODATE.id:
        filter.begin = moment()
          .subtract(moment().format('d') - 1, 'days')
          .startOf('day');
        break;
      case FILTERS_TIMELINE.MONTHTODATE.id:
        filter.begin = moment()
          .subtract(moment().format('DD') - 1, 'days')
          .startOf('day');
        break;
      case FILTERS_TIMELINE.YEARTODATE.id:
        filter.begin = moment().startOf('year');
        break;
      default:
        filter.begin = moment().subtract(6, 'days').startOf('day');
        break;
    }
    this.securSetState({ timelineFilter: filter });
  };
  setKpisFilter = (kpis) => {
    this.securSetState({ kpisFilter: kpis });
  };
  setStatusFilter = (status) => {
    this.securSetState({ statusFilter: status });
  };
  render() {
    const { children } = this.props;
    const {
      outcomeFilter,
      outcomes,
      channelFilter,
      channels,
      multiChannelsFilter,
      multiProductsFilter,
      singleProductFilter,
      products,
      multiCampaignTypesFilter,
      singleCampaignTypeFilter,
      campaignTypes,
      timelineFilter,
      kpisFilter,
      statusFilter
    } = this.state;

    const {
      setOutcomeFilter,
      setChannelFilter,
      setMultiChannelsFilter,
      setMultiProductsFilter,
      setSingleProductFilter,
      setMultiCampaignTypesFilter,
      setSingleCampaignTypeFilter,
      setTimelineFilter,
      setKpisFilter,
      setStatusFilter
    } = this;

    return (
      <FiltersContext.Provider
        value={{
          outcomes,
          outcomeFilter,
          setOutcomeFilter,
          channels,
          channelFilter,
          setChannelFilter,
          multiChannelsFilter,
          setMultiChannelsFilter,
          products,
          multiProductsFilter,
          setMultiProductsFilter,
          singleProductFilter,
          setSingleProductFilter,
          campaignTypes,
          multiCampaignTypesFilter,
          setMultiCampaignTypesFilter,
          singleCampaignTypeFilter,
          setSingleCampaignTypeFilter,
          timelineFilter,
          setTimelineFilter,
          kpisFilter,
          setKpisFilter,
          statusFilter,
          setStatusFilter
        }}
      >
        {children}
      </FiltersContext.Provider>
    );
  }
}

export default FiltersContext;

export { FiltersProvider };
