import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import SimpleTabsMenu from 'deep/components/materials/SimpleTabsMenu';
import SmallDataTable from 'deep/components/materials/SmallDataTable';
import CallSessionContext from './../../contexts/CallSessionProvider';

class Comparison extends Component {
  static contextType = CallSessionContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0
    };
    this.onChangeTab = this.onChangeTab.bind(this);
  }

  componentDidUpdate() {
    if (
      !this.rendered &&
      this.context.selectedOpportunity &&
      this.context.features
    ) {
      this.rendered = true;
      this.props.onRender(true);
    }
  }

  onChangeTab = (event, value) => {
    event.preventDefault();
    this.setState(() => ({ selectedTab: value }));
  };

  render() {
    const { selectedOpportunity, features, clientSettings } = this.context;

    let currentMonthlyPrice = 0;
    let currentAnnualPrice = 0;
    let proposedMonthlyPrice = 0;
    let proposedAnnualPrice = 0;
    let savings = 0;

    //TODO
    //DATA to Display to be re-discussed

    if (selectedOpportunity && selectedOpportunity.product) {
      const offer = selectedOpportunity.offer;
      const product = selectedOpportunity.product;
      currentMonthlyPrice = product.price;
      currentAnnualPrice = currentMonthlyPrice * 12;
      const reduction = offer.discount ? offer.discount.value : 0;
      proposedMonthlyPrice =
        currentMonthlyPrice - currentMonthlyPrice * reduction;
      proposedAnnualPrice = proposedMonthlyPrice * 12;
      savings = currentAnnualPrice - proposedAnnualPrice;
    }

    const tabs = [
      {
        id: 1,
        name: this.props.t('session.comparison.tab-price'),
        value: 'price'
      },
      {
        id: 2,
        name: this.props.t('session.comparison.tab-features'),
        value: 'features'
      }
    ];

    const colsPrice = [
      { id: 'x1', numeric: false, label: '' },
      {
        id: 'x2',
        numeric: true,
        label: this.props.t('session.comparison.lbl-standard')
      },
      {
        id: 'x3',
        numeric: true,
        label: this.props.t('session.comparison.lbl-proposed')
      }
    ];

    function createDataPrice(id, x1, x2, x3) {
      return { id, x1, x2, x3 };
    }

    const prices = [
      createDataPrice(
        'xx1',
        this.props.t('session.comparison.lbl-monthly-price'),
        clientSettings.currency + currentMonthlyPrice.toFixed(2),
        clientSettings.currency + proposedMonthlyPrice.toFixed(2)
      ),
      createDataPrice(
        'xx2',
        this.props.t('session.comparison.lbl-annual-price'),
        clientSettings.currency + currentAnnualPrice.toFixed(2),
        clientSettings.currency + proposedAnnualPrice.toFixed(2)
      ),
      createDataPrice(
        'xx5',
        this.props.t('session.comparison.lbl-total-savings'),
        '-',
        clientSettings.currency + savings.toFixed(2)
      )
    ];

    const colsFeature = [
      { id: 'x1', numeric: false, label: '' },
      {
        id: 'x2',
        numeric: true,
        label: this.props.t('session.comparison.lbl-standard')
      },
      {
        id: 'x3',
        numeric: true,
        label: this.props.t('session.comparison.lbl-proposed')
      }
    ];

    function createDataFeature(id, x1, x2, x3) {
      return { id, x1, x2, x3 };
    }

    let dataFeatures = [];
    if (features) {
      features.forEach((item) => {
        dataFeatures.push(
          createDataFeature(
            'xx1',
            item.name_trans[i18next.language] ||
              item.name_trans[i18next.options.fallbackLng[0]],
            this.props.t('session.comparison.val-yes'),
            this.props.t('session.comparison.val-yes')
          )
        );
      });
    }

    return (
      <div>
        <SimpleTabsMenu
          tabs={tabs}
          defaultValue={0}
          onChange={(event, value) => this.onChangeTab(event, value)}
        />
        <div>
          {this.state.selectedTab === 0 && (
            <SmallDataTable
              data={prices}
              columns={colsPrice}
              highlight={false}
            />
          )}
          {this.state.selectedTab === 1 && features && features.length > 0 && (
            <SmallDataTable
              data={dataFeatures}
              columns={colsFeature}
              highlight={false}
            />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('cco')(Comparison);
