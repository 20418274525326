import React, { Component } from 'react';
import History from 'utils/History';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

import LanguagesMenu from './LanguagesMenu';
import MainMenu from './MainMenu';
import AppContext from 'deep/contexts/AppProvider';

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0
  });
}

class Header extends Component {
  static contextType = AppContext;

  componentDidMount() {
    History.listen((location) => {
      this.forceUpdate();
    });
  }

  render() {
    const {
      me,
      clientSettings,
      theme,
      gamificationActive,
      notificationActive
    } = this.context;

    const pathname = History.location.pathname;
    const isHome = pathname.slice(-4) === '/cco';

    return (
      <React.Fragment>
        <CssBaseline />
        <ElevationScroll {...this.props}>
          <AppBar style={{ background: '#fff', marginTop: 3, opacity: '1' }}>
            <Toolbar
              variant='dense'
              style={{ paddingLeft: 18, paddingRight: 20 }}
            >
              <MainMenu
                theme={theme}
                clientSettings={clientSettings}
                meUser={me.user}
                meType={me.type}
                gamificationActive={gamificationActive}
              />
              <div
                style={{
                  flexGrow: '1',
                  flexAlign: 'center',
                  textAlign: 'left'
                }}
              ></div>
              <LanguagesMenu
                customLanguages={clientSettings.languages}
                theme={theme}
                clientSettings={clientSettings}
              />
              <img
                src={clientSettings.logo}
                alt='Logo'
                style={{
                  height: '2.5rem',
                  paddingTop: '0.4rem',
                  paddingLeft: '1.5rem',
                  opacity: notificationActive ? 0 : 1,
                  width: notificationActive
                    ? isHome
                      ? '7rem'
                      : '6rem'
                    : 'auto'
                }}
              />
            </Toolbar>
          </AppBar>
        </ElevationScroll>
      </React.Fragment>
    );
  }
}

export default Header;
