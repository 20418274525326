import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import i18next from 'i18next';

import Divider from '@material-ui/core/Divider';
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ButtonStyled = withStyles((theme) => ({
  label: {
      justifyContent: 'flex-start'
  },
  text: {
      '&:hover': {
      color: theme.palette.primary.main
      }
  }
  }))(Button);


  const formatLang = (transItem) => {
    if (!transItem){
      return
    }
  
    return transItem[i18next.language] || transItem[i18next.options.fallbackLng[0]]
  }

const  OfferList = ({ 
  offerScripts,
  setSelectedOffer,
  selectedOffer,
  t
  
}) => {
  

  const [isExpanded, setIsExpanded] = useState(false)

  const handleClickAway = () => {
    setIsExpanded(false);
  }  

  const toggle = () => {
    if (offerScripts.length > 1) {
      setIsExpanded(!isExpanded);
    }
  }

  const handleSelect = (offerIndex) => {

    toggle();
    setSelectedOffer(offerIndex)

  }
  
  const getStyle = () => ({
    wrapper: {
      zIndex: isExpanded ? 15 : 14,
      border: "1px solid #bcbcbc",
      borderRadius: "1.8rem",
      width: "100%",
    },
    paper: {
      borderRadius: "1.8rem",
      padding: "0rem 1rem 0rem",
    },
    selectedItem: {
      position: "relative",
      cursor:  offerScripts && offerScripts.length > 1 ? "pointer" : "default",
      padding: "0.7rem 0rem 1.7rem 0rem",
      picto: {
        position: "absolute",
        top: "40%",
        transform: "translateY(-50%)",
      },
      label: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      arrow: {
        position: "absolute",
        fontSize: "2rem",
        top: "0.8rem",
        right: "-0.3rem",
      },
    },
    divider: {
      marginBottom: "1rem",
    },
    subItemBt: {
      zIndex: 11,
      textTransform: "none",
      textAlign: "left",
      paddingLeft: "1.6rem",
      lineHeight: "1",
      minHeight: "3.7rem",
      backgroundColor: "transparent",
      picto: {
        minWidth: "2rem",
      },
    },
  });

const styles = getStyle()
return (
    <div>
    <ClickAwayListener onClickAway={() => handleClickAway()}>
    <div style={{ width: "100%", position: "relative" }}>
      <div style={styles.wrapper}>

        <Paper elevation={isExpanded ? 4 : 0} style={styles.paper}>
        <Collapse in={isExpanded} collapsedHeight="3.7rem">
            <div style={styles.selectedItem} onClick={e => toggle()}>
            <div style={styles.selectedItem.label}>
                {formatLang(offerScripts[selectedOffer] && offerScripts[selectedOffer].offer.sub_type_trans)}
            </div>
            { offerScripts && offerScripts.length > 1 && <ExpandMoreIcon style={styles.selectedItem.arrow} />}
            </div>
              <Divider style={styles.divider} />
              {offerScripts && offerScripts.map((offer, offerIndex) => {

                    return offerIndex !== selectedOffer ? (
                        <div key={offerIndex}>
                          <ButtonStyled
                              style={styles.subItemBt}
                              variant='text'
                              disableElevation
                              disableRipple
                              onClick={() => handleSelect(offerIndex)}
                          >
                              {formatLang(offer.offer.sub_type_trans)}
                          </ButtonStyled>
                        </div>
                    ) : (
                        <div key={offerIndex}></div>
                    )
                })
             }
            <div style={{ height: "1.5rem" }}></div>
        </Collapse>
        </Paper>
      </div>
    </div>
  </ClickAwayListener>
  </div>
);
}

export default withTranslation("cco")(OfferList);
