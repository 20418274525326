import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallSessionContext from './../../contexts/CallSessionProvider';

import { ReactComponent as PictoXSelling } from 'assets/images/campaign_xselling.svg';
import { ReactComponent as PictoRetention } from 'assets/images/campaign_retention.svg';
import { ReactComponent as PictoRenewal } from 'assets/images/campaign_renewal.svg';
import { ReactComponent as PictoCollection } from 'assets/images/campaign_collection.svg';
import { ReactComponent as PictoAcquisition } from 'assets/images/campaign_acquisition.svg';

class CampaignsList extends Component {
  static contextType = CallSessionContext;

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      selectedIndex: 0
    };
    this.toggle = this.toggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
  }

  colorizePicto(id) {
    const picto = document.getElementById(id);
    if (picto && this.context.clientSettings.colors.primary) {
      picto.setAttribute(
        'fill',
        // TODO: should get color from theme rather than context
        this.context.clientSettings.colors.primary.main
      );
    }
  }

  componentDidUpdate() {
    this.colorizePicto('svg-churn');
    this.colorizePicto('svg-cross-selling-1');
    this.colorizePicto('svg-cross-selling-2');
    this.colorizePicto('svg-renewal');
    this.colorizePicto('svg-debt-collection');
    this.colorizePicto('svg-acquisition');
  }

  toggle(e) {
    if (this.context.campaigns.length > 1) {
      this.setState(() => ({ isExpanded: !this.state.isExpanded }));
    }
  }
  handleSelect(event, index) {
    this.toggle(event);
    this.context.setSelectedCampaign(this.context.campaigns[index], this.context.contractAccountId);
    this.setState(() => ({ selectedIndex: index }));
  }

  handleClickAway(e) {
    this.setState(() => ({ isExpanded: false }));
  }

  formatCampaigns(data) {
    if (!data) {
      return null;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      dataFormatted.push({
        id: item.id,
        creation_date: item.creation_date,
        expiration_date: item.expiration_date,
        name:
          item.name_trans[i18next.language] ||
          item.name_trans[i18next.options.fallbackLng[0]],
        type_id: item.type.id,
        type_name:
          item.type.name_trans[i18next.language] ||
          item.type.name_trans[i18next.options.fallbackLng[0]],
        product: item.product
      });
    });

    return dataFormatted;
  }

  render() {
    const campaigns = this.formatCampaigns(this.context.campaigns);
    const { isExpanded, selectedIndex } = this.state;

    const getPicto = (slug, style) => {
      switch (slug) {
        case 1:
          //Retention
          return <PictoRetention style={style} />;
        case 2:
          //Collections
          return <PictoCollection style={style} />;
        case 3:
          //Cross selling
          return <PictoXSelling style={style} />;
        case 4:
          //Acquisitions
          return <PictoAcquisition style={style} />;
        case 5:
          //Smart pricing
          return <PictoRenewal style={style} />;
        default:
          return <></>;
      }
    };

    const styles = {
      wrapper: {
        position: 'absolute',
        zIndex: 30,
        top: '-0.4rem',
        border: '1px solid #bcbcbc',
        borderRadius: '1.8rem',
        width: '100%'
      },
      paper: {
        borderRadius: '1.8rem',
        padding: '0rem 1rem 0rem'
      },
      selectedItem: {
        position: 'relative',
        cursor: campaigns && campaigns.length > 1 ? 'pointer' : 'default',
        padding: '0.7rem 0rem 1.7rem 0rem',
        picto: {
          position: 'absolute',
          top: '40%',
          transform: 'translateY(-50%)'
        },
        label: {
          marginLeft: '2.6rem',
          marginRight: '2rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        arrow: {
          position: 'absolute',
          fontSize: '2rem',
          top: '0.8rem',
          right: '-0.3rem'
        }
      },
      divider: {
        marginBottom: '1rem'
      },
      subItemBt: {
        textTransform: 'none',
        textAlign: 'left',
        paddingLeft: '1.6rem',
        lineHeight: '1',
        minHeight: '3.7rem',
        backgroundColor: 'transparent',
        picto: {
          minWidth: '2rem'
        }
      }
    };

    const ButtonStyled = withStyles((theme) => ({
      label: {
        justifyContent: 'flex-start'
      },
      text: {
        '&:hover': {
          color: theme.palette.primary.main
        }
      }
    }))(Button);

    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div style={{ width: '100%', position: 'relative' }}>
          {campaigns && (
            <div style={styles.wrapper}>
              <Paper elevation={isExpanded ? 4 : 0} style={styles.paper}>
                <Collapse in={isExpanded} collapsedHeight='3.7rem'>
                  <div style={styles.selectedItem} onClick={this.toggle}>
                    {getPicto(
                      campaigns[selectedIndex].type_id,
                      styles.selectedItem.picto
                    )}
                    <div style={styles.selectedItem.label}>
                    {this.props.t('session.campaign.holdback')}
                    </div>
                    {campaigns.length > 1 && (
                      <ExpandMoreIcon style={styles.selectedItem.arrow} />
                    )}
                  </div>

                  <Divider style={styles.divider} />
                  {campaigns.map((item, index) =>
                    index !== selectedIndex ? (
                      <div key={index}>
                        <ButtonStyled
                          style={styles.subItemBt}
                          variant='text'
                          disableElevation
                          disableRipple
                          startIcon={getPicto(
                            item.type_id,
                            styles.subItemBt.picto
                          )}
                          onClick={(event) => this.handleSelect(event, index)}
                        >
                          n/a
                        </ButtonStyled>
                      </div>
                    ) : (
                      <div key={index}></div>
                    )
                  )}
                  <div style={{ height: '1.5rem' }}></div>
                </Collapse>
              </Paper>
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

export default withTranslation('cco')(CampaignsList);
