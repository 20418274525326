import React, { Component } from 'react';
import * as NotificationsApi from './../api/endpoints';
import { PUSH_SETTINGS } from './../utils/Constants';
import { settings } from './Settings';

const NotificationsContext = React.createContext();

class NotificationsProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pushActivated: true,
      pushSetting: PUSH_SETTINGS.DETAILED,
      pushTime: settings.push_time,
      categories: null,
      pushNotificationsIds: [],
      pushNotifications: [],
      notifications: null,
      notificationCenterOpen: false,
      unreadNotificationsCount: 0,
      keyPressFunction: null
    };
  }

  componentDidMount() {
    //DEMO ONLY : BEGIN
    const provider = this;
    function keyPressFunction(event) {
      event.stopPropagation();
      if (event.key === 'Control') {
        provider.handleKeyPress();
      }
    }
    this.setState({ keyPressFunction: keyPressFunction });
    document.addEventListener('keydown', keyPressFunction, true);
    //DEMO ONLY : END

    this.loadCategories();
    this.loadUnreadNotificationsCount();
    //this.openNotificationsCenter();
  }

  //DEMO ONLY : BEGIN
  componentWillUnmount() {
    const keyPressFunction = this.state.keyPressFunction;
    document.removeEventListener('keydown', keyPressFunction, true);
  }
  //DEMO ONLY : END

  //DEMO PURPOSE : BEGIN ////////////////////////////
  handleKeyPress = () => {
    if (this.state.notificationCenterOpen || !this.state.pushActivated) {
      this.addNotification();
      this.loadNotifications();
    } else {
      this.raisePushNotification();
    }
  };
  async addNotification() {
    await NotificationsApi.pushNotification();
  }
  async raisePushNotification() {
    const pushNotification = await NotificationsApi.pushNotification();
    let pushNotifications = this.state.pushNotifications;
    pushNotifications.push({ ...pushNotification });
    this.setState({ pushNotifications: pushNotifications });
  }
  handleExitedPushNotification = (notification) => {
    let pushNotifications = this.state.pushNotifications;
    for (let i = 0; i < pushNotifications.length; i++) {
      if (pushNotifications[i]?.id === notification.id) {
        pushNotifications[i] = null;
        break;
      }
    }
    let isPushNotification = false;
    for (let i = 0; i < pushNotifications.length; i++) {
      if (pushNotifications[i]) {
        isPushNotification = true;
        break;
      }
    }
    if (!isPushNotification) {
      pushNotifications = [];
    }
    this.setState({ pushNotifications: pushNotifications });
    this.loadUnreadNotificationsCount();
  };
  //DEMO PURPOSE : END ////////////////////////////

  async loadCategories() {
    this.setState({ categories: null });
    const categories = await NotificationsApi.getCategories();
    this.setState({ categories: categories });
  }
  async loadUnreadNotificationsCount() {
    const count = await NotificationsApi.getUnreadNotificationsCount();
    this.setState({ unreadNotificationsCount: count });
  }
  async loadNotifications() {
    //this.setState({ notifications: null });
    const notifications = await NotificationsApi.getNotifications();
    this.setState({ notifications: notifications });
    this.loadUnreadNotificationsCount();
  }
  async likeNotification(notificationId) {
    await NotificationsApi.likeNotification(notificationId);
    this.loadNotifications();
  }
  async unlikeNotification(notificationId) {
    await NotificationsApi.unlikeNotification(notificationId);
    this.loadNotifications();
  }
  async deleteNotificationsGroup(startDate, endDate) {
    const notifications = await NotificationsApi.deleteNotificationsGroup(
      startDate,
      endDate
    );
    this.setState({ notifications: notifications });
    this.loadUnreadNotificationsCount();
  }
  async markAllNotificationsRead() {
    await NotificationsApi.markAllRead();
    this.loadUnreadNotificationsCount();
  }

  changePushSetting = (setting) => {
    this.setState({ pushSetting: setting });
  };
  activatePush = () => {
    this.setState({ pushActivated: true });
  };
  deactivatePush = () => {
    this.setState({ pushActivated: false });
  };
  toggleNotificationsCenter = () => {
    if (this.state.notificationCenterOpen) {
      this.setState({ notificationCenterOpen: false });
    } else {
      this.openNotificationsCenter();
    }
  };
  openNotificationsCenter = () => {
    this.loadNotifications();
    this.setState({ notificationCenterOpen: true });
  };
  closeNotificationsCenter = () => {
    this.setState({ notificationCenterOpen: false });
  };
  like = (notification) => {
    this.likeNotification(notification.id);
  };
  unlike = (notification) => {
    this.unlikeNotification(notification.id);
  };
  deleteGroup = (startDate, endDate) => {
    this.deleteNotificationsGroup(startDate, endDate);
  };
  markAllRead = () => {
    this.markAllNotificationsRead();
  };

  render() {
    const { children } = this.props;
    const {
      pushActivated,
      pushSetting,
      pushTime,
      categories,
      pushNotifications,
      notificationCenterOpen,
      notifications,
      unreadNotificationsCount
    } = this.state;
    const {
      activatePush,
      deactivatePush,
      changePushSetting,
      handleExitedPushNotification,
      like,
      unlike,
      toggleNotificationsCenter,
      openNotificationsCenter,
      closeNotificationsCenter,
      deleteGroup,
      markAllRead,
      handleKeyPress
    } = this;

    return (
      <NotificationsContext.Provider
        value={{
          activatePush,
          deactivatePush,
          pushActivated,
          pushSetting,
          pushTime,
          changePushSetting,
          categories,
          pushNotifications,
          notificationCenterOpen,
          handleExitedPushNotification,
          like,
          unlike,
          toggleNotificationsCenter,
          openNotificationsCenter,
          closeNotificationsCenter,
          notifications,
          deleteGroup,
          markAllRead,
          unreadNotificationsCount,
          handleKeyPress
        }}
      >
        <div id='notifications'>{children}</div>
      </NotificationsContext.Provider>
    );
  }
}

export default NotificationsContext;

export { NotificationsProvider };
