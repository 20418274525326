import React, { Component } from 'react';
import styles from './GamificationWrapper.module.css';

import Grid from '@material-ui/core/Grid';
import Menu from './../components/Menu';
import Footer from 'deep/components/Footer';

class GamificationWrapper extends Component {
  render() {
    return (
      <div className={styles.Wrapper}>
        <div className={`${styles.Activity} ${styles.Transparent}`}></div>
        <Grid container className={styles.Box} wrap='nowrap'>
          <Grid item>
            <Menu />
          </Grid>
          <Grid item style={{ flexGrow: 1 }}>
            <div className={styles.Right}>{this.props.children}</div>
          </Grid>
        </Grid>
        <Footer />
      </div>
    );
  }
}

export default GamificationWrapper;
