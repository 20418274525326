import * as FormatTrans from './formatTransModels';

export function notifications(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.notification(item));
  });
  return dataTranslated;
}

export function categories(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.category(item));
  });
  return dataTranslated;
}
