import React, { Component } from 'react';
import styles from './Monitoring.module.css';
import OverallActiveAgents from './../figures/OverallActiveAgents';
import OverallActions from './../figures/OverallActions';
import DownloadAllFeedbacks from './../figures/DownloadAllFeedbacks';
import OverallTime from './../figures/OverallTime';
import OverallSuccessRate from './../figures/OverallSuccessRate';
import Grid from '@material-ui/core/Grid';

class OverallPerformance extends Component {
  render() {
    return (
      <>
        <div className={styles.Wrapper}>
          <Grid
            container
            spacing={3}
            alignItems='stretch'
            style={{ maxWidth: 1400 }}
          >
            <Grid item xs={12} md={6}>
              <OverallActiveAgents />
            </Grid>
            <Grid item xs={12} md={6}>
              <OverallActions />
            </Grid>
            <Grid item xs={12} md={4}>
              <DownloadAllFeedbacks />
            </Grid>
            <Grid item xs={12} md={4}>
              <OverallTime />
            </Grid>
            <Grid item xs={12} md={4}>
              <OverallSuccessRate />
            </Grid>
          </Grid>
        </div>
      </>
    );
  }
}

export default OverallPerformance;
