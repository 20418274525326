import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './AvatarChoice.module.css';
import { withStyles } from '@material-ui/core/styles';
import * as Api from 'deep/api/endpoints';
import * as Tenancy from 'utils/Tenancy';
import { STORAGE_VARS } from 'utils/Constants';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import SubmitButton from 'deep/components/materials/SubmitButton';
import GamificationContext from 'gamification/contexts/GamificationProvider';

const muiStyles = (theme) => ({
  dialogContainer: {
    padding: '3rem 3rem 2rem 3rem'
  },
  container: {
    opacity: 1,
    position: 'absolute',
    top: '20vh',
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 2000,
    width: '72.5rem',
    [theme.breakpoints.down('sm')]: {
      width: '80%'
    }
  },
  avatarRoot: {
    height: '10rem',
    width: '10rem',
    '&:hover': {
      border: '1px solid ' + theme.palette.primary.main
    }
  },
  avatarRootSelected: {
    height: '10rem',
    width: '10rem',
    border: '1px solid ' + theme.palette.primary.main
  },
  button: {
    textTransform: 'none',
    fontSize: '1.5rem',
    fontWeight: 600,
    margin: '0rem 1rem',
    paddingLeft: '4.5rem',
    paddingRight: '4.5rem',
    borderRadius: '2.2rem'
  }
});

class AvatarChoice extends Component {
  static contextType = GamificationContext;

  constructor(props) {
    super(props);
    this.state = {
      avatars: null,
      selectedAvatar: null,
      wonBadges: null,
      isWonAvatarBadge: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.loadAvatars();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }

  async loadAvatars() {
    const result = await Api.getAvatars();
    this.securSetState({
      avatars: result
    });
  }

  handleSelectAvatar(event, avatar) {
    event.preventDefault();
    this.securSetState({
      selectedAvatar: avatar
    });
  }

  handleSkip(event) {
    event.preventDefault();
    if (Tenancy.isMonoTenant()) {
      localStorage.setItem(STORAGE_VARS.AVATAR_DISCLAIMER, true);
    } else {
      const tenant = Tenancy.getTenant();
      localStorage.setItem(tenant + '_' + STORAGE_VARS.AVATAR_DISCLAIMER, true);
    }
    this.props.onClose();
  }
  handleSubmit(event) {
    event.preventDefault();
    if (Tenancy.isMonoTenant()) {
      localStorage.setItem(STORAGE_VARS.AVATAR_DISCLAIMER, true);
    } else {
      const tenant = Tenancy.getTenant();
      localStorage.setItem(tenant + '_' + STORAGE_VARS.AVATAR_DISCLAIMER, true);
    }
    const meUserId = this.context.me.user.id;
    const avatarId = this.state.selectedAvatar.id;
    this.patchAvatar(meUserId, avatarId);
  }

  async patchAvatar(userId, avatarId) {
    await Api.patchUserAvatar(userId, avatarId);
    this.context.refreshMe();
    const { myWonBadges, haveAvatarBadge } = this.context;
    if (myWonBadges && !haveAvatarBadge) {
      this.context.raiseAvatarBadge();
    }
    this.props.onClose();
  }

  render() {
    const { classes, inDialog } = this.props;
    const { me, haveAvatarBadge } = this.context;

    let title = '';
    if (me) {
      if (!me.user.avatar) {
        title = 'Before you start, please select your avatar!';
      } else if (!haveAvatarBadge) {
        title = 'Please select your avatar!';
      } else {
        title = 'Update your avatar!';
      }
    }

    return (
      <Container
        disableGutters
        classes={{
          root: inDialog ? classes.dialogContainer : classes.container
        }}
      >
        <div className={styles.badgesContainer}>
          <div className={styles.Title}>{title}</div>
          <div className={styles.Message}>
            This will be used as a display picture for the application.
            {!haveAvatarBadge && (
              <>
                <br />
                Get <strong>10 points</strong> and an{' '}
                <strong>Avatar badge!!</strong>
              </>
            )}
          </div>
          {this.state.avatars && (
            <Grid container spacing={4}>
              {this.state.avatars.map((avatar, index) => {
                return (
                  <Grid
                    item
                    key={index}
                    style={{ position: 'relative', cursor: 'pointer' }}
                    onClick={(event) => this.handleSelectAvatar(event, avatar)}
                  >
                    <Avatar
                      alt={avatar.id.toString()}
                      src={avatar.file}
                      classes={{
                        root:
                          this.state.selectedAvatar?.id === avatar.id
                            ? classes.avatarRootSelected
                            : classes.avatarRoot
                      }}
                    />
                  </Grid>
                );
              })}
            </Grid>
          )}
        </div>
        <div style={{ textAlign: 'right' }}>
          <Button
            variant='outlined'
            color='primary'
            disableElevation
            size='large'
            classes={{ root: classes.button }}
            onClick={(event) => this.handleSkip(event)}
          >
            Skip
          </Button>
          <SubmitButton
            label='Set Avatar'
            isLoading={false}
            disabled={!this.state.selectedAvatar}
            type='button'
            onClick={(event) => this.handleSubmit(event)}
          />
        </div>
      </Container>
    );
  }
}

export default withTranslation('cco')(withStyles(muiStyles)(AvatarChoice));
