import React, { Component } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';
import Popper from '@material-ui/core/Popover';
const muiStyles = (theme) => ({
  btRoot: {
    color: '#3A404D',
    textTransform: 'none',
    padding: '0.2rem 2.4rem 0.2rem 1.6rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
    borderRadius: '2.2rem',
    marginRight: '1.6rem',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.19)',
    minWidth: '17rem',
    border: '1px solid #fff',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#f2f2f2',
      boxShadow: 'inset 0rem 0.1rem 0.3rem 0rem rgba(0,0,0,0.19)'
    }
  },
  popupIndicatorOpen: {
    transform: 'rotate(90deg)'
  },
  textField: {
    marginLeft: '0',
    marginRight: '0',
    paddingBottom: 0,
    marginTop: 0
    // backgroundColor: 'red'
  },
  textFieldInput: {
    '&:focus&::placeholder': {
      opacity: 0.42
    },
    '&::placeholder': {
      color: '#3A404D',
      opacity: 1,
      fontSize: '1.5rem',
      fontWeight: '400'
    }
  },
  menuListItem: {
    padding: '0.4rem 1.6rem',
    //marginBottom: '1rem',
    width: 'fit-content',
    fontSize: '1.3rem',
    '&:hover': {
      backgroundColor: 'transparent',
      color: '#13AE57'
    },
    '&&selected': {
      backgroundColor: 'transparent'
    }
  },
  menuListItemSelected: {
    backgroundColor: 'transparent !important'
  },
  menuListItemIcon: {
    fontSize: '1.5rem',
    minWidth: '2.6rem'
  },
  menuListItemIconSelected: {
    fontSize: '1.7rem',
    minWidth: '4.3rem'
  },
  menuListItemText: {
    fontSize: '1.3rem',
    width: 'fit-content'
  },
  popper: {
    width: 'fit-content !important'
  }
});

class AutocompleteCheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelectedIds: null,
      selectedIndexes: [],
      open: false
    };
  }

  componentDidUpdate() {
    if (
      this.props.items &&
      !this.state.defaultSelectedIds &&
      this.props.defaultSelectedIds
    ) {
      this.securSetState({ defaultSelectedIds: this.props.defaultSelectedIds });
      let selectedIndexes = [];
      this.props.items.forEach((item, index) => {
        if (this.props.defaultSelectedIds.indexOf(item.id) > -1) {
          selectedIndexes.push(index);
        }
      });
      this.securSetState({ selectedIndexes: selectedIndexes });
    }
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  render() {
    const { classes, title, items, disabled, value } = this.props;
    return (
      <Autocomplete
        id='channel-selection'
        disabled={disabled}
        value={value || []}
        classes={{
          root: clsx(classes.btRoot, {
            [classes.btSelected]: this.state.open
          }),
          label: classes.btText,
          popupIndicatorOpen: classes.popupIndicatorOpen,
          popper: classes.popper
        }}
        popupIcon={
          <FontAwesomeIcon
            icon={faChevronRight}
            style={{ fontSize: '1.5rem', color: '#3A404D', opacity: 1 }}
          />
        }
        PopperComponent={(props) => {
          return <Popper {...props} style={{ width: 'fit-content' }} />;
        }}
        freeSolo={false}
        options={items ? items : []}
        multiple
        disablePortal
        disableCloseOnSelect
        getOptionLabel={(option) => option.label}
        onChange={(event, value) => this.props.onChange(value.map((d) => d))}
        groupBy={(option) => option.parent}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <ListItemIcon classes={{ root: classes.menuListItemIcon }}>
              <FontAwesomeIcon icon={selected ? faCheckSquare : faSquare} />
            </ListItemIcon>
            <ListItemText
              primary={option.label}
              classes={{ primary: classes.menuListItemText }}
            />
          </React.Fragment>
        )}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <Input
              {...params.inputProps}
              endAdornment={params.InputProps.endAdornment}
              classes={{
                root: classes.textField,
                input: classes.textFieldInput
              }}
              placeholder={title}
              size='small'
              type='text'
              disableUnderline={true}
            />
          </div>
        )}
      />
    );
  }
}

export default withTranslation('monitoring')(
  withStyles(muiStyles)(AutocompleteCheckList)
);
