import React, { Component } from 'react';

export const FILTERS_STATUS = {
  ALL: { id: 'all', label: 'All Status' },
  UPCOMING: { id: 'upcoming', label: 'Upcoming' },
  ONGOING: { id: 'ongoing', label: 'Ongoing' },
  ARCHIVES: { id: 'archives', label: 'Archives' }
};

const FiltersContext = React.createContext();

class FiltersProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      statusFilter: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.setStatusFilter(FILTERS_STATUS.ALL);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  setStatusFilter = (status) => {
    this.securSetState({ statusFilter: status });
  };

  render() {
    const { children } = this.props;
    
    const { setStatusFilter } = this;

    return (
      <FiltersContext.Provider
        value={{setStatusFilter}}
      >
        {children}
      </FiltersContext.Provider>
    );
  }
}

export default FiltersContext;

export { FiltersProvider };
