import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './Badges.module.css';
import * as GamificationApi from './../api/endpoints';
import Grid from '@material-ui/core/Grid';
import BadgeWon from './BadgeWon';
import BadgeUpcoming from './BadgeUpcoming';
import GamificationContext from './../contexts/GamificationProvider';

class Badges extends Component {
  static contextType = GamificationContext;

  constructor(props) {
    super(props);
    this.state = {
      allBadges: null,
      wonBadges: [],
      upcomingBadges: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.loadAllBadges();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }

  componentDidUpdate() {
    if (
      this.state.allBadges &&
      this.context.myWonBadges &&
      this.state.wonBadges
    ) {
      if (this.context.myWonBadges.length !== this.state.wonBadges.length) {
        this.populateWonBadges(this.state.allBadges);
      }
    }
  }

  async loadAllBadges() {
    const allBadges = await GamificationApi.getBadges();
    this.securSetState({
      allBadges: allBadges
    });
    //this.populateWonBadges(allBadges);
  }

  populateWonBadges(allBadges) {
    const wonBadges = this.context.myWonBadges;
    this.securSetState({
      wonBadges: wonBadges
    });
    for (
      let i = 0;
      i < (this.props.limWon ? this.props.limWon : wonBadges.length);
      i++
    ) {
      if (i < wonBadges.length) {
        this.loadBadgeWinners(i, wonBadges[i].id);
      }
    }
    this.populateUpcomingBadges(allBadges, wonBadges);
  }

  async loadBadgeWinners(index, badgeId) {
    const result = await GamificationApi.getBadgeWinners(badgeId);
    const wonBadges = this.state.wonBadges;
    if (index < wonBadges.length && wonBadges[index]) {
      wonBadges[index].winnersCount = result.count;
      wonBadges[index].winners = result.results;
    }

    //ckeck all winners loaded to update view
    let allLoaded = true;
    for (let i = 0; i < wonBadges.length; i++) {
      if (!wonBadges[i].winners) {
        allLoaded = false;
        break;
      }
    }
    if (allLoaded) {
      this.securSetState({
        wonBadges: wonBadges
      });
    }
  }

  populateUpcomingBadges(allBadges, wonBadges) {
    if (!allBadges || !wonBadges) {
      return null;
    }

    const upcomingBadges = [];

    let wonBadgesIds = [];
    for (let i = 0; i < wonBadges.length; i++) {
      wonBadgesIds.push(wonBadges[i].id);
    }

    allBadges.forEach((badge) => {
      if (!wonBadgesIds.includes(badge.id)) {
        upcomingBadges.push(badge);
      }
    });

    this.securSetState({
      upcomingBadges: upcomingBadges
    });
  }

  render() {
    const { wrap, limWon, limUpcoming } = this.props;
    const wonBadges = this.state.wonBadges;
    const upcomingBadges = this.state.upcomingBadges;

    return (
      <Grid
        container
        direction='row'
        spacing={5}
        wrap={wrap}
        alignItems='flex-start'
        alignContent='flex-start'
      >
        {wonBadges && (
          <Grid item>
            <div className={styles.SectionTitle}>
              {this.props.t('badges.acquired')}
            </div>
            <Grid container direction='row' spacing={3} wrap='nowrap'>
              {wonBadges.slice(0, limWon).map((badge, index) => {
                return (
                  <Grid item key={index}>
                    <BadgeWon
                      badge={badge}
                      winners={badge.winners}
                      winnersCount={badge.winnersCount}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
        {upcomingBadges && (
          <Grid item>
            <div className={styles.SectionTitle}>
              {this.props.t('badges.upcoming')}
            </div>

            <Grid
              container
              direction='row'
              alignItems='flex-start'
              alignContent='flex-start'
              spacing={2}
            >
              {upcomingBadges.slice(0, limUpcoming).map((badge, index) => {
                return (
                  <Grid item key={index}>
                    <BadgeUpcoming badge={badge} />
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    );
  }
}

export default withTranslation('gamification')(Badges);
