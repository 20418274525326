import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export default function SimpleButton(props) {
  const paddingTopBottom = () => {
    if (props.size === 'small') {
      return props.variant === 'outlined' ? '0.5rem' : '0.6rem';
    } else {
      return props.variant === 'outlined' ? '0.8rem' : '0.9rem';
    }
  };

  const useStyles = makeStyles((theme) => ({
    root: {
      borderRadius: props.size === 'small' ? '2rem' : '2.2rem',
      textTransform: 'none',
      fontSize: '1.5rem',
      fontWeight: 600,
      paddingTop: paddingTopBottom(),
      paddingBottom: paddingTopBottom(),
      paddingLeft: props.size === 'small' ? '1.4rem' : '3rem',
      paddingRight: props.size === 'small' ? '1.6rem' : '3rem'
    },
    disabled: {
      '&:disabled': {
        color: props.variant !== 'outlined' ? '#ffffff' : null
      }
    }
  }));

  const classes = useStyles(props);

  return (
    <Button
      onClick={props.onClick}
      variant={props.variant}
      color='primary'
      fullWidth={props.fullWidth}
      type='button'
      disabled={props.disabled || props.isLoading}
      disableElevation
      disableRipple
      size={props.size ? props.size : 'small'}
      classes={{
        root: classes.root,
        disabled: classes.disabled
      }}
    >
      {props.icon}
      {props.label}
    </Button>
  );
}
