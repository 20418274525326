import { getRequest } from 'deep/api/utils';
import * as FormatData from './formatdata';
import * as RewriteData from './rewritemockdata';
import * as MwHistory from 'utils/MwHistory';

const MOCKDATA = false;

export function getProducts() {
  const endpoint = 'cco/product?parent__isnull=true&purchasable=true';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.products(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getAllFeedback() {
  const endpoint = 'cco/feedback-all-excel';
  return new Promise(function (resolve, reject) {
    getRequest(endpoint, true, true)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getOutcomes() {
  let endpoint = 'cco/feedbackoutcome?ordering=order';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.outcomes(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getChannels() {
  let endpoint = 'cco/channel';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.channels(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getCampaignTypes() {
  let endpoint = 'cco/campaigntype';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.campaignTypes(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getOverallActiveAgents(
  begin,
  end,
  interval,
  timelineFilterId,
  multiChannelsFilter
) {
  return new Promise(function (resolve, reject) {
    if (MOCKDATA) {
      var json = require('./mockdata/overall_activeagents_' +
        timelineFilterId +
        '.json');
      resolve(RewriteData.overallActiveAgents(json, begin, end, interval));
    } else {
      const beginISO = begin.format('YYYY-MM-DDTHH:mm:ss.000');
      const endISO = end.format('YYYY-MM-DDTHH:mm:ss.000');
      const endpoint =
        'user/event/stats?begin=' +
        beginISO +
        '&end=' +
        endISO +
        '&interval=' +
        interval +
        '&user__persona__channel_in=' +
        multiChannelsFilter;

      getRequest(endpoint)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          switch (error.status) {
            case 403:
              reject(error.status);
              MwHistory.replace('/403');
              break;
            default:
              reject('error.customer-data-unavailable');
              break;
          }
        });
    }
  });
}

export function getOverallActions(
  begin,
  end,
  interval,
  outcomeId,
  timelineFilterId,
  outcomeFilterCode,
  multiCampaignTypesFilter,
  multiChannelsFilter
) {
  return new Promise(function (resolve, reject) {
    if (MOCKDATA) {
      var json = require('./mockdata/overall_actions_' +
        outcomeFilterCode +
        '_' +
        timelineFilterId +
        '.json');
      resolve(
        RewriteData.overallActions(
          json,
          begin,
          end,
          interval,
          timelineFilterId,
          outcomeFilterCode
        )
      );
    } else {
      const beginISO = begin.format('YYYY-MM-DDTHH:mm:ss.000');
      const endISO = end.format('YYYY-MM-DDTHH:mm:ss.000');
      const endpoint =
        'cco/feedback/stats?begin=' +
        beginISO +
        '&end=' +
        endISO +
        '&interval=' +
        interval +
        '&agent__channel__in=' +
        multiChannelsFilter;
      getRequest(endpoint)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          switch (error.status) {
            case 403:
              reject(error.status);
              MwHistory.replace('/403');
              break;
            default:
              reject('error.customer-data-unavailable');
              break;
          }
        });
    }
  });
}

export function getOverallAverage(
  begin,
  end,
  interval,
  timelineFilterId,
  outcomeFilterCode,
  multiCampaignTypesFilter,
  multiChannelsFilter
) {
  return new Promise(function (resolve, reject) {
    if (MOCKDATA) {
      var jsonActiveAgents = require('./mockdata/overall_activeagents_' +
        timelineFilterId +
        '.json');
      const resultActiveAgents = RewriteData.overallActiveAgents(
        jsonActiveAgents,
        begin,
        end,
        interval
      );
      var jsonActions = require('./mockdata/overall_actions_' +
        outcomeFilterCode +
        '_' +
        timelineFilterId +
        '.json');
      const resultActions = RewriteData.overallActions(
        jsonActions,
        begin,
        end,
        interval,
        timelineFilterId,
        outcomeFilterCode
      );

      var json = require('./mockdata/overall_dailyactions.json');
      const days = Math.floor(
        (end.getTime() - begin.getTime()) / 1000 / 60 / 60 / 24
      );
      const result = RewriteData.overallAverage(
        json,
        resultActiveAgents,
        resultActions,
        days,
        outcomeFilterCode
      );
      resolve(result);
    } else {
      const beginISO = begin.format('YYYY-MM-DDTHH:mm:ss.000');
      const endISO = end.format('YYYY-MM-DDTHH:mm:ss.000');
      const endpoint =
        'cco/feedback/average?begin=' +
        beginISO +
        '&end=' +
        endISO +
        '&agent__channel__in=' +
        multiChannelsFilter;
      getRequest(endpoint)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          switch (error.status) {
            case 403:
              reject(error.status);
              MwHistory.replace('/403');
              break;
            default:
              reject('error.customer-data-unavailable');
              break;
          }
        });
    }
  });
}

export function getOverallSuccessRate(
  begin,
  end,
  interval,
  timelineFilterId,
  multiCampaignTypesFilter,
  multiChannelsFilter
) {
  return new Promise(function (resolve, reject) {
    if (MOCKDATA) {
      var jsonAccepted = require('./mockdata/overall_actions_accepted_' +
        timelineFilterId +
        '.json');
      const resultAccepted = RewriteData.overallActions(
        jsonAccepted,
        begin,
        end,
        interval,
        timelineFilterId,
        'accepted'
      );
      var jsonNotProposed = require('./mockdata/overall_actions_not_proposed_' +
        timelineFilterId +
        '.json');
      const resultNotProposed = RewriteData.overallActions(
        jsonNotProposed,
        begin,
        end,
        interval,
        timelineFilterId,
        'not_proposed'
      );
      var jsonRejected = require('./mockdata/overall_actions_rejected_' +
        timelineFilterId +
        '.json');
      const resultRejected = RewriteData.overallActions(
        jsonRejected,
        begin,
        end,
        interval,
        timelineFilterId,
        'rejected'
      );

      var json = require('./mockdata/overall_successrate.json');
      const result = RewriteData.overallSuccessRate(
        json,
        resultAccepted,
        resultNotProposed,
        resultRejected
      );
      resolve(result);
    } else {
      const beginISO = begin.format('YYYY-MM-DDTHH:mm:ss.000');
      const endISO = end.format('YYYY-MM-DDTHH:mm:ss.000');
      const endpoint =
        'cco/feedback/number_of_actions?begin=' +
        beginISO +
        '&end=' +
        endISO +
        '&agent__channel__in=' +
        multiChannelsFilter;

      getRequest(endpoint)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          switch (error.status) {
            case 403:
              reject(error.status);
              MwHistory.replace('/403');
              break;
            default:
              reject('error.customer-data-unavailable');
              break;
          }
        });
    }
  });
}

export function getTimeEvolution() {
  return new Promise(function (resolve, reject) {
    var json = require('./mockdata/time_evolution.json');
    const result = FormatData.timeEvolutions(json);
    const mockResult = RewriteData.timeEvolutions(result);
    resolve(mockResult);
  });
}

export function getFeedbackReasons(campaignTypeId, productId) {
  let endpoint = 'cco/feedbackreason?campaign_type=' + campaignTypeId;
  if (productId) {
    endpoint += '&product=' + productId;
  }

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.FeedbackReasons(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getFeedbakOutcomeWithReasonsStats(
  begin,
  end,
  channelIds,
  campaignTypeId,
  productId
) {
  let endpoint = 'cco/feedbackreason/aggregates?ordering=reason__order';
  const beginISO = begin.format('YYYY-MM-DDTHH:mm:ss.000');
  const endISO = end.format('YYYY-MM-DDTHH:mm:ss.000');
  endpoint += '&begin=' + beginISO + '&end=' + endISO;

  if (channelIds) {
    endpoint += '&channel=' + channelIds;
  }
  if (campaignTypeId) {
    endpoint += '&campaign=' + campaignTypeId;
  }
  if (productId) {
    endpoint += '&product=' + productId;
  }

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.outcomes(result);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            console.error(error);
            reject('error.customer-data-unavailable', error);
            break;
        }
      });
  });
}

export function getFeedbakOutcomeByProducts(
  begin,
  end,
  channelIds,
  campaignTypeIds,
  productId
) {
  let endpoint = 'cco/feedback/customer_analysis?';
  const beginISO = begin.format('YYYY-MM-DDTHH:mm:ss.000');
  const endISO = end.format('YYYY-MM-DDTHH:mm:ss.000');
  endpoint += '&started__gte=' + beginISO + '&started__lte=' + endISO;

  if (channelIds.length > 0) {
    endpoint += '&agent__channel__in=' + channelIds;
  }
  if (campaignTypeIds.length > 0) {
    endpoint += '&opportunity__campaign__type__in=' + campaignTypeIds;
  }
  if (productId) {
    endpoint += '&product=' + productId;
  }

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        resolve(FormatData.customerAnalysis(result));
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            console.error(error);
            reject('error.customer-data-unavailable', error);
            break;
        }
      });
  });
}

export function getInteractionOutcome(
  begin,
  end,
  interval,
  channelId,
  campaignTypeId,
  productId
) {
  return new Promise(function (resolve, reject) {
    var json = require('./mockdata/interaction_outcome.json');
    const result = FormatData.interactionOutcomes(json);

    if (campaignTypeId < 3) {
      productId = null;
    }
    getFeedbackReasons(campaignTypeId, productId)
      .then((reasons) => {
        const mockResult = RewriteData.interactionOutcomes(result, reasons);
        resolve(mockResult);
      })
      .catch((error) => {
        resolve(result);
      });
  });
}

export function getUserAnalysis(begin, end, channelId, timelineFilterId) {
  return new Promise(function (resolve, reject) {
    const beginISO = begin.format('YYYY-MM-DDTHH:mm:ss.000');
    const endISO = end.format('YYYY-MM-DDTHH:mm:ss.000');
    let endpoint = 'cco/persona/analysis?begin=' + beginISO + '&end=' + endISO;
    if (channelId) {
      endpoint += '&channel=' + channelId;
    }

    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.userAnalysis(result.results);
        const total = result.total;
        if (MOCKDATA) {
          resolve(RewriteData.userAnalysis(results, timelineFilterId));
        } else {
          resolve({
            results: results,
            total: total
          });
        }
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}
