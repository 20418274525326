import React, { Component } from 'react';
import styles from './Notifications.module.css';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import SimpleSwitch from 'deep/components/materials/SimpleSwitch';

const muiStyles = (theme) => ({
  TableHeadCell: {
    fontWeight: 600,
    borderBottom: 'none',
    padding: '0.9rem 1rem'
  },
  TableRow: {
    backgroundColor: '#fff'
  },
  TableCell: {
    borderBottom: 'none',
    padding: '1rem 1rem'
  },
  TableCellSwitch: {
    borderBottom: 'none',
    padding: '1rem 0.2rem 1rem 1.8rem',
    backgroundColor: '#FAFAFA'
  },
  TableCellUp: {
    borderBottom: 'none',
    padding: '1rem 0rem 1rem 1rem'
  },
  TableCellDown: {
    borderBottom: 'none',
    padding: '1rem 0rem 1rem 1rem'
  },
  TableCellView: {
    borderBottom: 'none',
    textAlign: 'right',
    padding: '1rem 1.8rem 1rem 0rem !important',
    backgroundColor: '#FAFAFA'
  },
  TableCellBlank: {
    padding: '5px',
    borderBottom: 'none'
  },
  btView: {
    textTransform: 'none',
    backgroundColor: '#fff !important',
    padding: '0.4rem 2rem 0.4rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem',
    borderRadius: '2.2rem'
  }
});

class NotificationsRules extends Component {
  handleChangeSwitch(checked) {}

  render() {

    const { classes } = this.props;

    const headers = [
      {label: '', colspan: 1},
      {label: 'Rule Name', colspan: 1},
      {label: 'From', colspan: 1},
      {label: 'To', colspan: 1},
      {label: 'Status', colspan: 1},
      {label: 'Reactions', colspan: 3},
    ]

    return (
      <>
        <Table>
          <TableHead>
            <TableRow>
              {headers.map((header, index) => {
                return (
                  <TableCell
                    key={index}
                    colSpan={header.colspan}
                    padding='none'
                    classes={{ root: classes.TableHeadCell }}
                  >{header.label}</TableCell>
                )
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow classes={{ root: classes.TableRow }}>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellSwitch }}
              >
                <SimpleSwitch
                  default={true}
                  color='#12AD56'
                  labelOff={null}
                  labelOn={null}
                  onChange={(checked) => this.handleChangeSwitch(checked)}
                />
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                Conversion rate collection
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                03 - Jan - 2020
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                03 - Feb - 2020
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                <div className={`${styles.Status} ${styles.Upcoming}`}>
                  upcoming
                </div>
              </TableCell>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellUp }}
              ></TableCell>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellDown }}
              ></TableCell>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellView }}
              >
                <Button
                  variant='outlined'
                  type='button'
                  disableElevation
                  disableRipple
                  size='small'
                  color='primary'
                  /*onClick={(event) => {
                    this.redirect(event);
                  }}*/
                  classes={{ root: classes.btView }}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
            <TableRow classes={{ root: classes.TableRowBlank }}>
              <TableCell
                colSpan={7}
                classes={{ root: classes.TableCellBlank }}
              ></TableCell>
            </TableRow>
            <TableRow classes={{ root: classes.TableRow }}>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellSwitch }}
              >
                <SimpleSwitch
                  default={true}
                  color='#12AD56'
                  labelOff={null}
                  labelOn={null}
                  onChange={(checked) => this.handleChangeSwitch(checked)}
                />
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                Average time of call price renewal
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                03 - Jan - 2020
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                03 - Feb - 2020
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                <div className={`${styles.Status} ${styles.Ongoing}`}>
                  ongoing
                </div>
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCellUp }}>
                <FontAwesomeIcon
                  className={`${styles.Icon} ${styles.Up}`}
                  icon={faThumbsUp}
                />
                20
              </TableCell>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellDown }}
              >
                <FontAwesomeIcon
                  className={`${styles.Icon} ${styles.Down}`}
                  icon={faThumbsDown}
                />
                15
              </TableCell>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellView }}
              >
                <Button
                  variant='outlined'
                  type='button'
                  disableElevation
                  disableRipple
                  size='small'
                  color='primary'
                  /*onClick={(event) => {
                    this.redirect(event);
                  }}*/
                  classes={{ root: classes.btView }}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
            <TableRow classes={{ root: classes.TableRowBlank }}>
              <TableCell
                colSpan={7}
                classes={{ root: classes.TableCellBlank }}
              ></TableCell>
            </TableRow>
            <TableRow classes={{ root: classes.TableRow }}>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellSwitch }}
              >
                <SimpleSwitch
                  default={true}
                  color='#12AD56'
                  labelOff={null}
                  labelOn={null}
                  onChange={(checked) => this.handleChangeSwitch(checked)}
                />
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                Average time of call price renewal
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                03 - Jan - 2020
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                03 - Feb - 2020
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCell }}>
                <div className={`${styles.Status} ${styles.Archived}`}>
                  archived
                </div>
              </TableCell>
              <TableCell padding='none' classes={{ root: classes.TableCellUp }}>
                <FontAwesomeIcon
                  className={`${styles.Icon} ${styles.Up}`}
                  icon={faThumbsUp}
                />
                20
              </TableCell>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellDown }}
              >
                <FontAwesomeIcon
                  className={`${styles.Icon} ${styles.Down}`}
                  icon={faThumbsDown}
                />
                15
              </TableCell>
              <TableCell
                padding='none'
                classes={{ root: classes.TableCellView }}
              >
                <Button
                  variant='outlined'
                  type='button'
                  disableElevation
                  disableRipple
                  size='small'
                  color='primary'
                  /*onClick={(event) => {
                    this.redirect(event);
                  }}*/
                  classes={{ root: classes.btView }}
                >
                  View
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </>
    );
  }
}

export default withStyles(muiStyles)(NotificationsRules);
