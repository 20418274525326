import React from 'react';
import * as Api from './../api/endpoints';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Card from './../components/Card';
import SubmitButton from 'deep/components/materials/SubmitButton';

function DownloadAllFeedbacks() {
  const loadStats = async () => {
    await Api.getAllFeedback();
  };

  return (
    <Card
      title='Download All Feedbacks'
      subtitle={'All feedbacks up to now'}
      icon={<CloudDownloadIcon />}
    >
      <div style={{ marginTop: '1rem' }}>
        <SubmitButton
          label='Download CSV'
          onClick={loadStats}
          color='primary'
          type='submit'
          size='small'
        />
      </div>
    </Card>
  );
}

export default DownloadAllFeedbacks;
