import React from 'react';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';



const useStyles = makeStyles(theme => ({
  box: props => {
    return {
      background: '#fff',
      //background: "red",
      boxShadow: '0px 0px 10px -7px rgba(0,0,0,0.5)',
      borderRadius: 6,
      border:
        '2px solid ' + (props.border ? theme.palette.secondary.light : 'transparent'),
      padding: '16px 24px 14px',
      marginBottom: 16,
      position: 'relative'
    }
  },
  title: {
    color: '#4C4C4C',
    fontWeight: 600,
    fontSize: '1.8rem',
    padding: '0rem 0rem 2rem 0rem',
    textTransform: 'none',
    backgroundColor: 'transparent',
    textAlign: 'left'
  }
}));

function SimpleCard(props) {
  const { title, children } = props;
  const styles = useStyles(props)

  return (
    <div className={styles.box}>
      {title && <div className={styles.title}>{title}</div>}
      {children}
    </div>
  );

}

export default withTranslation('cco')(SimpleCard);
