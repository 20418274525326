import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

export default function RadioTable(props) {
  const options = props.options;

  const [value, setValue] = React.useState(props.defaultValue);
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    props.onChange(event, newValue);
  };

  const useStyles = makeStyles((theme) => ({
    lblRoot: {
      minWidth: '100%',
      marginLeft: '0',
      paddingLeft: '1rem',

      '&:not(:last-child)': {
        borderBottom: '0.5px solid #E6E9EE'
      },
      '&:hover': {
        backgroundColor: theme.palette.primary.light
      }
    },
    lblTxt: {
      color: '#777777',
      fontSize: '1rem'
    },
    radio: {
      padding: '0.3rem 0.8rem 0.3rem 0rem',
      backgroundColor: 'transparent'
    },
    icon: {
      width: '1.3rem'
    }
  }));

  const classes = useStyles();

  return (
    <FormControl
      component='fieldset'
      style={{
        marginTop: '2rem',
        // marginBottom: '2rem',
        // marginLeft: '1.2rem',
        width: '100%',
        border: '1px solid #E6E9EE',
        borderRadius: '0.5rem',
        fontSize: '1.2rem',
        color: '#3A404D',
        minHeight: '9rem',
        fontFamily: "'Open Sans', sans-serif"
      }}
    >
      <RadioGroup
        aria-label='options'
        name='option'
        value={value}
        onChange={handleChange}
        row={true}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={
              <Radio
                classes={{
                  root: classes.radio
                }}
                checkedIcon={
                  <RadioButtonCheckedIcon className={classes.icon} />
                }
                icon={<RadioButtonUncheckedIcon className={classes.icon} />}
                size='small'
                disableRipple
                color='primary'
              />
            }
            label={option.label}
            classes={{ root: classes.lblRoot, label: classes.lblTxt }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
