import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import TreeView from '@material-ui/lab/TreeView';
import SvgIcon from '@material-ui/core/SvgIcon';
import TreeItem from '@material-ui/lab/TreeItem';
import Divider from '@material-ui/core/Divider';

import CallSessionContext from './../../contexts/CallSessionProvider';

import * as CcoApi from './../../api/endpoints';


function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}


const formatLang = (transItem) => {
  if (!transItem){
    return
  }

  return transItem[i18next.language] || transItem[i18next.options.fallbackLng[0]]
}


class FAQ extends Component {
  static contextType = CallSessionContext;

  UNSAFE_componentWillMount() {
    this.rendered = false;
  }

  constructor(props) {
    super(props);
    this.state = {
        requestExecuting: false,
        faqData: null
    }

  }

  componentDidUpdate() {
    if (!this.rendered && this.context.collections) {
      this.rendered = true;
      this.getFAQ()
    }
  }

  getFAQ = async () => {
    this.setState({
      requestExecuting: true
    });

    try {
      await CcoApi.getFAQs().then((res) =>
        this.setState({
            faqData: res
        })
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        requestExecuting: false
      });
    }
  }

  render() {

    const { faqData } = this.state;
    const styles = {
      label: {
        fontWeight: 'inherit',
        color: 'inherit',
      },
      labelRoot: {
        display: 'flex',
        alignItems: 'center',
      },
      labelText: {
        fontWeight: 'inherit',
        flexGrow: 1,
      },
      root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflowY: 'scroll',
        maxHeight: '60vh'
      },
      faqBox: {
        borderRadius: '5px',
        marginBottom: '1em',
        display: 'flex',
        width: '100%'
      }
    };

    return (
    <Box style={styles.root} flex={1}  display="flex" p={1}>
        <Divider />
        {faqData && faqData.map((faq, id) => (
            <div key={id} style={styles.faqBox}>
                <TreeView
                    defaultCollapseIcon={<MinusSquare />}
                    defaultExpandIcon={<PlusSquare />}
                    >
                    <TreeItem 
                      label={
                        <Typography variant="body2" >
                          {formatLang(faq.question_trans)}
                        </Typography>
                      }
                      nodeId="1">
                    <TreeItem 
                      label={
                        <Typography variant="caption" color="inherit" >
                          {formatLang(faq.answer_trans).split("- ").map((i, key) => {
                        return <div style={{margin: '0.5em'}} key={key}>- {i}</div>;
                    })}
                        </Typography>
                      }
                      nodeId="2" />
                    </TreeItem>
                </TreeView>
            </div>
        ))}


    </Box>
    );
  }
}

export default withTranslation('cco')(FAQ);
