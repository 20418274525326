import React, { Component } from 'react';
import styles from './HomeAgent.module.css';
import { withTranslation } from 'react-i18next';
import * as Tenancy from 'utils/Tenancy';
import i18next from 'i18next';
import Moment from 'react-moment';
import * as CcoApi from './../api/endpoints';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Footer from 'deep/components/Footer';
import AvatarChoice from 'gamification/components/AvatarChoice';
import StartCallRich from './../components/StartCallRich';
import Access from 'gamification/components/Access';
import { STORAGE_VARS } from 'utils/Constants';
import AppContext from 'deep/contexts/AppProvider';

class HomeAgent extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      feedbacks: null,
      avatarChoiceClosed: false
    };
    this.onCloseAvatarChoice = this.onCloseAvatarChoice.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.loadFeedbacks();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  async loadFeedbacks() {
    const result = await CcoApi.getAgentFeedbacks(this.context.me.id);
    this.securSetState({
      feedbacks: result
    });
  }

  formatFeedbacks(data) {
    if (!data) {
      return null;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      const started = new Date(item.started);
      let time = null;
      if (item.ended) {
        const ended = new Date(item.ended);
        time = ended.getTime() - started.getTime();
      }
      dataFormatted.push({
        trans: item.trans,
        id: item.customer.id,
        name: item.customer.firstname + ' ' + item.customer.lastname,
        phone: item.customer.phone_number,
        email: item.customer.email,
        outcomeLbl:
          item.outcome.name_trans[i18next.language] ||
          item.outcome.name_trans[i18next.options.fallbackLng[0]],
        outcomeId: item.outcome.id,
        outcomeCode: item.outcome.code,
        time: time
      });
    });

    return dataFormatted;
  }

  onCloseAvatarChoice() {
    this.securSetState({
      avatarChoiceClosed: true
    });
  }

  formatTime(time) {
    if (!time) {
      return 'unknown';
    } else {
      const s = parseInt(time / 1000);
      const minutes = parseInt(s / 60);
      const secondes = s - minutes * 60;
      return minutes + 'm ' + secondes + 's';
    }
  }

  getOutcomeColor(outcomeCode) {
    switch (outcomeCode) {
      case "accepted":
        return '#12AD56';
      case "undecided":
        return '#CCBD16';
      case "rejected":
        return '#F90000';
      case "not_proposed":
        return '#E87F19';
      default:
        return '#333333';
    }
  }

  renderWelcomeMessage() {
    const dateNow = new Date();
    const { me } = this.context;

    return (
      <div className={styles.Welcome}>
        {this.props.t('home.txt-its')}&nbsp;
        <span className={styles.Time}>
          <Moment date={dateNow} format={this.props.t('home.fmt-hours')} />
          {this.props.t('home.txt-time-separator')}
          <Moment date={dateNow} format={this.props.t('home.fmt-minutes')} />
        </span>
        , {this.props.t('home.txt-welcome')}{' '}
        <span className={styles.Me}>
          {me.user.first_name}&nbsp;{me.user.last_name}{' '}
        </span>
      </div>
    );
  }

  render() {
    const { me, gamificationActive } = this.context;

    let avatarDisclaimer = null;
    if (Tenancy.isMonoTenant()) {
      avatarDisclaimer = localStorage.getItem(STORAGE_VARS.AVATAR_DISCLAIMER);
    } else {
      const tenant = Tenancy.getTenant();
      avatarDisclaimer = localStorage.getItem(
        tenant + '_' + STORAGE_VARS.AVATAR_DISCLAIMER
      );
    }
    const showAvatarChoice =
      !me.user?.avatar.id && !avatarDisclaimer && gamificationActive;

    const feedbacks = this.formatFeedbacks(this.state.feedbacks);

    return (
      <>
        <div className={styles.Wrapper}>
          {showAvatarChoice && (
            <AvatarChoice
              //me={this.props.me}
              //setMe={(me) => this.props.setMe(me)}
              showNotification={(data, type) =>
                this.props.showNotification(data, type)
              }
              pullMeGameKeys={() => this.props.pullMeGameKeys()}
              onClose={this.onCloseAvatarChoice}
            />
          )}
          <div
            className={`
            ${styles.WrapperTop}
            ${showAvatarChoice ? styles.ShowAvatar : ''}
            ${this.state.avatarChoiceClosed ? styles.Translate : ''}
          `}
          >
            <div>
              {this.renderWelcomeMessage()}
              <div
                className={`
              ${showAvatarChoice ? styles.Hidden : ''}
              ${this.state.avatarChoiceClosed ? styles.FadeIn : ''}
            `}
              >
                <div className={styles.Help}>
                  {this.props.t('home.txt-help')}
                </div>
                <div className={styles.StartCall}>
                  <StartCallRich
                    size='large'
                    inputWidth='25rem'
                    onStart={() => {}}
                    onClose={() => {}}
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className={`
            ${styles.WrapperBottom}
            ${showAvatarChoice ? styles.Hidden : ''}
            ${this.state.avatarChoiceClosed ? styles.FadeIn : ''}
          `}
          >
            <Grid container justify='center' spacing={3}>
              {feedbacks &&
                feedbacks.map((feedback, index) => (
                  <Grid item key={index}>
                    <div className={styles.FeedbackTitle}>
                      {index === 0 ? this.props.t('home.lbl-last-calls') : ''}
                      &nbsp;
                    </div>
                    <Paper elevation={6} className={styles.FeedbackCard}>
                      <div className={styles.CustomerName}>{feedback.name}</div>
                      <div className={styles.CustomerInfo}>
                        {this.props.t('home.lbl-call-id')}
                        {feedback.id}
                      </div>
                      <div className={styles.CustomerInfo}>
                        {feedback.phone}
                      </div>
                      <div className={styles.CustomerInfo}>
                        {feedback.email}
                      </div>
                      <div className={styles.CustomerInfo}>
                        {this.props.t('home.lbl-call-time')}{' '}
                        {this.formatTime(feedback.time)}
                      </div>
                      <div>
                        {this.props.t('home.lbl-call-outcome')}
                        <span
                          className={styles.FeedbackStatus}
                          style={{
                            color: this.getOutcomeColor(feedback.outcomeCode)
                          }}
                        >
                          {feedback.outcomeLbl}
                        </span>
                      </div>
                    </Paper>
                  </Grid>
                ))}
              <Grid item>{gamificationActive && <Access />}</Grid>
            </Grid>
          </div>
        </div>
        <Footer style={{ position: 'fixed', left: '2rem', bottom: '0rem' }} />
      </>
    );
  }
}

export default withTranslation('cco')(HomeAgent);
