import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export default function CCOTabs(props) {
  const useStyles = makeStyles((theme) => ({
    tabs: {
      minHeight: 'auto',
      margin: '0 auto',
      //backgroundColor: 'pink',
      width: 'max-content'
    },
    indicator: {
      height: '1px'
    },
    tab: {
      minWidth: 'auto',
      minHeight: 'auto',
      textTransform: 'none',
      padding: '0rem 1.4rem 0.3rem',
      margin: '0rem 2.4rem 0rem 0rem',
      color: '#777777',
      opacity: '0.5',
      fontSize: '1.5rem'
    },
    selected: {
      fontWeight: 600,
      opacity: '1',
      backgroundColor: '#F5F7FA'
    }
  }));
  const classes = useStyles(props);

  const styles = {
    wrapper: {
      position: 'relative',
      marginBottom: '4rem',
      boxShadow: '0rem 0.3rem 0.4rem 0rem rgba(1,0,0,0.02)'
      //backgroundColor: 'yellow'
    },
    bgLine: {
      position: 'absolute',
      bottom: 0,
      height: '1px',
      width: '100%',
      backgroundColor: '#E6E9EE',
      opacity: 0.3
    }
  };

  const tabs = props.tabs;

  const [value, setValue] = React.useState(props.defaultValue);
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    props.onChange(event, newValue);
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.bgLine}></div>
      <Tabs
        value={value}
        indicatorColor='secondary'
        onChange={handleChange}
        classes={{
          root: classes.tabs,
          indicator: classes.indicator
        }}
      >
        {tabs.map((tab, index) => (
          <Tab
            key={tab.id}
            label={tab.name}
            wrapped={false}
            disableRipple={true}
            disableFocusRipple={true}
            classes={{
              root: classes.tab,
              selected: classes.selected
            }}
          />
        ))}
      </Tabs>
    </div>
  );
}
