import React, { Component } from 'react';
import i18next from 'i18next';
import styles from './../PushNotifications.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown } from '@fortawesome/free-solid-svg-icons';
import Slide from '@material-ui/core/Slide';
import Collapse from '@material-ui/core/Collapse';
import NotificationsContext from './../../contexts/NotificationsProvider';
import { PUSH_SETTINGS } from './../../utils/Constants';
import buddy_information from './../../assets/buddy_information.svg';

class PushNotification extends Component {
  static contextType = NotificationsContext;

  constructor(props) {
    super(props);
    this.state = {
      open: true,
      timer: null,
      exited: false
    };
    this.handleUnlike = this.handleUnlike.bind(this);
    this.handleLike = this.handleLike.bind(this);
    this.handleSlideExited = this.handleSlideExited.bind(this);
    this.handleCollapsed = this.handleCollapsed.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    const timer = setTimeout(
      () => this.setState({ open: false }),
      this.context.pushTime
    );
    this.setState({ timer: timer });
  }
  componentWillUnmount() {
    clearTimeout(this.state.timer);
  }

  close() {
    this.setState({ open: false });
  }

  handleLike(event) {
    event.stopPropagation();
    this.context.like(this.props.notification);
    this.close();
  }

  handleUnlike(event) {
    event.stopPropagation();
    this.context.unlike(this.props.notification);
    this.close();
  }

  handleSlideExited() {
    this.setState({ exited: true });
  }
  handleCollapsed() {
    this.context.handleExitedPushNotification(this.props.notification);
  }
  handleClick(event) {
    event.stopPropagation();
    if (this.context.pushSetting === PUSH_SETTINGS.MICRO) {
      this.context.openNotificationsCenter();
    }
  }

  render() {
    const { notification } = this.props;
    const { open, exited } = this.state;
    const { notificationCenterOpen, pushSetting } = this.context;

    const pushOpen = open && !notificationCenterOpen;

    let message = '...';
    let color = 'transparent';
    let buddy = buddy_information;
    if (notification) {
      if (pushSetting === PUSH_SETTINGS.DETAILED) {
        message =
          notification.message_trans[i18next.language] ||
          notification.message_trans[i18next.options.fallbackLng[0]];
      }
      color = notification.category.color;
      buddy = notification.category.buddy;
    }

    return (
      <Collapse
        in={!exited}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 0, exit: 200 }}
        onExited={(event) => this.handleCollapsed()}
      >
        <Slide
          direction='left'
          in={pushOpen}
          timeout={{ enter: 500, exit: 300 }}
          onExited={(event) => this.handleSlideExited()}
        >
          <div
            className={`${styles.Notification} ${
              pushSetting === PUSH_SETTINGS.MICRO ? styles.Micro : ''
            }`}
            style={{ backgroundColor: color }}
            onClick={(event) => this.handleClick(event)}
          >
            <div className={styles.Buddy}>
              <img src={buddy} alt='notification' />
            </div>
            <div className={styles.Message}>{message}</div>

            {pushSetting === PUSH_SETTINGS.DETAILED && (
              <>
                <div className={styles.Actions}>
                  <div>
                    <FontAwesomeIcon
                      className={`${styles.Icon} ${styles.Up}`}
                      icon={faThumbsUp}
                      onClick={(event) => this.handleLike(event)}
                    />
                  </div>
                  <div>
                    <FontAwesomeIcon
                      className={`${styles.Icon} ${styles.Down}`}
                      icon={faThumbsDown}
                      onClick={(event) => this.handleUnlike(event)}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Slide>
      </Collapse>
    );
  }
}

export default PushNotification;
