import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import * as Api from './../api/endpoints';
import Grid from '@material-ui/core/Grid';
import Card from './../components/Card';
import ProgressBar from './../charts/ProgressBar';
import StackedBarLarge from './../charts/StackedBarLarge';
import Fade from '@material-ui/core/Fade';
import Loader from './../components/Loader';
import FiltersContext from './../contexts/FiltersProvider';
import i18next from 'i18next';

class InteractionOutcome extends Component {
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
      timer: null,
      timelineFilter: null,
      multiChannelsFilter: null,
      multiCampaignTypesFilter: null,
      multiProductsFilter: null,
      stats: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.updateState();
  }
  componentDidUpdate() {
    this.updateState();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.state.timer);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  updateState() {
    if (
      this.context.timelineFilter &&
      this.context.multiChannelsFilter &&
      this.context.multiProductsFilter &&
      this.context.multiCampaignTypesFilter &&
      (this.context.timelineFilter.id !== this.state.timelineFilter?.id ||
        this.context.multiChannelsFilter !== this.state.multiChannelsFilter ||
        this.context.multiProductsFilter !== this.state.multiProductsFilter ||
        this.context.multiCampaignTypesFilter !==
          this.state.multiCampaignTypesFilter)
    ) {
      this.securSetState({
        fadeIn: false,
        timelineFilter: this.context.timelineFilter,
        multiChannelsFilter: this.context.multiChannelsFilter,
        multiProductsFilter: this.context.multiProductsFilter,
        multiCampaignTypesFilter: this.context.multiCampaignTypesFilter
      });
      this.loadStats();
    }
  }
  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }
  async loadStats() {
    const result = await Api.getFeedbakOutcomeWithReasonsStats(
      this.context.timelineFilter.begin,
      this.context.timelineFilter.end,
      this.context.multiChannelsFilter.map((c) => c.id),
      this.context.multiCampaignTypesFilter,
      this.context.multiProductsFilter
    );
    this.securSetState({
      stats: result,
      fadeIn: true
    });
  }

  formatStats(data) {
    if (!data) {
      return null;
    }
    const total = data.map((item) => item.feedbacks).reduce((a, b) => a + b);
    let ratios = data.map((item) => (total > 0 ? item.feedbacks / total : 0));
    const colors = {
      accepted: '#12AD56',
      undecided: '#CCBD16',
      rejected: '#F90000',
      not_proposed: '#E87F19'
    };

    const dataFormatted = data.map((object, index) => ({
      ...object,
      index: index,
      ratios: ratios,
      color: colors[object.code],
      label: this.getTrans(object?.name_trans),
      reasons: object.reasons.map((reason) => ({
        label:
          this.getTrans((reason.product && reason.product.name_trans)) || '' +
          ' - ' +
          this.getTrans(reason?.description_trans),
        value: reason.feedbacks,
        ratio: object.feedbacks > 0 ? reason.feedbacks / object.feedbacks : 0
      }))
    }));
    // let dataFormatted = [];
    // for (let i = 0; i < data.length; i++) {
    //   let reasons = [];
    //   console.log(reasons);
    //   for (let j = 0; j < data[i].reasons.length; j++) {
    //     reasons.push({
    //       label: data[i].reasons[j].reason.description_trans['en'],
    //       value: data[i].reasons[j].count,
    //       ratio: data[i].reasons[j].ratio,
    //       color: colors[i]
    //     });
    //   }

    //   dataFormatted.push({
    //     label: data[i].outcome.name_trans['en'],
    //     index: i,
    //     ratios: ratios,
    //     color: colors[i],
    //     reasons: reasons
    //   });
    // }

    return dataFormatted;
  }

  render() {
    const outcomes = this.formatStats(this.state.stats);

    return (
      <>
        <div style={{ margin: '0rem auto', maxWidth: 1400 }}>
          <Card>
            <Fade in={this.state.fadeIn} timeout={{ enter: 500 }}>
              <div style={{ margin: '0rem 2rem' }}>
                {outcomes && (
                  <Grid container spacing={6}>
                    {outcomes.map((outcome, index) => (
                      <Grid item key={index} xs={12} md={4}>
                        <div style={{ marginBottom: '4rem' }}>
                          <StackedBarLarge data={outcome} />
                        </div>
                        {outcome.reasons.map((reason, index) => (
                          <div key={index} style={{ marginBottom: '2.4rem' }}>
                            <ProgressBar data={reason} />
                          </div>
                        ))}
                      </Grid>
                    ))}
                  </Grid>
                )}
              </div>
            </Fade>

            {!this.state.fadeIn && <Loader />}
          </Card>
        </div>
      </>
    );
  }
}

export default withTranslation('monitoring')(InteractionOutcome);
