import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppContext, { AppProvider } from 'deep/contexts/AppProvider';
import Routes from 'utils/Routes';
import * as MwHistory from 'utils/MwHistory';
import Header from 'deep/components/Header';

class AppDeep extends Component {
  render() {
    const currentRoute = MwHistory.getCurrentRoute();

    return (
      <AppProvider>
        <AppContext.Consumer>
          {(appContext) => (
            <MuiThemeProvider theme={appContext.theme}>
              <CssBaseline />
              {currentRoute.protected && appContext.me && <Header />}
              <Routes />
            </MuiThemeProvider>
          )}
        </AppContext.Consumer>
      </AppProvider>
    );
  }
}

export default AppDeep;
