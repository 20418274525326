import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './Overall.module.css';
import * as Api from './../api/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import Card from './../components/Card';
import Fade from '@material-ui/core/Fade';
import Loader from './../components/Loader';
import FiltersContext from './../contexts/FiltersProvider';

let timer = null;
let delay = 1000;

class OverallTime extends Component {
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
      timelineFilter: null,
      multiChannelsFilter: null,
      outcomeFilter: null,
      multiCampaignTypesFilter: null,
      stats: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.updateState();
  }
  componentDidUpdate() {
    this.updateState();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(timer);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  updateState() {
    if (
      this.context.timelineFilter &&
      this.context.multiChannelsFilter &&
      this.context.outcomeFilter &&
      this.context.multiCampaignTypesFilter &&
      (this.context.timelineFilter.id !== this.state.timelineFilter?.id ||
        this.context.multiChannelsFilter.id !==
          this.state.multiChannelsFilter?.id ||
        this.context.outcomeFilter.id !== this.state.outcomeFilter?.id ||
        this.context.multiCampaignTypesFilter !==
          this.state.multiCampaignTypesFilter)
    ) {
      this.securSetState({
        fadeIn: false,
        timelineFilter: this.context.timelineFilter,
        multiChannelsFilter: this.context.multiChannelsFilter,
        outcomeFilter: this.context.outcomeFilter,
        multiCampaignTypesFilter: this.context.multiCampaignTypesFilter
      });
      this.loadStats();
    }
  }

  async loadStats() {
    timer = setTimeout(() => this.securSetState({ fadeIn: true }), delay);
    const result = await Api.getOverallAverage(
      this.context.timelineFilter.begin,
      this.context.timelineFilter.end,
      this.context.timelineFilter.interval,
      this.context.timelineFilter.id,
      this.context.outcomeFilter.code,
      this.context.multiCampaignTypesFilter,
      this.context.multiChannelsFilter.map((c) => c.id)
    );
    this.formatStats(result);
  }

  formatStats(stats) {
    const s = stats ? stats.duration.average_seconds : 0;
    const minutes = parseInt(s / 60);
    const secondes = s - minutes * 60;
    const average = minutes + 'm ' + secondes + 's';

    //format final data
    let statsFormatted = {
      count: stats ? stats.count : 0,
      per_day: stats ? Math.round(stats.per_day * 100) : 0 + '%',
      average: average
    };

    this.securSetState({
      stats: statsFormatted
    });
  }

  render() {
    const { stats } = this.state;

    return (
      <Card
        title={'Time per action'}
        subtitle={'Average handling time per action'}
        icon={<FontAwesomeIcon icon={faHourglassHalf} />}
      >
        <Fade in={this.state.fadeIn} timeout={{ enter: 500 }}>
          <div>
            <div className={styles.divider}></div>
            <div className={styles.figureWrapper}>
              <div className={styles.figureLabel}>Average</div>
              <div className={styles.figureValue}>{stats?.average || '-'}</div>
            </div>
          </div>
        </Fade>

        {!this.state.fadeIn && <Loader positionY='4rem' />}
      </Card>
    );
  }
}

export default withTranslation('monitoring')(OverallTime);
