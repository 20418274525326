import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import AppContext, { AppProvider } from 'deep/contexts/AppProvider';
import { FiltersProvider } from './contexts/FiltersProvider';
import CssBaseline from '@material-ui/core/CssBaseline';
import Routes from 'utils/Routes';
import Wrapper from './../components/Wrapper';
import Timeline from './components/Timeline';
import Filters from './components/Filters';

class AppMonitoringAnalysis extends Component {
  render() {
    return (
      <AppProvider>
        <AppContext.Consumer>
          {(appContext) => (
            <MuiThemeProvider theme={appContext.theme}>
              <CssBaseline />
              <FiltersProvider>
                <Wrapper
                  me={appContext.me}
                  anonymMonitoring={appContext.clientSettings.anonymMonitoring}
                >
                  <Filters />
                  <Timeline />
                  <Routes />
                </Wrapper>
              </FiltersProvider>
            </MuiThemeProvider>
          )}
        </AppContext.Consumer>
      </AppProvider>
    );
  }
}

export default AppMonitoringAnalysis;
