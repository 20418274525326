import * as FormatTrans from 'deep/api/formatTransModels';

export function customerAttributes(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.customerAttribute(item));
  });
  return dataTranslated;
}

export function contracts(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.contract(item));
  });
  return dataTranslated;
}

export function feedbacks(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.feedback(item));
  });
  return dataTranslated;
}

export function singleFeedback(data) {
  return FormatTrans.feedback(data);
}

export function campaigns(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.campaign(item));
  });
  return dataTranslated;
}

export function opportunities(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.opportunity(item));
  });
  return dataTranslated;
}

export function dataDrivenTips(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.dataDrivenTip(item));
  });
  return dataTranslated;
}

export function reasons(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.reason(item));
  });
  return dataTranslated;
}

export function outcomes(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.outcome(item));
  });
  return dataTranslated;
}

export function products(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.product(item));
  });
  return dataTranslated;
}

export function productsfeatures(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.productFeature(item));
  });
  return dataTranslated;
}

export function interactions(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.interaction(item));
  });
  return dataTranslated;
}

export function proactiveOpportunities(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push({
      ...item,
      offer: FormatTrans.offer(item.offer),
      contracts: contracts(item.contracts),
      campaign_type: FormatTrans.campaignType(item.campaign_type),
      product: FormatTrans.product(item.product)
    });
  });
  return dataTranslated;
}

export function dataCollection(data) {
  return data.map(FormatTrans.collectionQuestion);
}

export function quarantine(data) {
  return data.map(FormatTrans.quarantine);
}

export function faq(data) {
  const thing = data.map(FormatTrans.faq);
  return thing;
}

export function dataDiscovery(data) {
  const formattedData = {
    questions: data.questions && data.questions.map(FormatTrans.dataDiscoveryQuestion),
    answers: data.answers,
    segment: data.segment && FormatTrans.dataDiscoverySegment(data.segment),
  }
  return formattedData
}

const fakeOffer = () => ({

  audience_type: 1,
  display: true,
  id: null,
  margin_level_trans: {},
  offer: {
    argument_sentence_trans: {},
    discount: null,
    id: null,
    others: [],
    post_sale_trans: {},
    selling_sentence_trans: {},
    sub_type_trans: {items: [{text: "Andere", lang: "nl", group_id: null},
                              {text: "Other", lang: "en", group_id: null},
                              {text: "Autre", lang: "fr", group_id: null}]},
    type_trans: {}
  },
  priority: 5,
  product: {},
  script_trans: {},
  segment_trans: {}
})
export function customerOffer(data) {
    // other offer logic
    if (Array.isArray(data.offerScripts)){
        data.offerScripts.push(fakeOffer())
    }
    if(Object.keys(data).length === 0){
      // no offer allow other
      data.offerScripts = [fakeOffer()]
      data.is_other = true;
    }

    const offerData =  {
      offerScripts: data.offerScripts && data.offerScripts.map(FormatTrans.customerOffers),
      selectedOffer: FormatTrans.customerOffer(data.selectedOffer)
    };

    if (data.is_other){
      offerData.selectedOffer = {
        id: null, offer: {id: null}
      }
    }
  return offerData
}

