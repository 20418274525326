import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import Moment from 'react-moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faThumbsUp,
  faThumbsDown,
  faCircle
} from '@fortawesome/free-solid-svg-icons';
import Slide from '@material-ui/core/Slide';
import Grid from '@material-ui/core/Grid';
//import Fade from '@material-ui/core/Fade';
//import Collapse from '@material-ui/core/Collapse';
//import Grow from '@material-ui/core/Grow';
import styles from '../NotificationCenter.module.css';
import NotificationsContext from './../../contexts/NotificationsProvider';
import { FEEDBACKS_LIKE } from './../../utils/Constants';

class Notification extends Component {
  static contextType = NotificationsContext;

  constructor(props) {
    super(props);
    this.state = {
      actionsOpen: false
    };
  }

  closeActions() {
    this.setState({ actionsOpen: false });
  }

  openActions() {
    this.setState({ actionsOpen: true });
  }

  handleMouseOver(event) {
    event.stopPropagation();
    this.openActions();
  }
  handleMouseOut(event) {
    event.stopPropagation();
    this.closeActions();
  }
  handleLike(event) {
    event.stopPropagation();
    this.context.like(this.props.notification);
    this.closeActions();
  }

  handleUnlike(event) {
    event.stopPropagation();
    this.context.unlike(this.props.notification);
    this.closeActions();
  }

  formatNotification(data) {
    if (!data) {
      return null;
    }

    const dataFormatted = {
      id: data.id,
      date: data.date,
      message:
        data.message_trans[i18next.language] ||
        data.message_trans[i18next.options.fallbackLng[0]],
      like: data.like,
      read: data.read,
      category: data.category
    };

    return dataFormatted;
  }

  renderUnread(notification) {
    if (notification.read) {
      return null;
    }

    return (
      <div className={styles.Unread}>
        <Grid
          container
          direction='column'
          justify='center'
          style={{ height: '100%' }}
        >
          <Grid item>
            <FontAwesomeIcon className={styles.Icon} icon={faCircle} />
          </Grid>
        </Grid>
      </div>
    );
  }

  renderActions(notification) {
    if (notification.like !== FEEDBACKS_LIKE.UNKNOWN) {
      return null;
    }

    const { actionsOpen } = this.state;

    return (
      <Slide
        direction='left'
        in={actionsOpen}
        mountOnEnter
        unmountOnExit
        timeout={{ enter: 200, exit: 100 }}
      >
        <div className={styles.Actions}>
          <Grid
            container
            direction='column'
            justify='center'
            style={{ height: '100%' }}
          >
            <Grid item style={{ marginBottom: '0.8rem' }}>
              <FontAwesomeIcon
                className={`${styles.Icon} ${styles.Up}`}
                icon={faThumbsUp}
                onClick={(event) => this.handleLike(event)}
              />
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                className={`${styles.Icon} ${styles.Down}`}
                icon={faThumbsDown}
                onClick={(event) => this.handleUnlike(event)}
              />
            </Grid>
          </Grid>
        </div>
      </Slide>
    );
  }

  render() {
    const notification = this.formatNotification(this.props.notification);

    if (!notification) {
      return null;
    }

    let datetimeFormat = 'mmt-time';
    const now = new Date();
    const last7daysStartDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7
    );
    const last7daysEndDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 1
    );
    last7daysEndDate.setMilliseconds(last7daysEndDate.getMilliseconds() - 1);
    if (
      notification.date >= last7daysStartDate &&
      notification.date <= last7daysEndDate
    ) {
      datetimeFormat = 'mmt-datetime';
    }

    const category =
      notification.category.name_trans[i18next.language] ||
      notification.category.name_trans[i18next.options.fallbackLng[0]];

    return (
      <div className={styles.Notification}>
        <div
          className={styles.NotificationHeader}
          style={{ backgroundColor: notification.category.color }}
        >
          <Grid container spacing={1} justify='space-between'>
            <Grid item>{category}</Grid>
            <Grid item>
              {notification.like !== FEEDBACKS_LIKE.UNKNOWN && (
                <FontAwesomeIcon
                  className={styles.FeedbackIcon}
                  icon={
                    notification.like === FEEDBACKS_LIKE.LIKE
                      ? faThumbsUp
                      : faThumbsDown
                  }
                />
              )}
            </Grid>
          </Grid>
        </div>
        <div
          className={`${styles.Message} ${
            notification.like === FEEDBACKS_LIKE.UNKNOWN ? styles.Pointer : ''
          }`}
          onMouseOver={(event) => this.handleMouseOver(event)}
          onMouseOut={(event) => this.handleMouseOut(event)}
        >
          {this.renderActions(notification)}
          {this.renderUnread(notification)}
          {notification.message}
        </div>
        <div className={styles.Time}>
          <Moment
            date={notification.date}
            format={this.props.t('center.' + datetimeFormat)}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation('notification')(Notification);
