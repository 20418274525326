import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

export default function DropDownList(props) {
  const selected = props.value;
  const [expanded, setExpanded] = React.useState(false);

  const options = props.options;
  const placeholder = props.placeholder;

  const handleToggle = (even) => {
    if (!props.disabled) {
      if (!selected || (options && options.length > 1)) {
        setExpanded(!expanded);
      }
    }
  };
  const handleSelect = (event, option) => {
    if (option || options.length > 1) {
      setExpanded(false);
      props.onChange(option);
    }
  };

  const handleClickAway = (event) => {
    setExpanded(false);
  };

  const styles = {
    container: {
      position: 'relative',
      minHeight: '3.9rem'
    },
    disabled: {
      position: 'relative',
      minHeight: '3.9rem',
      opacity: 0.7
    },
    wrapper: {
      position: 'absolute',
      zIndex: props.zIndex ? props.zIndex : 30,
      top: 0,
      border:
        props.size === 'small' ? '1px solid #e2e6eb' : '1px solid #bcbcbc',
      borderRadius: '1.8rem',
      width: props.fullWidth ? '100%' : 'auto'
    },
    paper: {
      borderRadius: '1.8rem',
      padding: '0rem 1rem'
    },
    selectedItem: {
      position: 'relative',
      cursor:
        !props.disabled && (!selected || (options && options.length > 1))
          ? 'pointer'
          : 'default',
      padding:
        props.size === 'small'
          ? '0.9rem 1rem 0.9rem 0rem'
          : '0.8rem 1rem 0.9rem 0rem',
      picto: {
        position: 'absolute',
        top: '40%',
        transform: 'translateY(-50%)'
      },
      label: {
        marginLeft: '1rem',
        marginRight: '2rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        fontSize: props.size === 'small' ? '1.3rem' : '1.5rem'
      },
      placeholder: {
        color: '#b6b8bc'
      },
      arrow: {
        position: 'absolute',
        fontSize: '2rem',
        top: props.size === 'small' ? '0.8rem' : '0.9rem',
        right: '-0.3rem'
      }
    },
    divider: {
      marginBottom: '0.5rem'
    },
    subItemBt: {
      textTransform: 'none',
      textAlign: 'left',
      padding: '0.8rem 1rem',
      lineHeight: '1',
      backgroundColor: 'transparent',
      fontSize: props.size === 'small' ? '1.3rem' : '1.5rem'
    }
  };

  const ButtonStyled = withStyles((theme) => ({
    root: {
      width: '100%'
    },
    label: {
      justifyContent: 'flex-start'
    },
    text: {
      '&:hover': {
        color: theme.palette.primary.main
      }
    },
    disabled: {
      pointerEvents: 'none'
    }
  }))(Button);

  return (
    <div style={props.disabled ? styles.disabled : styles.container}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div style={styles.wrapper}>
          <Paper elevation={expanded ? 4 : 0} style={styles.paper}>
            <Collapse
              in={expanded}
              collapsedHeight={props.size === 'small' ? '3.6rem' : '3.8rem'}
            >
              <div style={styles.selectedItem} onClick={handleToggle}>
                <div style={styles.selectedItem.label}>
                  <span
                    style={!selected ? styles.selectedItem.placeholder : null}
                  >
                    {selected ? selected.label : placeholder}
                  </span>
                </div>
                {!props.disabled &&
                  (!selected || (options && options.length > 1)) && (
                    <ExpandMoreIcon style={styles.selectedItem.arrow} />
                  )}
              </div>

              {options && options.length > 0 && (
                <>
                  <Divider style={styles.divider} />
                  {options.map((option, index) =>
                    option.id !== selected?.id ? (
                      <div key={index}>
                        <ButtonStyled
                          style={styles.subItemBt}
                          variant='text'
                          disableElevation
                          disableRipple
                          disabled={!expanded}
                          onClick={(event) => handleSelect(event, option)}
                        >
                          {option.label}
                        </ButtonStyled>
                      </div>
                    ) : (
                      <div key={index}></div>
                    )
                  )}
                  <div style={{ height: '0.8rem' }}></div>
                </>
              )}
            </Collapse>
          </Paper>
        </div>
      </ClickAwayListener>
    </div>
  );
}
