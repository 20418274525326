import React from 'react';
import * as MwHistory from 'utils/MwHistory';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartPie,
  // faChartBar,
  faTachometerAlt,
  faAngleDoubleRight,
  faAngleDoubleLeft,
  faUserCircle,
  faUsers
} from '@fortawesome/free-solid-svg-icons';
// import { faClock } from '@fortawesome/free-regular-svg-icons';
import logo_engie from '../../../demo/logos/engie_logo_blue.png';

// import bt_buddy from 'assets/images/monitoring-buddy.svg';

const drawerMaxiWidth = 240;
const drawerMiniWidth = 68;
const primaryColor = 'rgba(0, 140, 255, 1)';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      overflow: 'hidden'
    },
    menuHeader: {
      padding: '2.2rem 1.6rem 1.6rem',
      cursor: 'pointer'
    },
    appBarPosition: {
      left: drawerMiniWidth
    },
    appBar: {
      boxShadow:
        '0px 2px 4px -1px rgba(0,0,0,0.02), 0px 4px 5px 0px rgba(0,0,0,0.02), 0px 1px 10px 0px rgba(0,0,0,0.02)',
      backgroundColor: '#fff',
      color: '#3A404D',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      })
    },
    appBarShift: {
      marginLeft: drawerMaxiWidth - drawerMiniWidth,
      width: `calc(100% - ${drawerMaxiWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    gridHeader: {
      width: `calc(100% - ${drawerMiniWidth}px)`,
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    gridHeaderShift: {
      width: `calc(100%)`,
      transition: theme.transitions.create(['width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    menuButton: {
      marginRight: 36
    },
    hide: {
      display: 'none'
    },
    drawer: {
      width: drawerMaxiWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap'
    },
    drawerOpen: {
      overflow: 'hidden',
      backgroundColor: '#3F4757',
      width: drawerMaxiWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    drawerClose: {
      overflow: 'hidden',
      backgroundColor: '#3F4757',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),
      overflowX: 'hidden',
      width: theme.spacing(4) + 1,
      [theme.breakpoints.up('sm')]: {
        width: drawerMiniWidth
      }
    },
    title: {
      fontWeight: 600,
      fontSize: '1.8rem'
    },
    toolbar: {
      minHeight: '50px !important',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar
    },
    content: {
      flexGrow: 1,
      padding: '1.4rem 2.4rem',
      marginTop: 60
    },
    menuListItem: {
      padding: '0.4rem 2.5rem',
      marginBottom: '1rem',
      width: 'fit-content',
      fontSize: '1.3rem',
      color: '#fff',
      '&:hover': {
        //color: primaryColor,
        //backgroundColor: 'transparent'
      },
      '&&selected': {
        backgroundColor: 'transparent'
      }
    },
    menuListItemSelected: {
      padding: '0.4rem 2.1rem',
      color: primaryColor,
      borderLeft: '4px solid ' + primaryColor,
      backgroundColor: 'transparent !important'
    },
    menuListItemIcon: {
      fontSize: '1.7rem',
      color: '#fff',
      minWidth: '4.3rem',
      opacity: 0.8
    },
    menuListItemIconSelected: {
      fontSize: '1.7rem',
      color: primaryColor,
      minWidth: '4.3rem'
    },
    menuListItemText: {
      fontWeight: 600,
      fontSize: '1.3rem'
    },
    menuFooterArrow: {
      fontSize: '1.5rem',
      color: '#fff',
      opacity: 0.8,
      cursor: 'pointer',
      marginLeft: '0.2rem',
      marginBottom: '0.8rem'
    },
    menuFooterHide: {
      display: 'none'
    },
    menuFooterProfileIcon: {
      fontSize: '1.7rem',
      color: '#fff',
      opacity: 0.8
    },
    menuFooterUsername: {
      color: '#fff',
      fontSize: '1.3rem',
      fontWeight: 600
    },
    menuFooterLogout: {
      color: primaryColor,
      fontSize: '1.3rem',
      fontWeight: 600,
      cursor: 'pointer'
    }
  })
);

export default function Wrapper(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [pathname, setPathname] = React.useState(window.location.pathname);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const redirect = (event, index, route) => {
    event.preventDefault();
    MwHistory.push(route);
    setPathname(window.location.pathname);
  };

  const logout = (event) => {
    MwHistory.redirect('/login');
  };

  const menuItems = [
    {
      icon: <FontAwesomeIcon icon={faTachometerAlt} />,
      label: 'Overall Performance',
      title: 'Overall Performance',
      route: '/monitoring/analysis/overall-performance'
    },
    // {
    //   icon: <FontAwesomeIcon icon={faClock} />,
    //   label: 'Time Evolution',
    //   title: 'Time Evolution',
    //   route: '/monitoring/analysis/time-evolution'
    // },
    // {
    //   icon: <FontAwesomeIcon icon={faChartBar} />,
    //   label: 'Cust. Feedback Analysis',
    //   title: 'Cust. Feedback Analysis',
    //   route: '/monitoring/analysis/feedback-analysis'
    // },
    {
      icon: <FontAwesomeIcon icon={faChartPie} />,
      label: 'Interaction Outcome',
      title: 'Interaction Outcome',
      route: '/monitoring/analysis/interaction-outcome'
    },
    ...(!props.anonymMonitoring
      ? [
          {
            icon: <FontAwesomeIcon icon={faUsers} />,
            label: 'User Analysis',
            title: 'User Analysis',
            route: '/monitoring/analysis/user-analysis'
          }
        ]
      : [])
    // {
    //   icon: <img src={bt_buddy} alt="Buddy Notifications" style={{transform: 'translate(-1.2rem, 0rem)'}} />,
    //   label: 'Buddy Notifications',
    //   title: 'Buddy Notifications',
    //   route: '/monitoring/notifications/rules'
    // }
  ];

  let title = '';
  for (let i = 0; i < menuItems.length; i++) {
    if (pathname.indexOf(menuItems[i].route) > 0) {
      title = menuItems[i].title;
      break;
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position='fixed'
        elevation={0}
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open
        })}
        classes={{ positionFixed: classes.appBarPosition }}
      >
        <Toolbar className={classes.toolbarTop}>
          <span className={classes.title}>{title}</span>
        </Toolbar>
      </AppBar>
      <Drawer
        variant='permanent'
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open
          })
        }}
      >
        <div className={classes.menuHeader} onClick={handleDrawerToggle}>
          <img
            src={logo_engie}
            alt='Logo'
            style={{ height: '1.7rem', width: 'auto' }}
          />
          <div
            style={{
              position: 'absolute',
              color: '#fff',
              top: '2rem',
              left: '13rem'
            }}
          >
            <div
              style={{
                fontWeight: 700,
                fontSize: '1.1rem',
                letterSpacing: 0.6,
                lineHeight: 0.8
              }}
            >
              MONITORING
            </div>
            <div
              style={{
                fontWeight: 300,
                fontSize: '1.2rem',
                letterSpacing: 1.2
              }}
            >
              DASHBOARD
            </div>
          </div>
        </div>
        <Divider style={{ backgroundColor: '#60646f' }} />
        <List style={{ marginTop: '1.5rem' }}>
          {menuItems.map((item, index) => {
            return (
              <ListItem
                button
                key={index}
                selected={pathname.indexOf(item.route) > 0}
                disableRipple
                disableGutters
                classes={{
                  root: classes.menuListItem,
                  selected: classes.menuListItemSelected
                }}
                onClick={(event) => {
                  redirect(event, index, item.route);
                }}
              >
                <ListItemIcon
                  classes={{
                    root:
                      pathname.indexOf(item.route) > -1
                        ? classes.menuListItemIconSelected
                        : classes.menuListItemIcon
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.label}
                  classes={{ primary: classes.menuListItemText }}
                />
              </ListItem>
            );
          })}
        </List>
        <div
          style={{
            position: 'absolute',
            bottom: '1.6rem',
            paddingLeft: '2.7rem'
          }}
        >
          <FontAwesomeIcon
            icon={faAngleDoubleRight}
            className={clsx(classes.menuFooterArrow, {
              [classes.menuFooterHide]: open
            })}
            onClick={handleDrawerOpen}
          />
          <Grid
            container
            spacing={2}
            alignItems='center'
            style={{ width: 200 }}
            className={clsx({ [classes.menuFooterHide]: !open })}
          >
            <Grid item>
              <FontAwesomeIcon
                icon={faUserCircle}
                className={classes.menuFooterProfileIcon}
              />
            </Grid>
            <Grid item>
              <div className={classes.menuFooterUsername}>
                {props.me.user.first_name}&nbsp;{props.me.user.last_name}
              </div>
              <div className={classes.menuFooterLogout} onClick={logout}>
                Logout
              </div>
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                icon={faAngleDoubleLeft}
                className={clsx(classes.menuFooterArrow, {
                  [classes.menuFooterHide]: !open
                })}
                style={{ position: 'absolute', right: 0, top: 9 }}
                onClick={handleDrawerClose}
              />
            </Grid>
          </Grid>
        </div>
      </Drawer>
      <main className={classes.content}>{props.children}</main>
    </div>
  );
}
