import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import AppContext from 'deep/contexts/AppProvider';
import * as MwHistory from 'utils/MwHistory';
import * as Api from '../api/endpoints';

class OktaLogin extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      authenticated: null,
      loginError: null,
      requestExecuting: false
    };
    this.setStateValue = this.setStateValue.bind(this);
    this.onMeSucceed = this.onMeSucceed.bind(this);
    this.onRequestFailed = this.onRequestFailed.bind(this);
    this.checkAuthenticationAndLogin = this.checkAuthenticationAndLogin.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.securSetState({
      requestExecuting: true
    });
    this.checkAuthenticationAndLogin()
  }

  componentWillUnmount() {
    this.mounted = false;
  }
  
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  UNSAFE_componentWillMount() {
    this.context.clearMe();
  }

  setStateValue(field, value) {
    this.securSetState({
      [field]: value,
      loginError: null
    });
  }

  async checkAuthenticationAndLogin() {
    const authenticated = await this.props.authState.isAuthenticated;
    if (authenticated !== this.state.authenticated) {
      this.okta_login();
    }
  }

  async okta_login() {
    try {
      const response = await Api.okta_login(
        this.props.authState.accessToken.accessToken
      );
      this.onLoginSucceed(response);
    } catch (error) {
      this.onRequestFailed(error);
    }
  }  

  onLoginSucceed(response) {
    this.securSetState({
      requestExecuting: false,
      loginError: null
    });
    this.getMe();
  }

  async getMe() {
    try {
      const response = await Api.getMe();
      this.onMeSucceed(response);
    } catch (error) {
      this.onRequestFailed(error);
    }
  }

  onMeSucceed(response) {
    this.securSetState({
      requestExecuting: false,
      loginError: null
    });
    this.context.setMe(response);
    MwHistory.replace('/cco');
  }

  onRequestFailed(error) {
    this.securSetState({
      requestExecuting: false,
      loginError: error
    });
    this.context.clearMe();
  }

  render() {
    if (this.props.authState.isPending) return <div>Loading...</div>;
    if (this.state.requestExecuting) return <div>Request Executing...</div>;
    return <div></div>;
  }
};

export default withOktaAuth(OktaLogin);