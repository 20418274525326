import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faDotCircle } from '@fortawesome/free-regular-svg-icons';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import styles from '../NotificationCenter.module.css';
import NotificationsContext from './../../contexts/NotificationsProvider';
import { PUSH_SETTINGS } from './../../utils/Constants';
import SimpleSwitch from 'deep/components/materials/SimpleSwitch';

class Header extends Component {
  static contextType = NotificationsContext;

  handleChangeOption(event, setting) {
    event.stopPropagation();
    const { pushActivated } = this.context;
    if (pushActivated) {
      this.context.changePushSetting(setting.id);
    }
  }

  handleChangeSwitch(checked) {
    if (checked) {
      this.context.deactivatePush();
    } else {
      this.context.activatePush();
    }
  }

  renderOptions() {
    const { pushActivated, pushSetting } = this.context;

    const settings = [
      {
        id: PUSH_SETTINGS.MICRO,
        checked: pushSetting === PUSH_SETTINGS.MICRO,
        label: this.props.t('center.txt-micro'),
        preview: require('./../../assets/setting_micro.svg')
      },
      {
        id: PUSH_SETTINGS.DETAILED,
        checked: pushSetting === PUSH_SETTINGS.DETAILED,
        label: this.props.t('center.txt-detailed'),
        preview: require('./../../assets/setting_detailed.svg')
      }
    ];

    return (
      <List
        component='nav'
        aria-label='settings'
        className={styles.List}
        style={{
          backgroundColor: '#FAFAFA',
          opacity: pushActivated ? 1 : 0.3,
          cursor: pushActivated ? 'pointer' : 'default'
        }}
      >
        {settings.map((setting, index) => (
          <ListItem
            key={index}
            className={styles.Cell}
            style={{ backgroundColor: 'transparent' }}
            onClick={(event) => this.handleChangeOption(event, setting)}
          >
            <ListItemText className={styles.Option}>
              <FontAwesomeIcon
                className={styles.Icon}
                icon={setting.checked ? faDotCircle : faCircle}
              />{' '}
              {setting.label}
            </ListItemText>

            {setting.preview && (
              <ListItemIcon edge='end' className={styles.Preview}>
                <img src={setting.preview} alt='preview' />
              </ListItemIcon>
            )}
          </ListItem>
        ))}
      </List>
    );
  }

  render() {
    const { pushActivated } = this.context;

    return (
      <div className={styles.Header}>
        <Grid container spacing={1} justify='space-between'>
          <Grid item className={styles.Title}>
            {this.props.t('center.txt-title')}
          </Grid>
          <Grid item style={{ paddingTop: '0.9rem' }}>
            <SimpleSwitch
              default={!pushActivated}
              color='#12AD56'
              labelOff={this.props.t('center.txt-deactivated')}
              labelOn={null}
              onChange={(checked) => this.handleChangeSwitch(checked)}
            />
          </Grid>
        </Grid>
        {this.renderOptions()}
      </div>
    );
  }
}

export default withTranslation('notification')(Header);
