import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './Progress.module.css';
import Avatar from '@material-ui/core/Avatar';

class Progress extends Component {
  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }

  renderMeAvatar(me, prc) {
    return (
      <Avatar
        className={styles.Avatar}
        src={me?.user?.avatar?.file}
        style={{ left: 'calc(' + prc + '% - 0rem)' }}
      />
    );
  }

  render() {
    const { myKeyInfo, me } = this.props;

    if (!myKeyInfo) {
      return <div />;
    }

    const currentLevel = myKeyInfo.current_level;
    const currentLevelName = this.getTrans(currentLevel.name_trans);
    const currentLevelIcon = currentLevel.image;
    const nextLevel = myKeyInfo.next_level;
    const nextLevelName = this.getTrans(nextLevel.name_trans);
    const nextLevelIcon = nextLevel.image;
    const mePoints = myKeyInfo.points;
    const prc =
      100 *
      ((mePoints - currentLevel.points) /
        (nextLevel.points - currentLevel.points));

    return (
      <div className={styles.Progress}>
        <img
          className={styles.CurrentLevelIcon}
          alt={currentLevelName}
          src={currentLevelIcon}
        />
        <div className={styles.PrevPts}>
          {currentLevel.points} {this.props.t('progress.pts')}
        </div>
        <div className={styles.Bar}>
          {this.renderMeAvatar(me, prc)}
          <div
            className={styles.Line}
            style={{ left: 'calc(' + prc + '% - 0.2rem)' }}
          ></div>
          <div className={styles.BgBar}></div>
          <div
            className={styles.CurrentLevelBar}
            style={{
              width: 'calc(' + prc + '% - 0rem)',
              backgroundColor: currentLevel.color
            }}
          ></div>
        </div>
        <img
          className={styles.NextLevelIcon}
          alt={nextLevelName}
          src={nextLevelIcon}
        />
        <div className={styles.NextPts}>
          {nextLevel.points} {this.props.t('progress.pts')}
        </div>
      </div>
    );
  }
}

export default withTranslation('gamification')(Progress);
