import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';
import { STORAGE_VARS } from 'utils/Constants';
import i18next from 'i18next';

import monitoring_en from './translations/en/monitoring.json';
import common_en from './translations/en/common.json';
import cco_en from './translations/en/cco.json';
import gamification_en from './translations/en/gamification.json';
import notification_en from './translations/en/notification.json';

import monitoring_fr from './translations/fr/monitoring.json';
import common_fr from './translations/fr/common.json';
import cco_fr from './translations/fr/cco.json';
import gamification_fr from './translations/fr/gamification.json';
import notification_fr from './translations/fr/notification.json';

import monitoring_de from './translations/de/monitoring.json';
import common_de from './translations/de/common.json';
import cco_de from './translations/de/cco.json';
import gamification_de from './translations/de/gamification.json';
import notification_de from './translations/de/notification.json';

import cco_nl from './translations/nl/cco.json';
import monitoring_nl from './translations/nl/monitoring.json';
import common_nl from './translations/nl/common.json';
import gamification_nl from './translations/nl/gamification.json';
import notification_nl from './translations/nl/notification.json';

import { Security  } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import History from 'utils/History';

const oktaAuth = new OktaAuth({
  pkce:  false,
  issuer: window.location.hostname === 'engie-cmb.gamma.bcg.com' ? 'https://engie.okta-emea.com/oauth2/default' : window.location.hostname === 'engie-cmb-pre.gamma.bcg.com' ? 'https://engieapppreview.oktapreview.com/oauth2/default' : 'https://dev-98791661.okta.com/oauth2/default',
  clientId: window.location.hostname === 'engie-cmb.gamma.bcg.com' ? '0oa59khpzgE3mmiAh0i7' : window.location.hostname === 'engie-cmb-pre.gamma.bcg.com' ? '0oa1a8fdmlpF2V0mB0x7' : '0oa5zao5dpa17tBcs5d6',
  redirectUri: window.location.origin + '/okta/callback',
  restoreOriginalUri: async (oktaAuth, originalUri) => {
    History.push('/okta/login')
  }
});

i18next.init({
  fallbackLng: 'en',
  interpolation: { escapeValue: false }, // React already does escaping
  react: {
    useSuspense: false
  },
  lng: localStorage.getItem(STORAGE_VARS.LANGUAGE)
    ? localStorage.getItem(STORAGE_VARS.LANGUAGE)
    : 'en',
  resources: {
    en: {
      common: common_en,
      cco: cco_en,
      gamification: gamification_en,
      notification: notification_en,
      monitoring: monitoring_en
    },
    fr: {
      common: common_fr,
      cco: cco_fr,
      gamification: gamification_fr,
      notification: notification_fr,
      monitoring: monitoring_fr
    },
    de: {
      common: common_de,
      cco: cco_de,
      gamification: gamification_de,
      notification: notification_de,
      monitoring: monitoring_de,
    },
    nl: {
      common: common_nl,
      cco: cco_nl,
      gamification: gamification_nl,
      notification: notification_nl,
      monitoring: monitoring_nl
    }
  }
});

ReactDOM.render(
  //<React.StrictMode>
  <Security oktaAuth={oktaAuth}>
    <BrowserRouter>
      <I18nextProvider i18n={i18next}>
        <App />
      </I18nextProvider>
    </BrowserRouter>
  </Security>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
