import React, { Component } from 'react';
import * as MwHistory from 'utils/MwHistory';

import AppMonitoringAnalysis from './analysis/AppMonitoringAnalysis';
import AppMonitoringNotifications from './notifications/AppMonitoringNotifications';

class AppMonitoring extends Component {

  render() {
    const currentRoute = MwHistory.getCurrentRoute();

    if (currentRoute.path.indexOf('/monitoring/analysis') > -1) {
      return <AppMonitoringAnalysis />;
    } else if (currentRoute.path.indexOf('/monitoring/notifications') > -1) {
      return <AppMonitoringNotifications />;
    } else {
      return <AppMonitoringAnalysis />;
    }
  }

}

export default AppMonitoring;
