import React, { Component } from 'react';
import History from 'utils/History';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './NotificationsAccess.module.css';
import NotificationsContext from './../contexts/NotificationsProvider';

class NotificationsAccess extends Component {
  static contextType = NotificationsContext;

  componentDidMount() {
    History.listen((location) => {
      this.forceUpdate();
    });
  }

  handleClick(event) {
    event.stopPropagation();
    this.context.toggleNotificationsCenter();
  }

  render() {
    const { unreadNotificationsCount } = this.context;

    const access = require('./../assets/buddy_access.svg');

    const pathname = History.location.pathname;
    const isHome = pathname.slice(-4) === '/cco';

    return (
      <div
        className={`${styles.Access} ${isHome ? styles.Big : ''}`}
        onClick={(event) => this.handleClick(event)}
      >
        {unreadNotificationsCount > 0 && (
          <FontAwesomeIcon className={styles.Bubble} icon={faCircle} />
        )}
        <img src={access} alt='Notifications Center' />
      </div>
    );
  }
}

export default withTranslation('notification')(NotificationsAccess);
