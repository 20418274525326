import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import EnumerationTable from 'deep/components/materials/EnumerationTable';
import CallSessionContext from './../../contexts/CallSessionProvider';

class AdditionalProducts extends Component {
  static contextType = CallSessionContext;

  UNSAFE_componentWillMount() {
    this.rendered = false;
  }

  componentDidUpdate() {
    if (
      !this.rendered &&
      this.context.products &&
      this.context.portfolio &&
      this.context.allProducts
    ) {
      this.rendered = true;
      this.props.onRender(true);
    }
  }

  formatProducts(clientSettings, allProducts, products, portfolio) {
    if (!products || !portfolio || !allProducts) {
      return null;
    }

    let ids = [];
    products.forEach((product) => {
      ids.push(product.id);
    });
    portfolio.forEach((portfolio) => {
      if (portfolio.product) {
        ids.push(portfolio.product.id);
      }
    });

    let additionalProducts = [];
    allProducts.forEach((product) => {
      if (!ids.includes(product.id)) {
        additionalProducts.push({
          id: product.id,
          name:
            product.name_trans[i18next.language] ||
            product.name_trans[i18next.options.fallbackLng[0]],
          value: clientSettings.currency + product.price.toFixed(2),
          numeric: true
        });
      }
    });
    return additionalProducts;
  }

  render() {
    const { clientSettings, allProducts, products, portfolio } = this.context;

    const additionalProducts = this.formatProducts(
      clientSettings,
      allProducts,
      products,
      portfolio
    );

    return (
      <div>
        {additionalProducts && <EnumerationTable data={additionalProducts} />}
      </div>
    );
  }
}

export default withTranslation('cco')(AdditionalProducts);
