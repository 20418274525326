import React, { Component } from 'react';
import styles from '../NotificationCenter.module.css';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import Notification from './Notification';
import NotificationsContext from './../../contexts/NotificationsProvider';

class Group extends Component {
  static contextType = NotificationsContext;

  render() {
    const { title, notifications, startDate, endDate } = this.props;

    const { deleteGroup } = this.context;

    if (!notifications || notifications.length === 0) {
      return null;
    }

    return (
      <div className={styles.Group}>
        <div className={styles.GroupHeader}>
          <Grid container spacing={1} justify='space-between'>
            <Grid item>
              <strong>{title}</strong>
            </Grid>
            <Grid item>
              <FontAwesomeIcon
                className={styles.DeleteIcon}
                icon={faTimesCircle}
                onClick={() => {
                  deleteGroup(startDate, endDate);
                }}
              />
            </Grid>
          </Grid>
        </div>
        {notifications &&
          notifications.map((notification, index) => (
            <Notification
              key={index}
              index={index}
              notification={notification}
            />
          ))}
      </div>
    );
  }
}

export default Group;
