import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './Overall.module.css';
import * as Api from './../api/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import Card from './../components/Card';
import StackedBar from './../charts/StackedBar';
import Fade from '@material-ui/core/Fade';
import Loader from './../components/Loader';
import FiltersContext from './../contexts/FiltersProvider';

let timer = null;
let delay = 1300;

class OverallSuccessRate extends Component {
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
      timelineFilter: null,
      outcomeFilter: null,
      multiCampaignTypesFilter: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.updateState();
  }
  componentDidUpdate() {
    this.updateState();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(timer);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  updateState() {
    if (
      this.context.timelineFilter &&
      this.context.multiChannelsFilter &&
      this.context.multiCampaignTypesFilter &&
      (this.context.timelineFilter.id !== this.state.timelineFilter?.id ||
        this.context.multiChannelsFilter.id !==
          this.state.multiChannelsFilter?.id ||
        this.context.multiCampaignTypesFilter !==
          this.state.multiCampaignTypesFilter)
    ) {
      this.securSetState({
        fadeIn: false,
        timelineFilter: this.context.timelineFilter,
        multiChannelsFilter: this.context.multiChannelsFilter,
        multiCampaignTypesFilter: this.context.multiCampaignTypesFilter
      });
      this.loadStats();
    }
  }

  async loadStats() {
    timer = setTimeout(() => this.securSetState({ fadeIn: true }), delay);
    const result = await Api.getOverallSuccessRate(
      this.context.timelineFilter.begin,
      this.context.timelineFilter.end,
      this.context.timelineFilter.interval,
      this.context.timelineFilter.id,
      this.context.multiCampaignTypesFilter,
      this.context.multiChannelsFilter.map((c) => c.id)
    );
    this.formatStats(result);
  }

  formatStats(stats) {
    let statsFormatted = [];
    stats.forEach((data) => {
      statsFormatted.push({
        // label: this.props.t('common.' + data.label),
        label: data.label,
        value: data.count,
        ratio: data.coef,
        color: statsFormatted.length === 0 ? '#0BDCE9' : null
      });
    });

    this.securSetState({
      stats: statsFormatted
    });
  }

  render() {
    const { stats } = this.state;

    return (
      <Card
        title='Success rate'
        subtitle='Ratio of successful and unsuccessful actions'
        icon={<FontAwesomeIcon icon={faPhoneAlt} />}
      >
        <Fade in={this.state.fadeIn} timeout={{ enter: 500 }}>
          <div>
            <div className={styles.divider}></div>
            <div style={{ width: '95%' }}>
              <StackedBar data={stats} />
            </div>
          </div>
        </Fade>

        {!this.state.fadeIn && <Loader positionY='4rem' />}
      </Card>
    );
  }
}

export default withTranslation('monitoring')(OverallSuccessRate);
