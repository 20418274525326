import React, { Component } from 'react';
import { withOktaAuth } from '@okta/okta-react';
import AppContext from 'deep/contexts/AppProvider';

class OktaHomeCallback extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.mounted = true;

    const oktaHomeLogin = new URLSearchParams(this.props.location.search).get(
      'iss'
    );
    if (oktaHomeLogin !== null) {
      this.props.oktaAuth.signInWithRedirect();
    } else {
      // Do nothing
    }
  }

  render() {
    return <div>Loading...</div>;
  }
}

export default withOktaAuth(OktaHomeCallback);
