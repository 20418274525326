import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './Overall.module.css';
import * as Api from './../api/endpoints';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import Card from './../components/Card';
import Histogram from './../charts/Histogram';
import Loader from './../components/Loader';
import Fade from '@material-ui/core/Fade';
import Grid from '@material-ui/core/Grid';
import FiltersContext from './../contexts/FiltersProvider';
import Moment from 'react-moment';
import { FILTERS_TIMELINE } from './../contexts/FiltersProvider';

let timer = null;
let delay = 800;

class OverallActiveAgents extends Component {
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
      timelineFilter: null,
      multiChannelsFilter: null,
      stats: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.updateState();
  }
  componentDidUpdate() {
    this.updateState();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(timer);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  updateState() {
    if (
      this.context.timelineFilter &&
      this.context.multiChannelsFilter &&
      (this.context.timelineFilter.id !== this.state.timelineFilter?.id ||
        this.context.multiChannelsFilter.id !==
          this.state.multiChannelsFilter?.id)
    ) {
      this.securSetState({
        fadeIn: false,
        timelineFilter: this.context.timelineFilter,
        multiChannelsFilter: this.context.multiChannelsFilter
      });
      this.loadStats();
    }
  }

  async loadStats() {
    timer = setTimeout(() => this.securSetState({ fadeIn: true }), delay);
    const result = await Api.getOverallActiveAgents(
      this.context.timelineFilter.begin,
      this.context.timelineFilter.end,
      this.context.timelineFilter.interval,
      this.context.timelineFilter.id,
      this.context.multiChannelsFilter.map((c) => c.id)
    );
    this.formatStats(result);
  }

  formatStats(stats) {
    //get higher count for histogram
    let maxCount = 1;
    stats.results.forEach((data) => {
      if (data.count > maxCount) {
        maxCount = data.count;
      }
    });

    let prefix = '';
    let labelFormat = '';
    switch (this.context.timelineFilter.id) {
      case FILTERS_TIMELINE.WEEK.id:
      case FILTERS_TIMELINE.WEEKTODATE.id:
        labelFormat = 'ddd';
        break;
      case FILTERS_TIMELINE.MONTH.id:
      case FILTERS_TIMELINE.MONTHTODATE.id:
        labelFormat = 'w';
        prefix = 'Week';
        break;
      case FILTERS_TIMELINE.YEARTODATE.id:
        labelFormat = 'MMM';
        break;
      default:
    }

    //format chart data for histogram
    let chartData = [];
    stats.results.forEach((data) => {
      const date = new Date(data.begin);
      chartData.push({
        label: (
          <>
            {prefix}{' '}
            <Moment
              date={date}
              format={labelFormat}
              locale={this.props.i18n.language}
            />
          </>
        ),
        value: data.count,
        ratio: data.count / maxCount
      });
    });

    //format final data
    let statsFormatted = {
      users: stats.users.toString(),
      trends:
        stats.trends > 0
          ? '+' + Math.round(stats.trends * 100) + '%'
          : Math.round(stats.trends * 100) + '%',
      previous: stats.previous.toString(),
      chartData: chartData
    };

    this.securSetState({
      stats: statsFormatted
    });
  }

  render() {
    let colWidth = 0;
    const { timelineFilter, stats } = this.state;

    switch (timelineFilter?.id) {
      case FILTERS_TIMELINE.WEEK.id:
      case FILTERS_TIMELINE.WEEKTODATE.id:
        colWidth = 4;
        break;
      case FILTERS_TIMELINE.MONTH.id:
      case FILTERS_TIMELINE.MONTHTODATE.id:
        colWidth = 5.8;
        break;
      case FILTERS_TIMELINE.YEARTODATE.id:
        colWidth = 3.2;
        break;
      default:
    }

    return (
      <Card
        title={this.props.t('active-agents.title')}
        subtitle={this.props.t('active-agents.subtitle')}
        icon={<FontAwesomeIcon icon={faUser} />}
      >
        <Fade in={this.state.fadeIn} timeout={{ enter: 500 }}>
          <Grid
            container
            spacing={1}
            alignItems='flex-end'
            justify='flex-start'
            style={{ maxWidth: 500 }}
          >
            <Grid item>
              <div className={styles.figuresTitle}>
                {this.props.t(timelineFilter?.label)}
              </div>
              <div
                className={styles.figureWrapper}
                style={{ marginBottom: '2.1rem' }}
              >
                <div className={styles.figureLabel}>
                  {this.props.t('common.users')}
                </div>
                <div
                  className={styles.figureValue}
                  style={{ color: '#19ABB4' }}
                >
                  {stats?.users || '-'}
                </div>
              </div>
              <div className={styles.figureWrapper}>
                <div className={styles.figureLabel}>
                  {this.props.t('common.trends')}
                </div>
                <div
                  className={styles.figureValue}
                  style={{ color: '#17B554' }}
                >
                  {stats?.trends || '-'}
                </div>
              </div>
              <div className={styles.figuresTitle}>
                {this.props.t('common.previous')}
              </div>
              <div className={styles.figureWrapper}>
                <div className={styles.figureLabel}>
                  {this.props.t('common.users')}
                </div>
                <div className={styles.figureValue}>
                  {stats?.previous || '-'}
                </div>
              </div>
            </Grid>
            <Grid item>
              {stats?.chartData && (
                <Histogram
                  data={stats.chartData}
                  colWidth={colWidth}
                  barWidth={0.4}
                  barHeight={10}
                />
              )}
            </Grid>
          </Grid>
        </Fade>

        {!this.state.fadeIn && <Loader positionY='3rem' />}
      </Card>
    );
  }
}

export default withTranslation('monitoring')(OverallActiveAgents);
