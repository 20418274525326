import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './Feedback.module.css';
import * as MwHistory from 'utils/MwHistory';
import * as CcoApi from './../../api/endpoints';
import Box from '@material-ui/core/Box';
import SimpleTabsMenu from 'deep/components/materials/SimpleTabsMenu';
import SmartTextarea from 'deep/components/materials/SmartTextarea';
import RadioButtonsList from 'deep/components/materials/RadioButtonsList';
import SubmitButton from 'deep/components/materials/SubmitButton';
import ConfirmTimerModal from 'deep/components/ConfirmTimerModal';
import CallSessionContext from './../../contexts/CallSessionProvider';
import { prettifyScript, TransScriptSep } from './utils';

class Feedback extends Component {
  static contextType = CallSessionContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
      selectedOutcome: -1,
      selectedReason: null,
      selectedQuarantine: null,
      comment: null,
      feedBackRequestExecuting: false,
      quarantineRequestExecuting: false,
      submitError: null,
      regularConfirmModalOpen: false,
      confirmModalTimer: 15,
      intervalId: null,
      feedbackId: null
    };
    this.onChangeTab = this.onChangeTab.bind(this);
    this.onChangeOutcome = this.onChangeOutcome.bind(this);
    this.onChangeReason = this.onChangeReason.bind(this);
    this.onRequestSubmit = this.onRequestSubmit.bind(this);
    this.handleConfirmModal = this.handleConfirmModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.securSetState({
      confirmModalTimer: 15,
      startDate: new Date()
    });
  }
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.state.intervalId);
    clearTimeout(this.timeout);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  componentDidUpdate() {
    if (this.state.selectedOutcome < 0 && this.context.outcomeAcceptedId) {
      this.securSetState({ selectedOutcome: this.context.outcomeAcceptedId });
      if (this.context.contractAccountId) {
        this.getContractAccountFeedback(this.context.contractAccountId)

      }  
    }
  }

  getContractAccountFeedback = async (accId) => {
    try {

      await  CcoApi.getContractAccountFeedback(accId).then((res) => {

        let selectedOutcome = this.state.selectedOutcome;
        if (res.outcome) {
          // selectedOutcome = this.context.outcomes.findIndex(x => x.id === res.outcome.id);
          selectedOutcome = res.outcome.id
        }

        let selectedReason = this.state.selectedReason
        if (res.reason) {
          selectedReason =  res.reason.id
        }

        if (res.offer_comment){
          this.context.setCustomerOfferComment(res.offer_comment)
        }
        if (res.id){
            this.setState({
              // feedbackId: res.id,
              comment: res.comment,
              selectedOutcome: selectedOutcome,
              selectedReason: selectedReason
          })
       }

      });
    } catch (error) {
      console.log(error);
    } 
  }

  onChangeTab = (event, value) => {
    event.preventDefault();
    this.securSetState({
      selectedTab: value,
      submitError: null
    });
  };

  onChangeOutcome = (event, value) => {
    event.preventDefault();
    this.securSetState({
      selectedOutcome: value,
      selectedReason: null,
      selectedQuarantine: null,
      submitError: null
    });
  };

  onChangeReason = (event, value) => {
    event.preventDefault();
    this.securSetState({
      selectedReason: value,
      submitError: null
    });
  };

  onChangeQuarantine = (event, value) => {
    event.preventDefault();
    this.securSetState({
      selectedQuarantine: value,
      submitError: null
    });
  };

  onChangeComment = (value) => {
    this.securSetState({
      comment: value,
      submitError: null
    });
  };

  onRequestSubmit(event) {
    const { selectedQuarantine } = this.state;

    event.preventDefault();
    if (this.state.feedbackId) {
      this.patchFeedback(this.state.feedbackId);
    } else {
      this.postFeedback();
    }
    this.securSetState({
      feedBackRequestExecuting: true
    });
    if (selectedQuarantine) {
      this.securSetState({
        quarantineRequestExecuting: true
      });
      this.postQuarantine();
    }
  }

  async patchFeedback(feedbackId) {
    const ended = new Date().toISOString();
    const comment = this.state.comment;
    const outcome = parseInt(this.state.selectedOutcome);
    const reasonId = parseInt(this.state.selectedReason);
    const { selectedOffer, customerOffers, offerComment  } = this.context;
    const offerId = ((Number.isInteger(selectedOffer) && selectedOffer > -1) && customerOffers.offerScripts[selectedOffer].offer.id ) || null

    try {
      const result = await CcoApi.patchFeedback(
        feedbackId,
        ended,
        comment,
        outcome,
        reasonId,
        offerId,
        offerComment
      );
      this.onSubmitFeedbackSucceed(result);
    } catch (error) {
      this.securSetState({
        feedBackRequestExecuting: false,
        submitError: this.props.t(error)
      });
    }
  }
  async postQuarantine() {
    const customerId = this.context.customer;
    const { selectedQuarantine } = this.state;
    try {
      await CcoApi.postQuarantine(customerId, selectedQuarantine);
    } catch (error) {
      this.securSetState({
        quarantineRequestExecuting: false,
        submitError: this.props.t(error)
      });
    }
  }
  async postFeedback() {
    const opportunityId = null;
    const { selectedOffer, customerOffers } = this.context;
    const offerId = ((Number.isInteger(selectedOffer) && selectedOffer > -1) && customerOffers.offerScripts[selectedOffer].offer.id ) || null
    const started = this.state.startDate.toISOString();
    const ended = new Date().toISOString();
    const customerId = this.context.customer.id;
    const comment = this.state.comment;
    const outcome = parseInt(this.state.selectedOutcome);
    const reasonId = parseInt(this.state.selectedReason);
    const { contractAccountId, offerComment } = this.context;

    try {
      const result = await CcoApi.postFeedback(
        opportunityId,
        offerId,
        started,
        ended,
        customerId,
        comment,
        outcome,
        reasonId,
        contractAccountId,
        offerComment
      );
      this.onSubmitFeedbackSucceed(result);
    } catch (error) {
      this.securSetState({
        feedBackRequestExecuting: false,
        submitError: this.props.t(error)
      });
    }
  }

  onSubmitFeedbackSucceed(response) {
    if (this.context.gamificationActive) {
      this.props.gamificationContext.raiseFeedbackNotification();
    } else {
      this.openRegularConfirmModal();
    }
    this.securSetState({
      feedBackRequestExecuting: false,
      submitError: null,
      feedbackId: response.id
    });
  }

  openRegularConfirmModal() {
    let intervalId = setInterval(() => {
      this.setTimerConfirmModal(this.state.confirmModalTimer);
    }, 1000);
    this.securSetState({
      regularConfirmModalOpen: true,
      intervalId: intervalId
    });
  }

  setTimerConfirmModal(timer) {
    if (timer === 0) {
      clearInterval(this.state.intervalId);
      MwHistory.replace('/cco');
    } else {
      this.securSetState({
        confirmModalTimer: timer - 1
      });
    }
  }

  handleCloseModal(event) {
    clearInterval(this.state.intervalId);
    this.securSetState({
      regularConfirmModalOpen: false
    });
    this.timeout = setTimeout(() => {
      this.securSetState({ confirmModalTimer: 15 });
    }, 200);
  }
  handleConfirmModal(event) {
    clearInterval(this.state.intervalId);
    MwHistory.replace('/cco');
  }

  formatReasons(outcomes, reasons) {
    if (!outcomes || !reasons) {
      return null;
    }

    let dataFormatted = {};
    outcomes.forEach((item) => {
      dataFormatted[item.id] = [];
    });
    reasons.forEach((item) => {
      if (!dataFormatted[item.outcome]){
        return
      }
      dataFormatted[item.outcome].push({
        id: item.id,
        label:
          item.description_trans[i18next.language] ||
          item.description_trans[i18next.options.fallbackLng[0]],
        value: item.id.toString()
      });
    });

    return dataFormatted;
  }
  formatQuarantines(quarantines) {
    if (!quarantines) {
      return null;
    }

    return quarantines.map((quarantine) => ({
      id: quarantine.id,
      label:
        quarantine.label[i18next.language] ||
        quarantine.label[i18next.options.fallbackLng[0]],
      value: quarantine.id.toString()
    }));
  }
  renderScripts() {
    const { selectedOutcome } = this.state;
    const { outcomeAcceptedId, outcomeRejectedId } = this.context;

    const scripts = {
      [outcomeAcceptedId]: prettifyScript(this.props.t('session.scripts.outcome-script-accept'), TransScriptSep),
      [outcomeRejectedId]: prettifyScript(this.props.t('session.scripts.outcome-script-reject'), TransScriptSep)
    }

    const tabs = [
      {
        id: 1,
        name: this.props.t('session.scripts.outcome-script-header'),
        value: 'outcomeScript'
      }
    ];

    const showScript =
      selectedOutcome === outcomeAcceptedId ||
      selectedOutcome === outcomeRejectedId;
    return showScript && (
      <>
        <SimpleTabsMenu
          tabs={tabs}
          defaultValue={0}
          onChange={(event, value) => this.onChangeTab(event, value)}
        />
        <div className={styles.Script}>
          {scripts[selectedOutcome] && (
            <div>
                {scripts[selectedOutcome]}
            </div>
          )}
        </div>
      </>
    );
  }

  renderOutcomes() {
    const opportunity = this.context.selectedOpportunity;
    const {
      outcomes,
      outcomeAcceptedId,
      outcomeNotOfferedId,
      outcomeRejectedId,
      outcomeUndecidedId
    } = this.context;
    const { selectedOutcome } = this.state;

    if (!outcomes) {
      return null;
    }

    return (
      <>
        <div className={styles.Subtitle}>
          {this.props.t('session.feedback.lbl-outcome')}
        </div>
        <div className={styles.ChipsContainer}>
          <Box className={styles.ChipsBorder}>
            <div
              className={`
                  ${styles.Chip}
                  ${styles.Accepted}
                  ${selectedOutcome === outcomeAcceptedId ? styles.Active : ''}
                `}
              onClick={(event) =>
                this.onChangeOutcome(event, outcomeAcceptedId)
              }
            >
              {this.props.t('session.feedback.tab-outcome-accepted')}
            </div>
            {opportunity?.product && (
              <div
                className={`
                    ${styles.Chip}
                    ${styles.NotProposed}
                    ${
                      selectedOutcome === outcomeNotOfferedId
                        ? styles.Active
                        : ''
                    }
                  `}
                onClick={(event) =>
                  this.onChangeOutcome(event, outcomeNotOfferedId)
                }
              >
                {this.props.t('session.feedback.tab-outcome-notoffered')}
              </div>
            )}
            <div
              className={`
                    ${styles.Chip}
                    ${styles.Undecided}
                    ${
                      selectedOutcome === outcomeUndecidedId
                        ? styles.Active
                        : ''
                    }
                  `}
              onClick={(event) =>
                this.onChangeOutcome(event, outcomeUndecidedId)
              }
            >
              {this.props.t('session.feedback.tab-outcome-notoffered')}
            </div>
            <div
              className={`
                  ${styles.Chip}
                  ${styles.Rejected}
                  ${selectedOutcome === outcomeRejectedId ? styles.Active : ''}
                `}
              onClick={(event) =>
                this.onChangeOutcome(event, outcomeRejectedId)
              }
            >
              {this.props.t('session.feedback.tab-outcome-rejected')}
            </div>
          </Box>
        </div>
      </>
    );
  }

  renderReasons() {
    const { outcomes, reasons } = this.context;

    if (!reasons || !reasons) {
      return null;
    }

    const formattedReasons = this.formatReasons(outcomes, reasons);
    const { selectedOutcome, selectedReason } = this.state;
    const { outcomeNotOfferedId, outcomeRejectedId } = this.context;

    return (
      <div>
        {selectedOutcome === outcomeNotOfferedId && (
          <RadioButtonsList
            onChange={(event, value) => this.onChangeReason(event, value)}
            defaultValue={(selectedReason && selectedReason.toString()) || 1}
            options={formattedReasons[outcomeNotOfferedId]}
          />
        )}
        {selectedOutcome === outcomeRejectedId && (
          <RadioButtonsList
            onChange={(event, value) => this.onChangeReason(event, value)}
            defaultValue={(selectedReason && selectedReason.toString()) || 1}
            options={formattedReasons[outcomeRejectedId]}
          />
        )}
      </div>
    );
  }
  renderQuanrantines() {
    const { quarantines } = this.context;
    if (!quarantines) {
      return null;
    }

    const formattedQuarantines = this.formatQuarantines(quarantines);
    const { selectedOutcome } = this.state;
    const { outcomeUndecidedId } = this.context;
    return (
      <>
        {selectedOutcome === outcomeUndecidedId && (
          <RadioButtonsList
            onChange={(event, value) => this.onChangeQuarantine(event, value)}
            defaultValue={1}
            options={formattedQuarantines}
          />
        )}
      </>
    );
  }
  renderComment() {
    const comment = this.state.comment;

    return (
      <>
        <div className={styles.Subtitle}>
          {this.props.t('session.feedback.lbl-comments')}
        </div>
        <div>
          <SmartTextarea
            maxLength='3000'
            onChange={(value) => this.onChangeComment(value)}
            value={comment}
          />
        </div>
      </>
    );
  }


  render() {
    const isSubmitEnable = () => {
      const {
        selectedOutcome,
        selectedReason,
        selectedQuarantine
      } = this.state;
      const {
        outcomeAcceptedId,
        outcomeNotOfferedId,
        outcomeRejectedId,
        outcomeUndecidedId
      } = this.context;

      if (selectedOutcome === outcomeAcceptedId) {
        return true;
      } else if (selectedOutcome === outcomeNotOfferedId && selectedReason) {
        return true;
      } else if (selectedOutcome === outcomeRejectedId) {
        return true;
      } else if (selectedOutcome === outcomeUndecidedId && selectedQuarantine) {
        return true;
      }
      return false;
    };

    return (
      <div>
        <ConfirmTimerModal
          open={this.state.regularConfirmModalOpen}
          onClose={this.handleCloseModal}
          onConfirm={this.handleConfirmModal}
          timer={this.state.confirmModalTimer}
          lblTitle={this.props.t('session.feedback.modal-title')}
          btClose={this.props.t('session.feedback.bt-back')}
          btConfirm={this.props.t('session.feedback.bt-continue')}
        />
        <form onSubmit={this.onRequestSubmit}>
          {this.renderOutcomes()}
          {this.renderScripts()}
          {this.renderReasons()}
          {/* {this.renderQuanrantines()} */}
          {this.renderComment()}
          {this.state.submitError && (
            <Box color='error.main' className={styles.error}>
              <span>{this.state.submitError}</span>
            </Box>
          )}
          <div style={{ textAlign: 'right', marginTop: '1rem' }}>
            <SubmitButton
              disabled={!isSubmitEnable()}
              label={this.props.t('session.feedback.bt-end-session')}
              isLoading={
                this.state.feedBackRequestExecuting ||
                this.state.quarantineRequestExecuting
              }
              color='secondary'
              type='submit'
              size='small'
            />
          </div>
        </form>
      </div>
    );
  }
}

export default withTranslation('cco')(Feedback);
