import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';

export default function SmallDataTable(props) {
  const { data } = props;

  const useStyles = makeStyles((theme) => ({
    TableHeadCell: {
      color: '#4C4C4C',
      borderBottom: 'none',
      fontWeight: 600,
      lineHeight: '1.4',
      padding: '0 1.2rem 0.4rem',
      verticalAlign: 'top'
    },
    TableBody: {
      border: '1px solid #E6E9EE'
    },
    TableCell: {
      color: '#777777',
      borderBottom: '1px solid #E6E9EE',
      padding: '0.4rem 1.2rem',
      verticalAlign: 'top',
      '&:nth-child(n+2)': {
        fontWeight: 700
      }
    }
  }));

  const classes = useStyles();

  const useCustomStyles = makeStyles((theme) => ({
    TableHeadCell: props.customHeadStyle,
    TableBody: props.customBodyStyle,
    TableCell: props.customCellStyle,
    TableRow: props.customRowStyle
  }));
  const customClasses = useCustomStyles();

  const handleSelect = (event, row) => {
    event.preventDefault();
    if (props.onSelect) {
      props.onSelect(row);
    }
  };

  return (
    <Table size='small' style={{ margin: '0rem 0rem 0.5rem' }}>
      <TableHead>
        <TableRow>
          {props.columns.map((head, index) => {
            if (!head.hide)
              return (
                <TableCell
                  key={index}
                  classes={{
                    root: `${classes.TableHeadCell} ${customClasses.TableHeadCell}`
                  }}
                  style={head.customHeadStyle || {}}
                  align={head.numeric ? 'right' : 'left'}
                >
                  {head.label}
                </TableCell>
              );
            return null;
          })}
        </TableRow>
      </TableHead>
      {data && (
        <TableBody
          classes={{ root: `${classes.TableBody} ${customClasses.TableBody}` }}
        >
          {data.map((row, rowIndex) => (
            <TableRow
              key={rowIndex}
              classes={{
                root: `${classes.TableRow} ${customClasses.TableRow}`
              }}
            >
              {props.columns.map((head, colIndex) => {
                if (!head.hide)
                  return (
                    <TableCell
                      classes={{
                        root: `${classes.TableCell} ${customClasses.TableCell}`
                      }}
                      key={colIndex}
                      align={head.numeric ? 'right' : 'left'}
                      size='small'
                      colSpan={row.colspan}
                      onClick={(event) => {
                        handleSelect(event, row);
                      }}
                    >
                      {row[head.id]}
                    </TableCell>
                  );
                return null;
              })}
            </TableRow>
          ))}
        </TableBody>
      )}
    </Table>
  );
}
