import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import styles from './Monitoring.module.css';
import Card from './../components/Card';
import Histogram from './../charts/Histogram';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Fade from '@material-ui/core/Fade';
import Loader from './../components/Loader';
import FiltersContext from './../contexts/FiltersProvider';
import * as Api from './../api/endpoints';

const muiStyles = (theme) => ({
  TableHeadCellHisto: {
    borderBottom: 'none'
  },
  TableHeadCell: {
    color: '#383838',
    borderBottom: 'none',
    fontWeight: 600,
    lineHeight: '1.4',
    padding: '0.4rem 1.2rem',
    verticalAlign: 'bottom',
    width: '17%',
    backgroundColor: '#F6F6F6',
    '&:nth-child(1)': {
      backgroundColor: 'transparent'
    }
  },
  TableCell: {
    color: '#4D4D4D',
    borderBottom: '1px solid #E6E9EE',
    padding: '1rem 1.2rem 0.4rem',
    verticalAlign: 'top',
    whiteSpace: 'nowrap',
    '&:nth-child(n+2)': {
      fontWeight: 700
    },
    '&:nth-child(1)': {
      borderBottom: 'none'
    }
  }
});

class FeedbackAnalysis extends Component {
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
      timer: null,
      multiProductsFilter: null,
      timelineFilter: null,
      multiCampaignTypesFilter: null,
      multiChannelsFilter: null,
      count: null,
      headers: null,
      counts: null,
      charts: null,
      products_outcomes: null
    };
  }

  componentDidUpdate() {
    this.updateState();
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.updateState();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.state.timer);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }
  updateState() {
    if (
      this.context.timelineFilter &&
      this.context.multiChannelsFilter &&
      this.context.multiProductsFilter &&
      this.context.multiCampaignTypesFilter &&
      (this.context.timelineFilter.id !== this.state.timelineFilter?.id ||
        this.context.multiChannelsFilter !== this.state.multiChannelsFilter ||
        this.context.multiProductsFilter !== this.state.multiProductsFilter ||
        this.context.multiCampaignTypesFilter !==
          this.state.multiCampaignTypesFilter)
    ) {
      this.securSetState({
        timelineFilter: this.context.timelineFilter,
        multiChannelsFilter: this.context.multiChannelsFilter,
        multiCampaignTypesFilter: this.context.multiCampaignTypesFilter,
        multiProductsFilter: this.context.multiProductsFilter
      });
      this.loadStats();
    }
  }
  async loadStats() {
    const result = await Api.getFeedbakOutcomeByProducts(
      this.context.timelineFilter.begin,
      this.context.timelineFilter.end,
      this.context.multiChannelsFilter.map((c) => c.id),
      this.context.multiCampaignTypesFilter,
      this.context.multiProductsFilte
    );
    this.formatStats(result);
    this.securSetState({ fadeIn: true });
  }
  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }
  formatOutcomes(outcomes) {
    return Object.fromEntries(
      outcomes.map((o) => [o.code, (o.ratio * 100).toFixed(1)])
    );
  }
  formatStats(stats) {
    this.securSetState({
      count: stats.products.length + 1,
      headers: [
        '',
        'Total',
        ...stats.products.map((product) => this.getTrans(product.name_trans))
      ],
      counts: [
        '',
        stats.count,
        ...stats.products.map((product) => product.feedback.count)
      ],
      charts: [
        null,
        [{ label: '', value: stats.count, ratio: 1 }],
        ...stats.products.map((product) => [
          {
            label: '',
            value: product.feedback.count,
            ratio: product.feedback.count / stats.count
          }
        ])
      ],
      products_outcomes: [
        this.formatOutcomes(stats.total),
        ...stats.products.map((product) =>
          this.formatOutcomes(product.feedback.outcome)
        )
      ]
    });
  }
  render() {
    const { fadeIn, charts, counts, headers, products_outcomes } = this.state;
    const { outcomes } = this.context;
    const colors = {
      accepted: '#12AD56',
      undecided: '#CCBD16',
      rejected: '#F90000',
      not_proposed: '#E87F19'
    };
    const { classes } = this.props;
    return (
      <>
        <div
          className={styles.Wrapper}
          style={{ width: '100%', maxWidth: 1400 }}
        >
          {counts && (
            <Card>
              <Fade in={fadeIn} timeout={{ enter: 500 }}>
                <div>
                  <Table
                    size='small'
                    style={{
                      margin: '3rem 0rem 5rem',
                      width: counts.length === 0 ? 'auto' : '100%'
                    }}
                  >
                    <TableHead>
                      <TableRow>
                        {charts.map((chart, index) => (
                          <TableCell
                            key={index}
                            classes={{ root: classes.TableHeadCellHisto }}
                            align='center'
                          >
                            <Histogram
                              data={chart}
                              colWidth={6}
                              barWidth={2.3}
                              barHeight={20}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        {headers.map((header, index) => (
                          <TableCell
                            key={index}
                            style={{
                              width: counts.length === 0 ? '50%' : 'auto'
                            }}
                            classes={{ root: classes.TableHeadCell }}
                            align={index === 0 ? 'left' : 'center'}
                          >
                            {header}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody classes={{ root: classes.TableBody }}>
                      {outcomes.map((outcome, rowIndex) => (
                        <TableRow
                          key={rowIndex}
                          classes={{ root: classes.TableRow }}
                        >
                          <TableCell>
                            <div
                              style={{
                                border: '1px solid',
                                borderColor: colors[outcome.code],
                                borderRadius: '21px',
                                fontSize: '1.3rem',
                                padding: '0.3rem 2rem 0.4rem'
                              }}
                            >
                              {outcome.label}
                            </div>
                          </TableCell>
                          {products_outcomes.map((_outcome, colIndex) => (
                            <TableCell
                              classes={{ root: classes.TableCell }}
                              key={colIndex}
                              align='center'
                              size='small'
                              // colSpan={row.colspan}
                              style={{
                                paddingRight: colIndex === 0 ? '3rem' : '1.2rem'
                              }}
                            >
                              {_outcome[outcome.code]} %
                            </TableCell>
                          ))}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </div>
              </Fade>
              {!fadeIn && <Loader />}
            </Card>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation('monitoring')(
  withStyles(muiStyles)(FeedbackAnalysis)
);
