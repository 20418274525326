import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon'
import okta_logo from 'assets/images/Okta_logo.svg';

export default function SignInWithOktaButton(props) {
  const useStyles = makeStyles({
    root: {
      borderRadius: '2rem',
      textTransform: 'none',
      fontSize: '1.5rem',
      fontWeight: 600,
      marginLeft: '1rem',
      padding:
        props.size === 'small'
          ? '0.6rem 4.4rem 0.6rem 1.4rem'
          : '0.9rem 4.5rem 0.9rem 1.5rem'
    },
    imageIcon: {
      display: 'flex',
      height: 'inherit',
      width: 'inherit'
    },
    iconRoot: {
      textAlign: 'center',
      marginLeft: '0.5rem'
    }
  });

  const classes = useStyles(props);

  return (
    <Button
      variant='outlined'
      color={props.color?props.color:'primary'}
      disableElevation
      disableRipple
      fullWidth={props.fullWidth}
      type={props.type ? props.type : 'submit'}
      disabled={props.disabled || props.isLoading}
      size={props.size ? props.size : 'small'}
      onClick={props.onClick}
      classes={{
        root: classes.root,
        disabled: classes.disabled
      }}
    >
      {props.label}
      <Icon classes={{root: classes.iconRoot}}>
        <img className={classes.imageIcon} alt="okta" src={okta_logo}/>
      </Icon>
    </Button>
  );
}