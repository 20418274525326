import React, { Component } from 'react';
import * as MwHistory from 'utils/MwHistory';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faTrophy } from '@fortawesome/free-solid-svg-icons';
import podium from 'assets/images/podium_transparent.svg';
import GamificationContext from './../contexts/GamificationProvider';

import GamificationWrapper from './GamificationWrapper';
import Grid from '@material-ui/core/Grid';
import Card from './../components/Card';
import Level from './../components/Level';
import Ranking from './../components/Ranking';
import Badges from './../components/Badges';

class GamificationDashboard extends Component {
  static contextType = GamificationContext;

  constructor(props) {
    super(props);
    this.state = {
      rankingExtended: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.checkWelcomeBadge();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }

  componentDidUpdate() {
    this.checkWelcomeBadge();
  }

  checkWelcomeBadge() {
    const { myWonBadges, haveWelcomeBadge } = this.context;
    if (myWonBadges && !haveWelcomeBadge) {
      this.context.raiseWelcomeBadge();
    }
  }

  redirect(route) {
    MwHistory.push(route);
  }

  handleOpenRankingExtended() {
    this.securSetState({
      rankingExtended: true
    });
  }
  handleCloseRankingExtended() {
    this.securSetState({
      rankingExtended: false
    });
  }

  render() {
    const { myKeyInfo } = this.context;
    if (!myKeyInfo) {
      return null;
    }

    const currentLevel = myKeyInfo.current_level;
    const currentLevelColor = currentLevel.color;
    const currentLevelName =
      currentLevel.name_trans[i18next.language] ||
      currentLevel.name_trans[i18next.options.fallbackLng[0]];

    const parse = require('html-react-parser');
    let txtTitleRanking = this.props.t('ranking.title');
    txtTitleRanking = txtTitleRanking.replace(
      '{currentLevelColor}',
      currentLevelColor
    );
    txtTitleRanking = txtTitleRanking.replace(
      '{currentLevelName}',
      currentLevelName
    );
    const titleRanking = parse(txtTitleRanking);

    return (
      <GamificationWrapper>
        <Grid container spacing={3} alignContent='stretch'>
          <Grid item xs={12} md={12} lg={6}>
            <Card
              title={this.props.t('level.title')}
              icon=<FontAwesomeIcon icon={faAsterisk} />
              onView={() => {
                this.redirect('/cco/game/levels');
              }}
            >
              <Level />
            </Card>
          </Grid>
          <Grid item xs={12} md={12} lg={6}>
            <Card
              title={titleRanking}
              icon=<img
                alt='ranking'
                src={podium}
                style={{ height: '1.45rem', width: 'auto', opacity: 0.8 }}
              />
              onView={() => this.handleOpenRankingExtended()}
            >
              <Ranking
                rankingExtendedOpen={this.state.rankingExtended}
                onCloseRankingExtended={() => this.handleCloseRankingExtended()}
              />
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card
              title={this.props.t('badges.title')}
              icon=<FontAwesomeIcon icon={faTrophy} />
              onView={() => {
                this.redirect('/cco/game/badges');
              }}
            >
              <Badges limWon={2} limUpcoming={6} />
            </Card>
          </Grid>
        </Grid>
      </GamificationWrapper>
    );
  }
}

export default withTranslation('gamification')(GamificationDashboard);
