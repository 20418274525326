import React, { Component } from 'react';
import styles from './Notifications.module.css';
import { withStyles } from '@material-ui/core/styles';
import DropDownList from 'deep/components/materials/DropDownList';
import BasicInput from 'deep/components/materials/BasicInput';
import Grid from '@material-ui/core/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import NotificationsLogic from './../components/NotificationsLogic';
import NotificationsOrAnd from './../components/NotificationsOrAnd';

const muiStyles = (theme) => ({
  group: {
    marginBottom: '2.4rem',
    maxWidth: '94.5rem'
  },
  title: {
    fontWeight: 600,
    marginBottom: '0.7rem'
  }
});

class NotificationsRuleEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      message: null,
      startDate: null,
      endDate: null,
      sendingTime: null,
      timeZone: null,
      campaignType: null,
      groups: []
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.handleAddGroup(null);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  handleAddGroup(event) {
    if (event) {
      event.stopPropagation();
    }
    const groups = this.state.groups;
    const logics = [{
        kpi: null,
        operand: null,
        value: null,
        timeline: null
      }];
      groups.push({
        logics: logics,
        operator: 'AND'
      });
    this.securSetState({ groups: groups });
  }
  handleAddLogic(event, groupIndex) {
    const groups = this.state.groups;
    groups[groupIndex].logics.push({
      kpi: null,
      operand: null,
      value: null,
      timeline: null
    });
    this.securSetState({ groups: groups });
  }
  removeLogic(groupIndex, logicIndex) {
    const groups = this.state.groups;
    groups[groupIndex].logics.splice(logicIndex, 1);
    this.securSetState({ groups: groups });
  }
  handleRemoveGroup(event, groupIndex) {
    event.stopPropagation();
    if (this.state.groups.length > 1) {
      const groups = this.state.groups;
      groups.splice(groupIndex, 1);
      this.securSetState({ groups: groups });
    }
  }
  onSelectGroupOperator(groupIndex, operator) {
    const groups = this.state.groups;
    groups[groupIndex].operator = operator;
    this.securSetState({ groups: groups });
  }

  renderRuleName() {
    const { classes } = this.props;

    return (
      <div className={classes.group} style={{ width: '40rem' }}>
        <div className={classes.title}>Rule Name</div>
        <div>
          <BasicInput
            disabled={false}
            autoFocus={false}
            value={this.state.name}
            placeholder='Rule Name'
            error={false}
            valid={true}
            type='text'
            onChange={(value) => this.securSetState({ name: value })}
            style={{ backgroundColor: '#fff' }}
            maxLength='250'
          />
        </div>
      </div>
    );
  }

  renderMessages() {
    const { classes } = this.props;
    let messages = [
      { id: 1, label: 'Congratulations Top Performer' },
      { id: 2, label: 'Objective Achieved' },
      { id: 3, label: 'End Year Wishies' }
    ];

    return (
      <div className={classes.group} style={{ width: '40rem' }}>
        <div className={classes.title}>Predefined Messages</div>
        <DropDownList
          disabled={false}
          fullWidth={true}
          options={messages}
          value={this.state.message}
          placeholder='Predefined Message'
          onChange={(value) => this.securSetState({ message: value })}
        />
      </div>
    );
  }

  renderDateTimes() {
    const { classes } = this.props;
    let timeZones = [
      { id: 1, label: 'ECT (GMT+1:00)' },
      { id: 2, label: 'EET (GMT+2:00)' },
      { id: 3, label: 'ART (GMT+2:00)' }
    ];

    return (
      <Grid container spacing={2}>
        <Grid item>
          <div className={classes.group} style={{ width: '18rem' }}>
            <div className={classes.title}>Start Date</div>
            <BasicInput
              disabled={false}
              autoFocus={false}
              value={this.state.startDate}
              placeholder='03 - Jan - 2020'
              error={false}
              valid={true}
              type='text'
              onChange={(value) => this.securSetState({ startDate: value })}
              style={{ backgroundColor: '#fff' }}
              maxLength='250'
            />
          </div>
        </Grid>
        <Grid item>
          <div className={classes.group} style={{ width: '18rem' }}>
            <div className={classes.title}>End Date</div>
            <BasicInput
              disabled={false}
              autoFocus={false}
              value={this.state.endDate}
              placeholder='03 - Jan - 2020'
              error={false}
              valid={true}
              type='text'
              onChange={(value) => this.securSetState({ endDate: value })}
              style={{ backgroundColor: '#fff' }}
              maxLength='250'
            />
          </div>
        </Grid>
        <Grid item>
          <div className={classes.group} style={{ width: '18rem' }}>
            <div className={classes.title}>Time of Sending</div>
            <BasicInput
              disabled={false}
              autoFocus={false}
              value={this.state.sendingTime}
              placeholder='09:00'
              error={false}
              valid={true}
              type='text'
              onChange={(value) => this.securSetState({ sendingTime: value })}
              style={{ backgroundColor: '#fff' }}
              maxLength='250'
            />
          </div>
        </Grid>
        <Grid item>
          <div className={classes.group} style={{ width: '18rem' }}>
            <div className={classes.title}>Time Zone</div>
            <DropDownList
              disabled={false}
              fullWidth={true}
              options={timeZones}
              value={this.state.timeZone}
              placeholder='Time Zone'
              onChange={(value) => this.securSetState({ timeZone: value })}
            />
          </div>
        </Grid>
      </Grid>
    );
  }

  renderLogicHeader() {

    return (
      <div style={{background: '#e7e8eb', fontWeight: '600', padding: '0.8rem 1.4rem', marginBottom: '2rem', borderRadius: '6px'}}>
        <div style={{width: '22.7rem', display: 'inline-block'}}>Select KPI</div>
        <div style={{width: '22.7rem', display: 'inline-block'}}>Select Operand</div>
        <div style={{width: '22.7rem', display: 'inline-block'}}>Enter Values</div>
        <div style={{width: '22.7rem', display: 'inline-block'}}>Select Timeline</div>
      </div>
    )

  }

  renderCampaignTypes() {
    const { classes } = this.props;
    let campaignTypes = [
      { id: 1, label: 'All' },
      { id: 2, label: 'Retention' },
      { id: 3, label: 'Cross Selling' },
      { id: 4, label: 'Debt Collection' }
    ];

    return (
      <div className={classes.group} style={{ width: '40rem' }}>
        <div className={classes.title}>Apply to Campaign Type</div>
        <DropDownList
          disabled={false}
          fullWidth={true}
          options={campaignTypes}
          value={this.state.campaignType}
          placeholder='Campaign Type'
          zIndex={9}
          onChange={(value) => this.securSetState({ campaignType: value })}
        />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { groups } = this.state;

    return (
      <>
        <div style={{ minHeight: '100vh' }}>
          {this.renderRuleName()}
          {this.renderMessages()}
          {this.renderDateTimes()}

          <div className={classes.group}>
            <div className={classes.title}>Define Rule Logic</div>

            {this.renderLogicHeader()}

            <div style={{paddingLeft: '1.2rem'}}>
            {groups.map((group, groupIndex) => {
              return (
                <div key={groupIndex}>
                  {group.logics.map((logic, logicIndex) => {
                    return (
                      <NotificationsLogic
                        key={logicIndex}
                        index={logicIndex}
                        removable={group.logics.length > 1}
                        onRemove={() =>
                          this.removeLogic(groupIndex, logicIndex)
                        }
                      />
                    );
                  })}

                  <div style={{ position: 'relative', margin: '0.5rem 0rem' }}>
                    <Grid container spacing={4} justify='flex-end'>
                      <Grid item
                        onClick={(event) => {
                          this.handleAddLogic(event, groupIndex);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <FontAwesomeIcon
                          className={`${styles.Icon} ${styles.Add}`}
                          icon={faPlusCircle}
                        />
                        Add Logic
                      </Grid>
                      <Grid item
                        onClick={(event) => {
                          this.handleRemoveGroup(event, groupIndex);
                        }}
                        style={{ cursor: groups.length === 1 ? 'default' : 'pointer'}}
                      >
                        <FontAwesomeIcon
                          className={`${styles.Icon} ${styles.Trash} ${groups.length === 1 ? styles.Disabled : ''}`}
                          icon={faTrashAlt}
                        />
                        <span style={{opacity: groups.length === 1 ? 0.3 : 1}}>Delete Group</span>
                      </Grid>
                    </Grid>
                  </div>

                  <div className={styles.AddGroupWrapper}>
                    <NotificationsOrAnd
                      operator={group.operator}
                      onSelect={(operator) => { this.onSelectGroupOperator(groupIndex, operator); }}
                    />
                    {groupIndex < groups.length -1 && (
                        <div className={styles.Line} />
                    )}
                    {groupIndex === groups.length -1 && (
                      <>
                        <div className={`${styles.Line} ${styles.Left}`} />
                        <div className={styles.AddGroup} onClick={(event) => { this.handleAddGroup(event); }}>
                          <FontAwesomeIcon
                            className={`${styles.Icon} ${styles.Add}`}
                            icon={faPlusCircle}
                          />Add Group
                        </div>
                        <div className={`${styles.Line} ${styles.Right}`} />
                      </>
                    )}
                  </div>
                </div>
              );
            })}
            </div>

          </div>

          {this.renderCampaignTypes()}
        </div>
      </>
    );
  }
}

export default withStyles(muiStyles)(NotificationsRuleEdit);
