export const ROLES = {
  ADMIN: 'admin',
  MANAGER: 'manager',
  AGENT: 'agent',
  CCMANAGER: 'ccmanager',
  CCAGENT: 'ccagent'
};
export const STORAGE_VARS = {
  TOKEN: 'token',
  LANGUAGE: 'language',
  AVATAR_DISCLAIMER: 'avatar',
  TENANT: 'tenant',
  GAMIFICATION_ACTIVATED: 'gamification',
  NOTIFICATION_ACTIVATED: 'notification',
  DEMO_CALL_ACCEPTED: 'dca',
  DEMO_CALL_NOTOFFERED: 'dcno',
  DEMO_CALL_REJECTED: 'dcr'
};
