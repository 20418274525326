import React, { Component } from 'react';
import styles from './Monitoring.module.css';
import TimeEvolutionCard from './../figures/TimeEvolutionCard';
import Grid from '@material-ui/core/Grid';
import * as Api from './../api/endpoints';
import Loader from './../components/Loader';

let delay = 800;

class TimeEvolution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      charts: null,
      fadeIn: false,
      timer: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.loadCharts();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.state.timer);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  async loadCharts() {
    const result = await Api.getTimeEvolution();
    this.securSetState({
      charts: result
    });
    this.securSetState({
      timer: setTimeout(() => this.securSetState({ fadeIn: true }), delay)
    });
  }

  formatResults(data) {
    if (!data) {
      return null;
    }

    const dataFormatted = [];

    data.forEach((chart) => {
      const title = chart.label_trans['en'];
      let chartData = [];
      chart.data.forEach((figure) => {
        chartData.push({
          label: figure.label_trans['en'],
          value: figure.count,
          ratio: figure.ratio
        });
      });

      dataFormatted.push({
        title: title,
        data: chartData
      });
    });

    return dataFormatted;
  }

  render() {
    const charts = this.formatResults(this.state.charts);
    const { fadeIn } = this.state;

    return (
      <div className={styles.Wrapper}>
        {fadeIn && charts && (
          <Grid container spacing={3} alignItems='stretch' justify='center'>
            {charts.map((chart, index) => (
              <Grid item key={index}>
                <div style={{ width: 372, margin: '0 auto' }}>
                  <TimeEvolutionCard
                    title={chart.title}
                    chartData={chart.data}
                    index={index}
                  />
                </div>
              </Grid>
            ))}
          </Grid>
        )}
        {!fadeIn && <Loader />}
      </div>
    );
  }
}

export default TimeEvolution;
