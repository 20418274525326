import React from 'react';

export default function Footer(props) {
  const style = {
    fontFamily: 'proxima-nova, sans-serif',
    fontSize: '1rem',
    color: '#777777',
    padding: '1rem 0rem 1.5rem',
    textAlign: 'left'
  };

  return (
    <div style={{ ...style, ...props.style }}>
      © 2021 Boston Consulting Group
    </div>
  );
}
