import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './GamificationLevels.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import * as GamificationApi from './../api/endpoints';
import Grid from '@material-ui/core/Grid';
import GamificationContext from './../contexts/GamificationProvider';

import GamificationWrapper from './GamificationWrapper';
import Card from './../components/Card';
import Avatar from '@material-ui/core/Avatar';

class GamificationLevels extends Component {
  static contextType = GamificationContext;

  constructor(props) {
    super(props);
    this.state = {
      allLevels: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.loadAllLevels();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }

  async loadAllLevels() {
    const result = await GamificationApi.getLevels();
    this.securSetState({
      allLevels: result
    });
  }

  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }

  formatLevels(levels, currentLevel, mePoints, mePrc) {
    if (!levels) {
      return null;
    }

    let dataFormatted = [];
    levels.forEach((level) => {
      let bgColor = '#6A717A';
      if (level.points < mePoints) {
        bgColor = level.color;
      } else {
        bgColor = 'transparent';
      }

      let width = 0;
      if (level.id === currentLevel.id) {
        if (dataFormatted.length > 0) {
          dataFormatted[dataFormatted.length - 1].width = 100;
        }
        width = mePrc;
      }

      dataFormatted.push({
        id: level.id,
        name: this.getTrans(level.name_trans),
        icon: level.image,
        color: level.color,
        bgColor: bgColor,
        points: level.points,
        width: width
      });
    });

    return dataFormatted;
  }

  render() {
    const { myKeyInfo, me } = this.context;
    const { allLevels } = this.state;

    if (!myKeyInfo || !allLevels) {
      return (
        <GamificationWrapper>
          <Card
            title='Current Level & Points'
            icon=<FontAwesomeIcon icon={faAsterisk} />
            onView={null}
          ></Card>
        </GamificationWrapper>
      );
    }

    const widths = [14, 17, 20, 24, 26];

    const currentLevel = myKeyInfo.current_level;
    const nextLevel = myKeyInfo.next_level;
    const mePoints = myKeyInfo.points;

    const currentLevelName = this.getTrans(currentLevel.name_trans);
    const nextLevelName = this.getTrans(nextLevel.name_trans);
    const diffPoints = nextLevel.points - mePoints;
    const compPoints = mePoints - currentLevel.points;
    const mePrc = 100 * (compPoints / (nextLevel.points - currentLevel.points));
    const allLevelsFormatted = this.formatLevels(
      allLevels,
      currentLevel,
      mePoints,
      mePrc
    );

    //const meDiffPoints = mePoints

    return (
      <GamificationWrapper>
        <Card
          title={this.props.t('menu.ent_level')}
          icon=<FontAwesomeIcon icon={faAsterisk} />
          onView={null}
        >
          <div className={styles.Message}>
            {this.props.t('level.congrats')}{' '}
            <span style={{ color: currentLevel.color }}>
              {currentLevelName}
            </span>
            !<br />
            {this.props.t('level.need')}
            <span> {diffPoints} </span>
            {this.props.t('level.more')}
            <span style={{ color: nextLevel.color }}> {nextLevelName}</span>
          </div>
          <div className={styles.Path}>
            {allLevels && allLevels.length > 0 && (
              <div className={styles.PathLevels}>
                <Grid container wrap='nowrap' spacing={3}>
                  {allLevelsFormatted.map((level, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        style={{ width: widths[index] + '%' }}
                        className={styles.LevelGridItem}
                      >
                        <div
                          className={styles.LevelCard}
                          style={{
                            border:
                              '1px solid ' +
                              (level.id === currentLevel.id
                                ? level.color
                                : 'transparent')
                          }}
                        >
                          <div
                            className={styles.Title}
                            style={{
                              color:
                                level.id === currentLevel.id ? level.color : ''
                            }}
                          >
                            {level.name}
                          </div>
                          <div className={styles.Icon}>
                            <img alt={level.name} src={level.icon} />
                          </div>
                          <div
                            className={styles.Points}
                            style={{
                              color:
                                level.id === currentLevel.id ? level.color : ''
                            }}
                          >
                            {level.points} {this.props.t('level.pts')}
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            )}
            <div className={styles.PathLine}>
              {allLevels && allLevels.length > 0 && (
                <Grid container wrap='nowrap' spacing={3}>
                  {allLevelsFormatted.map((level, index) => {
                    return (
                      <Grid
                        key={index}
                        item
                        style={{ width: widths[index] + '%' }}
                      >
                        <div
                          className={`${styles.Tick} ${
                            level.bgColor === 'transparent' ? '' : styles.active
                          }`}
                        >
                          <FontAwesomeIcon icon={faCheckCircle} />
                        </div>
                        <div className={styles.BgBar}>
                          {level.id === currentLevel.id && (
                            <>
                              <Avatar
                                className={styles.Avatar}
                                src={me.user.avatar.file}
                                style={{ marginLeft: mePrc + '%' }}
                              />
                              <div
                                className={styles.Line}
                                style={{ marginLeft: mePrc + '%' }}
                              />
                            </>
                          )}
                          <div
                            className={styles.FillBar}
                            style={{
                              width: level.width + '%',
                              background: level.bgColor
                            }}
                          >
                            {level.id === currentLevel.id && (
                              <div
                                className={styles.PtsBar}
                                style={{ color: level.color }}
                              >
                                {mePoints}&nbsp;pts
                              </div>
                            )}
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </div>
          </div>
        </Card>
      </GamificationWrapper>
    );
  }
}

export default withTranslation('gamification')(GamificationLevels);
