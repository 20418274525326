import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';

export default function BasicInput(props) {
  const useStyles = makeStyles({
    root: {
      fontSize: '1.5rem',
      lineHeight: 1.5,
      borderRadius: '2rem',
      border: '1px solid #cac9ca',
      padding: props.size === 'large' ? '0.5rem 2rem' : '0.4rem 2rem 0.3rem',
      color: '#3A404D'
    },
    error: {
      border: '1px solid #e20909',
      backgroundColor: 'none'
    }
  });

  const classes = useStyles(props);

  //const [value, setValue] = React.useState(props.defaultValue);
  const change = (event) => {
    event.stopPropagation();
    event.preventDefault();
    //setValue(event.target.value);
    props.onChange(event.target.value);
  };

  const inputRef = React.useRef();

  return (
    <Input
      autoFocus={props.autoFocus}
      value={props.value}
      type={props.type}
      error={props.error}
      disabled={props.disabled}
      placeholder={props.placeholder}
      disableUnderline={true}
      fullWidth
      onChange={change}
      classes={{
        root: classes.root,
        error: classes.error
      }}
      inputRef={inputRef}
      inputProps={{
        maxLength: props.maxLength
      }}
      style={props.style}
      autoComplete='on'
    />
  );
}
