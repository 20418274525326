import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import CallSessionContext from './../../contexts/CallSessionProvider';
import Autocomplete from 'deep/components/materials/Autocomplete';
import RadioTable from 'deep/components/materials/RadioTable';
import SimpleButton from 'deep/components/materials/SimpleButton';
import TextField from '@material-ui/core/TextField';

import * as CcoApi from './../../api/endpoints';

const styles = (theme) => ({
  textField: {}
});
class DataCollection extends Component {
  static contextType = CallSessionContext;

  UNSAFE_componentWillMount() {
    this.rendered = false;
  }
  constructor(props) {
    super(props);

    this.state = {
      requestExecuting: false
    };
    this.handleAnswerChange = this.handleAnswerChange.bind(this);
    this.handleCollectionChange = this.handleCollectionChange.bind(this);
    this.postCollection = this.postCollection.bind(this);
  }

  componentDidUpdate() {
    if (!this.rendered && this.context.collections) {
      this.rendered = true;
      this.props.onRender(true);
    }
  }

  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }
  formatChoices(choices) {
    return choices.map((c) => ({
      id: c.id,
      label: this.getTrans(c.choice_trans),
      value: c.id.toString()
    }));
  }
  handleAnswerChange(event, answer) {
    this.context.setSelectedCollectionAnswer(answer);
  }

  handleCollectionChange(event, question) {
    this.context.setSelectedCollectionQuestion(question);
  }

  async postCollection() {
    const customer = this.context.customer;
    const choice = this.context.selectedCollectionAnswer;
    this.setState({
      requestExecuting: true
    });

    try {
      await CcoApi.postCollection(customer, choice).then((res) =>
        this.context.loadCollectionData(customer.id)
      );
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({
        requestExecuting: false
      });
    }
  }
  render() {
    const {
      collections,
      selectedCollection,
      selectedCollectionAnswer
    } = this.context;
    const { requestExecuting } = this.state;
    return (
      <div>
        <Autocomplete
          id='data-colection-question'
          size='small'
          options={collections || []}
          getOptionLabel={(option) => this.getTrans(option.question_trans)}
          onChange={this.handleCollectionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={
                selectedCollection
                  ? this.getTrans(selectedCollection.question_trans)
                  : this.props.t('session.datacollection.input-label')
              }
              variant='outlined'
              size='small'
            />
          )}
        />
        {selectedCollection && (
          <RadioTable
            onChange={this.handleAnswerChange}
            defaultValue={selectedCollection?.choices[0]?.id || null}
            options={this.formatChoices(selectedCollection?.choices || [])}
          />
        )}
        {selectedCollection && (
          <div style={{ textAlign: 'right', marginTop: '1rem' }}>
            <SimpleButton
              onClick={this.postCollection}
              size='small'
              label={this.props.t('session.datacollection.call-to-action')}
              variant='outlined'
              disabled={requestExecuting || !selectedCollectionAnswer}
            />
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation('cco')(withStyles(styles)(DataCollection));
