import React, { Component } from 'react';
import styles from './../pages/Notifications.module.css';
import Grid from '@material-ui/core/Grid';
import DropDownList from 'deep/components/materials/DropDownList';
import BasicInput from 'deep/components/materials/BasicInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';

class NotificationsLogic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kpi: null,
      operand: null,
      value: null,
      timeline: null
    };
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  handleRemove(event) {
    event.stopPropagation();
    if (this.props.removable) {
        this.props.onRemove();
    }
  }

  render() {
    const { index, removable } = this.props;
    const zIndex = 20 - index;

    let kpis = [
      { id: 1, label: 'KPI 1' },
      { id: 2, label: 'KPI 2' },
      { id: 3, label: 'KPI 3' }
    ];

    let operands = [
      { id: 1, label: '<' },
      { id: 2, label: '>=' },
      { id: 3, label: '==' },
      { id: 4, label: '!=' },
      { id: 4, label: '<=' },
      { id: 4, label: '<' }
    ];

    let timelines = [
      { id: 1, label: '1 week' },
      { id: 2, label: '2 weeks' },
      { id: 3, label: '3 weeks' },
      { id: 4, label: '4 weeks' }
    ];

    return (
      <Grid container spacing={2} justify='space-between' alignItems='center'>
        <Grid item style={{ width: '22rem' }}>
          <DropDownList
            disabled={false}
            fullWidth={true}
            options={kpis}
            value={this.state.kpi}
            placeholder='KPI'
            zIndex={zIndex}
            onChange={(value) => this.securSetState({ kpi: value })}
          />
        </Grid>
        <Grid item style={{ width: '22rem' }}>
          <DropDownList
            disabled={false}
            fullWidth={true}
            options={operands}
            value={this.state.operand}
            placeholder='Operand'
            zIndex={zIndex}
            onChange={(value) => this.securSetState({ operand: value })}
          />
        </Grid>
        <Grid item style={{ width: '23rem' }}>
          <BasicInput
            disabled={false}
            autoFocus={false}
            value={this.state.value}
            placeholder='0'
            error={false}
            valid={true}
            type='text'
            onChange={(value) => this.securSetState({ value: value })}
            style={{ backgroundColor: '#fff' }}
            maxLength='250'
          />
        </Grid>
        <Grid item style={{ width: '22rem' }}>
          <DropDownList
            disabled={false}
            fullWidth={true}
            options={timelines}
            value={this.state.timeline}
            placeholder='Timeline'
            zIndex={zIndex}
            onChange={(value) => this.securSetState({ timeline: value })}
          />
        </Grid>
        <Grid
          item
          onClick={(event) => {
            this.handleRemove(event);
          }}
        >
          <FontAwesomeIcon
            className={`${styles.Icon} ${styles.Trash} ${!removable ? styles.Disabled : ''}`}
            icon={faTrashAlt}
          />
        </Grid>
      </Grid>
    );
  }
}

export default NotificationsLogic;
