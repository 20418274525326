import React, { Component } from 'react';
import styles from './PushNotifications.module.css';
import NotificationsContext from './../contexts/NotificationsProvider';
import PushNotification from './pushnotification/PushNotification';

class PushNotifications extends Component {
  static contextType = NotificationsContext;

  render() {
    const { pushNotifications } = this.context;

    if (!pushNotifications) {
      return null;
    }

    return (
      <div className={styles.Bundle}>
        {pushNotifications &&
          pushNotifications.map((notification, index) => (
            <PushNotification key={index} notification={notification} />
          ))}
      </div>
    );
  }
}

export default PushNotifications;
