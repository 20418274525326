import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import History from 'utils/History';
import * as MwHistory from 'utils/MwHistory';
import { STORAGE_VARS } from 'utils/Constants';

import AppDeep from 'deep/AppDeep';
import AppMonitoring from 'modules/monitoring/AppMonitoring';
import AppCMB from 'modules/cco/AppCMB';

class App extends Component {
  constructor(props) {
    super(props);
    document.title = 'Buddy';
  }

  UNSAFE_componentWillMount() {
    //clean up local storage old keys
    const values = Object.values(STORAGE_VARS);
    for (let i = 0; i < localStorage.length; i++) {
      let key = localStorage.key(i);
      if (
        values.indexOf(key) < 0 &&
        key.indexOf('_' + STORAGE_VARS.AVATAR_DISCLAIMER) < 0
      ) {
        localStorage.removeItem(key);
      }
    }

    History.listen((location) => {
      this.forceUpdate();
    });
  }

  render() {
    const currentRoute = MwHistory.getCurrentRoute();

    if (currentRoute.path.indexOf('/monitoring') > -1) {
      return <AppMonitoring />;
    } else if (currentRoute.path.indexOf('/cco') > -1) {
      return <AppCMB />;
    } else {
      return <AppDeep />;
    }
  }
}

export default withTranslation('common')(App);
