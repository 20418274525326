import React from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { STORAGE_VARS } from 'utils/Constants';

import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import LanguageSharpIcon from '@material-ui/icons/LanguageSharp';

function LanguagesMenu(props) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const onSelectLanguage = (language) => (event) => {
    localStorage.setItem(STORAGE_VARS.LANGUAGE, language);
    i18next.changeLanguage(language);
    handleClose(event);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }
    prevOpen.current = open;
  }, [open]);

  const useStyles = makeStyles((theme) => ({
    button: {
      boxShadow:
        (open ? 'inset' : '') + ' 0rem 0.1rem 0.3rem 0rem rgba(0,0,0,0.6)',
      borderRadius: '3rem',
      width: '3.5rem',
      height: '3rem',
      color: '#000',
      textAlign: 'center',
      opacity: '0.5',
      paddingTop: '0.5rem',
      cursor: 'pointer',
      backgroundColor: open ? '#f2f2f2' : 'none',
      '&:hover': {
        backgroundColor: '#f2f2f2'
      }
    },
    item: {
      fontWeight: 600,
      padding: '0.2rem 1rem',
      margin: 0,
      textTransform: 'capitalize',
      textAlign: 'center',
      backgroundColor: 'transparent',
      '&:hover': {
        color: props.theme.palette.primary.main
      }
    }
  }));

  const classes = useStyles();

  const styles = {
    item: {
      backgroundColor: 'transparent'
    }
  };

  return (
    <div>
      <Box
        classes={{ root: classes.button }}
        ref={anchorRef}
        onClick={handleToggle}
      >
        <LanguageSharpIcon style={{ fontSize: '2.1rem' }} />
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ width: 'inherit' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{ transformOrigin: 'center bottom' }}
          >
            <Paper elevation={2} style={{ marginTop: '0.5rem' }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='menu-list-grow'
                  onKeyDown={handleListKeyDown}
                >
                  {props.customLanguages.map((language, index) => {
                    return (
                      <MenuItem
                        key={language.key}
                        classes={{ root: classes.item }}
                        onClick={onSelectLanguage(language.key)}
                        style={styles.item}
                      >
                        {language.name}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default withTranslation('common')(LanguagesMenu);
