import { STORAGE_VARS } from 'utils/Constants';
import * as Tenancy from 'utils/Tenancy';
import moment from 'moment';

function getApiUrl() {
  const tenant = Tenancy.getTenant();
  let apibase = 'http://localhost:8000/deep/api/v1/engie/';
  if (!Tenancy.isLocal()) {
    apibase = 'https://'+window.location.hostname+'/deep/api/v1/engie/';
  }
  return apibase;
}

function handleResponse(response, resolve, reject, notJSON) {
  if (response.ok) {
    let key = Tenancy.isLocal() ? 'Token' : 'token';
    const token = response.headers.get(key);
    localStorage.setItem(STORAGE_VARS.TOKEN, token);
    /* Move this to a better place in the future */
    if (notJSON) {
      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `All feedbacks ${moment().format('YYYYMMDD')}.csv`;
        a.click();
      });
      return resolve();
    }
    if (response.status === 204) {
      resolve();
    } else {
      response.json().then((result) => {
        resolve(result);
      });
    }
  } else {
    response
      .json()
      .then((result) => {
        const output = {
          status: response.status,
          message: result
        };
        reject(output);
      })
      .catch((error) => {
        const output = {
          status: response.status,
          message: null
        };
        reject(output);
      });
  }
}

export function getRequest(endpoint, isAuth = true, notJSON = false) {
  return new Promise(function (resolve, reject) {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem(STORAGE_VARS.TOKEN)
    };
    if (!isAuth) delete headers.Authorization;

    fetch(getApiUrl() + endpoint, {
      method: 'GET',
      headers: headers
    })
      .then((response) => {
        handleResponse(response, resolve, reject, notJSON);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function postRequest(endpoint, data, isAuth = true) {
  return new Promise(function (resolve, reject) {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem(STORAGE_VARS.TOKEN)
    };
    if (!isAuth) delete headers.Authorization;

    fetch(getApiUrl() + endpoint, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data)
    })
      .then((response) => {
        handleResponse(response, resolve, reject);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function patchRequest(endpoint, data, isAuth = true) {
  return new Promise(function (resolve, reject) {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem(STORAGE_VARS.TOKEN)
    };
    if (!isAuth) delete headers.Authorization;

    fetch(getApiUrl() + endpoint, {
      method: 'PATCH',
      headers: headers,
      body: JSON.stringify(data)
    })
      .then((response) => {
        handleResponse(response, resolve, reject);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function deleteRequest(endpoint, isAuth = true) {
  return new Promise(function (resolve, reject) {
    let headers = {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem(STORAGE_VARS.TOKEN)
    };
    if (!isAuth) delete headers.Authorization;

    fetch(getApiUrl() + endpoint, {
      method: 'DELETE',
      headers: headers
    })
      .then((response) => {
        handleResponse(response, resolve, reject);
      })
      .catch((error) => {
        reject(error);
      });
  });
}
