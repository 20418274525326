import React, { Component } from 'react';
import styles from './Login.module.css';
import { withOktaAuth } from '@okta/okta-react';
import { withTranslation } from 'react-i18next';
import * as Api from './../api/endpoints';
import AppContext from 'deep/contexts/AppProvider';
import * as MwHistory from 'utils/MwHistory';
import BasicInput from './../components/materials/BasicInput';
import SubmitButton from './../components/materials/SubmitButton';
import SignInViaButton from './../components/materials/SignInViaButton';
import Footer from './../components/Footer';
import Grid from '@material-ui/core/Grid';

import mascot from 'assets/images/mascot.svg';
import bg from 'assets/images/login.jpg';

import Box from '@material-ui/core/Box';

class Login extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      loginError: null,
      requestExecuting: false
    };
    this.setStateValue = this.setStateValue.bind(this);
    this.onRequestSubmit = this.onRequestSubmit.bind(this);
    this.onMeSucceed = this.onMeSucceed.bind(this);
    this.onRequestFailed = this.onRequestFailed.bind(this);
    this.next = new URLSearchParams(this.props.location.search).get('next') || '/cco';
    this.login_with_okta = this.login_with_okta.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  UNSAFE_componentWillMount() {
    this.context.clearMe();
  }

  setStateValue(field, value) {
    this.securSetState({
      [field]: value,
      loginError: null
    });
  }

  async login_with_okta() {
    this.props.oktaAuth.signInWithRedirect();
  }

  onRequestSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.securSetState({
      requestExecuting: true
    });
    this.login();
  }

  async login() {
    try {
      const response = await Api.login(
        this.state.email.trim(),
        this.state.password
      );
      this.onLoginSucceed(response);
    } catch (error) {
      this.onRequestFailed(error);
    }
  }

  onLoginSucceed(response) {
    this.securSetState({
      requestExecuting: false,
      loginError: null
    });
    this.getMe();
  }

  async getMe() {
    try {
      const response = await Api.getMe();
      this.onMeSucceed(response);
    } catch (error) {
      this.onRequestFailed(error);
    }
  }

  onMeSucceed(response) {
    this.securSetState({
      requestExecuting: false,
      loginError: null
    });
    this.context.setMe(response);
    MwHistory.replace(this.next);
  }

  onRequestFailed(error) {
    this.securSetState({
      requestExecuting: false,
      loginError: error
    });
    this.context.clearMe();
  }

  isFormValid() {
    return (
      this.state.email.trim() !== '' &&
      this.state.password !== '' &&
      this.isEmailValid()
    );
  }
  isEmailValid() {
    if (this.state.email.trim() !== '') {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email);
    }
    return true;
  }

  render() {
    const { clientSettings } = this.context;
    const { onlyOktaLogin = false } = clientSettings.sessionConfig;

    const bgStyle = {
      style: {
        backgroundSize: 'cover',
        backgroundImage: 'url(' + bg + ')',
        backgroundColor: '#7a8486'
      }
    };

    return (
      <div className={styles.Wrapper} style={bgStyle.style}>
        <div className={styles.Content}>
          <Grid container spacing={0} wrap='nowrap' alignItems='center'>
            <Grid item>
              <div className={styles.Left}>
                <div className={styles.Mascot}>

                  
                  <img src={mascot} alt='Logo' />
                </div>
                <div
                  className={styles.Cmb}
                  style={{
                    fontFamily: 'proxima-nova, sans-serif',
                    fontWeight: 700
                  }}
                >
                  CUSTOMER
                  <br />
                  MANAGEMENT
                  <br />
                  BUDDY
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className={styles.Right}>
                <img
                  src={clientSettings.logo}
                  alt='Logo'
                  className={styles.ClientLogo}
                />
                <form onSubmit={this.onRequestSubmit}>
                  <div className={styles.Form}>
                    <div className={styles.Title}>
                      {this.props.t('login.txt-title')}
                    </div>

                    {onlyOktaLogin && 
                      <Box color='text.secondary' className={styles.Help}>
                        {this.props.t('login.txt-subtitle-okta')}    
                      </Box>
                    }

                    {!onlyOktaLogin && <>
                      <Box color='text.secondary' className={styles.Help}>
                        {this.props.t('login.txt-subtitle')}
                      </Box>
                      <Box color='text.secondary' className={styles.InputLabel}>
                        {this.props.t('login.lbl-email')}
                      </Box>
                      <BasicInput
                        autoFocus={this.state.email === ''}
                        defaultValue={this.state.email}
                        placeholder={this.props.t('login.plh-email')}
                        error={this.state.loginError !== null}
                        valid={this.isEmailValid() && this.state.email !== ''}
                        type='text'
                        onChange={(value) => this.setStateValue('email', value)}
                        maxLength='250'
                        style={{ marginBottom: '3.1rem' }}
                      />
                      <Box color='text.secondary' className={styles.InputLabel}>
                        {this.props.t('login.lbl-password')}
                      </Box>
                      <BasicInput
                        autoFocus={this.state.email !== ''}
                        defaultValue=''
                        placeholder={this.props.t('login.plh-password')}
                        error={this.state.loginError !== null}
                        valid={
                          this.state.password !== '' && !this.state.loginError
                        }
                        type='password'
                        onChange={(value) =>
                          this.setStateValue('password', value)
                        }
                        maxLength='250'
                      />
                      <Box color='error.main' className={styles.Error}>
                        {this.state.loginError && (
                          <span>{this.props.t(this.state.loginError)}</span>
                        )}
                      </Box>
                    </>}
                    <div style={{ textAlign: 'right' }}>
                    {!onlyOktaLogin && <>
                      <SubmitButton
                          disabled={!this.isFormValid()}
                          label={this.props.t('login.bt-signin')}
                          isLoading={this.state.requestExecuting}
                        /> or</>}<SignInViaButton 
                        onClick={this.login_with_okta}
                        label={this.props.t('login.bt-signin-via')}
                      />
                    </div>
                  </div>
                </form>
              </div>
            </Grid>
          </Grid>
        </div>
        <Footer
          style={{ position: 'absolute', left: '2rem', bottom: '0rem' }}
        />
      </div>
    );
  }
}

export default withTranslation('common')(withOktaAuth(Login));
