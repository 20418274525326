import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallSessionContext from './../../contexts/CallSessionProvider';

class ProductsList extends Component {
  static contextType = CallSessionContext;

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      selectedIndex: 0
    };
    this.toggle = this.toggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
  }

  toggle(e) {
    if (this.context.products.length > 1) {
      this.setState(() => ({ isExpanded: !this.state.isExpanded }));
    }
  }
  handleSelect(event, index) {
    this.context.setSelectedProduct(this.context.products[index]);
    this.setState(() => ({
      isExpanded: !this.state.isExpanded,
      selectedIndex: index
    }));
  }

  handleClickAway(e) {
    this.setState(() => ({ isExpanded: false }));
  }

  formatProducts(data) {
    if (!data) {
      return null;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      dataFormatted.push({
        id: item.id,
        name:
          item.name[i18next.language] ||
          item.name[i18next.options.fallbackLng[0]],
        propensity: item.propensity
      });
    });

    return dataFormatted;
  }

  render() {
    const products = this.formatProducts(this.context.products);
    const { isExpanded, selectedIndex } = this.state;

    const styles = {
      wrapper: {
        position: 'absolute',
        zIndex: 20,
        top: '-0.4rem',
        border: '1px solid #bcbcbc',
        borderRadius: '1.8rem',
        width: '100%'
      },
      paper: {
        borderRadius: '1.8rem',
        padding: '0rem 1rem 0rem'
      },
      selectedItem: {
        position: 'relative',
        cursor: products && products.length > 1 ? 'pointer' : 'default',
        padding: '0.7rem 0rem 1.7rem 0rem',
        picto: {
          position: 'absolute',
          top: '40%',
          transform: 'translateY(-50%)'
        },
        label: {
          marginLeft: '0rem',
          marginRight: '2rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        arrow: {
          position: 'absolute',
          fontSize: '2rem',
          top: '0.8rem',
          right: '-0.3rem'
        }
      },
      divider: {
        marginBottom: '1rem'
      },
      subItemBt: {
        textTransform: 'none',
        textAlign: 'left',
        lineHeight: '1',
        backgroundColor: 'transparent',
        picto: {
          minWidth: '2rem'
        }
      },
      propensity: {
        backgroundColor: '#CEF7E7',
        fontSize: '1.2rem',
        borderRadius: '1rem',
        margin: '0rem 1rem 0rem 0rem',
        fontWeight: 600,
        color: 'black!important'
      }
    };

    const ButtonStyled = withStyles((theme) => ({
      label: {
        justifyContent: 'flex-start'
      },
      text: {
        '&:hover': {
          color: this.props.theme.palette.primary.main
        },
        padding: 0
      }
    }))(Button);

    const getPropensity = (propensity) => {
      if (propensity < 0.33) {
        return {
          color: '#E6E9EE',
          label: this.props.t('session.customerdetails.val-low').toUpperCase()
        };
      } else if (propensity < 0.66) {
        return {
          color: '#F8CB9D',
          label: this.props
            .t('session.customerdetails.val-medium')
            .toUpperCase()
        };
      } else {
        return {
          color: '#CEF7E7',
          label: this.props.t('session.customerdetails.val-high').toUpperCase()
        };
      }
    };

    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div style={{ width: '100%', position: 'relative' }}>
          {products && (
            <div style={styles.wrapper}>
              <Paper elevation={isExpanded ? 4 : 0} style={styles.paper}>
                <Collapse in={isExpanded} collapsedHeight='3.7rem'>
                  <div style={styles.selectedItem} onClick={this.toggle}>
                    <div style={styles.selectedItem.label}>
                      {products[selectedIndex].propensity && (
                        <span
                          style={{
                            ...styles.propensity,
                            ...{
                              padding: ' 0rem 0.5rem 0rem',
                              backgroundColor: getPropensity(
                                products[selectedIndex].propensity
                              ).color
                            }
                          }}
                        >
                          {
                            getPropensity(products[selectedIndex].propensity)
                              .label
                          }
                        </span>
                      )}
                      {products[selectedIndex].name}
                    </div>
                    {products.length > 1 && (
                      <ExpandMoreIcon style={styles.selectedItem.arrow} />
                    )}
                  </div>

                  <Divider style={styles.divider} />
                  {products.map((item, index) =>
                    index !== selectedIndex ? (
                      <div
                        key={index}
                        style={{
                          display: 'flex',
                          alignItems: 'flex-start',
                          alignContent: 'flex-start'
                        }}
                      >
                        {item.propensity && (
                          <div
                            style={{
                              ...styles.propensity,
                              ...{
                                padding: '0.2rem 0.5rem 0.2rem',
                                backgroundColor: getPropensity(item.propensity)
                                  .color
                              }
                            }}
                          >
                            {getPropensity(item.propensity).label}{' '}
                          </div>
                        )}
                        <div>
                          <ButtonStyled
                            style={styles.subItemBt}
                            variant='text'
                            disableElevation
                            disableRipple
                            onClick={(event) => this.handleSelect(event, index)}
                          >
                            {item.name}
                          </ButtonStyled>
                        </div>
                      </div>
                    ) : (
                      <div key={index}></div>
                    )
                  )}
                  <div style={{ height: '1.5rem' }}></div>
                </Collapse>
              </Paper>
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

export default withTranslation('cco')(ProductsList);
