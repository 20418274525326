import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const muiStyles = (theme) => ({
  label: {
    fontSize: '1.3rem',
    color: '#3A404D',
    opacity: 0.9,
    marginBottom: '0.5rem'
  },
  bar: {
    width: '100%',
    height: '4.6rem',
    backgroundColor: '#0BDCE9',
    borderRadius: '2px',
    margin: '0.5rem 0.2rem 0rem 0rem'
  },
  ratio: {
    width: '100%',
    backgroundColor: '#0BDCE9',
    position: 'absolute',
    bottom: 0,
    borderRadius: '3px'
  },
  figures: {
    marginTop: '0.5rem',
    fontSize: '1.5rem',
    fontWeight: 700
  },
  divider: {
    backgroundColor: '#E3E3E3',
    opacity: 0.5
  }
});

class StackedBarLarge extends Component {
  render() {
    const { classes, data } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <div className={classes.label}>{data.label}</div>
        <Divider classes={{ root: classes.divider }} />
        {data &&
          data.ratios.map((stack, index) => (
            <div
              key={index}
              style={{
                width: stack * 100 + '%',
                display: 'inline-block',
                paddingRight:
                  stack > 0 && index < data.ratios.length - 1
                    ? '0.8rem'
                    : '0rem'
              }}
            >
              <div
                className={classes.bar}
                style={{
                  backgroundColor: index === data.index ? data.color : '#F3F3F3'
                }}
              ></div>
            </div>
          ))}
        <Divider classes={{ root: classes.divider }} />
        <div className={classes.figures}>
          {(data.ratios[data.index] * 100).toFixed(1)}% - {data.feedbacks}
        </div>
      </div>
    );
  }
}

export default withTranslation('monitoring')(
  withStyles(muiStyles)(StackedBarLarge)
);
