import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import Box from '@material-ui/core/Box';
import CallSessionContext from './../../contexts/CallSessionProvider';
import moment from 'moment';
import SvgIcon from '@material-ui/core/SvgIcon';
import InfoIcon from '@material-ui/icons/Info';
import EcoIcon from '@material-ui/icons/Eco';
function Fire(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 18, height: 18 }} {...props} viewBox="-4 -2 29 29">
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M1.02282 19.5865C1.64954 22.8106 3.54878 26.7348 7.96887 27.3846C22.4925 27.3846 16.8094 10.4888 16.8094 12.4383C16.8094 14.3878 14.915 18.2869 14.2835 17.637C16.178 9.18913 7.96887 -3.15781 9.23178 0.741223C10.4947 4.64025 10.4944 16.9872 6.07435 17.637C3.54838 16.3373 4.81169 12.2217 5.44315 10.4888C4.18024 12.2217 0.391217 16.3373 1.02282 19.5865Z" fill="#FFDA56"/>
      <path d="M16.8859 21.5895C16.2944 23.8265 14.5018 26.5491 10.3301 27C-3.37758 27 1.98618 15.2773 1.98618 16.63C1.98618 17.9826 3.77413 20.6878 4.37024 20.2369C2.58216 14.3756 10.3301 5.80905 9.13811 8.51428C7.94615 11.2195 7.94639 19.7861 12.1182 20.2369C14.5022 19.3352 13.3099 16.4797 12.7139 15.2773C13.9059 16.4797 17.482 19.3352 16.8859 21.5895Z" fill="#FF7F56" fillOpacity="0.3"/>
    </SvgIcon>
  );
}

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

const TestTip = () => (
  <HtmlTooltip
  placement="top-start"
  title={
    <React.Fragment>
      <Typography color="inherit">Tooltip with HTML</Typography>
      <em>{"And here's"}</em> <b>{'some'}</b> <u>{'amazing content'}</u>.{' '}
      {"It's very engaging. Right?"}
    </React.Fragment>
  }
>
  <InfoIcon />
</HtmlTooltip>
)

const SimpleTip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  arrow: {
    "&:before": {
      border: "1px solid #E6E8ED"
    },
    color: theme.palette.common.white
  }
}))(Tooltip)

const styles = (theme) => ({
  item: {
    position: 'relative',
    '&:nth-child(n+3)': {
      paddingTop: '1.5rem !important'
    },
  },
  left: {
    paddingRight: '0.2rem !important'
  },
  right: {
    paddingLeft: '0.2rem !important'
  },
  full: {
    padding: '0rem'
  },
  label: {
    color: '#4C4C4C',
    fontWeight: 600,
    textAlign: 'left',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1.5rem',
    marginTop: '0.5em'
  },
  value: {
    position: 'relative',
    color: '#777777',
    textAlign: 'left',
    marginBottom: '1.5rem',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  value2: {
    color: '#777777',
    textAlign: 'left',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block'
  },
  value3: {
    color: '#777777',
    textAlign: 'left',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'inline-block'
  },
  icon: {
    position: 'absolute',
    right: '0rem',
    top: '0rem'
  },
  separator: {
    // position: 'absolute',
    bottom: '0rem',
    height: '1px',
    width: '100%',
    backgroundColor: '#E6E9EE'
  },
  
  portfolio: {
    marginTop: '1rem'
  },
  portfolioTitle: {
    fontSize: '1.7rem',
    color: '#4C4C4C',
    fontWeight: 600,
    marginBottom: '0.6rem'
  },
  chip: {
    boxShadow: '0rem 0.1rem 0.3rem 0rem rgba(0,0,0,0.3)',
    borderRadius: '3rem',
    padding: '0.7rem 1.2rem 0.7rem',
    marginRight: '1rem',
    color: '#777777',
    textAlign: 'center',
    backgroundColor: '#fff',
    display: 'inline-block',
    marginBottom: '1rem'
  },
  valid: {
    opacity: 1
  },
  invalid: {
    opacity: 0.5,
    background:'#e8e8e8'
  },
  otherChip: {
    display: 'inline-block',
    padding: '0.2em',
    fontSize: '10px',
    borderRadius: '5px',
    backgroundColor: 'rgba(0, 140, 255, 1)',
    color: 'white'
  }
});

//DISPLAY CODE
//1 : string
//2 : int
//3 : float
//4 : currency
//5 : rate
//6 : gauge
//7 : year
//8 : month
//9 : day
//10 : rating

class CustomerDetails extends Component {
  static contextType = CallSessionContext;

  UNSAFE_componentWillMount() {
    this.rendered = false;
  }
  componentWillUnmount() {
    this.rendered = false;
  }
  componentDidUpdate() {
    if (
      !this.rendered &&
      this.context.customer &&
      this.context.personalAttributes &&
      this.context.portfolio
    ) {
      this.rendered = true;
      this.props.onRender(true);
      return true;
    }
    return false;
  }

  getRateStyle(value) {
    if (value < 0.33) {
      return (
        <span style={{ fontWeight: 400, color: '#75CC19' }}>
          {this.props.t('session.customerdetails.val-low')}
        </span>
      );
    } else if (value < 0.66) {
      return (
        <span style={{ fontWeight: 400, color: 'orange' }}>
          {this.props.t('session.customerdetails.val-medium')}
        </span>
      );
    } else {
      return (
        <span style={{ fontWeight: 400, color: '#FF3838' }}>
          {this.props.t('session.customerdetails.val-high')}
        </span>
      );
    }
  }

  formatPersonalInfo(data) {
    if (!data) {
      return null;
    }

    const dataFormatted = {
      id: {
        label: this.props.t('session.customerdetails.id'),
        value: data?.id,
        icon: null,
        size: 'medium',
        position: 'left'
      },
      name: {
        label: this.props.t('session.customerdetails.fullname'),
        value: data?.firstname + ' ' + data?.lastname,
        icon: null,
        size: 'medium',
        position: 'right'
      },
     
    };
    return dataFormatted;
  }

  numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  renderPersonalAttributes(formattedData, classes, isFull) {
          return formattedData.map((item, index) => {
            if (item.special){
              return (
                <GridListTile
                  key={index}
                  cols={item.position === 'full' ? 2 : 1}
                  className={`${classes.item}`}
                  style={{margin: 0, padding: 0}}
                >
                  <div className={classes.label}>{item.label}</div>
                  <div className={classes.value}>
                    {![5, 6, 10].includes(item.display) && <>{item.value}</>}
                   </div>
                  <div className={classes.separator}></div>
                </GridListTile>
              )
            }

            
            return (
              <GridListTile
                key={index}
                cols={item.position === 'full' ? 2 : 1}
                className={`${classes.item}`}
              >
                <div className={classes.label}>{item.label}</div>
                <div className={classes.value}>
                  {![5, 6, 10].includes(item.display) && <>{item.value}</>}
                </div>
                <div className={classes.separator}  style={{width: isFull ? '100%': '90%'}}></div>
              </GridListTile>
            )
          })
  }

  formatPersonalAttributes(data, classes) {
    if (!data) {
      return null;
    }

    const dataFormatted = [];

    const joinAtrribute = [
      'consump_gas_mapping', 'consump_pwr_mapping'
    ]
    let i = 0;
    let attr_cache;
    let skipLast;
    data.forEach((item) => {

      // display two sequential attributes comma separated
      let skipNext = joinAtrribute.includes(item.attribute.code);
      if (!skipNext && skipLast) {
        skipLast = null
      } else if (skipNext && !skipLast) {
        skipLast = skipNext;
        attr_cache = item;
        return
      } else {
        attr_cache = null
        skipLast = false;
      }

      let value = null;
      switch (item.attribute.display) {
        case 4:
          value =
            this.context.clientSettings.currency +
            parseFloat(parseFloat(item.value).toFixed(2)).toString();
          break;
        case 3:
          value = moment(item.value, 'YYYYMMDD').format('DD/MM/YYYY');
          break;
        case 6:
          value = parseFloat(parseFloat(item.value).toFixed(2));
          break;
        case 2:
          value = parseFloat(item.value).toFixed(1);
          break
        case 7:
          const fireCount = {}
          fireCount['very low'] = 1
          fireCount['low'] = 2
          fireCount['medium'] = 3
          fireCount['high'] = 4 
          fireCount['very high'] = 5
          const attr = (attr_cache && attr_cache.value) || ''
          const count = fireCount[attr.toLowerCase()] || 0
          value = parseFloat(item.value).toFixed(2);
          value = [<div key="left" style={{float: 'left'}}>{attr}  <div key="left" style={{backgroundColor: '#F4F4F4', display: 'inline-block', padding: '0.2em', borderRadius: '5%'}}>{Array(count).fill(1).map((_, i) => <Fire key={i} />)}</div></div>,
                    // <div key="right" style={{float: 'right', position: 'absolute', bottom: 0, right: 0}}>{`KWh ${this.numberWithCommas(value)}`} </div>]
                    <div key="right" style={{float: 'right', position: 'absolute', bottom: 0, right: 0}}> </div>]

          break
        case 8:
          value = parseInt(item.value);
          break;
        case 9:
          value = `${parseInt(item.value)}  ${this.props.t(
            'session.customerdetails.days'
          )}`;
          break;
        case 10:
          value = parseFloat(item.value).toFixed(2) * 5;
          break;
        case 11:
          value = moment();
          value = value.subtract(item.value, "years");
          value = value.format("DD/MM/YYYY");
          break;    
        default:
          value = item.value;
      }

      if (!item.attribute.name_trans){
        return
      }

      dataFormatted.push({
        label:
          item.attribute.name_trans[i18next.language] ||
          item.attribute.name_trans[i18next.options.fallbackLng[0]],
        value: value,
        display: item.attribute.display,
        icon: null,
        position: i % 2 === 0 ? 'left' : 'right', 
        special: item.attribute.display === 7
      });
      i += 1;
    });

    return dataFormatted;
  }

  clean(productName){
    if(!productName){
      return
    }

    if (productName.startsWith('"')){
        return productName.slice(1,-1);
    }

    return productName

  }

  formatPortfolio(data) {
    if (!data) {
      return null;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      if (item.product) {
        dataFormatted.push({
          id: item.product.id,
          name:
            this.clean(item.product.name_trans[i18next.language] ||
            item.product.name_trans[i18next.options.fallbackLng[0]]),
          contract_account_id: item.contract_account_id,
          price: item.product.price,
          annulment_date: item.annulment_date,
          prices: item.prices,
          green: item.green
        });
      }
    });

    return dataFormatted;
  }

  render() {

    const { contractAccountId } = this.context;
    const { classes } = this.props;

    const personalInfo = this.formatPersonalInfo(this.context.customer);
    const personalAttributes = this.formatPersonalAttributes(
      this.context.personalAttributes, classes
    );
    const portfolio = this.formatPortfolio(this.context.portfolio);
    const priceHeaders =  ["UP_P","UP_OP","UP_NO","UP_EN"];

    return (
      <div>
        <GridList cellHeight='auto' cols={1} spacing={0}>
          {personalInfo && (
              <GridListTile
                cols={1}
                className={`${classes.item} ${classes[personalInfo.name.position]}`}
              >
                <div className={classes.label} >{personalInfo.name.label}</div>
                <div className={classes.value} style={{ width:'100%'}}>
                  <span style={{ paddingRight: '1.5rem', width:'100%' }}>{personalInfo.name.value}</span>
                </div>
                <div className={classes.separator}></div>
              </GridListTile>

          )}
        </GridList>
        <GridList cellHeight='auto' cols={2} spacing={0}>
         {personalInfo && (
            <GridListTile
                cols={1}
                className={`${classes.item} `}
              >
                <div className={classes.label}>{personalInfo.id.label}</div>
                <div className={classes.value}>
                  <span style={{ paddingRight: '1.5rem' }}>{personalInfo.id.value}</span>
                </div>
                <div className={classes.separator} style={{width: '90%'}}></div>
           </GridListTile>
         )}
        {personalInfo && (
                <GridListTile
                cols={1}
                className={`${classes.item}`}
              >
                <div className={classes.label}>Contract Acc. ID</div>
                <div className={classes.value}>
                  <span style={{ paddingRight: '1.5rem' }}>{contractAccountId}</span>
                </div>
                <div className={classes.separator} style={{width: '90%'}}></div>

              </GridListTile>

          )}

        </GridList>
        {/* {personalAttributes && <GridList cellHeight='auto' cols={2} spacing={0}>
          {this.renderPersonalAttributes(personalAttributes.slice(0, 2), classes, false)}

        </GridList>} */}

        {/* // PORTFOLIO */}
        <GridList cellHeight='auto' cols={3} spacing={0}>
          <div className={classes.label} style={{width: '100%', marginTop: '1em'}}>{this.props.t('session.customerdetails.product-portfolio')}</div>
          <GridList cellHeight='auto' cols={3} spacing={0} >
          <GridListTile
              cols={1}
            >
              <div className={classes.label}>{this.props.t('session.customerdetails.product')}</div>
            </GridListTile>
            <GridListTile
              cols={1}
            >
              <div className={classes.label} >Tarrif</div>
                <div style={{display: 'block'}} className={classes.value3} >
                </div>
            </GridListTile>
            <GridListTile
              cols={1}
            >
              <div className={classes.label} >c€/kWh</div>
                <div style={{display: 'block'}} className={classes.value3} >
                </div>
            </GridListTile>
          </GridList>
        {portfolio && portfolio.map((item, index) =>  {
            
            const annul = moment(item.annulment_date || '0001-01-01', 'YYYY-MM-DD')
            const today = moment();
            const annul_dif = today.diff(annul, 'days');

            const priceHeaders =  ["UP_P","UP_OP","UP_NO","UP_EN"];
            let tarrif
            let tarrifValue
            if (item.prices && item.prices['UP_P']){
              // tarrif = this.props.t('session.customerdetails.tariff-peak-off-peak');
              tarrif = 'Peak/Off-peak'
              tarrifValue = `${item.prices['UP_P']} / ${ item.prices['UP_OP'] || '-'}`

            } else if (item.prices && item.prices['UP_NO']){
              tarrif = this.props.t('session.customerdetails.tariff-normal');
              tarrif = 'Normal'
              tarrifValue = `${item.prices['UP_NO']}`
            } else if (item.prices && item.prices['UP_EN']){
              // tarrif = this.props.t('session.customerdetails.tariff-peak-off-peak');
              tarrif = 'Exclusive Night'
              tarrifValue = `${item.prices['UP_EN']}`
            }  else if (item.prices && item.prices['UP_GAS']){
              // tarrif = this.props.t('session.customerdetails.tariff-peak-off-peak');
              tarrif = 'Gas'
              tarrifValue = `${item.prices['UP_GAS']}`
            }

            let productClass;
            if (annul.format('DDMMYYYY') == moment('0001-01-01', 'YYYY-MM-DD').format('DDMMYYYY')) {
              productClass = 'valid'
            } else if(annul_dif < -6) {
              productClass = 'valid'
            } else if( annul_dif < 0){
              productClass = 'invalid'
            } else {
              productClass = 'invalid'
            }


            if (item.contract_account_id != contractAccountId) {
                return
            }
            return (
                  <GridList cellHeight='auto' className={classes[productClass]} cols={3} spacing={0} key={index}>
                    <GridListTile
                          cols={1}
                        >
                       
                        <div className={classes.value2}>
                         {item.name} {item.green? (<SimpleTip title="Renewably sourced" placement="top" arrow>
                                      <EcoIcon style={{fill: 'green',  verticalAlign: "top"}} viewBox="0 0 28 28"/>
                                </SimpleTip>): null}
                        </div>
                      </GridListTile>
                      <GridListTile
                        cols={1}
                      >
                        {tarrif && (<SimpleTip title={tarrif} placement="top" arrow>
                        <Box component="div" textOverflow="ellipsis"  style={{width: '90%', whiteSpace:'nowrap', overflow: 'hidden'}}>
                              {tarrif}
                        </Box>
                      </SimpleTip>)}
                      </GridListTile>
                      <GridListTile
                        cols={1}
                      >
                        <div className={classes.value2}>
                        {tarrifValue}
                        </div>
                      </GridListTile>
                    </GridList>
              )
          })
        }
        </GridList>
       
        {/* Annul Deets */}
        <GridList cellHeight='auto' cols={5} spacing={0}>
        <GridListTile cols={5}>
              <div style={{color: 'white'}}>.</div>
              <div className={classes.separator}></div>
        </GridListTile>

             <GridListTile
                cols={3} 
              >
              <div className={classes.label} style={{width: '100%'}}>
                {this.props.t('session.customerdetails.annul-dets')}
              </div>
              </GridListTile>
              <GridListTile
                cols={2} 
              >
              <div className={classes.label} style={{width: '100%'}}>
                {this.props.t('session.customerdetails.annul-date-title')}
              </div>
              </GridListTile>
       


          {portfolio && portfolio.map((item, index) =>  {
            
            const annul = moment(item.annulment_date || '0001-01-01', 'YYYY-MM-DD')
            const today = moment();
            const annul_dif = today.diff(annul, 'days');
            let productClass;
            let daysLeft
            if (annul.format('DDMMYYYY') == moment('0001-01-01', 'YYYY-MM-DD').format('DDMMYYYY')) {
              productClass = 'valid'
            } else if(annul_dif < -6) {
              productClass = 'valid'
              daysLeft = <div className={classes.otherChip}> {`${Math.abs(annul_dif)} days`}</div>
            } else if( annul_dif < 0){
              productClass = 'valid'
              daysLeft = <div className={classes.otherChip}> {`${Math.abs(annul_dif)} days`}</div>

            } else {
              productClass = 'valid'
            }

            if (item.contract_account_id != contractAccountId) {
                return
            }
            return (
                  <GridList cellHeight='auto' className={classes[productClass]} cols={5} spacing={0} key={index}>
                    <GridListTile
                        cols={3} 
                      >
                        <div className={classes.value2}>
                          <span style={{ paddingRight: '1.5rem' }}>{item.name}</span>
                        </div>
                      </GridListTile>

                      <GridListTile
                        cols={2}
                      >
                        <div className={classes.value2}>
                          <span style={{ paddingRight: '0.5rem', height: '1.1em' }}>
                            {item.annulment_date && moment(item.annulment_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}
                            </span>
                          {/* {daysLeft} */}
                        </div>
                      </GridListTile>
                    </GridList>
              )
          })
        }
                <GridListTile cols={5}>
              <div style={{color: 'white'}}>.</div>
              <div className={classes.separator}></div>
        </GridListTile>
        </GridList>
        {/* // ------END */}
        {personalAttributes && <GridList cellHeight='auto' cols={1} spacing={0}>
          {/* {this.renderPersonalAttributes(personalAttributes.slice(2, personalAttributes.length), classes, true)} */}
          {this.renderPersonalAttributes(personalAttributes, classes, true)}

        </GridList>}
{/* 
        {portfolio && displayPortfolio && (
          <div className={classes.portfolio}>
            <div className={classes.portfolioTitle}>
              {this.props.t('session.customerdetails.portfolio-title')}
            </div>
            <div>
              <div>
                {portfolio.map((item, index) => (
                  <div key={index} className={classes.chip}>
                    {item.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
        )} */}
      </div>
    );
  }
}

export default withTranslation('cco')(withStyles(styles)(CustomerDetails));
