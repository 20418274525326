import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import SearchInput from 'deep/components/materials/SearchInput';

class Navigator extends Component {
  render() {
    const bg = require('assets/images/home.jpg');

    const styles = {
      box: {
        backgroundSize: '100% auto',
        backgroundPosition: 'bottom',
        backgroundImage: 'url(' + bg + ')',
        boxShadow: '0rem 0rem 1rem -0.7rem rgba(0,0,0,0.5)',
        borderRadius: 6,
        padding: '1.6rem 2.4rem 1.4rem',
        overflow: 'hidden'
      }
    };

    return (
      <div style={styles.box}>
        <SearchInput
          placeholder={this.props.t('session.navigator.plh-keyword')}
        />
      </div>
    );
  }
}

export default withTranslation('cco')(Navigator);
