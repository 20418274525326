import React from 'react';
import { withOktaAuth } from '@okta/okta-react';
import { withTranslation } from 'react-i18next';
import * as MwHistory from 'utils/MwHistory';
import { ROLES } from 'utils/Constants';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Divider from '@material-ui/core/Divider';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import HomeIcon from '@material-ui/icons/Home';
import Box from '@material-ui/core/Box';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import SettingsRoundedIcon from '@material-ui/icons/SettingsRounded';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import StartCall from 'modules/cco/components/StartCall';
import StartCallRich from 'modules/cco/components/StartCallRich';
function MainMenu(props) {
  const logout = (event) => {
    event.preventDefault();
    handleClose(event);
    MwHistory.redirect('/login');
  };

  const newTab = (route) => (event) => {
    event.preventDefault();
    MwHistory.open(route);
    handleClose(event);
  };

  const redirect = (route) => (event) => {
    event.preventDefault();
    MwHistory.push(route);
    handleClose(event);
  };

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    event.preventDefault();
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const useStylesBt = makeStyles({
    text: {
      padding: 0,
      '&:hover': {
        color: props.theme.palette.primary.main
      }
    }
  });
  const classesBt = useStylesBt(props);

  return (
    <div style={{ marginRight: '1rem' }}>
      <IconButton
        edge='start'
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
        variant='outlined'
        color='secondary'
        size='small'
        disableRipple
        style={{ backgroundColor: 'transparent' }}
      >
        <MenuIcon style={{ fontSize: '2.6rem' }} />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement='bottom-start'
        style={{ width: 'inherit' }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: 'left top' }}>
            <Paper
              elevation={7}
              square={true}
              style={{
                marginTop: '0.5rem',
                textAlign: 'left',
                borderRadius: 6,
                padding: 0
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <div>
                  <div style={{ padding: '2rem 2.4rem 0rem' }}>
                    <Button
                      variant='text'
                      disableElevation
                      startIcon={
                        <HomeIcon color='primary' style={{ fontSize: 14 }} />
                      }
                      disableRipple
                      onClick={redirect('/cco')}
                      style={{
                        textTransform: 'none',
                        backgroundColor: 'transparent'
                      }}
                      classes={{
                        text: classesBt.text
                      }}
                    >
                      {props.t('mainmenu.txt-home')}
                    </Button>
                    <div>
                      <Box
                        style={{
                          fontSize: '1.6rem',
                          fontWeight: 600,
                          marginTop: '3rem',
                          marginBottom: '0.5rem'
                        }}
                      >
                        {props.t('mainmenu.lbl-new-call')}
                      </Box>
                      <StartCallRich
                        size='small'
                        inputWidth='20rem'
                        onStart={handleToggle}
                        onClose={() => {
                          setOpen(false);
                        }}
                        clientSettings={props.clientSettings}
                      />
                    </div>
                  </div>
                  {/* {props.gamificationActive && ( */}
                  {false && (
                    <>
                      <Divider />
                      <div
                        style={{ textAlign: 'left', padding: '1.2rem 2.4rem' }}
                      >
                        <Button
                          variant='text'
                          disableElevation
                          startIcon={
                            <EmojiEventsIcon
                              color='primary'
                              style={{ fontSize: '1.6rem' }}
                            />
                          }
                          disableRipple
                          onClick={redirect('/cco/game/dashboard')}
                          style={{
                            textTransform: 'none',
                            backgroundColor: 'transparent'
                          }}
                          classes={{
                            text: classesBt.text
                          }}
                        >
                          <span style={{ textTransform: 'capitalize' }}>
                            {props.t('mainmenu.bt-gamification')}
                          </span>
                        </Button>
                      </div>
                    </>
                  )}
                  {props.meType?.code === ROLES.ADMIN && (
                    <>
                      <Divider />
                      <div
                        style={{ textAlign: 'left', padding: '1.2rem 2.4rem' }}
                      >
                        <Button
                          variant='text'
                          disableElevation
                          startIcon={
                            <SettingsRoundedIcon
                              color='primary'
                              style={{ fontSize: '1.4rem' }}
                            />
                          }
                          disableRipple
                          onClick={redirect('/admin')}
                          style={{
                            textTransform: 'none',
                            backgroundColor: 'transparent'
                          }}
                          classes={{
                            text: classesBt.text
                          }}
                        >
                          <span style={{ textTransform: 'capitalize' }}>
                            {props.t('mainmenu.bt-admin')}
                          </span>
                        </Button>
                      </div>
                    </>
                  )}
                  {props.meType?.code === ROLES.ADMIN && (
                    <>
                      <Divider />
                      <div
                        style={{ textAlign: 'left', padding: '1.2rem 2.4rem' }}
                      >
                        <Button
                          variant='text'
                          disableElevation
                          startIcon={
                            <EqualizerIcon
                              color='primary'
                              style={{ fontSize: '1.4rem' }}
                            />
                          }
                          disableRipple
                          onClick={newTab(
                            '/monitoring/analysis/overall-performance'
                          )}
                          style={{
                            textTransform: 'none',
                            backgroundColor: 'transparent'
                          }}
                          classes={{
                            text: classesBt.text
                          }}
                        >
                          <span style={{ textTransform: 'capitalize' }}>
                            {props.t('mainmenu.bt-monitoring')}
                          </span>
                        </Button>
                      </div>
                    </>
                  )}{' '}
                  <Divider />
                  <div
                    style={{ textAlign: 'left', padding: '1.2rem 2.4rem 2rem' }}
                  >
                    <Button
                      variant='text'
                      disableElevation
                      disableRipple
                      startIcon={
                        <ExitToAppIcon
                          color='primary'
                          style={{ fontSize: '1.4rem' }}
                        />
                      }
                      onClick={logout}
                      style={{
                        textTransform: 'none',
                        backgroundColor: 'transparent'
                      }}
                      classes={{
                        text: classesBt.text
                      }}
                    >
                      <span style={{ textTransform: 'capitalize' }}>
                        {props.meUser?.first_name}&nbsp;
                        {props.meUser?.last_name}
                      </span>
                      , {props.t('mainmenu.bt-logout')}
                    </Button>
                  </div>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}

export default withTranslation('common')(withOktaAuth(MainMenu));
