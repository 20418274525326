import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';

export default function ConfirmTimerModal(props) {
  const useStyles = makeStyles({
    paper: {
      padding: '3rem',
      minWidth: '40rem',
      borderRadius: '0.6rem'
    },
    button: {
      textTransform: 'none',
      fontSize: '1.3rem',
      fontWeight: 600,
      margin: '0rem 1rem',
      paddingLeft: '1.7rem',
      paddingRight: '1.7rem',
      borderRadius: '1.8rem'
    }
  });
  const classes = useStyles();

  const styles = {
    icon: {
      fontSize: '7.2rem',
      fontWeight: 600,
      textAlign: 'center',
      lineHeight: 1
    },
    close: {
      position: 'absolute',
      top: '1rem',
      right: '1rem',
      color: '#979797',
      cursor: 'pointer',
      fontWeight: 'bold'
    },
    message: {
      fontSize: '2rem',
      fontWeight: 600,
      textAlign: 'center',
      marginTop: '1rem'
    },
    timer: {
      fontSize: '4.6rem',
      fontWeight: 300,
      textAlign: 'center',
      marginTop: '0.4rem',
      marginBottom: '2rem'
    }
  };

  const handleClose = (event) => {
    props.onClose(event);
  };
  const handleConfirm = (event) => {
    props.onConfirm(event);
  };

  return (
    <Dialog
      onClose={handleConfirm}
      aria-labelledby='confirmation'
      open={props.open}
      classes={{ paper: classes.paper }}
      disableBackdropClick={true}
      disableEscapeKeyDown={true}
    >
      <div style={styles.icon}>
        <CheckCircleRoundedIcon color='primary' style={styles.icon} />
      </div>
      <div style={styles.message}>{props.lblTitle}</div>
      <div style={styles.timer}>:{props.timer}</div>
      <div style={{ textAlign: 'center' }}>
        <Button
          variant='outlined'
          color='primary'
          disableElevation
          size='small'
          classes={{
            root: classes.button
          }}
          onClick={handleClose}
        >
          {props.btClose}
        </Button>
        <Button
          variant='contained'
          color='primary'
          disableElevation
          size='small'
          classes={{
            root: classes.button
          }}
          onClick={handleConfirm}
        >
          {props.btConfirm}
        </Button>
      </div>
    </Dialog>
  );
}
