import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const muiStyles = (theme) => ({
  label: {
    fontSize: '1.3rem',
    color: '#3A404D'
  },
  bar: {
    width: '100%',
    height: '0.8rem',
    backgroundColor: '#0BDCE9',
    borderRadius: '2px',
    margin: '0.5rem 0.2rem 0.5rem 0rem'
  },
  number: {
    fontWeight: 700,
    position: 'absolute',
    transform: 'translateX(calc(-50% + 0.2rem))'
  },
  ratio: {
    width: '100%',
    backgroundColor: '#0BDCE9',
    position: 'absolute',
    bottom: 0,
    borderRadius: '3px'
  },
  figures: {
    fontSize: '1.2rem'
  }
});

class ProgressBar extends Component {
  render() {
    const { classes, data } = this.props;

    return (
      <div style={{ width: '100%' }}>
        <div className={classes.label}>{data.label}</div>
        <div style={{ width: data.ratio * 100 + '%', display: 'inline-block' }}>
          <div
            className={classes.bar}
            style={{ backgroundColor: data.color }}
          ></div>
          <div className={classes.figures}>
            {data.value}&nbsp;&nbsp;
            <span style={{ fontWeight: 700 }}>
              ({(data.ratio * 100).toFixed()}%)
            </span>
          </div>
        </div>
        <div
          style={{
            width: (1 - data.ratio) * 100 + '%',
            display: 'inline-block'
          }}
        >
          <div
            className={classes.bar}
            style={{ backgroundColor: '#F3F3F3' }}
          ></div>
          <div className={classes.figures}>&nbsp;</div>
        </div>
      </div>
    );
  }
}

export default withTranslation('monitoring')(
  withStyles(muiStyles)(ProgressBar)
);
