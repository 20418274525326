import { AvatarsSrc } from 'demo/ImagesSrc';

function formatTrans(trans) {
  if (trans) {
    let labels = {};
    trans.forEach((lbl) => {
      labels[lbl.lang] = lbl.text;
    });
    return labels;
  } else {
    return null;
  }
}

export function user(data) {
  data.avatar = avatar(data.avatar);
  return data;
}

export function persona(data) {
  let dataTranslated = {
    ...data,
    user: user(data.user),
    type: personaType(data.type),
    channel: channel(data.channel)
  };
  return dataTranslated;
}

export function personaType(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items)
  };
  return dataTranslated;
}

export function channel(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items)
  };
  return dataTranslated;
}

export function campaignType(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items)
  };
  return dataTranslated;
}

export function campaign(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items),
    type: campaignType(data.type)
  };
  return dataTranslated;
}

export function offer(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    type_trans: formatTrans(data.type_trans?.items),
    sub_type_trans: formatTrans(data.sub_type_trans?.items),
    argument_sentence_trans: formatTrans(data.argument_sentence_trans?.items),
    selling_sentence_trans: formatTrans(data.selling_sentence_trans?.items),
    post_sale_trans: formatTrans(data.post_sale_trans?.items)
  };
  if (data.others) {
    dataTranslated.others = data.others.map(offer);
  }

  return dataTranslated;
}

export function product(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items)
  };
  return dataTranslated;
}

export function contract(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    product: product(data.product)
  };
  return dataTranslated;
}

export function outcome(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items)
  };
  return dataTranslated;
}

export function reason(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    description_trans: formatTrans(data.description_trans?.items),
    product: product(data.product)
  };
  return dataTranslated;
}

export function productFeature(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items),
    unit_trans: formatTrans(data.unit_trans?.items)
  };
  return dataTranslated;
}

export function feedback(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    outcome: outcome(data.outcome),
    channel: channel(data.channel),
    reason: reason(data.reason)
  };
  return dataTranslated;
}

export function opportunity(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    offer: offer(data.offer),
    product: product(data.product)
  };
  return dataTranslated;
}

export function customerAttribute(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    attribute: {
      ...data.attribute,
      name_trans: formatTrans(data.attribute.name_trans?.items)
    }
  };
  return dataTranslated;
}

export function interaction(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    kind: {
      ...data.kind,
      name_trans: formatTrans(data.kind.name_trans?.items)
    },
    channel: channel(data.channel),
    outcome: outcome(data.outcome)
  };
  return dataTranslated;
}

export function dataDrivenTip(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    sentence_trans: formatTrans(data.sentence_trans?.items)
  };
  return dataTranslated;
}

export function collectionChoice(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    choice_trans: formatTrans(data.choice_trans?.items)
  };
  return dataTranslated;
}

export function collectionQuestion(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    question_trans: formatTrans(data.question_trans?.items),
    choices: data.choices.map(collectionChoice)
  };
  return dataTranslated;
}

export function quarantine(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    label: formatTrans(data.label?.items)
  };
  return dataTranslated;
}
export function faq(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    answer_trans: formatTrans(data.answer_trans?.items),
    question_trans: formatTrans(data.question_trans?.items),
  };
  return dataTranslated;
}

export function dataDiscoveryQuestion(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items),
    possible_answers : data.possible_answers.map(p => ({
      ...p,  name_trans: formatTrans(p.name_trans?.items)
    }))
  
  };
  return dataTranslated;
}


export function dataDiscoverySegment(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items),
    segment_script_trans: formatTrans(data.segment_script_trans?.items)
  };
  return dataTranslated;
}

export function customerOffer(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = { 
    ...data,
    argument_sentence_trans: formatTrans(data.argument_sentence_trans?.items),
    post_sale_trans: formatTrans(data.post_sale_trans?.items),
    selling_sentence_trans: formatTrans(data.selling_sentence_trans?.items),
    sub_type_trans: formatTrans(data.sub_type_trans?.items),
    type_trans: formatTrans(data.type_trans?.items),
   };
  return dataTranslated;
}
export function customerOffers(data) {
  if (!data) {
    return null;
  }

  let dataTranslated = {
    ...data,
    offer: {
      ...customerOffer(data.offer),
      others: data.offer.others.map(customerOffer)
    },
    margin_level_trans: formatTrans(data.margin_level_trans?.items),
    product_trans: formatTrans(data.product_trans?.items),
    segment_trans: formatTrans(data.segment_trans?.items),
    script_trans: formatTrans(data.script_trans?.items),
  };


  return dataTranslated;
}


export function avatar(data) {
  if (!data) {
    return {
      id: null,
      file: require('assets/images/default_avatar.png')
    };
  }
  let dataFormatted = {
    id: data.id,
    file: data.file
  };
  if (
    data &&
    data.file &&
    (data.file.indexOf('localhost') > 0 || data.file.indexOf('http') < 0)
  ) {
    let file = data.file;
    let splits = file.split('/');
    const fileFullName = splits[splits.length - 1];
    splits = fileFullName.split('.');
    const fileExt = splits[splits.length - 1];
    const fileName = splits[0];
    splits = fileName.split('_');
    const fileSuf = splits[splits.length - 1];
    const fileRealName = fileName.replace('_' + fileSuf, '') + '.' + fileExt;
    const filePath = '/uploads/avatars/' + fileRealName;
    dataFormatted.file = AvatarsSrc[filePath];
  }

  return dataFormatted;
}
