import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Slide from '@material-ui/core/Slide';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grid from '@material-ui/core/Grid';
import styles from './NotificationCenter.module.css';
import NotificationsContext from './../contexts/NotificationsProvider';
import Group from './notificationcenter/Group';
import Header from './notificationcenter/Header';

class NotificationCenter extends Component {
  static contextType = NotificationsContext;

  constructor(props) {
    super(props);
    this.state = {
      exited: true
    };
  }

  formatNotifications(data) {
    if (!data) {
      return null;
    }

    //set startDate, endDate
    const now = new Date();
    const todayStartDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const todayEndDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() + 1
    );
    todayEndDate.setMilliseconds(todayEndDate.getMilliseconds() - 1);
    const yesterdayStartDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 1
    );
    const yesterdayEndDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    yesterdayEndDate.setMilliseconds(yesterdayEndDate.getMilliseconds() - 1);
    const last7daysStartDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 7
    );
    const last7daysEndDate = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate() - 1
    );
    last7daysEndDate.setMilliseconds(last7daysEndDate.getMilliseconds() - 1);

    //repartition today / yesterday / last7days
    let todayNotifications = [];
    let yesterdayNotifications = [];
    let last7daysNotifications = [];
    data.forEach((item) => {
      if (item.date >= todayStartDate && item.date <= todayEndDate) {
        todayNotifications.push(item);
      } else if (
        item.date >= yesterdayStartDate &&
        item.date <= yesterdayEndDate
      ) {
        yesterdayNotifications.push(item);
      } else if (
        item.date >= last7daysStartDate &&
        item.date <= last7daysEndDate
      ) {
        last7daysNotifications.push(item);
      }
    });

    //output
    const groups = [];
    if (todayNotifications.length > 0) {
      groups.push({
        title: this.props.t('center.txt-today'),
        startDate: todayStartDate,
        endDate: todayEndDate,
        notifications: todayNotifications
      });
    }
    if (yesterdayNotifications.length > 0) {
      groups.push({
        title: this.props.t('center.txt-yesterday'),
        startDate: yesterdayStartDate,
        endDate: yesterdayEndDate,
        notifications: yesterdayNotifications
      });
    }
    if (last7daysNotifications.length > 0) {
      groups.push({
        title: this.props.t('center.txt-last7days'),
        startDate: last7daysStartDate,
        endDate: last7daysEndDate,
        notifications: last7daysNotifications
      });
    }
    return groups;
  }

  handleExited() {
    this.setState({ exited: true });
    this.context.markAllRead();
  }

  render() {
    const {
      notificationCenterOpen,
      closeNotificationsCenter,
      notifications
    } = this.context;

    const { exited } = this.state;

    const groups = this.formatNotifications(notifications);

    if (exited && !notificationCenterOpen) {
      return null;
    }
    return (
      <ClickAwayListener
        onClickAway={() => {
          closeNotificationsCenter();
        }}
      >
        <Slide
          direction='left'
          in={notificationCenterOpen}
          mountOnEnter
          unmountOnExit
          timeout={{ enter: 500, exit: 300 }}
          onEnter={() => this.setState({ exited: false })}
          onExited={() => this.handleExited()}
        >
          <div className={styles.Center}>
            <Header />
            <div className={styles.Groups}>
              {groups &&
                groups.length > 0 &&
                groups.map((group, index) => (
                  <Group
                    key={index}
                    title={group.title}
                    notifications={group.notifications}
                    startDate={group.startDate}
                    endDate={group.endDate}
                  />
                ))}
              {groups && groups.length === 0 && (
                <Grid
                  container
                  direction='column'
                  justify='center'
                  alignContent='center'
                  className={styles.Empty}
                >
                  <Grid item>{this.props.t('center.txt-empty')}</Grid>
                </Grid>
              )}
            </div>
          </div>
        </Slide>
      </ClickAwayListener>
    );
  }
}

export default withTranslation('notification')(NotificationCenter);
