//import { postRequest, getRequest, patchRequest, deleteRequest } from 'deep/api/utils';
//import * as MwHistory from 'utils/MwHistory';
import * as FormatData from './formatdata';
import { FEEDBACKS_LIKE } from './../utils/Constants';

let pushNotificationsIds = [];
let notifications = null;

export function getCategories() {
  return new Promise(function (resolve, reject) {
    var categories = require('./mockdata/categories.json');
    const result = FormatData.categories(categories);
    resolve(result);
  });
}

export function pushNotification() {
  return new Promise(function (resolve, reject) {
    var poolNotifications = require('./mockdata/notifications_pool.json');
    const result = FormatData.notifications(poolNotifications);
    if (pushNotificationsIds.length === poolNotifications.length) {
      pushNotificationsIds = [];
    }
    let pushNotif = null;
    for (let notification of result) {
      if (pushNotificationsIds.indexOf(notification.id) < 0) {
        const date = new Date();
        pushNotif = {
          ...notification,
          id: date.getTime(),
          date: date,
          like: FEEDBACKS_LIKE.UNKNOWN,
          read: false
        };
        pushNotificationsIds.push(notification.id);
        break;
      }
    }

    getNotifications();
    notifications.unshift(pushNotif);

    resolve(pushNotif);
  });
}

export function getNotifications() {
  return new Promise(function (resolve, reject) {
    if (!notifications) {
      var json = require('./mockdata/notifications.json');
      const results = FormatData.notifications(json);
      notifications = [];
      let last7daysMinusDays = 4;
      for (let notification of results) {
        let date = new Date();
        let hours = Math.floor(9 + Math.random() * (18 - 9));
        let minutes = Math.floor(0 + Math.random() * (59 - 0));
        date.setHours(hours);
        date.setMinutes(minutes);
        if (notification.date === 'yesterday') {
          date.setDate(date.getDate() - 1);
        } else if (notification.date === 'last7days') {
          date.setDate(date.getDate() - last7daysMinusDays);
          last7daysMinusDays = last7daysMinusDays + 1;
        }
        let notif = {
          ...notification,
          date: date,
          like: FEEDBACKS_LIKE.UNKNOWN,
          read: false
        };
        notifications.push(notif);
      }
    }

    resolve(notifications);
  });
}

export function getUnreadNotificationsCount() {
  return new Promise(function (resolve, reject) {
    let count = 0;
    if (!notifications) {
      getNotifications();
    }
    for (let notification of notifications) {
      if (!notification.read) {
        count++;
      }
    }

    resolve(count);
  });
}

export function likeNotification(notificationId) {
  return new Promise(function (resolve, reject) {
    for (let notification of notifications) {
      if (notification.id === notificationId) {
        notification.like = FEEDBACKS_LIKE.LIKE;
        notification.read = true;
        resolve(notification);
      }
    }
    resolve(null);
  });
}

export function unlikeNotification(notificationId) {
  return new Promise(function (resolve, reject) {
    for (let notification of notifications) {
      if (notification.id === notificationId) {
        notification.like = FEEDBACKS_LIKE.UNLIKE;
        notification.read = true;
        resolve(notification);
      }
    }
    resolve(null);
  });
}

export function deleteNotificationsGroup(startDate, endDate) {
  return new Promise(function (resolve, reject) {
    let toRemove = [];
    for (let i = 0; i < notifications.length; i++) {
      let notification = notifications[i];
      if (notification.date >= startDate && notification.date <= endDate) {
        toRemove.push(i);
      }
    }
    for (var i = toRemove.length - 1; i >= 0; i--) {
      notifications.splice(toRemove[i], 1);
    }
    resolve(notifications);
  });
}

export function markAllRead() {
  return new Promise(function (resolve, reject) {
    for (let i = 0; i < notifications.length; i++) {
      notifications[i].read = true;
    }
    resolve(notifications);
  });
}
