import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const muiStyles = (theme) => ({
  paper: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#fff',
    padding: '3.6rem 2.3rem',
    boxShadow: '0px 0px 10px -7px rgba(0,0,0,0.5)',
    borderRadius: 6
  },
  icon: {
    fontSize: '1.7rem',
    color: '#C7C7C7',
    position: 'absolute'
  },
  innerWithoutIcon: {
    marginLeft: '0rem'
  },
  innerWithIcon: {
    marginLeft: '3rem'
  },
  title: {
    fontWeight: 600,
    fontSize: '1.8rem'
  },
  subtitle: {
    fontWeight: 300,
    fontSize: '1.3rem',
    opacity: 0.9
  },
  content: {
    marginTop: '2rem'
  }
});

class Card extends Component {
  render() {
    const { classes, title, subtitle, icon } = this.props;

    return (
      <Paper elevation={0} className={classes.paper}>
        <div className={classes.icon}>{icon}</div>
        <div
          className={`${
            icon ? classes.innerWithIcon : classes.innerWithoutIcon
          }`}
        >
          <div className={classes.title}>{title}</div>
          <div className={classes.subtitle}>{subtitle}</div>
          <div className={classes.content}>{this.props.children}</div>
        </div>
      </Paper>
    );
  }
}

export default withTranslation('monitoring')(withStyles(muiStyles)(Card));
