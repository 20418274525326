import React, { Component } from 'react';
import * as MwHistory from 'utils/MwHistory';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './Menu.module.css';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import game_bg_left from 'assets/images/game_bg_left.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAsterisk,
  faTrophy,
  faTachometerAlt,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';
import AvatarChoice from './AvatarChoice';
import GamificationContext from './../contexts/GamificationProvider';

const muiStyles = (theme) => ({
  paper: {
    maxWidth: '78.5rem !important'
  },
  avatarRoot: {
    height: '12rem',
    width: '12rem',
    border: '1px solid #E4E8EA',
    margin: '0rem auto 1.4rem',
    cursor: 'pointer',
    '&:hover': {
      border: '1px solid ' + theme.palette.primary.main
    }
  },
  menuListItem: {
    padding: '1rem 0rem',
    width: 'fit-content',
    '&:hover': {
      color: theme.palette.primary.main,
      backgroundColor: 'transparent'
    },
    '&&selected': {
      backgroundColor: 'transparent'
    }
  },
  menuListItemSelected: {
    padding: '1rem 0rem',
    color: theme.palette.primary.main,
    backgroundColor: 'transparent !important'
  },
  menuListItemIcon: {
    color: 'inherit',
    minWidth: '5rem',
    fontSize: '1.7rem',
    opacity: '0.7'
  },
  menuListItemText: {
    fontSize: '1.5rem'
  }
});

class Menu extends Component {
  static contextType = GamificationContext;

  constructor(props) {
    super(props);
    this.state = {
      avatarChoiceOpen: false
    };
    this.handleCloseAvatarChoice = this.handleCloseAvatarChoice.bind(this);
    this.handleClickAvatar = this.handleClickAvatar.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }

  redirect(event, index, route) {
    event.preventDefault();
    MwHistory.push(route);
  }

  handleClickAvatar(event) {
    event.preventDefault();
    this.securSetState({
      avatarChoiceOpen: true
    });
  }

  handleCloseAvatarChoice() {
    this.securSetState({
      avatarChoiceOpen: false
    });
  }

  renderProfile() {
    const { classes } = this.props;
    const { me, myKeyInfo } = this.context;

    let mePoints = '-';
    let meRank = '-';
    let meBadgesCount = '-';
    let meLevelName = '-';
    if (myKeyInfo) {
      mePoints = myKeyInfo.points;
      meRank = myKeyInfo.rank;
      meBadgesCount = myKeyInfo.badges;
      meLevelName =
        myKeyInfo.current_level.name_trans[i18next.language] ||
        myKeyInfo.current_level.name_trans[i18next.options.fallbackLng[0]];
    }

    let meFirstname = '';
    let meLastname = '';
    let meAvatar = require('assets/images/default_avatar.png');
    if (me) {
      meAvatar = me.user.avatar.file;
      meFirstname = me.user.first_name;
      meLastname = me.user.last_name;
    }

    return (
      <div className={styles.Profile}>
        <Avatar
          classes={{ root: classes.avatarRoot }}
          src={meAvatar}
          onClick={(event) => this.handleClickAvatar(event)}
        />
        <div className={styles.Name}>
          {this.props.t('menu.hello')} {meLevelName}, <br />
          {meFirstname}&nbsp;{meLastname}!
        </div>
        <Grid container wrap='nowrap'>
          <Grid item className={styles.Key}>
            <span className={styles.Value}>{mePoints}</span>
            <br />
            <span className={styles.Label}>{this.props.t('menu.points')}</span>
          </Grid>
          <Grid item className={styles.Key}>
            <span className={styles.Value}>{meRank}</span>
            <br />
            <span className={styles.Label}>{this.props.t('menu.rank')}</span>
          </Grid>
          <Grid item className={styles.Key}>
            <span className={styles.Value}>{meBadgesCount}</span>
            <br />
            <span className={styles.Label}>{this.props.t('menu.badges')}</span>
          </Grid>
        </Grid>
      </div>
    );
  }

  renderMenu() {
    const currentRoute = MwHistory.getCurrentRoute();
    const { classes } = this.props;

    const menuItems = [
      {
        icon: faTachometerAlt,
        label: this.props.t('menu.ent_dashboard'),
        route: '/cco/game/dashboard'
      },
      {
        icon: faAsterisk,
        label: this.props.t('menu.ent_level'),
        route: '/cco/game/levels'
      },
      {
        icon: faTrophy,
        label: this.props.t('menu.ent_badges'),
        route: '/cco/game/badges'
      },
      //{icon: faUser, label: 'Profile' },
      //{icon: faCog, label: 'Setup' },
      {
        icon: faExclamationCircle,
        label: this.props.t('menu.ent_rules'),
        route: '/cco/game/rules'
      }
    ];

    return (
      <List disablePadding className={styles.Menu}>
        {menuItems.map((item, index) => {
          return (
            <ListItem
              key={index}
              button
              disableRipple
              disableGutters
              selected={item.route === currentRoute.path}
              classes={{
                root: classes.menuListItem,
                selected: classes.menuListItemSelected
              }}
              onClick={(event) => {
                this.redirect(event, index, item.route);
              }}
            >
              <ListItemIcon classes={{ root: classes.menuListItemIcon }}>
                <FontAwesomeIcon icon={item.icon} />
              </ListItemIcon>
              <ListItemText
                primary={item.label}
                classes={{ primary: classes.menuListItemText }}
              />
            </ListItem>
          );
        })}
      </List>
    );
  }

  render() {
    const { classes } = this.props;
    const { avatarChoiceOpen } = this.state;

    return (
      <>
        <Dialog
          onClose={this.handleCloseAvatarChoice}
          aria-labelledby='avatar'
          open={avatarChoiceOpen}
          classes={{ paper: classes.paper }}
        >
          <AvatarChoice
            inDialog={true}
            onClose={this.handleCloseAvatarChoice}
          />
        </Dialog>
        <div
          className={styles.Wrapper}
          style={{
            backgroundImage: `url(${game_bg_left})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'bottom center'
          }}
        >
          {this.renderProfile()}
          {this.renderMenu()}
        </div>
      </>
    );
  }
}

export default withTranslation('gamification')(withStyles(muiStyles)(Menu));
