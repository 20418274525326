import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const muiStyles = (theme) => ({
  gridItem: {
    fontSize: '1.2rem',
    textAlign: 'center'
  },
  bar: {
    margin: '0.2rem auto',
    position: 'relative'
  },
  number: {
    fontWeight: 700,
    position: 'absolute'
  },
  ratio: {
    width: '100%',
    backgroundColor: '#0BDCE9',
    position: 'absolute',
    bottom: 0,
    borderRadius: '3px'
  },
  label: {
    marginTop: '1rem',
    opacity: 0.8
  }
});

class Histogram extends Component {
  render() {
    const { classes, data, colWidth, barWidth, barHeight } = this.props;

    return (
      <Grid
        container
        spacing={0}
        justify='space-around'
        wrap='nowrap'
        style={{ marginTop: '3rem' }}
      >
        {data &&
          data.map((item, index) => (
            <Grid
              item
              key={index}
              classes={{ root: classes.gridItem }}
              style={{ width: colWidth + 'rem' }}
            >
              <div
                className={classes.bar}
                style={{ width: barWidth + 'rem', height: barHeight + 'rem' }}
              >
                <div
                  className={classes.number}
                  style={{
                    bottom: 'calc(' + item.ratio * 100 + '% + 0.4rem)',
                    transform:
                      'translateX(calc(-50% + ' + barWidth / 2 + 'rem))'
                  }}
                >
                  {item.value}
                </div>
                <div
                  className={classes.ratio}
                  style={{ height: item.ratio * 100 + '%' }}
                ></div>
              </div>
              <div className={classes.label}>{item.label}</div>
            </Grid>
          ))}
      </Grid>
    );
  }
}

export default withTranslation('monitoring')(withStyles(muiStyles)(Histogram));
