import * as FormatTrans from 'deep/api/formatTransModels';

export function personas(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.persona(item));
  });
  return dataTranslated;
}

export function channels(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.channel(item));
  });
  return dataTranslated;
}

export function personaTypes(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(FormatTrans.personaType(item));
  });
  return dataTranslated;
}

export function users(data) {
  let dataFormatted = [];
  data.forEach((item) => {
    dataFormatted.push(FormatTrans.user(item));
  });
  return dataFormatted;
}

export function avatars(data) {
  let dataFormatted = [];
  data.forEach((item) => {
    dataFormatted.push(FormatTrans.avatar(item));
  });
  return dataFormatted;
}
