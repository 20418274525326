import React, { Component } from 'react';
import * as GamificationApi from './../api/endpoints';
import { BADGES_CODE } from './../utils/Constants';
import { NOTIF_TYPES } from './../utils/Constants';
import * as MwHistory from 'utils/MwHistory';
import NotificationBadge from 'gamification/components/NotificationBadge';
import NotificationLevel from 'gamification/components/NotificationLevel';
import NotificationPoints from 'gamification/components/NotificationPoints';

const GamificationContext = React.createContext();

class GamificationProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myWonBadges: null,
      myKeyInfo: null,
      haveWelcomeBadge: false,
      haveAvatarBadge: false,
      showNotification: false,
      notificationType: null,
      notificationData: null,
      feedbackNotificationTimer: 15,
      intervalId: null
    };
  }
  componentDidMount() {
    this.mounted = true;
    this.securSetState({
      feedbackNotificationTimer: 15
    });
    this.loadMyKeyInfo();
    this.loadMyWonBadges();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearInterval(this.state.intervalId);
    clearTimeout(this.timeout);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  async loadMyWonBadges() {
    const meUserId = this.props.appContext.me.user.id;
    const result = await GamificationApi.getWonBadges(meUserId);
    this.onLoadMyWonBadgesSucceed(result);
  }

  onLoadMyWonBadgesSucceed(myWonBadges) {
    for (let i = 0; i < myWonBadges.length; i++) {
      if (myWonBadges[i].code === BADGES_CODE.WELCOME) {
        this.securSetState({ haveWelcomeBadge: true });
      } else if (myWonBadges[i].code === BADGES_CODE.AVATAR) {
        this.securSetState({ haveAvatarBadge: true });
      }
    }
    this.securSetState({ myWonBadges: myWonBadges });
  }

  async loadBadgeAndRaise(badgeCode, post = false) {
    const badge = await GamificationApi.getBadge(badgeCode);
    if (post) {
      this.postBadge(badge);
    } else {
      this.raiseBadge(badge);
    }
  }

  async postBadge(badge) {
    await GamificationApi.postBadge(badge);
    this.raiseBadge(badge);
  }

  raiseBadge(badge) {
    this.loadMyKeyInfo();
    this.loadMyWonBadges();
    this.showNotification(NOTIF_TYPES.BADGE, badge);
  }

  async loadMyKeyInfo() {
    const result = await GamificationApi.getMyKeyInfo();
    this.securSetState({ myKeyInfo: result });
  }

  showNotification(type, data) {
    this.securSetState({
      showNotification: true,
      notificationType: type,
      notificationData: data
    });
  }
  closeNotification() {
    clearInterval(this.state.intervalId);
    this.setState(() => ({
      feedbackNotificationTimer: 15,
      showNotification: false,
      notificationType: null,
      notificationData: null
    }));
  }

  async loadLastEventAndRaise() {
    const meUserId = this.props.appContext.me.user.id;
    const gamificationEvents = await GamificationApi.getEvents(meUserId, 1);
    const lastEvent = gamificationEvents[0];
    const newMyKeyInfo = await GamificationApi.getMyKeyInfo();
    this.showFeedbackNotification(
      this.state.myKeyInfo,
      newMyKeyInfo,
      lastEvent
    );
  }

  showFeedbackNotification(previousMyKeyInfo, newMyKeyInfo, gamificationEvent) {
    let gamification = null;

    if (previousMyKeyInfo.current_level.id !== newMyKeyInfo.current_level.id) {
      gamification = {
        type: NOTIF_TYPES.LEVEL,
        previousLevel: previousMyKeyInfo.current_level
      };
    } else if (gamificationEvent.what === 'feedback_fast_accepted') {
      gamification = {
        type: NOTIF_TYPES.FAST_SUCCESS_CALL,
        what: gamificationEvent.what,
        earnedPoints: gamificationEvent.points,
        nextLevel: previousMyKeyInfo.next_level
      };
    } else {
      gamification = {
        type: NOTIF_TYPES.POINTS,
        what: gamificationEvent.what,
        earnedPoints: gamificationEvent.points
      };
    }

    let intervalId = setInterval(() => {
      this.setTimerFeedbackNotification(this.state.feedbackNotificationTimer);
    }, 1000);
    this.securSetState({
      showNotification: true,
      notificationType: gamification.type,
      notificationData: gamification,
      intervalId: intervalId,
      myKeyInfo: newMyKeyInfo
    });
  }
  setTimerFeedbackNotification(timer) {
    if (timer === 0) {
      this.closeNotification();
      MwHistory.replace('/cco');
    } else {
      this.securSetState({
        feedbackNotificationTimer: timer - 1
      });
    }
  }
  onConfirmFeedbackNotification() {
    this.closeNotification();
    MwHistory.replace('/cco');
  }

  raiseWelcomeBadge = () => {
    this.securSetState({ haveWelcomeBadge: true });
    this.loadBadgeAndRaise(BADGES_CODE.WELCOME, true);
  };
  raiseAvatarBadge = () => {
    this.securSetState({ haveAvatarBadge: true });
    this.loadBadgeAndRaise(BADGES_CODE.AVATAR, false);
  };
  raiseFeedbackNotification = () => {
    this.loadLastEventAndRaise();
  };
  refreshMe = () => {
    this.props.appContext.refreshMe();
  };

  render() {
    const { children } = this.props;
    const {
      myKeyInfo,
      myWonBadges,
      haveWelcomeBadge,
      haveAvatarBadge,
      showNotification,
      notificationType,
      notificationData,
      feedbackNotificationTimer
    } = this.state;
    const {
      refreshMe,
      raiseWelcomeBadge,
      raiseAvatarBadge,
      raiseFeedbackNotification
    } = this;

    const me = this.props.appContext.me;

    return (
      <GamificationContext.Provider
        value={{
          me,
          myKeyInfo,
          myWonBadges,
          haveWelcomeBadge,
          haveAvatarBadge,
          refreshMe,
          raiseWelcomeBadge,
          raiseAvatarBadge,
          raiseFeedbackNotification
        }}
      >
        {showNotification && notificationType === NOTIF_TYPES.BADGE && (
          <NotificationBadge
            open={showNotification}
            badge={notificationData}
            myKeyInfo={myKeyInfo}
            onClose={() => this.closeNotification()}
          />
        )}
        {showNotification && notificationType === NOTIF_TYPES.LEVEL && (
          <NotificationLevel
            open={showNotification}
            data={notificationData}
            myKeyInfo={myKeyInfo}
            onClose={() => this.closeNotification()}
            timer={feedbackNotificationTimer}
            onConfirm={() => this.onConfirmFeedbackNotification()}
          />
        )}
        {showNotification &&
          (notificationType === NOTIF_TYPES.POINTS ||
            notificationType === NOTIF_TYPES.FAST_SUCCESS_CALL) && (
            <NotificationPoints
              open={showNotification}
              data={notificationData}
              me={me}
              myKeyInfo={myKeyInfo}
              onClose={() => this.closeNotification()}
              timer={feedbackNotificationTimer}
              onConfirm={() => this.onConfirmFeedbackNotification()}
            />
          )}
        {children}
      </GamificationContext.Provider>
    );
  }
}

export default GamificationContext;

export { GamificationProvider };
