import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const muiStyles = (theme) => ({
  wrapper: {
    position: 'relative'
  },
  root: {
    position: 'absolute',
    left: 'calc(50% - 2.5rem)',
    top: 'calc(50% - 2.5rem)'
  },
  progress: {
    color: '#dedede'
  }
});

class Loader extends Component {
  render() {
    const { classes, positionY } = this.props;

    return (
      <CircularProgress
        classes={{
          root: classes.root,
          colorSecondary: classes.progress
        }}
        style={{ marginTop: positionY || 0 }}
        color='secondary'
      />
    );
  }
}

export default withStyles(muiStyles)(Loader);
