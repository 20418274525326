import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CallSessionContext from './../../contexts/CallSessionProvider';

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';

import SimpleTabsMenu from 'deep/components/materials/SimpleTabsMenu';
import OfferList from './OfferList';
import { prettifyScript, DBScriptSep} from './utils';

import i18next from 'i18next';


const formatLang = (transItem) => {
  if (!transItem){
    return
  }

  return transItem[i18next.language] || transItem[i18next.options.fallbackLng[0]]
}

class Offer extends Component {
  static contextType = CallSessionContext;


  componentDidUpdate() {
    if (!this.rendered && this.context.collections) {
      this.rendered = true;

    }
  }

  render() {
    const styles = {
      gridItem: {
        minHeight: '7rem'
      },
      label: {
        color: '#4C4C4C',
        fontWeight: 600,
        paddingTop: '0.1rem',
        fontSize: '1.8rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      gridItem: {
        minHeight: '7rem'
      },
    };

    const { 
      customerOffers,
      setSelectedOffer,
      selectedOffer,
      offerComment,
      setCustomerOfferComment
    } = this.context;

    return customerOffers && customerOffers.offerScripts.length > 0 && (
      <div style={{margin: '1em'}}>
        <Grid
          container
          spacing={2}
          alignItems='center'
          alignContent='flex-start'
        >
             <Grid item xs={2} lg={2} style={styles.gridItem}>
             <Typography gutterBottom style={styles.label}>
                    {this.props.t('session.offer.card-title')}:
                </Typography>
            </Grid>
            <Grid item xs={10} lg={10} style={styles.gridItem}>
                <OfferList
                    offerScripts={customerOffers.offerScripts}
                    setSelectedOffer={setSelectedOffer}
                    selectedOffer={selectedOffer}
                />

            </Grid>
            {selectedOffer === (customerOffers.offerScripts.length - 1) && <Grid item xs={12} lg={12} style={styles.gridItem}>
              <TextField
                id="outlined-secondary"
                variant="outlined"
                color="secondary"
                value={offerComment || ''}
                onChange={(event) => setCustomerOfferComment(event.target.value)}
                fullWidth
              />
            </Grid>}
            {Number.isInteger(selectedOffer) && selectedOffer > -1 && customerOffers.offerScripts[selectedOffer].offer.id &&  (
            <Grid item xs={12} lg={12} style={styles.gridItem}>
              <SimpleTabsMenu
                tabs={[
                  {
                    id: 1,
                    name: this.props.t('session.scripts.offer-script-header'),
                    value: 'offerScript'
                  }
                ]}
                defaultValue={0}
                onChange={() => null}
              />
              <div>
                  <div>
                    {prettifyScript(
                      formatLang(customerOffers.offerScripts[selectedOffer].offer.selling_sentence_trans),
                      DBScriptSep)}
                  </div>
              
              </div>
            </Grid>
            )}
      
        </Grid>
      </div>
    );
  }
}

export default withTranslation('cco')(Offer);
