import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

export default function RadioButtonsList(props) {
  const options = props.options;

  const [value, setValue] = React.useState(props.defaultValue);
  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    props.onChange(event, newValue);
  };

  const useStyles = makeStyles((theme) => ({
    lblRoot: {
      minWidth: '100%'
    },
    lblTxt: {
      color: '#777777',
      fontSize: '1.5rem'
    },
    radio: {
      padding: '0.3rem 0.8rem 0.3rem 0rem',
      backgroundColor: 'transparent'
    },
    icon: {
      width: '1.3rem'
    }
  }));

  const classes = useStyles();

  return (
    <FormControl
      component='fieldset'
      style={{
        marginBottom: '2rem',
        marginLeft: '1.2rem'
      }}
    >
      <RadioGroup
        aria-label='options'
        name='option'
        value={value}
        onChange={handleChange}
        row={true}
      >
        {options.map((option, index) => (
          <FormControlLabel
            key={index}
            value={option.value}
            control={
              <Radio
                classes={{ root: classes.radio }}
                checkedIcon={
                  <RadioButtonCheckedIcon className={classes.icon} />
                }
                icon={<RadioButtonUncheckedIcon className={classes.icon} />}
                size='small'
                disableRipple
                color='primary'
              />
            }
            label={option.label}
            classes={{ root: classes.lblRoot, label: classes.lblTxt }}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
