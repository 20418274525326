import React, { Component } from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import styles from './BadgeUpcoming.module.css';

class BadgeUpcoming extends Component {
  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }

  render() {
    const { badge } = this.props;

    const badgeName = this.getTrans(badge.name_trans);
    const badgeImage = badge.image;

    return (
      <div className={styles.Wrapper}>
        <div className={styles.Image}>
          <img alt={badgeName} src={badgeImage} />
        </div>
        <div className={styles.Name}>{badgeName}</div>
      </div>
    );
  }
}

export default withTranslation('gamification')(BadgeUpcoming);
