import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

export default function SimpleSwitch(props) {
  const [state, setState] = React.useState({ checked: props.default });

  const handleChange = (event) => {
    setState({ checked: event.target.checked });
    props.onChange(event.target.checked);
  };

  const useStyles = makeStyles({
    root: {
      width: 24,
      height: 14,
      padding: 0,
      display: 'flex'
    },
    switchBase: {
      padding: 2,
      color: '#fff',
      '&$checked': {
        transform: 'translateX(10px)',
        color: '#fff',
        '& + $track': {
          opacity: 1,
          backgroundColor: props.color,
          borderColor: props.color
        }
      }
    },
    thumb: {
      width: 10,
      height: 10,
      boxShadow: 'none'
    },
    track: {
      border: `1px solid #BBC2C9}`,
      borderRadius: 14 / 2,
      opacity: 1,
      backgroundColor: '#BBC2C9'
    },
    checked: {}
  });

  const classes = useStyles(props);

  return (
    <Grid component='label' container alignItems='center' spacing={1}>
      {props.labelOff && (
        <Grid item style={{ fontSize: '1.3rem' }}>
          {props.labelOff}
        </Grid>
      )}
      <Grid item>
        <Switch
          disableRipple
          checked={state.checked}
          onChange={handleChange}
          name='checkedC'
          classes={classes}
        />
      </Grid>
      {props.labelOn && (
        <Grid item style={{ fontSize: '1.3rem' }}>
          {props.labelOn}
        </Grid>
      )}
    </Grid>
  );
}
