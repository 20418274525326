import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Paper from '@material-ui/core/Paper';
import Collapse from '@material-ui/core/Collapse';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CallSessionContext from './../../contexts/CallSessionProvider';

class OpportunitiesList extends Component {
  static contextType = CallSessionContext;

  constructor(props) {
    super(props);
    this.state = {
      isExpanded: false,
      selectedIndex: 0
    };
    this.toggle = this.toggle.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleClickAway = this.handleClickAway.bind(this);
  }

  toggle(e) {
    if (this.context.opportunities.length > 1) {
      this.setState(() => ({ isExpanded: !this.state.isExpanded }));
    }
  }
  handleSelect(event, index) {
    this.toggle(event);
    this.context.setSelectedOpportunity(this.context.opportunities[index]);
    this.setState(() => ({ selectedIndex: index }));
  }

  handleClickAway(e) {
    this.setState(() => ({ isExpanded: false }));
  }

  formatOpportunities(data) {
    if (!data) {
      return null;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      dataFormatted.push({
        id: item.id,
        sub_type:
          item.offer.sub_type_trans[i18next.language] ||
          item.offer.sub_type_trans[i18next.options.fallbackLng[0]]
      });
    });

    return dataFormatted;
  }

  render() {
    const opportunities = this.formatOpportunities(this.context.opportunities);
    const { isExpanded, selectedIndex } = this.state;

    const styles = {
      wrapper: {
        position: 'absolute',
        zIndex: 10,
        top: '-0.4rem',
        border: '1px solid #bcbcbc',
        borderRadius: '1.8rem',
        width: '100%'
      },
      paper: {
        borderRadius: '1.8rem',
        padding: '0rem 1rem 0rem'
      },
      selectedItem: {
        position: 'relative',
        cursor:
          opportunities && opportunities.length > 1 ? 'pointer' : 'default',
        padding: '0.7rem 0rem 1.7rem 0rem',
        picto: {
          position: 'absolute',
          top: '40%',
          transform: 'translateY(-50%)'
        },
        label: {
          marginLeft: '0rem',
          marginRight: '2rem',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        },
        arrow: {
          position: 'absolute',
          fontSize: '2rem',
          top: '0.8rem',
          right: '-0.3rem'
        }
      },
      divider: {
        marginBottom: '1rem'
      },
      subItemBt: {
        textTransform: 'none',
        textAlign: 'left',
        paddingLeft: '1.6rem',
        lineHeight: '1',
        minHeight: '3.7rem',
        backgroundColor: 'transparent',
        picto: {
          minWidth: '2rem'
        }
      }
    };

    const ButtonStyled = withStyles((theme) => ({
      label: {
        justifyContent: 'flex-start'
      },
      text: {
        '&:hover': {
          color: theme.palette.primary.main
        }
      }
    }))(Button);

    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <div style={{ width: '100%', position: 'relative' }}>
          {opportunities && (
            <div style={styles.wrapper}>
              <Paper elevation={isExpanded ? 4 : 0} style={styles.paper}>
                <Collapse in={isExpanded} collapsedHeight='3.7rem'>
                  <div style={styles.selectedItem} onClick={this.toggle}>
                    <div style={styles.selectedItem.label}>
                      {opportunities[selectedIndex].sub_type}
                    </div>
                    {opportunities.length > 1 && (
                      <ExpandMoreIcon style={styles.selectedItem.arrow} />
                    )}
                  </div>

                  <Divider style={styles.divider} />
                  {opportunities.map((item, index) =>
                    index !== selectedIndex ? (
                      <div key={index}>
                        <ButtonStyled
                          style={styles.subItemBt}
                          variant='text'
                          disableElevation
                          disableRipple
                          onClick={(event) => this.handleSelect(event, index)}
                        >
                          {item.sub_type}
                        </ButtonStyled>
                      </div>
                    ) : (
                      <div key={index}></div>
                    )
                  )}
                  <div style={{ height: '1.5rem' }}></div>
                </Collapse>
              </Paper>
            </div>
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

export default withTranslation('cco')(OpportunitiesList);
