import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import GamificationWrapper from './GamificationWrapper';
import Card from './../components/Card';
import page_1 from 'assets/mockdata/rules/page_1.png';
import GamificationContext from './../contexts/GamificationProvider';

class GamificationRules extends Component {
  static contextType = GamificationContext;

  render() {
    return (
      <GamificationWrapper>
        <Card
          title={this.props.t('menu.ent_rules')}
          icon=<FontAwesomeIcon icon={faExclamationCircle} />
          onView={null}
        >
          <img alt='1' src={page_1} style={{ width: '100%' }} />
        </Card>
      </GamificationWrapper>
    );
  }
}

export default withTranslation('gamification')(GamificationRules);
