export const BADGES_CODE = {
  WELCOME: 'badge_1',
  AVATAR: 'badge_2'
};
export const NOTIF_TYPES = {
  BADGE: 'badge',
  LEVEL: 'level',
  POINTS: 'points',
  FAST_SUCCESS_CALL: 'fastsuccesscall'
};
