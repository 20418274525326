import React from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import DropDownList from './DropDownList';
import Box from '@material-ui/core/Box';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function TableHeadLabels(props) {
  const classes = useHeadLabelsStyles();
  const { headLabels } = props;

  return (
    <TableRow>
      {headLabels.map((headCell) => (
        <TableCell
          key={headCell.id}
          colSpan={headCell.colspan}
          classes={{ root: classes.headerCell }}
        >
          <Box classes={{ root: classes.box }}>{headCell.label}</Box>
        </TableCell>
      ))}
    </TableRow>
  );
}

const useHeadLabelsStyles = makeStyles((theme) => ({
  headerCell: {
    padding: '0rem',
    borderBottom: 'none',
    verticalAlign: 'bottom'
  },
  box: {
    fontSize: '1.4rem',
    color: '#4C4C4C',
    fontWeight: 600,
    backgroundColor: '#E7E9EC',
    marginRight: '1rem',
    marginBottom: '1.2rem',
    borderRadius: '6px',
    textAlign: 'center'
  }
}));

function EnhancedTableHead(props) {
  const { columns, classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableRow>
      {columns.map((headCell) => (
        <TableCell
          key={headCell.id}
          align={headCell.numeric ? 'right' : 'left'}
          padding='none'
          sortDirection={orderBy === headCell.id ? order : false}
          classes={{ root: classes.headerCell }}
          style={headCell.customHeadStyle || {}}
        >
          <TableSortLabel
            disabled={!headCell.sortable}
            active={orderBy === headCell.id}
            direction={orderBy === headCell.id ? order : 'asc'}
            onClick={createSortHandler(headCell.id)}
          >
            {headCell.label}
            {orderBy === headCell.id ? (
              <span className={classes.visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </span>
            ) : null}
          </TableSortLabel>
        </TableCell>
      ))}
    </TableRow>
  );
}

EnhancedTableHead.propTypes = {
  columns: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired
};

const usePaginationStyles = makeStyles((theme) => ({
  pagination: {
    marginTop: '3.6rem',
    display: 'flex',
    alignItems: 'center',
    spacing: 1
  },
  pager: {
    border: '1px solid #e2e6eb',
    borderRadius: '1.8rem',
    height: '3.6rem',
    padding: '0rem 1rem'
  },
  page: {
    padding: '0rem 1rem',
    fontSize: '1.3rem',
    cursor: 'pointer'
  },
  pageSelected: {
    color: theme.palette.primary.main,
    padding: '0rem 1rem 0rem',
    fontSize: '1.3rem',
    fontWeight: 700
  },
  numbers: {
    marginLeft: '3rem',
    marginRight: '1.2rem',
    fontSize: '1.3rem'
  },
  leftArrow: {
    fontSize: '2rem',
    paddingRight: '1rem',
    cursor: 'pointer'
  },
  rightArrow: {
    fontSize: '2rem',
    paddingLeft: '1rem',
    cursor: 'pointer'
  }
}));

const useStyles = makeStyles({
  root: {
    width: '100%'
  },
  headerCell: {
    fontSize: '1.4rem',
    color: '#4C4C4C',
    fontWeight: 600,
    borderBottom: 'none',
    verticalAlign: 'bottom',
    whiteSpace: 'wrap',
    lineHeight: '1.6rem',
    padding: '0.6rem 0.6rem 0.6rem !important',
    '&:first-child': {
      padding: '0rem 0rem 0.6rem 0.8rem !important'
    },
    '&:last-child': {
      padding: '0rem 0rem 0.6rem !important'
    }
  },
  body: {
    border: '1px solid #E6E9EE',
    borderRadius: '10px'
  },
  cell: {
    fontSize: '1.3rem',
    color: '#777777',
    backgroundColor: '#fff',
    borderBottom: '1px solid #E6E9EE',
    padding: '1rem 0.8rem',
    verticalAlign: 'top',
    '&:first-child': {
      padding: '1rem 0rem 0rem 0.8rem !important'
    },
    '&:last-child': {
      padding: '1rem 0.8rem 0rem 0rem !important'
    }
  },
  table: {},
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1
  }
});

const Pagination = (props) => {
  const classes = usePaginationStyles();
  const {
    data,
    rowsPerPage,
    rowsPerPageSelected,
    page,
    rowsPerPageOptions,
    onChangeRowsPerPage,
    onChangePage,
    txtTo,
    txtOf
  } = props;
  const changeRowsPerPageHandler = (property) => {
    onChangeRowsPerPage(property);
  };
  const changePageHandler = (newPage) => {
    if (newPage < numPages && newPage > -1) {
      onChangePage(newPage);
    }
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);
  const from = page * rowsPerPage + 1;
  const to = from + rowsPerPage - emptyRows - 1;
  const numPages = Math.ceil(data.length / rowsPerPage);

  return (
    <div className={classes.pagination}>
      <div>
        <Grid className={classes.pager} container alignItems='center'>
          <ArrowBackIosRoundedIcon
            className={classes.leftArrow}
            onClick={() => changePageHandler(page - 1)}
          />
          <Divider orientation='vertical' flexItem />
          {[...Array(numPages)].map((e, index) => {
            return (
              <div
                key={index}
                className={index === page ? classes.pageSelected : classes.page}
                onClick={() => changePageHandler(index)}
              >
                {index + 1}
              </div>
            );
          })}
          <Divider orientation='vertical' flexItem />
          <ArrowForwardIosRoundedIcon
            className={classes.rightArrow}
            onClick={() => changePageHandler(page + 1)}
          />
        </Grid>
      </div>
      <div className={classes.numbers}>
        <span style={{ color: '#1D4659' }}>
          <span style={{ fontWeight: 600 }}>
            {from} {txtTo} {to}&nbsp;
          </span>
          {txtOf} {data.length}
        </span>
      </div>
      <div className={classes.rowsPerPage} style={{ flexGrow: 1 }}>
        <DropDownList
          options={rowsPerPageOptions}
          value={rowsPerPageSelected}
          onChange={(value) => changeRowsPerPageHandler(value)}
          size='small'
          fullWidth={false}
        />
      </div>
    </div>
  );
};

function BigDataTable(props) {
  const isSelected = (name) => selected.indexOf(name) !== -1;
  const rowsPerPageOptions = [
    { id: 10, label: '10 ' + props.t('table.pagination-perpage') },
    { id: 25, label: '25 ' + props.t('table.pagination-perpage') },
    { id: 50, label: '50 ' + props.t('table.pagination-perpage') }
  ];

  const [order, setOrder] = React.useState(props.defaultOrder);
  const [orderBy, setOrderBy] = React.useState(props.defaultOrderBy);
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    rowsPerPageOptions[0].id
  );
  const [rowsPerPageSelected, setRowsPerPageSelected] = React.useState(
    rowsPerPageOptions[0]
  );

  const { columns, data, headLabels, pagination } = props;
  const classes = useStyles(props);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = data.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value) => {
    setRowsPerPageSelected(value);
    setRowsPerPage(parseInt(value.id));
    setPage(0);
  };

  return (
    <div className={classes.root}>
      <Box className={classes.box}>
        <Table
          className={classes.table}
          aria-labelledby='tableTitle'
          size='small'
          aria-label='enhanced table'
          style={{ position: 'relative' }}
        >
          <TableHead>
            {headLabels && (
              <TableHeadLabels headLabels={headLabels} classes={classes} />
            )}
            <EnhancedTableHead
              columns={columns}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={data.length}
            />
          </TableHead>
          <TableBody classes={{ root: classes.body }}>
            {stableSort(data, getComparator(order, orderBy))
              .slice(
                pagination ? page * rowsPerPage : 0,
                pagination ? page * rowsPerPage + rowsPerPage : data.length
              )
              .map((row, rowIndex) => {
                const isItemSelected = isSelected(row.name);

                return (
                  <TableRow
                    hover={false}
                    role='checkbox'
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={rowIndex}
                    selected={isItemSelected}
                  >
                    {columns.map((head, colIndex) => (
                      <TableCell
                        classes={{ root: classes.cell }}
                        padding='none'
                        align={head.numeric ? 'right' : 'left'}
                        key={colIndex}
                        style={{ whiteSpace: head.wrap ? 'wrap' : 'nowrap' }}
                        onClick={() => {
                          props.onSelect(
                            colIndex === columns.length - 1,
                            row.id
                          );
                        }}
                      >
                        {head.component ? (
                          <head.component value={row[head.id]} />
                        ) : head.currency && !isNaN(row[head.id]) ? (
                          head.currency + row[head.id].toFixed(2).toString()
                        ) : (
                          row[head.id]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </Box>
      {pagination && data && data.length > 10 && (
        <Pagination
          data={data}
          rowsPerPage={rowsPerPage}
          rowsPerPageSelected={rowsPerPageSelected}
          rowsPerPageOptions={rowsPerPageOptions}
          page={page}
          txtTo={props.t('table.pagination-to')}
          txtOf={props.t('table.pagination-of')}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangePage={(value) => handleChangePage(value)}
        />
      )}
    </div>
  );
}

export default withTranslation('common')(BigDataTable);
