import React, { Component } from 'react';
import styles from './../pages/Notifications.module.css';

class NotificationsOrAnd extends Component {
  constructor(props) {
    super(props);
    this.state = {
      kpi: null,
      operand: null,
      value: null,
      timeline: null
    };
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  handleSelect(event, operator) {
    event.stopPropagation();
    this.props.onSelect(operator);
  }

  render() {
    const { operator } = this.props;

    return (
      <div className={styles.AndOr}>
        <div
          className={`${styles.BtAndOr} ${
            operator === 'AND' ? styles.Selected : styles.Unselected
          }`}
          onClick={(event) => this.handleSelect(event, 'AND')}
        >
          AND
        </div>
        <div
          className={`${styles.BtAndOr} ${
            operator === 'OR' ? styles.Selected : styles.Unselected
          }`}
          onClick={(event) => this.handleSelect(event, 'OR')}
        >
          OR
        </div>
      </div>
    );
  }
}

export default NotificationsOrAnd;
