import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as GamificationApi from './../api/endpoints';
import styles from './Level.module.css';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Progress from './Progress';
import GamificationContext from './../contexts/GamificationProvider';

class Level extends Component {
  static contextType = GamificationContext;

  constructor(props) {
    super(props);
    this.state = {
      allLevels: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.loadAllLevels();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }
  async loadAllLevels() {
    const result = await GamificationApi.getLevels();
    this.securSetState({
      allLevels: result
    });
  }

  renderCurrentLevel() {
    const { myKeyInfo } = this.context;

    if (!myKeyInfo) {
      return <div className={styles.Current} />;
    }

    const currentLevel = myKeyInfo.current_level;
    const currentLevelName =
      currentLevel.name_trans[i18next.language] ||
      currentLevel.name_trans[i18next.options.fallbackLng[0]];
    const currentLevelIcon = currentLevel.image;

    return (
      <div className={styles.Current}>
        <div className={styles.Title} style={{ color: currentLevel.color }}>
          {currentLevelName}
        </div>
        <img
          className={styles.Icon}
          alt={currentLevelName}
          src={currentLevelIcon}
        />
        <div className={styles.Points} style={{ color: currentLevel.color }}>
          {myKeyInfo.points} {this.props.t('level.pts')}
        </div>
      </div>
    );
  }

  renderCurrentMap() {
    const { myKeyInfo, me } = this.context;
    const { allLevels } = this.state;

    if (!myKeyInfo || !allLevels) {
      return <div className={styles.Map} />;
    }

    const currentLevel = myKeyInfo.current_level;
    const nextLevel = myKeyInfo.next_level;
    const mePoints = myKeyInfo.points;

    const currentLevelName =
      currentLevel.name_trans[i18next.language] ||
      currentLevel.name_trans[i18next.options.fallbackLng[0]];
    const nextLevelName =
      nextLevel.name_trans[i18next.language] ||
      nextLevel.name_trans[i18next.options.fallbackLng[0]];
    const diffPoints = nextLevel.points - mePoints;

    return (
      <div className={styles.Map}>
        <div className={styles.Message}>
          {this.props.t('level.congrats')}{' '}
          <span style={{ color: currentLevel.color }}>{currentLevelName}</span>!
          <br />
          {this.props.t('level.need')}
          <span> {diffPoints} </span>
          {this.props.t('level.more')}
          <span style={{ color: nextLevel.color }}> {nextLevelName}</span>
        </div>
        <Progress me={me} myKeyInfo={myKeyInfo} />
      </div>
    );
  }

  render() {
    return (
      <Box className={styles.Wrapper}>
        <Grid
          container
          direction='row'
          alignContent='stretch'
          style={{ height: '100%' }}
        >
          <Grid item xs={4}>
            {this.renderCurrentLevel()}
          </Grid>
          <Grid item xs={8}>
            {this.renderCurrentMap()}
          </Grid>
        </Grid>
      </Box>
    );
  }
}

export default withTranslation('gamification')(Level);
