import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BaseRating from '@material-ui/lab/Rating';

export default function Rating(props) {
  const value = parseFloat(props.value);
  const { size = 'small', precision = 1, readOnly = true } = props;

  const StyledRating = withStyles((theme) => ({
    iconFilled: {
      color: theme.palette.primary.main
    },
    iconHover: {
      color: theme.palette.primary.main
    }
  }))(BaseRating);

  return (
    <StyledRating
      value={value}
      size={size}
      precision={precision}
      readOnly={readOnly}
    />
  );
}
