//Mockup static data
import logo_engie from './logos/engie_logo_blue.png';

export const clients = [
  // {
  //   id: 'energy',
  //   code: 'energy',
  //   type: 'Industry',
  //   show: true,
  //   name: 'Energy',
  //   logo: logo_deep,
  //   sessionConfig: {
  //     displayDataCollection: false
  //   },
  //   currency: '$',
  //   colors: {
  //     primary: {
  //       main: 'rgba(19, 174, 87, 1)',
  //       light: 'rgba(19, 174, 87, 0.3)',
  //       dark: 'rgba(19, 174, 87, 0.7)',
  //       contrastText: '#FFF'
  //     },
  //     secondary: {
  //       main: 'rgba(19, 174, 87, 1)',
  //       light: 'rgba(19, 174, 87, 0.3)',
  //       dark: 'rgba(19, 174, 87, 0.7)',
  //       contrastText: '#FFF'
  //     },
  //   },
  //   languages: [
  //     {
  //       key: 'en',
  //       name: 'English'
  //     },
  //     {
  //       key: 'fr',
  //       name: 'Français'
  //     }
  //   ]
  // },
  {
    id: 'engie',
    code: 'engie',
    type: 'Client',
    show: true,
    name: 'Engie',
    logo: logo_engie,
    sessionConfig: {
      displayPortfolio: false,
      displayHistory: false,
      displayAdditionalProducts: false,
      displayKnowledgeNavigator: false,
      displayProductComparison: false,
      displayDataCollection: false,
      displayExpectedValuePct: false,
      displayFAQ: true,
      onlyOktaLogin: true
    },
    currency: '$',
    colors: {
      main: 'rgba(0, 140, 255, 1)',
      light: 'rgba(0, 140, 255, 0.3)',
      dark: 'rgba(0, 140, 255, 0.7)'
    },
    languages: [
      {
        key: 'en',
        name: 'English'
      },
      {
        key: 'fr',
        name: 'Français'
      },
      {
        key: 'nl',
        name: 'Dutch'
      }
    ]
  }
];
