import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './NotificationBadge.module.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const muiStyles = (theme) => ({
  paper: {
    padding: '1.8rem 2rem 3rem',
    borderRadius: '0.6rem',
    textAlign: 'center',
    minWidth: '36rem'
  },
  button: {
    textTransform: 'none',
    fontSize: '1.5rem',
    fontWeight: 600,
    paddingLeft: '4.5rem',
    paddingRight: '4.5rem',
    borderRadius: '2.2rem'
  }
});

class NotificationBadge extends Component {
  handleClose() {
    this.props.onClose();
  }

  renderBadgeMessage(badgeName, badgePoints) {
    const parse = require('html-react-parser');
    let txt = this.props.t('notification.badge-txt');
    txt = txt.replace('{badgeName}', badgeName);
    txt = txt.replace('{badgePoints}', badgePoints);
    const html = parse('<div>' + txt + '</div>');
    return html;
  }

  render() {
    const { classes, myKeyInfo, badge, open } = this.props;

    let title = this.props.t('notification.badge-title');
    let gameBadgeName =
      badge.name_trans[i18next.language] ||
      badge.name_trans[i18next.options.fallbackLng[0]];
    let gameBadgePoints = badge.points;
    let gameBadgeIcon = badge.image;

    return (
      <Dialog
        onClose={(event) => this.handleClose(event)}
        aria-labelledby='confirmation'
        open={open}
        classes={{ paper: classes.paper }}
      >
        <div>
          <div className={styles.Close}>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={(event) => this.handleClose(event)}
            />
          </div>
          <div className={styles.Title}>{title}</div>
          {this.renderBadgeMessage(gameBadgeName, gameBadgePoints)}
          <img alt='Badge' src={gameBadgeIcon} className={styles.Icon} />
          <div className={styles.SummaryContainer}>
            <Grid container justify='space-between' className={styles.Counter}>
              <Grid item>{this.props.t('notification.badge-points')}</Grid>
              <Grid item>
                <strong>{myKeyInfo.points}</strong>
              </Grid>
            </Grid>
            <Grid container justify='space-between' className={styles.Counter}>
              <Grid item>{this.props.t('notification.badge-badges')}</Grid>
              <Grid item>
                <strong>{myKeyInfo.badges}</strong>
              </Grid>
            </Grid>
          </div>
          <div className={styles.BtContainer}>
            <Button
              fullWidth
              variant='outlined'
              color='primary'
              disableElevation
              size='small'
              classes={{ root: classes.button }}
              onClick={(event) => this.handleClose(event)}
            >
              {this.props.t('notification.badge-close')}
            </Button>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation('gamification')(
  withStyles(muiStyles)(NotificationBadge)
);
