import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import SimpleTabsMenu from 'deep/components/materials/SimpleTabsMenu';
import EnumerationTable from 'deep/components/materials/EnumerationTable';
import SmallDataTable from 'deep/components/materials/SmallDataTable';
import CallSessionContext from './../../contexts/CallSessionProvider';

const styles = (theme) => ({
  item: {
    '&:nth-child(n+2)': {
      paddingTop: '2rem !important'
    }
  },
  label: {
    color: '#4C4C4C',
    fontWeight: 600,
    textAlign: 'left',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  value: {
    position: 'relative',
    color: '#777777',
    textAlign: 'left',
    marginBottom: '1.5rem',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  separator: {
    position: 'absolute',
    bottom: '0rem',
    height: '1px',
    width: '100%',
    backgroundColor: '#E6E9EE'
  }
});

class CustomerHistory extends Component {
  static contextType = CallSessionContext;

  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0
    };
    this.onChangeTab = this.onChangeTab.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }
  UNSAFE_componentWillMount() {
    this.rendered = false;
  }

  shouldComponentUpdate() {
    if (
      !this.rendered &&
      this.context.interactionHistory &&
      this.context.relationship &&
      this.context.bill
    ) {
      this.rendered = true;
      this.props.onRender(true);
      return true;
    }
    return false;
  }

  formatInteraction(data) {
    if (!data) {
      return null;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      dataFormatted.push({
        name:
          item.attribute.name_trans[i18next.language] ||
          item.attribute.name_trans[i18next.options.fallbackLng[0]],
        value: parseFloat(item.value)
      });
    });

    return dataFormatted;
  }

  formatRelationship(data) {
    if (!data) {
      return false;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      const date = new Date(item.date);
      dataFormatted.push({
        trans: item.trans,
        date:
          ('0' + date.getMonth()).slice(-2) +
          '/' +
          ('0' + date.getDate()).slice(-2) +
          '/' +
          date.getFullYear(),
        channel:
          item.channel.name_trans[i18next.language] ||
          item.channel.name_trans[i18next.options.fallbackLng[0]],
        outcome:
          item.outcome.name_trans[i18next.language] ||
          item.outcome.name_trans[i18next.options.fallbackLng[0]],
        reason:
          item.kind.name_trans[i18next.language] ||
          item.kind.name_trans[i18next.options.fallbackLng[0]],
        colspan: 1
      });
    });

    return dataFormatted;
  }

  formatBill(data) {
    if (!data) {
      return false;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      const date = new Date(item.date);
      const paid =
        item.paid_services + item.paid_products + item.paid_additional;
      dataFormatted.push({
        label:
          this.props.t('fullMonth.' + date.getMonth().toString()) +
          ' ' +
          date.getFullYear().toString(),
        owed:
          this.context.clientSettings.currency +
          item.pending.toFixed(2).toString(),
        paid: this.context.clientSettings.currency + paid.toFixed(2).toString()
      });
    });

    return dataFormatted;
  }

  onChangeTab = (event, value) => {
    event.preventDefault();
    this.securSetState({ selectedTab: value });
    this.props.onRender(false);
    this.rendered = false;
  };

  renderRelationship(data) {
    const { classes } = this.props;

    return data.map((item, index) => (
      <GridList
        key={index}
        cellHeight='auto'
        cols={3}
        spacing={0}
        className={classes.item}
      >
        <GridListTile cols={1} style={{ paddingRight: '1rem' }}>
          <div className={classes.label}>
            {this.props.t('session.history.lbl-relationship-date')}
          </div>
          <div className={classes.value}>{item.date}</div>
          <div className={classes.separator}></div>
        </GridListTile>
        <GridListTile cols={1} style={{ paddingRight: '1rem' }}>
          <div className={classes.label}>
            {this.props.t('session.history.lbl-relationship-channel')}
          </div>
          <div className={classes.value}>{item.channel}</div>
          <div className={classes.separator}></div>
        </GridListTile>
        <GridListTile cols={1}>
          <div className={classes.label}>
            {this.props.t('session.history.lbl-relationship-outcome')}
          </div>
          <div className={classes.value}>{item.outcome}</div>
          <div className={classes.separator}></div>
        </GridListTile>
        {item.reason && (
          <GridListTile cols={3} style={{ paddingTop: '1rem' }}>
            <div className={classes.label}>
              {this.props.t('session.history.lbl-relationship-reason')}
            </div>
            <div className={classes.value}>{item.reason}</div>
            <div className={classes.separator}></div>
          </GridListTile>
        )}
      </GridList>
    ));
  }

  render() {
    const interactionHistory = this.formatInteraction(
      this.context.interactionHistory
    );
    const relationship = this.formatRelationship(this.context.relationship);
    const bill = this.formatBill(this.context.bill);

    const tabs = [
      {
        id: 1,
        name: this.props.t('session.history.tab-interaction'),
        value: 'interaction'
      },
      {
        id: 2,
        name: this.props.t('session.history.tab-relationship'),
        value: 'relationship'
      },
      {
        id: 3,
        name: this.props.t('session.history.tab-billing'),
        value: 'billing'
      }
    ];

    const colsBill = [
      { id: 'label', numeric: false, label: '' },
      {
        id: 'owed',
        numeric: true,
        label: this.props.t('session.history.lbl-billing-owed')
      },
      {
        id: 'paid',
        numeric: true,
        label: this.props.t('session.history.lbl-billing-paid')
      }
    ];

    return (
      <div>
        <SimpleTabsMenu
          tabs={tabs}
          defaultValue={this.state.selectedTab}
          onChange={(event, value) => this.onChangeTab(event, value)}
        />
        <div>
          {this.state.selectedTab === 0 && interactionHistory && (
            <EnumerationTable data={interactionHistory} />
          )}

          {this.state.selectedTab === 1 &&
            relationship &&
            this.renderRelationship(relationship)}
          {this.state.selectedTab === 2 && bill && (
            <SmallDataTable data={bill} columns={colsBill} highlight={false} />
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation('cco')(withStyles(styles)(CustomerHistory));
