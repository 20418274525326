import React, { Component } from 'react';
import { NotificationsProvider } from './contexts/NotificationsProvider';
import PushNotifications from './components/PushNotifications';
import NotificationCenter from './components/NotificationCenter';
import NotificationsAccess from './components/NotificationsAccess';

class Notifications extends Component {
  render() {
    return (
      <NotificationsProvider>
        <NotificationsAccess />
        <PushNotifications />
        <NotificationCenter />
      </NotificationsProvider>
    );
  }
}

export default Notifications;
