import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './NotificationPoints.module.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import i18next from 'i18next';
import { NOTIF_TYPES } from './../utils/Constants';
import Progress from './Progress';

const muiStyles = (theme) => ({
  paper: {
    padding: '1.8rem 2rem 3rem',
    borderRadius: '0.6rem',
    textAlign: 'center',
    minWidth: '36rem'
  },
  button: {
    textTransform: 'none',
    fontSize: '1.5rem',
    fontWeight: 600,
    paddingLeft: '4.5rem',
    paddingRight: '4.5rem',
    borderRadius: '2.2rem'
  }
});

class NotificationPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleConfirm = this.handleConfirm.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }

  handleClose() {
    this.props.onClose();
  }
  handleConfirm() {
    this.props.onConfirm();
  }

  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }

  render() {
    const { classes, data, me, myKeyInfo, open, timer } = this.props;

    let diffPoints = 0;
    let title = '';
    let message = '';
    if (myKeyInfo) {
      const nextLevel = myKeyInfo.next_level;
      const mePoints = myKeyInfo.points;
      diffPoints = nextLevel.points - mePoints;

      switch (data.what) {
        case 'feedback_not_offered':
          title = this.props.t('notification.points-title-not-offered');
          break;
        case 'feedback_fast_accepted':
        case 'feedback_accepted':
          title = this.props.t('notification.points-title-accepted');
          break;
        case 'feedback_refused':
        default:
          title = this.props.t('notification.points-title-refused');
          break;
      }

      const parse = require('html-react-parser');
      let txt = '';
      if (data.type === NOTIF_TYPES.FAST_SUCCESS_CALL) {
        txt = this.props.t('notification.points-txt-fast');
        txt = txt.replace('{earnedPoints}', data.earnedPoints);
      } else if (data.earnedPoints) {
        txt = this.props.t('notification.points-txt-earned');
        txt = txt.replace('{earnedPoints}', data.earnedPoints);
      } else {
        txt = this.props.t('notification.points-txt-need');
        txt = txt.replace('{diffPoints}', diffPoints);
      }
      message = parse(txt);
    }

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby='confirmation'
        open={open}
        classes={{ paper: classes.paper }}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <div>
          <div className={styles.Timer}>:{timer}</div>
          <div className={styles.Close}>
            <FontAwesomeIcon icon={faTimes} onClick={this.handleClose} />
          </div>
          <div className={styles.Title}>{title}</div>
          <div>{message}</div>
          <div className={styles.ProgressContainer}>
            <Progress me={me} myKeyInfo={myKeyInfo} />
          </div>
          <div className={styles.SummaryContainer}>
            <Grid container justify='space-between' className={styles.Counter}>
              <Grid item>{this.props.t('notification.points-txt-next')}</Grid>
              <Grid item>
                <strong>{diffPoints}</strong>
              </Grid>
            </Grid>
          </div>
          <div className={styles.BtContainer}>
            <div style={{ marginBottom: '1rem' }}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                disableElevation
                size='small'
                classes={{ root: classes.button }}
                onClick={this.handleConfirm}
              >
                {this.props.t('notification.points-bt-new-call')}
              </Button>
            </div>
            <div>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                disableElevation
                size='small'
                classes={{ root: classes.button }}
                onClick={this.handleClose}
              >
                {this.props.t('notification.points-bt-back')}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation('gamification')(
  withStyles(muiStyles)(NotificationPoints)
);
