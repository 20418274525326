import React, { Component } from 'react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import styles from './BadgeWon.module.css';
import { withStyles } from '@material-ui/core/styles';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import Avatar from '@material-ui/core/Avatar';
import GamificationContext from './../contexts/GamificationProvider';

const muiStyles = (theme) => ({
  avatarGroupRoot: {
    position: 'absolute',
    bottom: '2.8rem',
    left: '3.2rem'
  },
  avatarRoot: {
    height: '3.2rem',
    width: '3.2rem',
    border: 0
  },
  avatarMore: {
    position: 'absolute',
    bottom: '2.8rem',
    right: '2.7rem',
    height: '3.2rem',
    width: '3.2rem',
    border: 0,
    color: '#989aa0',
    backgroundColor: '#F1F1F1',
    fontSize: '1.2rem',
    fontWeight: 700
  }
});

class BadgeWon extends Component {
  static contextType = GamificationContext;

  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }

  render() {
    const { classes, badge, winners, winnersCount } = this.props;
    const { me } = this.context;

    const badgeName = this.getTrans(badge.name_trans);
    const badgeDescription = this.getTrans(badge.description_trans);
    const badgeImage = badge.image;

    if (winners) {
      for (let i = 0; i < winners.length; i++) {
        if (winners[i].id === me.user.id) {
          winners[i].avatar.file = me.user.avatar.file;
        }
      }
    }

    return (
      <div className={styles.Wrapper}>
        <div className={styles.Image}>
          <img alt={badgeName} src={badgeImage} />
        </div>
        <div className={styles.Name}>{badgeName}</div>
        <div className={styles.Description}>{badgeDescription}</div>
        {winners && winners.length > 0 && (
          <>
            <AvatarGroup
              max={5}
              spacing={5}
              classes={{ root: classes.avatarGroupRoot }}
            >
              {winners.map((winner, index) => {
                return (
                  <Avatar
                    key={index}
                    alt={winner.first_name + ' ' + winner.last_name}
                    src={winner.avatar.file}
                    classes={{ root: classes.avatarRoot }}
                  />
                );
              })}
            </AvatarGroup>
            <Avatar
              alt={winnersCount && winnersCount.toString()}
              classes={{ root: classes.avatarMore }}
            >
              {winnersCount}
            </Avatar>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation('gamification')(withStyles(muiStyles)(BadgeWon));
