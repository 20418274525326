import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import CallSessionContext from './../../contexts/CallSessionProvider';

import Divider from '@material-ui/core/Divider';


class Tips extends Component {
  static contextType = CallSessionContext;

  UNSAFE_componentWillMount() {
    this.rendered = false;
  }

  componentDidUpdate() {
    if (
      !this.rendered &&
      this.context.selectedOpportunity &&
      this.context.tips
    ) {
      this.rendered = true;
      this.props.onRender(true);
    }
  }

  formatOffer(opportunity) {
    if (!opportunity || !opportunity.offer) {
      return null;
    }

    let dataFormatted = {
      type:
        opportunity.offer.type_trans[i18next.language] ||
        opportunity.offer.type_trans[i18next.options.fallbackLng[0]],
      argument_sentence:
        opportunity.offer.argument_sentence_trans[i18next.language] ||
        opportunity.offer.argument_sentence_trans[
          i18next.options.fallbackLng[0]
        ]
    };

    return dataFormatted;
  }

  formaTips(data) {
    if (!data) {
      return null;
    }

    let dataFormatted = [];

    data.forEach((item) => {
      dataFormatted.push({
        sentence:
          item.sentence_trans[i18next.language] ||
          item.sentence_trans[i18next.options.fallbackLng[0]]
      });
    });

    return dataFormatted;
  }

  getTips() {

    const { contractAccountId, portfolio } = this.context;
    if (!portfolio){
      return null
    }
    for (let i=0; i < portfolio.length; i++){
      if (portfolio[i].contract_account_id.toString() === contractAccountId){
        return portfolio[i].tips
      }

    }
    return null;
  }

  render() {

    const tips = this.getTips();
    const styles = {
      label: {
        color: '#4C4C4C',
        fontWeight: 600
      },
      value: {
        color: '#777777'
      },
      divider: {
        margin: '0.8rem 0rem 1rem'
      },
      ul: {
        margin: 0,
        padding: '0.3rem 0rem 0.3rem 1.4rem'
      },
      li: {
        color: '#777777',
        margin: 0,
        padding: '0.2rem 0rem',
        lineHeight: 1.4
      }
    };

    return (
      <div>
        {tips && Object.keys(tips).map((item, index) => {
        const tip = tips[item]
          if (!tip){
            return null
          }
          return (
            <div key={index}>
              <div style={styles.label}>
                  {item.replaceAll('_', ' ')}
              </div>
              <div style={styles.value}>
                <ul style={styles.ul}>
                  <li style={styles.li}>{tip}</li>
                </ul>
              </div>
            </div>
          )
        })}

      </div>
    );
  }
}

export default withTranslation('cco')(Tips);
