import { STORAGE_VARS } from 'utils/Constants';

export function overallActiveAgents(data, begin, end, interval) {
  let rwData = {
    users: data.users,
    trends: (data.users - data.previous) / data.previous,
    previous: data.previous,
    results: null
  };
  let rwResults = [];
  let date;
  for (let i = 0; i < data.results.length; i++) {
    const row = data.results[i];
    date = begin.getTime() + i * interval * 1000;
    if (date <= end.getTime()) {
      rwResults.push({
        count: row.count,
        begin: new Date(date)
      });
    }
  }
  rwData.results = rwResults;

  return rwData;
}
export function overallActions(
  data,
  begin,
  end,
  interval,
  timelineFilterId,
  ouctomeFilterCode
) {
  let rwResults = [];
  let date;
  let total = 0;
  for (let i = 0; i < data.results.length; i++) {
    const row = data.results[i];
    date = begin.getTime() + i * interval * 1000;
    if (date <= end.getTime()) {
      rwResults.push({
        count: row.count,
        begin: new Date(date)
      });
      total += row.count;
    }
  }

  let list = [];
  if (ouctomeFilterCode === 'accepted') {
    list = localStorage.getItem(STORAGE_VARS.DEMO_CALL_ACCEPTED)
      ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_ACCEPTED))
      : [];
  } else if (ouctomeFilterCode === 'not_proposed') {
    list = localStorage.getItem(STORAGE_VARS.DEMO_CALL_NOTOFFERED)
      ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_NOTOFFERED))
      : [];
  } else if (ouctomeFilterCode === 'rejected') {
    list = localStorage.getItem(STORAGE_VARS.DEMO_CALL_REJECTED)
      ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_REJECTED))
      : [];
  }
  let count = list.length;
  rwResults[rwResults.length - 1].count += count;
  total += count;

  let rwData = {
    count: total,
    trends: (total - data.previous) / data.previous,
    previous: data.previous,
    results: rwResults
  };

  return rwData;
}
export function overallAverage(
  data,
  jsonActiveAgents,
  jsonActions,
  days,
  outcomeFilterCode
) {
  const usersCount = jsonActiveAgents.users;
  const actionsCount = jsonActions.count;
  const count = actionsCount / usersCount / days;
  const prc = (count * 2) / (actionsCount / days);

  let average_seconds = 350;
  switch (outcomeFilterCode) {
    case 'accepted':
      average_seconds = 421;
      break;
    case 'not_proposed':
      average_seconds = 354;
      break;
    case 'rejected':
      average_seconds = 224;
      break;
    default:
  }

  let rwData = {
    duration: {
      average: data.duration.average,
      average_seconds: average_seconds
    },
    per_day: prc,
    count: Math.round(count),
    slice_duration: data.slice_duration
  };

  return rwData;
}
export function overallSuccessRate(
  data,
  resultAccepted,
  resultNotProposed,
  resultRejected
) {
  const successfulCount = resultAccepted.count;
  const unsuccessfulCount = resultNotProposed.count + resultRejected.count;
  const successfulRatio =
    successfulCount / (successfulCount + unsuccessfulCount);
  const unsuccessfulRatio =
    unsuccessfulCount / (successfulCount + unsuccessfulCount);

  let rwData = [
    {
      label: 'Successful',
      count: successfulCount,
      ratio: successfulRatio,
      coef: successfulRatio
    },
    {
      label: 'Unsuccessful',
      count: unsuccessfulCount,
      ratio: unsuccessfulRatio,
      coef: unsuccessfulRatio
    }
  ];

  return rwData;
}

export function timeActiveAgents(data) {
  const listAccepted = localStorage.getItem(STORAGE_VARS.DEMO_CALL_ACCEPTED)
    ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_ACCEPTED))
    : [];
  const listNotOffered = localStorage.getItem(STORAGE_VARS.DEMO_CALL_NOTOFFERED)
    ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_NOTOFFERED))
    : [];
  const listRejected = localStorage.getItem(STORAGE_VARS.DEMO_CALL_REJECTED)
    ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_REJECTED))
    : [];
  const countAccepted = listAccepted.length;
  const countNotOffered = listNotOffered.length;
  const countRejected = listRejected.length;

  let rwResults = [];
  for (let i = 0; i < data.data.length; i++) {
    const row = data.data[i];
    rwResults.push({
      label_trans: row.label_trans,
      count: row.count + countAccepted + countNotOffered + countRejected
    });
  }

  let rwData = {
    label_trans: data.label_trans,
    data: rwResults
  };

  return rwData;
}

export function timeEvolutions(data) {
  const listAccepted = localStorage.getItem(STORAGE_VARS.DEMO_CALL_ACCEPTED)
    ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_ACCEPTED))
    : [];
  const listNotOffered = localStorage.getItem(STORAGE_VARS.DEMO_CALL_NOTOFFERED)
    ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_NOTOFFERED))
    : [];
  const listRejected = localStorage.getItem(STORAGE_VARS.DEMO_CALL_REJECTED)
    ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_REJECTED))
    : [];
  const countAccepted = listAccepted.length;
  const countNotOffered = listNotOffered.length;
  const countRejected = listRejected.length;

  let rwCharts = [];
  for (let i = 0; i < data.length; i++) {
    const row = data[i];
    const dataRow = data[i].data;

    let rwData = [];
    for (let j = 0; j < dataRow.length; j++) {
      const data = dataRow[j];
      let count = data.count;
      if (i === 2) {
        count = count + countAccepted + countNotOffered + countRejected;
      }

      rwData.push({
        label_trans: data.label_trans,
        count: count,
        ratio: data.ratio
      });
    }

    rwCharts.push({
      label_trans: row.label_trans,
      data: rwData
    });
  }

  return rwCharts;
}

export function interactionOutcomes(data, reasons) {
  //0 : Not Offered
  //1 : Accepted
  //2 : Rejected

  let notOfferedReasons = [];
  let refusedReasons = [];
  let notOfferedTotalCount = 0;
  let refusedTotalCount = 0;
  for (let i = 0; i < reasons.length; i++) {
    let randCount = Math.floor(2 + Math.random() * (500 - 2));

    if (reasons[i].outcome === 0) {
      notOfferedTotalCount += randCount;
      notOfferedReasons.push({
        count: randCount,
        ratio: null,
        reason: reasons[i]
      });
    } else if (reasons[i].outcome === 2) {
      refusedTotalCount += randCount;
      refusedReasons.push({
        count: randCount,
        ratio: null,
        reason: reasons[i]
      });
    }
  }

  for (let i = 0; i < notOfferedReasons.length; i++) {
    notOfferedReasons[i].ratio =
      notOfferedReasons[i].count / notOfferedTotalCount;
  }
  for (let i = 0; i < refusedReasons.length; i++) {
    refusedReasons[i].ratio = refusedReasons[i].count / refusedTotalCount;
  }

  let rwData = [];
  for (let i = 0; i < data.length; i++) {
    let reasons = [];
    if (data[i].outcome.id === 0) {
      reasons = notOfferedReasons;
    } else if (data[i].outcome.id === 2) {
      reasons = refusedReasons;
    }

    rwData.push({
      count: data[i].count,
      outcome: data[i].outcome,
      ratio: data[i].ratio,
      reasons: reasons
    });
  }
  return rwData;
}

export function userAnalysis(data, timelineFilterId) {
  let multiplier = 1;
  switch (timelineFilterId) {
    case 'week':
      multiplier = 1;
      break;
    case 'month':
      multiplier = 4;
      break;
    case 'weektodate':
      multiplier = 1;
      break;
    case 'monthtodate':
      multiplier = 3;
      break;
    case 'yeartodate':
      multiplier = 4 * 11;
      break;
    default:
  }

  let totalLogins = 0;
  let totalSuccess = 0;
  let totalActions = 0;

  let rwData = [];
  for (let i = 0; i < data.length; i++) {
    let randLoginsMin = 2;
    let randLoginsMax = 10;
    let randLogins = Math.floor(
      randLoginsMin + Math.random() * (randLoginsMax - randLoginsMin)
    );
    randLogins = randLogins * multiplier;
    totalLogins += randLogins;

    let randSuccessMin = 3;
    let randSuccessMax = 30;
    let randSuccess = Math.floor(
      randSuccessMin + Math.random() * (randSuccessMax - randSuccessMin)
    );
    randSuccess = randSuccess * multiplier;
    totalSuccess += randSuccess;

    let randActionsMin = 3 * 3;
    let randActionsMax = 30 * 3;
    let randActions = Math.floor(
      randActionsMin + Math.random() * (randActionsMax - randActionsMin)
    );
    randActions = randActions * multiplier + randSuccess;
    totalActions += randActions;

    let randComparison = Math.floor(500 + Math.random() * (20000 - 500));
    let randTime = Math.floor(100 + Math.random() * (1000 - 100));
    const minutes = parseInt(randTime / 60);
    const secondes = randTime - minutes * 60;
    const average = minutes + 'm ' + secondes + 's';

    rwData.push({
      id: data[i].id,
      channel: data[i].channel,
      user: data[i].user,
      feedbacks: randActions,
      feedbacks_successful: randSuccess,
      logins: randLogins,
      ratio: ((randSuccess * 100) / randActions).toFixed(2),
      comparison: (randComparison / 100).toFixed(2) + '%',
      average: average
    });
  }

  let randComparison = Math.floor(500 + Math.random() * (20000 - 500));
  let randTime = Math.floor(100 + Math.random() * (1000 - 100));
  const minutes = parseInt(randTime / 60);
  const secondes = randTime - minutes * 60;
  const average = minutes + 'm ' + secondes + 's';

  return {
    results: rwData,
    total: {
      logins: totalLogins,
      feedbacks_successful: totalSuccess,
      ratio: ((totalSuccess * 100) / totalActions).toFixed(2),
      feedbacks: totalActions,
      comparison: (randComparison / 100).toFixed(2) + '%',
      average: average
    }
  };
}
