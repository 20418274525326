import React, { Component } from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppContext, { AppProvider } from 'deep/contexts/AppProvider';
import { GamificationProvider } from 'gamification/contexts/GamificationProvider';
import { CallSessionProvider } from 'modules/cco/contexts/CallSessionProvider';
import Routes from 'utils/Routes';
import Header from 'deep/components/Header';

import Notifications from 'notifications/Notifications';

class AppCMB extends Component {
  render() {
    return (
      <AppProvider>
        <AppContext.Consumer>
          {(appContext) => (
            <MuiThemeProvider theme={appContext.theme}>
              <CssBaseline />
              <GamificationProvider appContext={appContext}>
                <CallSessionProvider appContext={appContext}>
                  {appContext.notificationActive && <Notifications />}
                  <Header />
                  <Routes />
                </CallSessionProvider>
              </GamificationProvider>
            </MuiThemeProvider>
          )}
        </AppContext.Consumer>
      </AppProvider>
    );
  }
}

export default AppCMB;
