import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';

export default function SearchInput(props) {
  const useStyles = makeStyles({
    root: {
      borderRadius: '1.8rem',
      backgroundColor: '#ffffff',
      padding: '0.3rem 0.7rem',
      color: '#777777'
    }
  });

  const classes = useStyles(props);

  const inputRef = React.useRef();

  return (
    <Input
      autoFocus={false}
      placeholder={props.placeholder}
      disableUnderline={true}
      fullWidth
      classes={{
        root: props.valid ? classes.valid : classes.root
      }}
      inputProps={{
        maxLength: props.maxLength
      }}
      style={props.style}
      inputRef={inputRef}
      startAdornment=<InputAdornment position='start'>
        <SearchIcon style={{ color: '#737373', fontSize: '1.8rem' }} />
      </InputAdornment>
    />
  );
}
