import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import ProductsList from './ProductsList';
import OpportunitiesList from './OpportunitiesList';
import CallSessionContext from './../../contexts/CallSessionProvider';

class Opportunities extends Component {
  static contextType = CallSessionContext;

  render() {
    const { opportunities, products } = this.context;

    const styles = {
      gridItem: {
        minHeight: '4.95rem'
      },
      label: {
        color: '#4C4C4C',
        fontWeight: 600,
        paddingTop: '0.1rem',
        fontSize: '1.8rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }
    };

    return (
      <div>
        <Grid
          container
          spacing={2}
          alignItems='center'
          alignContent='flex-start'
        >
          <Grid item xs={4} lg={3} style={styles.gridItem}>
            <div style={styles.label}>
              {this.props.t('session.campaign.offerings-title')}
            </div>
          </Grid>
          {products && products.length > 0 && (
            <Grid item xs={8} lg={9} style={styles.gridItem}>
              <ProductsList />
            </Grid>
          )}
          {opportunities && opportunities.length > 0 && (
            <Grid item xs={4} lg={3} style={styles.gridItem}>
              <div style={styles.label}>&nbsp;</div>
            </Grid>
          )}
          <Grid item xs={8} lg={9} style={styles.gridItem}>
            <OpportunitiesList />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation('cco')(Opportunities);
