import React, { Component } from 'react';
import i18next from 'i18next';
import { createMuiTheme } from '@material-ui/core/styles';
import * as DeepApi from 'deep/api/endpoints';
import * as MwHistory from 'utils/MwHistory';
import * as Tenancy from 'utils/Tenancy';
import { STORAGE_VARS } from 'utils/Constants';
import { clients } from 'demo/clients';

const AppContext = React.createContext();

class AppProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      clientSettings: null,
      theme: null,
      me: null,
      gamificationActive: false,
      notificationActive: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.INIT();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  INIT() {
    this.securSetState(
    //   {
    //   gamificationActive: localStorage.getItem(
    //     STORAGE_VARS.GAMIFICATION_ACTIVATED
    //   ),
    //   notificationActive: localStorage.getItem(
    //     STORAGE_VARS.NOTIFICATION_ACTIVATED
    //   )
    // }
    {}
    );

    this.setClientAndTheme();

    const currentRoute = MwHistory.getCurrentRoute();

    if (localStorage.getItem(STORAGE_VARS.TOKEN) && currentRoute.protected) {
      this.loadMe();
    } else {
      this.clearMe();
    }
  }

  setClientAndTheme() {
    const tenant = Tenancy.getTenant();

    let clientSettings = null;
    for (let client of clients) {
      if (client.code === tenant) {
        clientSettings = {
          sessionConfig: {},
          proactive: true,
          anonymMonitoring: false,
          ...client
        };
        break;
      }
    }
    if (!clientSettings) {
      clientSettings = {
        sessionConfig: {},
        anonymMonitoring: false,
        ...clients[0]
      };
    }

    i18next.options.fallbackLng[0] = clientSettings.languages[0].key;
    this.securSetState({ clientSettings: clientSettings });
    this.setTheme(clientSettings.colors);
  }

  setTheme(clientColors) {
    const theme = createMuiTheme({
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 960,
          lg: 1280,
          xl: 1920
        }
      },
      overrides: {
        MuiCssBaseline: {
          '@global': {
            html: {
              //in order to use rem for all elements size
              fontSize: '10px'
            }
          }
        }
      },
      typography: {
        fontFamily: "'Open Sans', sans-serif",
        fontWeightRegular: 400,
        htmlFontSize: 0.625, //16
        fontSize: 0.9375 //15
      },
      palette: {
        text: {
          primary: '#3A404D', //dark grey
          secondary: '#545454' // medium grey
        },
        background: {
          default: '#f5f7fa' // ultra light grey
        }
      }
    });

    //dropdownlist
    theme.shadows[4] =
      '0px 2px 4px -1px rgba(0,0,0,0.15),0px 4px 5px 0px rgba(0,0,0,0.1),0px 1px 10px 0px rgba(0,0,0,0.1)';
    //feedback cards
    theme.shadows[6] =
      '0px 3px 5px -1px rgba(0,0,0,0.05),0px 6px 10px 0px rgba(0,0,0,0.035),0px 1px 18px 0px rgba(0,0,0,0.035)';
    //main menu
    theme.shadows[7] =
      '0px 4px 5px -2px rgba(0,0,0,0.1),0px 7px 10px 1px rgba(0,0,0,0.06),0px 2px 16px 1px rgba(0,0,0,0.06)';

    // Tenant customization
    theme.palette.primary = clientColors.primary
      ? clientColors.primary
      : clientColors;
    theme.palette.secondary = clientColors.secondary
      ? clientColors.secondary
      : theme.palette.primary;
    this.setState(() => ({ theme: theme }));
  }

  async loadMe() {
    const response = await DeepApi.getMe();
    this.setMe(response);
  }

  refreshMe = () => {
    this.loadMe();
  };

  clearMe = () => {
    localStorage.removeItem(STORAGE_VARS.TOKEN);
    this.securSetState({
      isLoading: false,
      me: null
    });
  };

  setMe = (persona) => {
    this.securSetState({
      isLoading: false,
      me: persona
    });
  };

  activateGamification = () => {
    // localStorage.setItem(STORAGE_VARS.GAMIFICATION_ACTIVATED, true);
    // this.securSetState({ gamificationActive: true });
  };

  deactivateGamification = () => {
    localStorage.removeItem(STORAGE_VARS.GAMIFICATION_ACTIVATED);
    this.securSetState({ gamificationActive: false });
  };
  activateNotification = () => {
    // localStorage.setItem(STORAGE_VARS.NOTIFICATION_ACTIVATED, true);
    // this.securSetState({ notificationActive: true });
  };

  deactivateNotification = () => {
    localStorage.removeItem(STORAGE_VARS.NOTIFICATION_ACTIVATED);
    this.securSetState({ notificationActive: false });
  };
  render() {
    const { children } = this.props;
    const {
      isLoading,
      clientSettings,
      theme,
      me,
      gamificationActive,
      notificationActive
    } = this.state;
    const {
      setMe,
      clearMe,
      refreshMe,
      activateGamification,
      deactivateGamification,
      activateNotification,
      deactivateNotification
    } = this;
    const currentRoute = MwHistory.getCurrentRoute();

    if (isLoading) {
      return null;
    } else if (
      currentRoute.protected &&
      !me &&
      currentRoute.path.indexOf('/login') < 0
    ) {
      MwHistory.clear();
      MwHistory.replace('/login');
      MwHistory.go();
      return null;
    } else {
      return (
        <AppContext.Provider
          value={{
            clientSettings,
            theme,
            me,
            setMe,
            clearMe,
            refreshMe,
            gamificationActive,
            activateGamification,
            deactivateGamification,
            notificationActive,
            activateNotification,
            deactivateNotification
          }}
        >
          {children}
        </AppContext.Provider>
      );
    }
  }
}

export default AppContext;

export { AppProvider };
