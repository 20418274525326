import React, { Component } from 'react';
import History from 'utils/History';
import * as MwHistory from 'utils/MwHistory';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import FilterOptionsList from './../../components/FilterOptionsList';
import FiltersContext, { FILTERS_STATUS } from './../contexts/FiltersProvider';

const muiStyles = (theme) => ({
  btRoot: {
    color: '#3A404D',
    textTransform: 'none',
    padding: '0.8rem 2.4rem 0.8rem 1.6rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
    borderRadius: '2.2rem',
    marginRight: '1.6rem',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.19)',
    border: '1px solid #13AE57',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#f2f2f2',
      boxShadow: 'inset 0rem 0.1rem 0.3rem 0rem rgba(0,0,0,0.19)'
    }
  },
  filters: {
    position: 'fixed',
    top: '1.4rem',
    right: '1rem',
    zIndex: 1202
  }
});

class Filters extends Component {
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      pathname: History.location.pathname
    };
  }

  UNSAFE_componentWillMount() {
    History.listen((location) => {
      this.securSetState({ pathname: location.pathname });
    });
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  handleCreateRule(event) {
    event.stopPropagation();
    MwHistory.push('/monitoring/notifications/rule');
  }

  renderBtCreateRule() {
    const { classes } = this.props;

    return (
      <Button
        variant='outlined'
        type='button'
        disableElevation
        disableRipple
        size='small'
        classes={{
          root: classes.btRoot,
          label: classes.btText
        }}
        onClick={(event) => {
          this.handleCreateRule(event);
        }}
      >
        <FontAwesomeIcon
          icon={faPlusCircle}
          style={{
            color: '#13AE57',
            marginRight: '1rem'
          }}
        />
        Create Rule
      </Button>
    );
  }

  render() {
    const { classes } = this.props;
    const { pathname } = this.state;
    const { setStatusFilter } = this.context;

    let isFilters, isStatusFilter = false;
    let isBtCreateRule = false;

    if (pathname.indexOf('/notifications/rules') > -1) {
      isBtCreateRule = true;
      isStatusFilter = true;
    }

    let filtersStatus = [];
    for (let key in FILTERS_STATUS) {
      filtersStatus.push(FILTERS_STATUS[key]);
    }

    return (
      <div className={classes.filters}>
        <Grid container spacing={0} wrap='nowrap'>
          {isFilters && (
            <Grid item>
              <div style={{ fontWeight: 600, paddingTop: 9, paddingRight: 14 }}>
                Filter:
              </div>
            </Grid>
          )}
          <Grid item style={{ display: isStatusFilter ? 'block' : 'none' }}>
            <FilterOptionsList
              items={filtersStatus}
              onSelect={(value) => setStatusFilter(value)}
            />
          </Grid>
          <Grid item>
            {isBtCreateRule && this.renderBtCreateRule()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withStyles(muiStyles)(Filters);
