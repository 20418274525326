import React, { Component } from 'react';
import Card from './../components/Card';
import Histogram from './../charts/Histogram';
import Fade from '@material-ui/core/Fade';

let timer = null;
let delay = 300;
let duration = 500;

class TimeEvolutionCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    timer = setTimeout(
      () => this.securSetState({ fadeIn: true }),
      delay * this.props.index
    );
  }
  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(timer);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  render() {
    const { title, chartData } = this.props;
    const { fadeIn } = this.state;

    return (
      <Fade in={fadeIn} timeout={{ enter: duration }}>
        <div>
          <Card title={title}>
            <div style={{ margin: '5rem 1rem 0rem' }}>
              <Histogram
                data={chartData}
                colWidth={5}
                barWidth={0.6}
                barHeight={10}
              />
            </div>
          </Card>
        </div>
      </Fade>
    );
  }
}

export default TimeEvolutionCard;
