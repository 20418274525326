import { STORAGE_VARS } from 'utils/Constants';
import { clients } from 'demo/clients';

export function isLocal() {
  const hostname = window.location.hostname;
  return hostname === 'localhost';
}

function getMonoTenant() {
  let hostname = window.location.hostname;
  if (hostname.indexOf('.') < 0) {
    return false;
  } else {
    const a = hostname.split('.');
    if (a[0] === 'deep') {
      return false;
    } else {
      return true;
    }
  }
}

export function isMonoTenant() {
  return getMonoTenant();
}

function isExistsTenant(tenant) {
  let isExists = false;
  clients.forEach((client) => {
    if (client.code === tenant) {
      isExists = true;
    }
  });
  return isExists;
}

export function getTenant() {
  //priority 1 to mono tenant
  const isMonoTenant = getMonoTenant();
  if (isMonoTenant) {
    return clients[0].code;
  }

  //priority 2 to url
  const origin = window.location.origin;
  const route = window.location.href.replace(origin + '/', '');
  const a = route.split('/');
  if (a.length > 0 && a[0] !== '') {
    const tenant = a[0];
    const exists = isExistsTenant(tenant);
    if (exists) {
      localStorage.setItem(STORAGE_VARS.TENANT, tenant);
      return tenant;
    }
  }

  //priority 3 to local storage
  const tenantStorage = localStorage.getItem(STORAGE_VARS.TENANT);
  const exists = isExistsTenant(tenantStorage);
  if (tenantStorage && exists) {
    return tenantStorage;
  }

  //fallback on first client
  const tenantDemo = clients[0].code;
  localStorage.setItem(STORAGE_VARS.TENANT, tenantDemo);
  return tenantDemo;
}
