import History from 'utils/History';
import * as Tenancy from 'utils/Tenancy';

const UNPROTECTED = ['/', '', 'login', 'login/', '403', '403/', '404', '404/'];

export function getCurrentRoute() {
  if (History.location.pathname.startsWith('/okta')) {
    return {
      path:
        History.location.pathname +
        History.location.search +
        History.location.hash,
      protected: false
    };
  }

  let route = null;
  const isMonoTenant = Tenancy.isMonoTenant();
  if (isMonoTenant) {
    route = History.location.pathname.replace('/', '');
  } else {
    const tenant = Tenancy.getTenant();
    route = History.location.pathname.replace('/' + tenant + '/', '');
  }
  return {
    path: '/' + route,
    protected: UNPROTECTED.indexOf(route) < 0
  };
}

export function clear() {
  History.entries = [];
  History.index = -1;
}
export function requireLogin() {
  const path = `/login?next=${getCurrentRoute().path}`;
  replace(path);
}
export function replace(path) {
  const isMonoTenant = Tenancy.isMonoTenant();
  if (isMonoTenant) {
    History.replace(path);
  } else {
    const tenant = Tenancy.getTenant();
    History.replace('/' + tenant + path);
  }
}
export function push(path) {
  const isMonoTenant = Tenancy.isMonoTenant();
  if (isMonoTenant) {
    History.push(path);
  } else {
    const tenant = Tenancy.getTenant();
    History.push('/' + tenant + path);
  }
}
export function redirect(path) {
  History.entries = [];
  History.index = -1;
  const isMonoTenant = Tenancy.isMonoTenant();
  if (isMonoTenant) {
    window.location.href = path;
  } else {
    const tenant = Tenancy.getTenant();
    window.location.href = '/' + tenant + path;
  }
}
export function open(path) {
  const isMonoTenant = Tenancy.isMonoTenant();
  const origin = window.location.origin;
  let url = null;
  if (isMonoTenant) {
    url = origin + path;
  } else {
    const tenant = Tenancy.getTenant();
    url = origin + '/' + tenant + path;
  }
  window.open(url, '_blank');
}
export function go() {
  History.go();
}
