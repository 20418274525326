export const AvatarsSrc = {
  '/uploads/avatars/avatar_01.png': require('assets/mockdata/avatars/avatar_01.png'),
  '/uploads/avatars/avatar_02.png': require('assets/mockdata/avatars/avatar_02.png'),
  '/uploads/avatars/avatar_03.png': require('assets/mockdata/avatars/avatar_03.png'),
  '/uploads/avatars/avatar_04.png': require('assets/mockdata/avatars/avatar_04.png'),
  '/uploads/avatars/avatar_05.png': require('assets/mockdata/avatars/avatar_05.png'),
  '/uploads/avatars/avatar_06.png': require('assets/mockdata/avatars/avatar_06.png'),
  '/uploads/avatars/avatar_07.png': require('assets/mockdata/avatars/avatar_07.png'),
  '/uploads/avatars/avatar_08.png': require('assets/mockdata/avatars/avatar_08.png'),
  '/uploads/avatars/avatar_09.png': require('assets/mockdata/avatars/avatar_09.png'),
  '/uploads/avatars/avatar_10.png': require('assets/mockdata/avatars/avatar_10.png')
};

export const BadgesSrc = {
  '/uploads/assets/badges/badge_01_active.png': require('assets/mockdata/badges/badge_01_active.png'),
  '/uploads/assets/badges/badge_02_active.png': require('assets/mockdata/badges/badge_02_active.png'),
  '/uploads/assets/badges/badge_03_active.png': require('assets/mockdata/badges/badge_03_active.png'),
  '/uploads/assets/badges/badge_04_active.png': require('assets/mockdata/badges/badge_04_active.png'),
  '/uploads/assets/badges/badge_05_active.png': require('assets/mockdata/badges/badge_05_active.png'),
  '/uploads/assets/badges/badge_06_active.png': require('assets/mockdata/badges/badge_06_active.png'),
  '/uploads/assets/badges/badge_avatar_active.svg': require('assets/mockdata/badges/badge_avatar_active.svg'),
  '/uploads/assets/badges/badge_welcome_active.svg': require('assets/mockdata/badges/badge_welcome_active.svg'),
  '/uploads/assets/badges/badge_first_active.svg': require('assets/mockdata/badges/badge_first_active.svg'),
  '/uploads/assets/badges/badge_03_active_oNCyeHr.png': require('assets/mockdata/badges/badge_03_active.png'),
  '/uploads/assets/badges/badge_03_active_htFKr5X.png': require('assets/mockdata/badges/badge_03_active.png'),
  '/uploads/assets/badges/badge_04_active_asR2oDw.png': require('assets/mockdata/badges/badge_04_active.png'),
  '/uploads/assets/badges/badge_04_active_THEsVnk.png': require('assets/mockdata/badges/badge_04_active.png')
};
export const LevelsSrc = {
  '/uploads/assets/levels/level_newbie.png': require('assets/mockdata/levels/level_01_newbie_active.png'),
  '/uploads/assets/levels/level_novice.png': require('assets/mockdata/levels/level_02_novice_active.svg'),
  '/uploads/assets/levels/level_intermediate.png': require('assets/mockdata/levels/level_03_intermediate_active.svg'),
  '/uploads/assets/levels/level_advanced.png': require('assets/mockdata/levels/level_04_advanced_active.png'),
  '/uploads/assets/levels/level_guru.png': require('assets/mockdata/levels/level_05_guru_active.png')
};
