import React, { Component } from 'react';
import History from 'utils/History';
import { withStyles } from '@material-ui/core/styles';
import Moment from 'react-moment';
import FiltersContext from './../contexts/FiltersProvider';

const muiStyles = (theme) => ({
  timeline: {
    width: '100%',
    textAlign: 'right',
    paddingRight: '1.2rem',
    paddingBottom: '1rem',
    fontSize: '1.3rem'
  }
});

class Timeline extends Component {
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      pathname: History.location.pathname
    };
  }

  UNSAFE_componentWillMount() {
    History.listen((location) => {
      this.securSetState({ pathname: location.pathname });
    });
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  render() {
    const { classes } = this.props;
    const { pathname } = this.state;
    const { timelineFilter } = this.context;

    let show = true;
    let isBegin = true;
    if (pathname.indexOf('/time-evolution') > -1) {
      isBegin = false;
    }
    if (pathname.indexOf('/notifications/') > -1) {
      show = false;
    }
    return (
      <div className={classes.timeline}>
        {show && isBegin && (
          <>
            <Moment date={timelineFilter?.begin} format='Do MMMM' />
            &nbsp;-&nbsp;
          </>
        )}
        {show && <Moment date={timelineFilter?.end} format='Do MMMM, YYYY' />}
      </div>
    );
  }
}

export default withStyles(muiStyles)(Timeline);
