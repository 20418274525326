import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { withStyles } from '@material-ui/core/styles';
import * as CcoApi from './../api/endpoints';
import * as MwHistory from 'utils/MwHistory';
import BigDataTable from 'deep/components/materials/BigDataTable';
import Rating from 'deep/components/materials/Rating';

import Button from '@material-ui/core/Button';
import CallRoundedIcon from '@material-ui/icons/CallRounded';
import AppContext from 'deep/contexts/AppProvider';

const styles = (theme) => ({
  content: {
    margin: '1rem 4rem',
    padding: '0rem'
  }
});

class Proactive extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      opportunities: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.loadOpportunities();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  onStartCall(lastColumn, customerId) {
    if (lastColumn) {
      MwHistory.push('/cco/session/' + customerId);
    }
  }

  /*********************/
  /* LOAD DATA PROCESS */
  /*********************/
  async loadOpportunities() {
    try {
      const result = await CcoApi.getProactiveOpportunities();
      this.securSetState({
        opportunities: result
      });
    } catch (error) {
      this.securSetState({
        error: this.props.t(error)
      });
    }
  }

  formatOpportunities(data) {
    if (!data) {
      return false;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      let portfolio = '';
      item.contracts.forEach((contract, i) => {
        if (contract.product) {
          portfolio += this.getTrans(contract.product?.name_trans) + ', ';
        }
      });
      if (portfolio === '') {
        portfolio = '-';
      } else {
        portfolio = portfolio.substr(0, portfolio.length - 2);
      }

      dataFormatted.push({
        id: item.customer?.id,
        name: item.customer?.firstname + ' ' + item.customer?.lastname,
        segment: item.customer_type ? item.customer_type : '-',
        portfolio: portfolio,
        invoice: item.last_bill_value ? item.last_bill_value : '-',
        campaign: this.getTrans(item.campaign_type?.name_trans),
        product: item.product ? this.getTrans(item.product?.name_trans) : '-',
        actionType: this.getTrans(item.offer?.type_trans),
        actionSubType: this.getTrans(item.offer?.sub_type_trans),
        expected: item.expected_value ? item.expected_value : '-',
        expected_pct: item.expected_value_pct
          ? item.expected_value_pct * 5
          : '-',
        actions: this.setEditBt()
      });
    });

    return dataFormatted;
  }

  setEditBt() {
    return (
      <Button
        variant='text'
        disableElevation
        startIcon={
          <CallRoundedIcon color='primary' style={{ fontSize: '1.4rem' }} />
        }
        disableRipple
        color='primary'
        style={{
          fontSize: '1.3rem',
          padding: 0,
          textTransform: 'none',
          backgroundColor: 'transparent'
        }}
      >
        <span
          style={{
            textTransform: 'capitalize',
            lineHeight: 0.6,
            whiteSpace: 'nowrap'
          }}
        >
          {this.props.t('proactive.bt-start-call')}
        </span>
      </Button>
    );
  }

  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }

  createColumn(id, sortable, label, wrap, currency, component = null) {
    return {
      id: id,
      numeric: false,
      sortable: sortable,
      label: this.props.t('proactive.' + label),
      wrap: wrap,
      customHeadStyle: { whiteSpace: 'nowrap' },
      currency: currency,
      component
    };
  }

  render() {
    const { classes } = this.props;
    const { clientSettings } = this.context;
    const {
      displayPortfolio = true,
      displayHistory = true,
      displayExpectedValuePct = false
    } = clientSettings.sessionConfig;

    const columns = [
      this.createColumn('id', true, 'lbl-id', true, null),
      this.createColumn('name', true, 'lbl-name', false, null),
      this.createColumn('segment', true, 'lbl-segment', true, null),
      (displayPortfolio &&
        this.createColumn('portfolio', true, 'lbl-portfolio', true, null)) ||
        null,
      (displayHistory &&
        this.createColumn(
          'invoice',
          true,
          'lbl-invoice',
          true,
          clientSettings.currency
        )) ||
        null,
      this.createColumn('campaign', true, 'lbl-campaign', true, null),
      this.createColumn('product', true, 'lbl-product', true, null),
      this.createColumn('actionType', true, 'lbl-actionType', true, null),
      this.createColumn('actionSubType', true, 'lbl-actionSubType', true, null),
      this.createColumn(
        displayExpectedValuePct ? 'expected_pct' : 'expected',
        true,
        'lbl-expected',
        true,
        clientSettings.currency,
        displayExpectedValuePct ? Rating : null
      ),
      this.createColumn('actions', false, 'lbl-actions', true, null)
    ].filter((x) => !!x);

    const headLabels = [
      {
        id: 'customer',
        colspan: 3 + displayPortfolio + displayHistory,
        label: this.props.t('proactive.lbl-customer-info')
      },
      {
        id: 'offer',
        colspan: 4,
        label: this.props.t('proactive.lbl-offer-info')
      }
    ];

    const opportunities = this.formatOpportunities(this.state.opportunities);

    return (
      <div className={classes.content}>
        {opportunities && (
          <BigDataTable
            data={opportunities}
            headLabels={headLabels}
            columns={columns}
            defaultOrder='desc'
            defaultOrderBy='id'
            pagination={false}
            onSelect={(lastColumn, customerId) => {
              this.onStartCall(lastColumn, customerId);
            }}
          />
        )}
      </div>
    );
  }
}

export default withTranslation('cco')(withStyles(styles)(Proactive));
