import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import * as MwHistory from 'utils/MwHistory';
import SubmitButton from 'deep/components/materials/SubmitButton';
import BasicInput from 'deep/components/materials/BasicInput';
import Box from '@material-ui/core/Box';
import * as CcoApi from './../api/endpoints';
import CCOTabs from './../components/CCOTabs';
import Proactive from './../components/Proactive';
import Paper from '@material-ui/core/Paper';
import SmallDataTable from 'deep/components/materials/SmallDataTable';
import Collapse from '@material-ui/core/Collapse';
import AppContext from 'deep/contexts/AppProvider';

class StartCallRich extends Component {
  static contextType = AppContext;
  
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      loading: false,
      inputID: '',
      validID: '',
      inputKeyword: '',
      searchResults: null,
      searchCustomerID: null,
      selectedTab: 0,
      interval: null
    };
    this.handleIDChange = this.handleIDChange.bind(this);
    this.handleIDSubmit = this.handleIDSubmit.bind(this);
    this.handleKeywordSubmit = this.handleKeywordSubmit.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  handleIDChange(value) {
    this.securSetState({
      inputID: value,
      error: null
    });
  }
  handleIDSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.inputID !== '') {
      this.securSetState({
        loading: true,
        validID: this.state.inputID
      });
      if (this.mounted) {
        this.loadCustomer();
      }
    } else {
      this.securSetState({
        customers: [],
        loading: false
      });
    }
  }
  async loadCustomer() {
    const validID = this.state.inputID;

    try {
      const result = await CcoApi.getCustomer(validID);
      this.onLoadCustomerSucceed(result);
    } catch (error) {
      if (error.status === 403) {
        this.props.onClose();
      } else {
        this.securSetState({
          loading: false,
          error: error
        });
      }
    }
  }
  onLoadCustomerSucceed(customer) {
    this.securSetState({
      loading: false,
      error: false
    });
    this.props.onStart();
    MwHistory.replace('/cco/session/' + customer.id);
  }

  handleKeywordChange(value) {
    this.securSetState({
      inputKeyword: value,
      searchCustomerID: null
    });
    this.searchCustomer(value);
  }

  async searchCustomer(inputKeyword) {

    if (inputKeyword.trim() === '') {
      this.securSetState({
        searchResults: null,
        searchCustomerID: null
      });
    } else {
      try {


      const searchInterval = async (inputKeyword) => {
        if(this.state.interval === null){
        const interval = setTimeout(async ()=> {
          this.setState({interval: null})
          const results = await CcoApi.searchCustomer(inputKeyword);
          this.onSearchCustomerSucceed(results);

        }, 1000)
        this.setState({interval: interval})
        } else {
          clearTimeout(this.state.interval)
          const interval = setTimeout(async () => {
            this.setState({interval: null})
            const results = await CcoApi.searchCustomer(inputKeyword);
            this.onSearchCustomerSucceed(results);
          }, 1000)
          this.setState({interval: interval})

        }

      }

      await searchInterval(inputKeyword)

      } catch (error) {
        if (error.status === 403) {
          this.props.onClose();
        } else {
          this.securSetState({
            loading: false,
            error: error
          });
        }
      }
    }
  }

  onSearchCustomerSucceed(results) {
    this.securSetState({
      loading: false,
      error: false,
      searchResults: results
    });
  }

  highlightResult(data, keyword) {
    const search = keyword.toLowerCase();
  
    const aData = data.toLowerCase().split(search);
    let hiData = [];
    if (aData[0] !== '') {
      hiData.push(<span key={0}>{aData[0]}</span>);
    }
    for (let i = 1; i < aData.length; i++) {
      hiData.push(
        <span key={i}>
          <span style={{ fontWeight: 700 }}>{keyword}</span>
          {aData[i]}
        </span>
      );
    }
    return hiData;
  }

  formatSearchResults(data, keyword) {
    if (!data) {
      return false;
    }

    const dataFormatted = [];
    data.forEach((item) => {
      dataFormatted.push({
        id: item.id,
        namestr: item.firstname + ' ' + item.lastname,
        name: (
          <>
            {this.highlightResult(item.firstname, keyword)}&nbsp;
            {this.highlightResult(item.lastname, keyword)}
          </>
        ),
        idstr: <>{this.highlightResult(item.id.toString(), keyword)}</>,
        number: <>{this.highlightResult(item.phone_number, keyword)}</>,
        contract: item.contract_account_id,
        contractstr: <>{this.highlightResult(item.contract_account_id.toString(), keyword)}</>
      
      });
    });

    return dataFormatted;
  }

  onSelectCustomer(customer) {
    this.securSetState({
      inputKeyword: customer.contract,
      searchCustomerID: customer.id,
      searchResults: null
    });
  }

  handleKeywordSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    this.props.onStart();
    const currentRoute = MwHistory.getCurrentRoute();
    const shouldGo = !currentRoute.path.endsWith('/cco');
    MwHistory.push('/cco/session/' + this.state.inputKeyword);
    shouldGo && MwHistory.go();
  }
  onChangeTab(event, value) {
    event.preventDefault();
    this.securSetState({
      selectedTab: value,
      searchResults: null,
      error: null
    });
  }

  renderStartCallKeyword() {
    const styles = {
      error: {
        height: '2rem',
        fontSize: '1.1rem',
        padding: 0,
        textAlign: 'left'
      },
      results: {
        backgroundColor: '#fff',
        position: 'absolute',
        top: '4.4rem',
        left: '0rem',
        padding: '2rem 2.5rem',
        zIndex: 1
      }
    };

    const cols = [
      { id: 'id', numeric: false, label: 'Customer ID', hide: true },
      { id: 'namestr', numeric: false, label: 'Customer Name', hide: true },
      { id: 'name', numeric: false, label: 'Customer Name' },
      { id: 'idstr', numeric: false, label: 'BP ID' },
      { id: 'contractstr', numeric: false, label: 'Contract Account ID' }
    ];

    const customHeadStyle = {
      fontSize: '1.3rem'
    };
    const customCellStyle = {
      fontSize: '1.3rem',
      '&:nth-child(n+2)': {
        fontWeight: 400
      }
    };
    const customRowStyle = {
      '&:hover': {
        backgroundColor: '#f5f7fa',
        cursor: 'pointer'
      }
    };

    const results = this.formatSearchResults(
      this.state.searchResults,
      this.state.inputKeyword
    );

    return (
      <form onSubmit={this.handleKeywordSubmit}>
        <div
          style={{
            width: 'max-content',
            margin: '0rem auto',
            position: 'relative'
          }}
        >
          <BasicInput
            defaultValue=''
            autoFocus={true}
            placeholder={this.props.t('startcall.ph-search')}
            error={false}
            valid={this.state.inputKeyword !== ''}
            type='text'
            value={this.state.inputKeyword}
            onChange={(value) => this.handleKeywordChange(value)}
            size={this.props.size}
            maxLength='250'
            style={{
              width: this.props.inputWidth,
              maxWidth: '100%',
              margin: '0rem 1rem 1rem 0rem'
            }}
          />
          <span style={{ margin: '0rem 1rem 0rem 0rem' }}>
            <SubmitButton
              disabled={!this.state.searchCustomerID}
              label={this.props.t('startcall.bt-start')}
              isLoading={this.state.loading}
              type='submit'
              size={this.props.size}
            />
          </span>
          <Box color='error.main' style={styles.error}></Box>
          {results && results.length > 0 && (
            <Paper style={styles.results} elevation={3}>
              <SmallDataTable
                data={results}
                columns={cols}
                highlight={false}
                customHeadStyle={customHeadStyle}
                customCellStyle={customCellStyle}
                customRowStyle={customRowStyle}
                onSelect={(customer) => {
                  this.onSelectCustomer(customer);
                }}
              />
            </Paper>
          )}
        </div>
      </form>
    );
  }

  renderStartCallID() {
    const styles = {
      error: {
        height: '2rem',
        fontSize: '1.1rem',
        padding: 0,
        textAlign: 'left'
      }
    };

    //manage language
    let errorTrans = null;
    if (this.state.error) {
      errorTrans = this.props.t(this.state.error);
    }

    return (
      <form onSubmit={this.handleIDSubmit}>
        <div style={{ width: 'max-content', margin: '0rem auto' }}>
          <BasicInput
            defaultValue=''
            autoFocus={true}
            placeholder={this.props.t('startcall.ph-search')}
            error={this.state.error && this.state.error !== ''}
            valid={this.state.inputID !== '' && !this.state.error}
            type='text'
            onChange={(value) => this.handleIDChange(value)}
            size={this.props.size}
            maxLength='250'
            style={{
              width: this.props.inputWidth,
              maxWidth: '100%',
              margin: '0rem 1rem 1rem 0rem'
            }}
          />
          <span style={{ margin: '0rem 1rem 0rem 0rem' }}>
            <SubmitButton
              disabled={this.state.inputID === ''}
              label={this.props.t('startcall.bt-start')}
              isLoading={this.state.loading}
              type='submit'
              size={this.props.size}
            />
          </span>
          <Box color='error.main' style={styles.error}>
            <span>{errorTrans}</span>
          </Box>
        </div>
      </form>
    );
  }

  render() {
    const { inbound } = this.context;
    const tabs = [
      { id: 1, name: this.props.t('startcall.tab-inbound'), value: 'inbound' },
      ...(inbound
        ? [
            {
              id: 2,
              name: this.props.t('startcall.tab-proactive'),
              value: 'proactive'
            }
          ]
        : [])
    ];

    return (
      <>
        {tabs.length > 1 && (
          <CCOTabs
            tabs={tabs}
            defaultValue={this.state.selectedTab}
            onChange={(event, value) => this.onChangeTab(event, value)}
          />
        )}
        {this.state.selectedTab === 0 && this.renderStartCallKeyword()}
        {this.state.selectedTab === 1 && this.renderStartCallID()}
        {inbound && (
          <Collapse in={this.state.selectedTab === 1} timeout={{ enter: 1500 }}>
            <Proactive
              open={this.state.proactiveModalOpen}
              onClose={this.handleCloseModal}
              personaId={this.state.selectedPersonaId}
            />
          </Collapse>
        )}
      </>
    );
  }
}

export default withTranslation('cco')(StartCallRich);
