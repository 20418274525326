import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { withStyles } from "@material-ui/core/styles";
import i18next from 'i18next';

import Divider from '@material-ui/core/Divider';
import Paper from "@material-ui/core/Paper";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ButtonStyled = withStyles((theme) => ({
  label: {
      justifyContent: 'flex-start'
  },
  text: {
      '&:hover': {
      color: theme.palette.primary.main
      }
  }
  }))(Button);


  const formatLang = (transItem) => {
    if (!transItem){
      return
    }
  
    return transItem[i18next.language] || transItem[i18next.options.fallbackLng[0]]
  }

const  DataDiscoveryList = ({ 
  questionNumber,
  possibleAnswers,
  setNewDiscoveryAnswers,
  dataDiscoveryAnswers,
  t
}) => {
  

  const [isExpanded, setIsExpanded] = useState({})

  const getSelectedAnswer = () => {
    const selected = dataDiscoveryAnswers && dataDiscoveryAnswers[questionNumber]
    if (!Number.isInteger(selected)) {
      return t('session.data-discovery.select-option')
    }
    return formatLang(possibleAnswers[selected] && possibleAnswers[selected].name_trans);
  }

  const handleClickAway = () => {
    setIsExpanded( { 
      ...isExpanded,
      [questionNumber]: false
     });
  }  

  const toggle = () => {
    if (possibleAnswers.length > 1) {
      setIsExpanded( { 
        ...isExpanded,
        [questionNumber]: !isExpanded[questionNumber]
       });
    }
  }

  const handleSelect = (answerIndex) => {

    toggle();
    setNewDiscoveryAnswers({ ...dataDiscoveryAnswers, [questionNumber]: answerIndex})

  }
  
  const getStyle = () => ({
    wrapper: {
      zIndex: isExpanded[questionNumber] ? 15 : 14,
      border: "1px solid #bcbcbc",
      borderRadius: "1.8rem",
      width: "100%",
    },
    paper: {
      borderRadius: "1.8rem",
      padding: "0rem 1rem 0rem",
    },
    selectedItem: {
      position: "relative",
      cursor:
        possibleAnswers && possibleAnswers.length > 1 ? "pointer" : "default",
      padding: "0.7rem 0rem 1.7rem 0rem",
      picto: {
        position: "absolute",
        top: "40%",
        transform: "translateY(-50%)",
      },
      label: {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
      },
      arrow: {
        position: "absolute",
        fontSize: "2rem",
        top: "0.8rem",
        right: "-0.3rem",
      },
    },
    divider: {
      marginBottom: "1rem",
    },
    subItemBt: {
      zIndex: 11,
      textTransform: "none",
      textAlign: "left",
      paddingLeft: "1.6rem",
      lineHeight: "1",
      minHeight: "3.7rem",
      backgroundColor: "transparent",
      picto: {
        minWidth: "2rem",
      },
    },
  });



const styles = getStyle()
return (
    <div>
    <ClickAwayListener onClickAway={() => handleClickAway(questionNumber)}>
    <div style={{ width: "100%", position: "relative" }}>
      <div style={styles.wrapper}>

        <Paper elevation={isExpanded[questionNumber] ? 4 : 0} style={styles.paper}>
        <Collapse in={isExpanded[questionNumber]} collapsedHeight="3.7rem">
            <div style={styles.selectedItem} onClick={e => toggle(questionNumber, possibleAnswers)}>
            <div style={styles.selectedItem.label}>
                { getSelectedAnswer()}
            </div>
            <ExpandMoreIcon style={styles.selectedItem.arrow} />
            </div>
              <Divider style={styles.divider} />
              {possibleAnswers && possibleAnswers.map((answer, answerIndex) => {

                    return answerIndex !== (dataDiscoveryAnswers && dataDiscoveryAnswers[questionNumber]) ? (
                        <div key={`${questionNumber}_${answerIndex}`}>
                        <ButtonStyled
                            style={styles.subItemBt}
                            variant='text'
                            disableElevation
                            disableRipple
                            onClick={() => handleSelect(answerIndex)}
                        >
                            {formatLang(answer.name_trans)}
                        </ButtonStyled>
                        </div>
                    ) : (
                        <div key={answerIndex}></div>
                    )
                })
             }
            <div style={{ height: "1.5rem" }}></div>
        </Collapse>
        </Paper>
      </div>
    </div>
  </ClickAwayListener>
  </div>
);
}

export default withTranslation("cco")(DataDiscoveryList);