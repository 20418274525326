import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as Api from './../api/endpoints';
import { ROLES } from 'utils/Constants';
import Footer from './../components/Footer';
import SimpleButton from './../components/materials/SimpleButton';
import Box from '@material-ui/core/Box';
import UserEdition from './UserEdition';
import BigDataTable from './../components/materials/BigDataTable';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AppContext from 'deep/contexts/AppProvider';

const styles = (theme) => ({
  wrapper: {
    padding: '7.3rem 2rem 0rem',
    minHeight: '100vh'
  },
  activity: {
    height: '0.3rem',
    backgroundColor: '#fff',
    width: 'calc(100vw + 2rem)',
    position: 'fixed',
    top: 0,
    left: '-2rem',
    zIndex: 2000
  },
  content: {
    minHeight: 'calc(100vh - 11.2rem)',
    paddingBottom: '2rem'
  },
  title: {
    color: '#4C4C4C',
    fontSize: '2rem',
    fontWeight: 600,
    marginBottom: '1.8rem'
  },
  box: {
    boxShadow: '0px 0px 10px -7px rgba(0,0,0,0.5)',
    borderRadius: 6,
    padding: '1.6rem 2.4rem',
    backgroundColor: '#fff'
  },
  btAdd: {
    position: 'absolute',
    right: '2rem',
    top: '6.6rem'
  },
  btIcon: {
    marginRight: '1rem',
    color: '#ffffff',
    fontSize: '1.8rem'
  }
});

class UsersMgmt extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      personas: null,
      editionModalOpen: false,
      selectedPersonaId: null,
      error: null
    };
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleUserUpdate = this.handleUserUpdate.bind(this);
    this.handleUserCreate = this.handleUserCreate.bind(this);
    this.openEditionModal = this.openEditionModal.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.loadPersonas();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  async loadPersonas() {
    try {
      const result = await Api.getPersonas();
      this.securSetState({
        personas: result
      });
    } catch (error) {
      this.securSetState({
        error: this.props.t(error)
      });
    }
  }

  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }

  setEditBt() {
    return (
      <Button
        variant='text'
        disableElevation
        startIcon={
          <EditRoundedIcon color='primary' style={{ fontSize: '1.4rem' }} />
        }
        disableRipple
        color='primary'
        style={{
          padding: 0,
          textTransform: 'none',
          backgroundColor: 'transparent'
        }}
      >
        <span style={{ textTransform: 'capitalize', lineHeight: 0.6 }}>
          {this.props.t('usersmgmt.bt-edit')}
        </span>
      </Button>
    );
  }

  formatPersonas(data) {
    if (!data) {
      return false;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      dataFormatted.push({
        id: item.id,
        firstname: item.user?.first_name,
        lastname: item.user?.last_name,
        email: item.user?.email,
        //username: item.user?.username,
        nickname: item.nickname,
        role: this.getTrans(item.type?.name_trans),
        channel: this.getTrans(item.channel?.name_trans),
        actions: this.setEditBt()
      });
    });

    return dataFormatted;
  }

  openEditionModal(lastColumn, personaId) {
    const { me } = this.context;

    if (me.type?.code === ROLES.ADMIN && lastColumn) {
      this.securSetState({
        selectedPersonaId: personaId,
        editionModalOpen: true
      });
    }
  }
  handleCloseModal(event) {
    this.securSetState({
      editionModalOpen: false
    });
  }

  /*************************/
  /* UPDATE USER CALL BACK */
  /*************************/
  handleUserUpdate(persona) {
    //search persona index in array
    let updatedPersonas = this.state.personas;
    let index = 0;
    let personaIndex = -1;
    for (let item of updatedPersonas) {
      if (item.id === this.state.selectedPersonaId) {
        personaIndex = index;
        break;
      }
      index += 1;
    }
    updatedPersonas[personaIndex] = persona;
    //updatedPersonas.splice(personaIndex, 1);
    //update array
    this.securSetState({
      personas: updatedPersonas,
      editionModalOpen: false
    });
  }

  /*************************/
  /* CREATE USER CALL BACK */
  /*************************/
  handleUserCreate(persona) {
    //add new user
    let updatedPersonas = this.state.personas;
    updatedPersonas.unshift(persona);
    //update array
    this.securSetState({
      personas: updatedPersonas,
      editionModalOpen: false
    });
  }

  render() {
    const { me } = this.context;

    const columns = [
      {
        id: 'id',
        numeric: false,
        sortable: true,
        label: this.props.t('usersmgmt.lbl-id'),
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap' }
      },
      {
        id: 'firstname',
        numeric: false,
        sortable: true,
        label: this.props.t('usersmgmt.lbl-firstname'),
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap' }
      },
      {
        id: 'lastname',
        numeric: false,
        sortable: true,
        label: this.props.t('usersmgmt.lbl-lastname'),
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap' }
      },
      {
        id: 'email',
        numeric: false,
        sortable: true,
        label: this.props.t('usersmgmt.lbl-email'),
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap' }
      },
      {
        id: 'nickname',
        numeric: false,
        sortable: true,
        label: this.props.t('usersmgmt.lbl-nickname'),
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap' }
      },
      {
        id: 'role',
        numeric: false,
        sortable: true,
        label: this.props.t('usersmgmt.lbl-role'),
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap' }
      },
      {
        id: 'channel',
        numeric: false,
        sortable: true,
        label: this.props.t('usersmgmt.lbl-channel'),
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap' }
      }
    ];

    if (me.type?.code === ROLES.ADMIN) {
      columns.push({
        id: 'actions',
        numeric: false,
        sortable: false,
        label: this.props.t('usersmgmt.lbl-actions')
      });
    }

    const { classes } = this.props;

    const personas = this.formatPersonas(this.state.personas);

    return (
      <div>
        {this.state.editionModalOpen && (
          <UserEdition
            open={this.state.editionModalOpen}
            onClose={this.handleCloseModal}
            onUpdate={(persona) => {
              this.handleUserUpdate(persona);
            }}
            onCreate={(persona) => {
              this.handleUserCreate(persona);
            }}
            personaId={this.state.selectedPersonaId}
          />
        )}
        <div className={classes.wrapper}>
          <div className={classes.activity}></div>
          <div className={classes.content}>
            <div className={classes.title}>
              {this.props.t('usersmgmt.txt-title')}
            </div>
            {me.type?.code === ROLES.ADMIN && (
              <div className={classes.btAdd}>
                <SimpleButton
                  onClick={() => {
                    this.openEditionModal(true, null);
                  }}
                  disabled={false}
                  label={this.props.t('usersmgmt.bt-add')}
                  size='small'
                  variant='contained'
                  icon={<AddCircleIcon className={classes.btIcon} />}
                />
              </div>
            )}
            <Box className={classes.box}>
              {this.state.error && (
                <div
                  style={{
                    fontSize: '1.3rem',
                    textAlign: 'left',
                    color: 'red'
                  }}
                >
                  {this.state.error}
                </div>
              )}
              {personas && (
                <BigDataTable
                  data={personas}
                  columns={columns}
                  defaultOrder='desc'
                  defaultOrderBy='id'
                  pagination={true}
                  onSelect={(lastColumn, personaId) => {
                    this.openEditionModal(lastColumn, personaId);
                  }}
                />
              )}
            </Box>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default withTranslation('common')(withStyles(styles)(UsersMgmt));
