import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { withStyles } from '@material-ui/core/styles';
import * as Api from './../api/endpoints';

import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import BasicInput from './../components/materials/BasicInput';
import DropDownList from './../components/materials/DropDownList';
import SimpleButton from './../components/materials/SimpleButton';
import SubmitButton from './../components/materials/SubmitButton';
import AppContext from 'deep/contexts/AppProvider';

const styles = (theme) => ({
  dialog: {
    padding: '0rem',
    backgroundColor: '#F5F7FA',
    borderRadius: '0.6rem',
    minWidth: '40rem',
    maxWidth: '74rem'
  },
  header: {
    padding: '2.4rem',
    backgroundColor: '#ffffff',
    fontSize: '1.7rem',
    fontWeight: 600,
    borderBottom: '1px solid #E4E8EA'
  },
  content: {
    margin: '2.4rem',
    padding: '2.4rem',
    backgroundColor: '#ffffff',
    border: '1px solid #E4E8EA',
    borderRadius: '0.6rem'
  },
  footer: {
    padding: '2.4rem',
    backgroundColor: '#ffffff',
    borderTop: '1px solid #E4E8EA',
    textAlign: 'right'
  },
  label: {
    color: '#4C4C4C',
    fontWeight: 600,
    textAlign: 'left',
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: '1.7rem',
    marginBottom: '0.7rem'
  }
});

class UserEdition extends Component {
  static contextType = AppContext;

  constructor(props) {
    super(props);
    this.state = {
      persona: null,
      firstname: '',
      lastname: '',
      email: '',
      nickname: '',
      password: '',
      channel: null,
      role: null,
      channels: null,
      roles: null,
      requestExecuting: false,
      error: null
    };
    this.onRequestSubmit = this.onRequestSubmit.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    this.loadChannels();
    this.loadPersonaTypes();
    if (this.props.personaId) {
      this.loadPersona(this.props.personaId);
    }
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  /*********************/
  /* LOAD DATA PROCESS */
  /*********************/
  async loadPersona(personaId) {
    //try {
    const result = await Api.getPersona(personaId);
    this.securSetState({
      persona: result,
      firstname: result.user?.first_name,
      lastname: result.user?.last_name,
      email: result.user?.email,
      nickname: result.nickname,
      channel: this.formatChannel(result.channel),
      role: this.formatRole(result.type)
    });
    //} catch(error) {
    //  this.onRequestFailed(error);
    //}
  }

  async loadChannels() {
    try {
      const result = await Api.getChannels();
      if (result.length > 0) {
        const dataFormatted = [];
        result.forEach((item) => {
          dataFormatted.push(this.formatChannel(item));
        });
        this.securSetState({
          channels: dataFormatted
        });
      } else {
        this.onRequestFailed('error.user-add-issue');
      }
    } catch (error) {
      this.onRequestFailed('error.user-add-issue');
    }
  }

  async loadPersonaTypes() {
    try {
      const result = await Api.getPersonaTypes();
      if (result.length > 0) {
        const dataFormatted = [];
        result.forEach((item) => {
          dataFormatted.push(this.formatRole(item));
        });
        this.securSetState({
          roles: dataFormatted
        });
      } else {
        this.onRequestFailed('error.user-add-issue');
      }
    } catch (error) {
      this.onRequestFailed('error.user-add-issue');
    }
  }

  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }

  formatChannel(channel) {
    let dataFormatted = {
      id: channel.id,
      label: this.getTrans(channel.name_trans)
    };
    return dataFormatted;
  }

  formatRole(role) {
    let dataFormatted = {
      id: role.id,
      label: this.getTrans(role.name_trans)
    };
    return dataFormatted;
  }

  /*******************/
  /* EVENTS HANDLING */
  /*******************/
  onRequestSubmit(event) {
    event.preventDefault();
    this.securSetState({
      requestExecuting: true
    });
    if (this.state.persona) {
      this.updatePersonaInfo();
    } else {
      this.createUser();
    }
  }

  onRequestFailed(error) {
    this.securSetState({
      error: this.props.t(error),
      requestExecuting: false
    });
  }

  /***********************/
  /* UPDATE USER PROCESS */
  /***********************/
  async updatePersonaInfo() {
    try {
      await Api.patchPersona(
        this.state.persona.id,
        this.state.role.id,
        this.state.channel.id,
        this.state.nickname.trim()
      );
      this.updateUserInfo();
    } catch (error) {
      this.onRequestFailed(error);
    }
  }

  async updateUserInfo() {
    try {
      const response = await Api.patchUser(
        this.state.persona.user.id,
        this.state.email.trim(),
        this.state.firstname.trim(),
        this.state.lastname.trim()
      );
      if (this.state.password) {
        this.setUserPassword();
      } else {
        this.onUpdateSucceed(response);
      }
    } catch (error) {
      this.onRequestFailed(error);
    }
  }

  async setUserPassword() {
    try {
      const response = await Api.setUserPassword(
        this.state.persona.user.id,
        this.state.password
      );
      this.onUpdateSucceed(response);
    } catch (error) {
      this.onRequestFailed(error);
    }
  }

  async onUpdateSucceed(response) {
    //get persona updated info from server
    try {
      const result = await Api.getPersona(this.props.personaId);
      this.props.onUpdate(result);
    } catch (error) {
      this.onRequestFailed(error);
    }
  }

  /***********************/
  /* CREATE USER PROCESS */
  /***********************/
  async createUser() {
    try {
      const response = await Api.createUser(
        this.state.email.trim(),
        this.state.firstname.trim(),
        this.state.lastname.trim(),
        this.state.password
      );
      this.createPersona(response.id);
    } catch (error) {
      this.onRequestFailed(error);
    }
  }

  async createPersona(userId) {
    try {
      const response = await Api.createPersona(
        userId,
        this.state.role.id,
        this.state.channel.id,
        this.state.nickname.trim()
      );
      this.onCreateSucceed(response);
    } catch (error) {
      this.deleteUser(userId);
      this.onRequestFailed(error);
    }
  }

  async deleteUser(userId) {
    try {
      await Api.deleteUser(userId);
    } catch (error) {}
  }

  async onCreateSucceed(response) {
    //get persona created info from server
    try {
      const result = await Api.getPersona(response.id);
      this.props.onCreate(result);
    } catch (error) {
      this.onRequestFailed(error);
    }
  }

  isEmailValid() {
    if (this.state.email.trim() !== '') {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.state.email);
    }
    return true;
  }

  isFormValid() {
    if (!this.state.firstname || this.state.firstname.trim() === '') {
      return false;
    } else if (!this.state.lastname || this.state.lastname.trim() === '') {
      return false;
    } else if (
      !this.state.email ||
      this.state.email.trim() === '' ||
      !this.isEmailValid()
    ) {
      return false;
    } else if (!this.state.nickname || this.state.nickname.trim() === '') {
      return false;
    } else if (
      !this.props.personaId &&
      (!this.state.password || this.state.password.trim() === '')
    ) {
      return false;
    } else if (!this.state.channel) {
      return false;
    } else if (!this.state.role) {
      return false;
    }
    return true;
  }

  render() {
    const { classes } = this.props;

    const handleClose = (event) => {
      this.props.onClose(event);
    };

    const formDisabled =
      (this.props.personaId && !this.state.persona) ||
      !this.state.channels ||
      !this.state.roles;

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby='confirmation'
        open={this.props.open}
        classes={{ paper: classes.dialog }}
        disableBackdropClick={false}
        disableEscapeKeyDown={false}
      >
        <form onSubmit={this.onRequestSubmit}>
          <div className={classes.header}>
            {this.props.personaId
              ? this.props.t('useredition.ttl-edit')
              : this.props.t('useredition.ttl-add')}
          </div>
          <div className={classes.content}>
            <Grid container spacing={2} alignContent='space-between'>
              <Grid item xs={12} sm={6}>
                <div className={classes.label}>
                  {this.props.t('useredition.lbl-firstname')}
                </div>
                <div>
                  <BasicInput
                    disabled={formDisabled}
                    autoFocus={false}
                    value={this.state.firstname}
                    placeholder={this.props.t('useredition.phl-firstname')}
                    error={false}
                    valid={true}
                    type='text'
                    onChange={(value) =>
                      this.securSetState({ firstname: value })
                    }
                    maxLength='250'
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.label}>
                  {this.props.t('useredition.lbl-lastname')}
                </div>
                <div>
                  <BasicInput
                    disabled={formDisabled}
                    utoFocus={false}
                    value={this.state.lastname}
                    placeholder={this.props.t('useredition.phl-lastname')}
                    error={false}
                    valid={true}
                    type='text'
                    onChange={(value) =>
                      this.securSetState({ lastname: value })
                    }
                    maxLength='250'
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.label}>
                  {this.props.t('useredition.lbl-email')}
                </div>
                <div>
                  <BasicInput
                    disabled={formDisabled}
                    autoFocus={false}
                    value={this.state.email}
                    placeholder={this.props.t('useredition.phl-email')}
                    error={false}
                    valid={true}
                    type='text'
                    onChange={(value) => this.securSetState({ email: value })}
                    maxLength='250'
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.label}>
                  {this.props.t('useredition.lbl-nickname')}
                </div>
                <div>
                  <BasicInput
                    disabled={formDisabled}
                    autoFocus={false}
                    value={this.state.nickname}
                    placeholder={this.props.t('useredition.phl-nickname')}
                    error={false}
                    valid={true}
                    type='text'
                    onChange={(value) =>
                      this.securSetState({ nickname: value })
                    }
                    maxLength='250'
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.label}>
                  {this.props.t('useredition.lbl-password')}
                </div>
                <div>
                  <BasicInput
                    disabled={formDisabled}
                    autoFocus={false}
                    value={this.state.password}
                    placeholder={
                      this.props.personaId
                        ? this.props.t('useredition.phl-change-password')
                        : this.props.t('useredition.phl-password')
                    }
                    error={false}
                    valid={true}
                    type='password'
                    onChange={(value) =>
                      this.securSetState({ password: value })
                    }
                    maxLength='250'
                    required={true}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                {/*
                  <div className={classes.label}>{this.props.t('useredition.lbl-username')}</div>
                  <div>
                    {(!this.props.personaId || this.state.username !== '') && (
                      <BasicInput
                        autoFocus={false}
                        defaultValue={this.state.username}
                        placeholder={this.props.t('useredition.phl-username')}
                        error={false}
                        valid={true}
                        type="text"
                        onChange={value => this.securSetState({username: value})}
                        maxLength="250"
                      />
                    )}
                  </div>
                  */}
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.label}>
                  {this.props.t('useredition.lbl-channel')}
                </div>
                <div>
                  <DropDownList
                    disabled={formDisabled}
                    fullWidth={true}
                    options={this.state.channels}
                    value={this.state.channel}
                    placeholder={this.props.t('useredition.phl-channel')}
                    onChange={(value) => this.securSetState({ channel: value })}
                  />
                </div>
              </Grid>
              <Grid item xs={12} sm={6}>
                <div className={classes.label}>
                  {this.props.t('useredition.lbl-role')}
                </div>
                <div>
                  <DropDownList
                    disabled={formDisabled}
                    fullWidth={true}
                    options={this.state.roles}
                    value={this.state.role}
                    placeholder={this.props.t('useredition.phl-role')}
                    onChange={(value) => this.securSetState({ role: value })}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
          <div className={classes.footer}>
            <Grid container spacing={0}>
              <Grid
                item
                sm={4}
                md={6}
                style={{ fontSize: '1.3rem', textAlign: 'left', color: 'red' }}
              >
                {this.state.error}
              </Grid>
              <Grid item sm={4} md={3}>
                <SimpleButton
                  onClick={handleClose}
                  disabled={false}
                  label={this.props.t('useredition.bt-cancel')}
                  size='large'
                  variant='outlined'
                />
              </Grid>
              <Grid item sm={4} md={3}>
                <SubmitButton
                  disabled={!this.isFormValid()}
                  label={this.props.t('useredition.bt-submit')}
                  isLoading={this.state.requestExecuting}
                />
              </Grid>
            </Grid>
          </div>
        </form>
      </Dialog>
    );
  }
}

export default withTranslation('common')(withStyles(styles)(UserEdition));
