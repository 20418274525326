import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';

import GamificationWrapper from './GamificationWrapper';
import Badges from './../components/Badges';
import Card from './../components/Card';
import GamificationContext from './../contexts/GamificationProvider';

class GamificationBadges extends Component {
  static contextType = GamificationContext;

  render() {
    return (
      <GamificationWrapper>
        <Card
          title={this.props.t('menu.ent_badges')}
          icon=<FontAwesomeIcon icon={faTrophy} />
          onView={null}
        >
          <Badges wrap='wrap-reverse' />
        </Card>
      </GamificationWrapper>
    );
  }
}

export default withTranslation('gamification')(GamificationBadges);
