import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import i18next from 'i18next';
import { get } from 'lodash';

import Grid from '@material-ui/core/Grid';
import Footer from 'deep/components/Footer';
import ResizableCard from 'deep/components/materials/ResizableCard';
import SimpleCard from 'deep/components/materials/SimpleCard';
import CustomerDetails from './../components/callsession/CustomerDetails';
import CustomerHistory from './../components/callsession/CustomerHistory';
import FAQ from './../components/callsession/FAQ';
import DataDiscovery from './../components/callsession/DataDiscovery';
import Offer from './../components/callsession/Offer';
import Campaign from './../components/callsession/Campaign';
import NoOpportunity from './../components/callsession/NoOpportunity';
import Opportunities from './../components/callsession/Opportunities';
import Feedback from './../components/callsession/Feedback';
import Tips from './../components/callsession/Tips';
import Comparison from './../components/callsession/Comparison';
import AdditionalProducts from './../components/callsession/AdditionalProducts';
import DataCollection from './../components/callsession/DataCollection';
import Navigator from './../components/callsession/Navigator';
import CallSessionContext from './../contexts/CallSessionProvider';
import GamificationContext from 'gamification/contexts/GamificationProvider';

const styles = (theme) => ({
  wrapper: {
    marginTop: '51px',
    boxShadow: 'inset 0rem 0.3rem 0.4rem 0rem rgba(1,0,0,0.03)',
    padding: '2.4rem 2.4rem 0rem',
    minHeight: 'calc(100vh - 51px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  activity: {
    height: '0.3rem',
    backgroundColor: theme.palette.secondary.main,
    width: 'calc(100vw + 2rem)',
    position: 'fixed',
    top: 0,
    left: '-2rem',
    zIndex: 2000
  },
  content: {
    paddingBottom: '2rem'
  }
});

class CallSession extends Component {
  static contextType = CallSessionContext;

  constructor(props) {
    super(props);
    this.state = {
      cardCustomerDetailsRender: false,
      cardMwHistoryRender: false,
      cardTipsRender: false,
      cardComparisonRender: false,
      cardAdditionalProductsRender: false,
      cardDataCollectionRender: false,
      cardFAQRender: false
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    const {
      match: { params }
    } = this.props;
    this.context.load(params.customerId);
  }
  componentWillUnmount() {
    this.mounted = false;
    this.context.clearContext()
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }

  render() {
    const { classes } = this.props;
    const {
      cardCustomerDetailsRender,
      cardMwHistoryRender,
      cardTipsRender,
      cardComparisonRender,
      cardAdditionalProductsRender,
      cardDataCollectionRender
    } = this.state;

    const { customer, selectedCampaign, selectedOpportunity } = this.context;

    const {
      displayHistory = true,
      displayAdditionalProducts = false,
      displayKnowledgeNavigator = true,
      displayProductComparison = true,
      displayDataCollection = true,
      displayFAQ = true
    } = get(this.context.clientSettings, 'sessionConfig', {});

    let tipsTitle = ' ';
    if (selectedOpportunity && selectedOpportunity.product) {
      tipsTitle =
        selectedOpportunity.product.name_trans[i18next.language] ||
        selectedOpportunity.product.name_trans[i18next.options.fallbackLng[0]];
    } else if (selectedOpportunity && selectedCampaign) {
      tipsTitle =
        selectedCampaign.type.name_trans[i18next.language] ||
        selectedCampaign.type.name_trans[i18next.options.fallbackLng[0]];
    }

    return (
      <div className={classes.wrapper}>
        <div className={classes.activity}></div>
        <div className={classes.content}>
          {customer && (
            <div>
              <Grid
                container
                direction='row'
                justify='flex-start'
                alignItems='stretch'
                alignContent='stretch'
                spacing={2}
              >
                {/* <Grid item xs={12} sm={6} md={4} lg={3}></Grid> */}
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <ResizableCard
                    expanded={true}
                    expandable={true}
                    collapsable={true}
                    resizable={true}
                    featureTitle={this.props.t(
                      'session.customerdetails.card-title'
                    )}
                    contentId='customer_details'
                    childrenRender={cardCustomerDetailsRender}
                  >
                    <CustomerDetails
                      onRender={(render) =>
                        this.securSetState({
                          cardCustomerDetailsRender: render
                        })
                      }
                    />
                  </ResizableCard>
                  {displayHistory && (
                    <ResizableCard
                      expanded={false}
                      expandable={true}
                      collapsable={true}
                      resizable={true}
                      featureTitle={this.props.t('session.history.card-title')}
                      contentId='customer_history'
                      childrenRender={cardMwHistoryRender}
                    >
                      <CustomerHistory
                        onRender={(render) =>
                          this.securSetState({ cardMwHistoryRender: render })
                        }
                      />
                    </ResizableCard>
                  )}
                  {displayDataCollection && (
                    <ResizableCard
                      expanded={true}
                      expandable={true}
                      collapsable={true}
                      resizable={true}
                      featureTitle={this.props.t(
                        'session.datacollection.card-title'
                      )}
                      contentId='data_collection'
                      childrenRender={cardDataCollectionRender}
                    >
                      <DataCollection
                        onRender={(render) =>
                          this.securSetState({
                            cardDataCollectionRender: render
                          })
                        }
                      />
                    </ResizableCard>
                  )}
                </Grid>
                {!(selectedCampaign || selectedOpportunity) && (
                  <Grid item xs={12} sm={6} md={8} lg={9}>
                    <NoOpportunity />
                  </Grid>
                )}
                <Grid item xs={12} sm={6} md={4} lg={6}>
                  {selectedCampaign && (
                    <SimpleCard>
                      <Campaign />
                    </SimpleCard>
                  )}
                   {displayFAQ && (
                    <SimpleCard 
                      title={this.props.t(
                        'session.data-discovery.card-title'
                      )}>
                      <DataDiscovery />
                    </SimpleCard>
                  )}
                  <SimpleCard>
                    <Offer />
                  </SimpleCard>

                  {/* {selectedOpportunity && (
                    <SimpleCard
                      border={true}
                      clientSettings={this.context.clientSettings}
                    >
                      <Opportunities />
                    </SimpleCard>
                  )} */}
                  {/* {selectedOpportunity && ( */}
                  <SimpleCard>
                    <GamificationContext.Consumer>
                      {(gamificationContext) => (
                        <Feedback gamificationContext={gamificationContext} />
                      )}
                    </GamificationContext.Consumer>
                  </SimpleCard>
                  {/* )} */}
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={3}>
                  <SimpleCard title={this.props.t('session.tips.card-title')}>
                    <Tips />
                  </SimpleCard>

                  {selectedOpportunity?.product && displayProductComparison && (
                    <ResizableCard
                      expanded={true}
                      collapsable={true}
                      resizable={true}
                      featureTitle={this.props.t(
                        'session.comparison.card-title'
                      )}
                      contentId='comparison'
                      childrenRender={cardComparisonRender}
                    >
                      <Comparison
                        onRender={(render) =>
                          this.securSetState({ cardComparisonRender: render })
                        }
                      />
                    </ResizableCard>
                  )}
                  {displayAdditionalProducts && (
                    <ResizableCard
                      expandable={true}
                      collapsable={true}
                      resizable={true}
                      featureTitle={this.props.t(
                        'session.additionalproducts.card-title'
                      )}
                      contentId='additional_products'
                      childrenRender={cardAdditionalProductsRender}
                    >
                      <AdditionalProducts
                        onRender={(render) =>
                          this.securSetState({
                            cardAdditionalProductsRender: render
                          })
                        }
                      />
                    </ResizableCard>
                  )}

                  {displayKnowledgeNavigator && <Navigator />}

                  {displayFAQ && (
                    <SimpleCard title={this.props.t('session.faq.card-title')}>
                      <FAQ />
                    </SimpleCard>
                  )}
                </Grid>
                {/* <Grid item xs={12} sm={12} md={4} lg={3}></Grid> */}
              </Grid>
            </div>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default withTranslation('cco')(withStyles(styles)(CallSession));
