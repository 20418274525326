import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import styles from './Card.module.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

const muiStyles = (theme) => ({
  btRoot: {
    textTransform: 'none',
    backgroundColor: 'transparent !important',
    padding: '0.2rem 0.8rem 0.3rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2.3rem'
  }
});

class Card extends Component {
  renderTitle() {
    const { classes } = this.props;

    return (
      <Grid
        container
        alignItems='flex-end'
        alignContent='space-between'
        className={styles.Title}
      >
        <Grid item className={styles.Icon}>
          <>{this.props.icon}</>
        </Grid>
        <Grid item style={{ flexGrow: 1 }}>
          <span className={styles.Label}>{this.props.title}</span>
        </Grid>
        <Grid item>
          {this.props.onView && (
            <Button
              variant='outlined'
              type='button'
              disableElevation
              disableRipple
              size='small'
              color='primary'
              onClick={() => {
                this.props.onView();
              }}
              classes={{ root: classes.btRoot }}
            >
              {this.props.t('card.view')}
            </Button>
          )}
        </Grid>
      </Grid>
    );
  }

  render() {
    return (
      <>
        <Grid
          container
          spacing={0}
          direction='column'
          style={{ height: '100%' }}
        >
          <Grid item>{this.renderTitle()}</Grid>
          <Grid
            item
            style={{
              backgroundColor: '#f7f7f7',
              flexGrow: 1,
              padding: '2.4rem'
            }}
          >
            {this.props.children}
          </Grid>
        </Grid>
      </>
    );
  }
}

export default withTranslation('gamification')(withStyles(muiStyles)(Card));
