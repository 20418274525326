import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Card from './../components/Card';
import Fade from '@material-ui/core/Fade';
import Loader from './../components/Loader';
import FiltersContext, { FILTERS_KPIS } from './../contexts/FiltersProvider';
import BigDataTable from 'deep/components/materials/BigDataTable';
import SmallDataTable from 'deep/components/materials/SmallDataTable';
import * as Api from './../api/endpoints';

const delay = 500;

class UserAnalysis extends Component {
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      fadeIn: false,
      timer: null,
      kpisFilter: null,
      timelineFilter: null,
      channelFilter: null,
      personas: null,
      total: null
    };
  }

  componentDidUpdate() {
    this.updateState();
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
    this.updateState();
  }
  componentWillUnmount() {
    this.mounted = false;
    clearTimeout(this.state.timer);
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  updateState() {
    if (
      this.context.timelineFilter &&
      this.context.channelFilter &&
      this.context.kpisFilter &&
      (this.context.timelineFilter.id !== this.state.timelineFilter?.id ||
        this.context.channelFilter.id !== this.state.channelFilter?.id ||
        this.context.kpisFilter !== this.state.kpisFilter)
    ) {
      this.securSetState({
        fadeIn: false,
        timelineFilter: this.context.timelineFilter,
        channelFilter: this.context.channelFilter,
        kpisFilter: this.context.kpisFilter
      });
      this.loadStats();
    }
  }

  async loadStats() {
    this.securSetState({
      timer: setTimeout(() => this.securSetState({ fadeIn: true }), delay)
    });
    try {
      const result = await Api.getUserAnalysis(
        this.context.timelineFilter.begin,
        this.context.timelineFilter.end,
        this.context.channelFilter.id,
        this.context.timelineFilter.id
      );
      this.formatResults(result.results);
      this.formatTotal(result.total);
    } catch (error) {
      this.securSetState({
        error: this.props.t(error)
      });
    }
  }

  formatResults(data) {
    if (!data) {
      return false;
    }

    const dataFormatted = [];

    data.forEach((item) => {
      dataFormatted.push({
        id: item.id,
        firstname: item.user?.first_name,
        lastname: item.user?.last_name,
        channel: item.channel?.name_trans['en'],
        logins: item.logins,
        success: item.feedbacks_successful,
        rate: item.ratio ? (item.ratio * 100) + '%' : '-',
        actions: item.feedbacks,
        comparison: item.comparison || '-',
        average: item.average || '-'
      });
    });

    this.securSetState({
      personas: dataFormatted
    });
  }

  formatTotal(data) {
    if (!data) {
      return false;
    }

    const dataFormatted = {
      logins: data.logins,
      success: data.feedbacks_successful,
      rate: data.ratio ? data.ratio : '-',
      actions: data.feedbacks,
      comparison: data.comparison || '-',
      average: data.average || '-'
    };
    this.securSetState({
      total: dataFormatted
    });
  }

  render() {
    const { personas, total } = this.state;

    const columns = [
      {
        id: 'id',
        numeric: false,
        sortable: true,
        label: 'ID',
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap', width: '10%' }
      },
      {
        id: 'firstname',
        numeric: false,
        sortable: true,
        label: 'Firstname',
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap', width: '10%' }
      },
      {
        id: 'lastname',
        numeric: false,
        sortable: true,
        label: 'Lastname',
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap', width: '10%' }
      },
      {
        id: 'channel',
        numeric: false,
        sortable: true,
        label: 'Channel',
        wrap: true,
        customHeadStyle: { whiteSpace: 'nowrap', width: '10%' }
      }
    ];

    if (this.context.kpisFilter) {
      FILTERS_KPIS.forEach((filter) => {
        if (this.context.kpisFilter.indexOf(filter.id) > -1) {
          columns.push({
            id: filter.id,
            numeric: false,
            sortable: true,
            label: filter.label,
            wrap: false,
            customHeadStyle: {
              backgroundColor: '#F6F6F6',
              width: 60 / this.context.kpisFilter.length + '%'
            }
          });
        }
      });
    }

    const columnsTotal = [
      {
        id: 'id',
        numeric: false,
        label: ' ',
        customHeadStyle: { width: '10%', padding: '0.6rem 0.6rem 0.6rem' }
      },
      {
        id: 'firstname',
        numeric: false,
        label: ' ',
        customHeadStyle: { width: '10%', padding: '0.6rem 0.6rem 0.6rem' }
      },
      {
        id: 'lastname',
        numeric: false,
        label: ' ',
        customHeadStyle: { width: '10%', padding: '0.6rem 0.6rem 0.6rem' }
      },
      {
        id: 'total',
        numeric: false,
        label: 'Total',
        customHeadStyle: { width: '10%', padding: '0.6rem 0.6rem 0.6rem' }
      }
    ];
    if (this.context.kpisFilter && total) {
      FILTERS_KPIS.forEach((filter) => {
        if (this.context.kpisFilter.indexOf(filter.id) > -1) {
          columnsTotal.push({
            id: 'id',
            numeric: false,
            label: total[filter.id],
            customHeadStyle: {
              padding: '0.6rem 0.6rem 0.6rem',
              width: 60 / this.context.kpisFilter.length + '%'
            }
          });
        }
      });
    }

    return (
      <>
        <div style={{ margin: '0rem auto', maxWidth: 1400 }}>
          <Card>
            <Fade in={this.state.fadeIn} timeout={{ enter: 500 }}>
              <div style={{ margin: '0rem 2rem' }}>
                {personas && columnsTotal && (
                  <>
                    <BigDataTable
                      data={personas}
                      columns={columns}
                      defaultOrder='desc'
                      defaultOrderBy='id'
                      pagination={true}
                      onSelect={() => {}}
                    />
                    <div
                      style={{
                        marginTop: '3.5rem',
                        border: '1px solid #0ed7e6',
                        paddingTop: '0.4rem'
                      }}
                    >
                      <SmallDataTable
                        data={[]}
                        columns={columnsTotal}
                        highlight={false}
                      />
                    </div>
                  </>
                )}
              </div>
            </Fade>

            {!this.state.fadeIn && <Loader />}
          </Card>
        </div>
      </>
    );
  }
}

export default withTranslation('monitoring')(UserAnalysis);
