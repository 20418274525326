import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './NotificationLevel.module.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const muiStyles = (theme) => ({
  paper: {
    padding: '1.8rem 2rem 3rem',
    borderRadius: '0.6rem',
    textAlign: 'center',
    minWidth: '36rem'
  },
  button: {
    textTransform: 'none',
    fontSize: '1.5rem',
    fontWeight: 600,
    paddingLeft: '4.5rem',
    paddingRight: '4.5rem',
    borderRadius: '2.2rem'
  }
});

class NotificationLevel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true
    };
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }

  handleClose() {
    this.props.onClose();
  }

  getTrans(field) {
    if (!field) {
      return null;
    }
    if (field[i18next.language]) {
      return field[i18next.language];
    } else {
      return field[i18next.options.fallbackLng[0]];
    }
  }

  renderBody() {
    const { data, myKeyInfo } = this.props;

    if (!data || !data.previousLevel || !myKeyInfo) {
      return <div />;
    }

    const nextLevel = myKeyInfo.next_level;
    const previousLevel = data.previousLevel;

    const nextLevelName = this.getTrans(nextLevel.name_trans);
    const nextLevelIcon = nextLevel.image;
    const previousLevelName = this.getTrans(previousLevel.name_trans);
    const mePoints = myKeyInfo.points;

    return (
      <div>
        <div className={styles.Title}>
          {this.props.t('notification.level-title')}
        </div>
        <div>
          <span>{this.props.t('notification.level-congrats')} </span>
          <span style={{ color: previousLevel?.color }}>
            <strong>{previousLevelName}</strong>
          </span>
          ,<br />
          {this.props.t('notification.level-reach')}
          <span style={{ color: nextLevel?.color }}>
            <strong> {nextLevelName}</strong>
          </span>
        </div>
        <img alt='Badge' src={nextLevelIcon} className={styles.Icon} />
        <div className={styles.SummaryContainer}>
          <Grid container justify='space-between' className={styles.Counter}>
            <Grid item>{this.props.t('notification.level-points')}</Grid>
            <Grid item>
              <strong>{mePoints}</strong>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }

  render() {
    const { classes, open, timer } = this.props;

    const handleClose = (event) => {
      this.props.onClose(event);
    };
    const handleConfirm = (event) => {
      this.props.onConfirm(event);
    };

    return (
      <Dialog
        onClose={this.handleClose}
        aria-labelledby='confirmation'
        open={open}
        classes={{ paper: classes.paper }}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
      >
        <div>
          <div className={styles.Timer}>:{timer}</div>
          <div className={styles.Close}>
            <FontAwesomeIcon
              icon={faTimes}
              onClick={(event) => this.handleClose(event)}
            />
          </div>
          {this.renderBody()}
          <div className={styles.BtContainer}>
            <div style={{ marginBottom: '1rem' }}>
              <Button
                fullWidth
                variant='contained'
                color='primary'
                disableElevation
                size='small'
                classes={{ root: classes.button }}
                onClick={handleConfirm}
              >
                {this.props.t('notification.level-bt-new-call')}
              </Button>
            </div>
            <div>
              <Button
                fullWidth
                variant='outlined'
                color='primary'
                disableElevation
                size='small'
                classes={{ root: classes.button }}
                onClick={handleClose}
              >
                {this.props.t('notification.level-bt-back')}
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    );
  }
}

export default withTranslation('gamification')(
  withStyles(muiStyles)(NotificationLevel)
);
