import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import * as MwHistory from 'utils/MwHistory';
import styles from './Access.module.css';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';
import AppContext from 'deep/contexts/AppProvider';
import GamificationContext from 'gamification/contexts/GamificationProvider';

const muiStyles = (theme) => ({
  btRoot: {
    textTransform: 'none',
    backgroundColor: '#fff !important',
    padding: '0.8rem 2.4rem 0.8rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem',
    borderRadius: '2.2rem'
  },
  avatarRoot: {
    height: '9rem',
    width: '9rem',
    border: '1px solid #E4E8EA',
    margin: '0rem auto 1.4rem'
  }
});

class Access extends Component {
  static contextType = GamificationContext;

  constructor(props) {
    super(props);
    this.state = {
      avatar: null,
      avatarId: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    window.scrollTo(0, 0);
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  redirect(event) {
    event.preventDefault();
    MwHistory.push('/cco/game/dashboard');
  }

  renderProfile(meLevelColor, meLevelName) {
    const { classes } = this.props;

    return (
      <AppContext.Consumer>
        {(appContext) => {
          return (
            <>
              <Avatar
                classes={{ root: classes.avatarRoot }}
                src={appContext.me.user.avatar.file}
              />
              <div className={styles.Level}>
                {appContext.me.user.first_name}&nbsp;
                {appContext.me.user.last_name},<br />
                {this.props.t('access.level-1')}&nbsp;
                <span style={{ color: meLevelColor, fontWeight: 600 }}>
                  {meLevelName}
                </span>
                &nbsp;
                {this.props.t('access.level-2')}
              </div>
            </>
          );
        }}
      </AppContext.Consumer>
    );
  }

  render() {
    const { classes } = this.props;
    const { myKeyInfo } = this.context;

    let mePoints = '-';
    let meRank = '-';
    let meBadgesCount = '-';
    let meLevelName = '-';
    let meLevelColor = null;
    if (myKeyInfo) {
      mePoints = myKeyInfo.points;
      meRank = myKeyInfo.rank;
      meBadgesCount = myKeyInfo.badges;
      meLevelName =
        myKeyInfo.current_level.name_trans[i18next.language] ||
        myKeyInfo.current_level.name_trans[i18next.options.fallbackLng[0]];
      meLevelColor = myKeyInfo.current_level.color;
    }

    return (
      <div>
        <div className={styles.FeedbackTitle}>
          <Grid container alignItems='center'>
            <Grid item>{this.props.t('access.title')}</Grid>
            <Grid item style={{ flexGrow: 1, textAlign: 'right' }}>
              <Button
                variant='outlined'
                type='button'
                disableElevation
                disableRipple
                size='small'
                color='primary'
                onClick={(event) => {
                  this.redirect(event);
                }}
                classes={{ root: classes.btRoot }}
              >
                {this.props.t('access.view')}
              </Button>
            </Grid>
          </Grid>
        </div>
        <Paper elevation={6} className={styles.FeedbackCard}>
          {this.renderProfile(meLevelColor, meLevelName)}
          <div className={styles.KeyInfo}>
            <Grid container wrap='nowrap'>
              <Grid item className={styles.Key}>
                <span className={styles.Value}>{mePoints}</span>
                <br />
                <span className={styles.Label}>
                  {this.props.t('access.points')}
                </span>
              </Grid>
              <Grid item className={styles.Key}>
                <span className={styles.Value}>{meRank}</span>
                <br />
                <span className={styles.Label}>
                  {this.props.t('access.rank')}
                </span>
              </Grid>
              <Grid item className={styles.Key}>
                <span className={styles.Value}>{meBadgesCount}</span>
                <br />
                <span className={styles.Label}>
                  {this.props.t('access.badges')}
                </span>
              </Grid>
            </Grid>
          </div>
        </Paper>
      </div>
    );
  }
}

export default withTranslation('gamification')(withStyles(muiStyles)(Access));
