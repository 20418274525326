import React from 'react';
import DOMPurify from 'dompurify';

export const prettifyScript = (data, seperator) => {
    let dataList = []
    if (!data){
        return dataList;
    }

    if (seperator){
        dataList = data.split(seperator)
    } else {
        dataList.push(data)
    }
    const formatted = dataList.map((dirty, key) => {

        const clean = DOMPurify.sanitize( dirty , {USE_PROFILES: {html: true}} );
        return <div key={key} dangerouslySetInnerHTML={{ __html: clean }}></div>
    })
    return formatted
}

export const DBScriptSep = null;
export const TransScriptSep = "\n";
