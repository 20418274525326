import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import DataDiscoveryList from './DataDiscoveryList';
import CallSessionContext from './../../contexts/CallSessionProvider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import PersonIcon from '@material-ui/icons/Person';

import SimpleTabsMenu from 'deep/components/materials/SimpleTabsMenu';
import { prettifyScript, DBScriptSep} from './utils';

import i18next from 'i18next';


const formatLang = (transItem) => {
  if (!transItem){
    return
  }

  return transItem[i18next.language] || transItem[i18next.options.fallbackLng[0]]
}


class DataDiscovery extends Component {
  static contextType = CallSessionContext;

  state = {
    segment: null
  }

  componentDidUpdate() {
    if (!this.rendered && this.context.collections) {
      const { 
        dataDiscovery,
        setUserDiscoveryAnswers,
      } = this.context;
      this.rendered = true;
      dataDiscovery && setUserDiscoveryAnswers();

    }
  }

  render() {
    const styles = {
      gridItem: {
        minHeight: '7rem'
      },
      gridItemGrey: {
        minHeight: '7rem',
        backgroundColor: '#FBFBFB',
        padding: '0.5em'
      },
      label: {
        color: '#4C4C4C',
        fontWeight: 600,
        paddingTop: '0.1rem',
        fontSize: '1.8rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      question: {
          margin: '0.2em',  
          display: 'inline-block'        
      },
      chip: {
        display: 'inline-block',
        padding: '0.2em',
        fontSize: '12px',
        borderRadius: '5px',
        backgroundColor: 'rgba(0, 140, 255, 1)',
        display: 'inline-block',
        color: 'white'
      }
    };

    const { 
      dataDiscovery,
      dataDiscoveryAnswers,
      setNewDiscoveryAnswers,
      discoverySegment
    } = this.context;
    const segment = discoverySegment && formatLang(discoverySegment.name_trans);
    const segmentScript = discoverySegment && formatLang(discoverySegment.segment_script_trans);

    return (
      <div style={{marginBottom: '0.5em'}}>
        <Grid
          container
          spacing={0}
          alignItems='center'
          alignContent='flex-start'
        >
          {dataDiscovery && Object.keys(dataDiscovery).map((questionNumber) => {
                const { question,  possible_answers } = dataDiscovery[questionNumber];
                return(
                    <Grid item xs={12} lg={12} style={styles.gridItem} key={questionNumber}>
                           {question && (
                           <div>
                            <div style={styles.chip}>Q{parseInt(questionNumber) + 1} </div> 
                              <Typography variant="subtitle2" gutterBottom style={styles.question}>
                              {`${formatLang(question.name_trans)}`}
                              </Typography>
                           </div>)}
                        <DataDiscoveryList 
                            question={question}
                            possibleAnswers={possible_answers}
                            questionNumber={questionNumber}
                            setNewDiscoveryAnswers={setNewDiscoveryAnswers}
                            dataDiscoveryAnswers={dataDiscoveryAnswers}

                        />
    
                    </Grid>
                )
            })
          }

            {segment && <Grid item xs={12} lg={12} style={styles.gridItemGrey}>
             <PersonIcon style={{fill: "rgba(0, 140, 255, 1)", display: "inline-block", transform: 'translateY(0.2em)'}} />
              <Box 
                style={{ display: "inline-block", marginTop: '0.5em', marginBottom: '1em'}} 
                fontWeight="fontWeightBold"
                fontSize={17}
              >
                { `${this.props.t('session.data-discovery.archetype')}: ${segment}`}
              </Box>  <br/>
              <SimpleTabsMenu
                tabs={[
                  {
                    id: 1,
                    name: this.props.t('session.scripts.archetype-script-header'),
                    value: 'segmentScipt'
                  }
                ]}
                defaultValue={0}
                onChange={() => null}
              />
              <div className={styles.Script}>
                {segmentScript && (
                  <div >
                    {prettifyScript(segmentScript, DBScriptSep)}
                  </div>
                )}          
              </div>
            </Grid>}
      
        </Grid>
      </div>
    );
  }
}

export default withTranslation('cco')(DataDiscovery);
