import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import CampaignsList from './CampaignsList';
import CallSessionContext from './../../contexts/CallSessionProvider';
import Paper from '@material-ui/core/Paper';
import SimpleTabsMenu from 'deep/components/materials/SimpleTabsMenu';
import { prettifyScript, TransScriptSep } from './utils';


class Campaign extends Component {
  static contextType = CallSessionContext;


  renderScript = () => {
    const { me, customer, clientSettings } =  this.context;
    let script = this.props.t('session.scripts.intro-script')
    script = script.replaceAll('{title}', customer?.firstname);
    script = script.replaceAll('{lastname}', customer?.lastname);
    script = script.replaceAll('{me}', me?.nickname);
    script = script.replaceAll('{clientname}', clientSettings?.name);
   
    return  prettifyScript(script, TransScriptSep)

  } 

  render() {
    const { campaigns } = this.context;

    const styles = {
      gridItem: {
        minHeight: '4.9rem'
      },
      label: {
        color: '#4C4C4C',
        fontWeight: 600,
        paddingTop: '0.1rem',
        fontSize: '1.8rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      },
      paper: {
        borderRadius: '1.8rem',
        padding: '0rem 1rem 0rem'
      },
      wrapper: {
        position: 'absolute',
        zIndex: 10,
        top: '-0.4rem',
        border: '1px solid #bcbcbc',
        borderRadius: '1.8rem',
        width: '100%'
      },
      selectedItem: {
        position: "relative",
        cursor: "default",
        padding: "0.7rem 0rem 1.7rem 0rem",
        picto: {
          position: "absolute",
          top: "40%",
          transform: "translateY(-50%)",
        },
        label: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        },
        arrow: {
          position: "absolute",
          fontSize: "2rem",
          top: "0.8rem",
          right: "-0.3rem",
        },
      }
    };

    return (
      <div>
        <Grid
          container
          spacing={2}
          alignItems='center'
          alignContent='flex-start'
        >
          <Grid item xs={4} lg={3} style={styles.gridItem}>
            <div style={styles.label}>
              {this.props.t('session.campaign.campaign-title')}
            </div>
          </Grid>
          <Grid item xs={8} lg={9} style={styles.gridItem}>
            {campaigns && campaigns.length > 0 && <CampaignsList />}
            
          </Grid>
          <Grid item xs={12} lg={12} style={styles.gridItem}>
              <SimpleTabsMenu
                tabs={[
                  {
                    id: 1,
                    name: 'Intro Script',
                    value: this.props.t('session.scripts.intro-script-header')
                  }
                ]}
                defaultValue={0}
                onChange={() => null}
              />
              <div>

                {this.renderScript()}
              
              </div>
            </Grid>
      
        </Grid>
      </div>
    );
  }
}

export default withTranslation('cco')(Campaign);
