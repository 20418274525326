import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import ReportProblemRoundedIcon from '@material-ui/icons/ReportProblemRounded';
import SubmitButton from 'deep/components/materials/SubmitButton';
import SimpleCard from 'deep/components/materials/SimpleCard';
import * as MwHistory from 'utils/MwHistory';

class NoOpportunity extends Component {
  constructor(props) {
    super(props);
    this.endSession = this.endSession.bind(this);
  }
  endSession() {
    MwHistory.replace('/cco');
  }
  render() {
    const styles = {
      wrapper: { height: '100%', paddingBottom: 'calc( 1.9rem - 4px)' },
      card: { height: '100%' },
      iconWrapper: {
        position: 'absolute',
        margin: 'auto',
        inset: 0,
        textAlign: 'center',
        width: '50vw',
        height: '100px'
      },
      icon: { fontSize: 80 },
      buttonWrapper: {
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        display: 'flex',
        height: '100%'
      }
    };
    return (
      <div style={styles.wrapper}>
        <SimpleCard border='true' style={styles.card}>
          <div style={styles.iconWrapper}>
            <ReportProblemRoundedIcon color='disabled' style={styles.icon} />
            <p>{this.props.t('session.no-opportunity')}</p>
          </div>
          <div style={styles.buttonWrapper}>
            <SubmitButton
              onClick={this.endSession}
              size='small'
              label={this.props.t('session.feedback.bt-end-session')}
              variant='outlined'
              color='secondary'
            />
          </div>
        </SimpleCard>
      </div>
    );
  }
}

export default withTranslation('cco')(NoOpportunity);
