import React from 'react';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';

export default function SmartTextarea(props) {
  const styles = {
    textarea: {
      width: '100%',
      maxWidth: '100%',
      resize: 'vertical',
      padding: '1rem 1.4rem',
      border: '1px solid #E6E9EE',
      borderRadius: '0.5rem',
      fontSize: '1.2rem',
      color: '#3A404D',
      minHeight: '9rem',
      fontFamily: "'Open Sans', sans-serif"
    },
    counter: {
      textAlign: 'right',
      fontSize: '1.5rem',
      color: '#b4b3b3',
      position: 'absolute',
      top: '-3rem',
      right: 0
    }
  };

  const maxLength = props.maxLength;
  const [numChars, setNumChars] = React.useState(0);
  const handleOnChange = (event) => {
    event.preventDefault();
    setNumChars(event.target.value.length);
    props.onChange(event.target.value);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div style={styles.counter}>
        {numChars} / {maxLength}
      </div>
      <TextareaAutosize
        style={styles.textarea}
        onChange={handleOnChange}
        maxLength={maxLength}
        disabled={props.disabled}
        value={props.value || ''}
      />
    </div>
  );
}
