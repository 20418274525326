import React, { Component } from 'react';
import { Router, Switch, Route, Redirect } from 'react-router-dom';
import History from './History';
import * as Tenancy from 'utils/Tenancy';
import NotFound from '../deep/pages/NotFound';
import Forbidden from '../deep/pages/Forbidden';
import Login from '../deep/pages/Login';
import CallSession from '../modules/cco/pages/CallSession';
import HomeAgent from '../modules/cco/pages/HomeAgent';
import GamificationDashboard from '../gamification/pages/GamificationDashboard';
import GamificationBadges from '../gamification/pages/GamificationBadges';
import GamificationRules from '../gamification/pages/GamificationRules';
import GamificationLevels from '../gamification/pages/GamificationLevels';
import UsersMgmt from '../deep/pages/UsersMgmt';
import OverallPerformance from '../modules/monitoring/analysis/pages/OverallPerformance';
import TimeEvolution from '../modules/monitoring/analysis/pages/TimeEvolution';
import FeedbackAnalysis from '../modules/monitoring/analysis/pages/FeedbackAnalysis';
import InteractionOutcome from '../modules/monitoring/analysis/pages/InteractionOutcome';
import UserAnalysis from '../modules/monitoring/analysis/pages/UserAnalysis';
import NotificationsRules from '../modules/monitoring/notifications/pages/NotificationsRules';
import NotificationsRuleEdit from '../modules/monitoring/notifications/pages/NotificationsRuleEdit';
import AppContext from 'deep/contexts/AppProvider';
import { LoginCallback } from '@okta/okta-react';
import OktaLogin from '../deep/pages/OktaLogin';
import OktaHomeCallback from '../deep/pages/OktaHomeCallback';

const GuardedRoute = ({
  component: Component,
  user,
  options = [],
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        options.includes(user?.type?.code) ? (
          <Component {...props} />
        ) : (
          <Redirect to='/' />
        )
      }
    />
  );
};

export default class Routes extends Component {
  static contextType = AppContext;

  render() {
    const isMonoTenant = Tenancy.isMonoTenant();
    const tenant = Tenancy.getTenant();
    const { anonymMonitoring } = this.context.clientSettings;
    const MONITORING_PROFILES = ['admin', 'manager'];
    if (isMonoTenant) {
      return (
        <Router history={History}>
          <Switch>
            <Route path='/okta/callback' component={LoginCallback} />
            <Route path='/okta/callback-home' component={OktaHomeCallback} />
            <Route path='/okta/login' component={OktaLogin} />

            <Redirect path='/' exact to={'/login'} />

            <Route path='/login' component={Login} />
            <Route path='/admin' exact component={UsersMgmt} />
            <Route path='/cco' exact component={HomeAgent} />

            <Route
              path='/engie/cco/session/:customerId'
              exact
              render={(matchProps) => <CallSession {...matchProps} />}
            />

            <Route
              path='/cco/session/:customerId'
              exact
              render={(matchProps) => <CallSession {...matchProps} />}
            />
            <Route
              path='/cco/game/dashboard'
              exact
              component={GamificationDashboard}
            />
            <Route
              path='/cco/game/badges'
              exact
              component={GamificationBadges}
            />
            <Route path='/cco/game/rules' exact component={GamificationRules} />
            <Route
              path='/cco/game/levels'
              exact
              component={GamificationLevels}
            />
            <Route path='/404' exact component={NotFound} />
            <Route path='/403' exact component={Forbidden} />

            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/monitoring/analysis/overall-performance'
              exact
              component={OverallPerformance}
            />
            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/monitoring/analysis/time-evolution'
              exact
              component={TimeEvolution}
            />
            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/monitoring/analysis/feedback-analysis'
              exact
              component={FeedbackAnalysis}
            />
            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/monitoring/analysis/interaction-outcome'
              exact
              component={InteractionOutcome}
            />
            {!anonymMonitoring && (
              <GuardedRoute
                user={this.context.me}
                options={MONITORING_PROFILES}
                path='/monitoring/analysis/user-analysis'
                exact
                component={UserAnalysis}
              />
            )}
            <Redirect
              path='/monitoring/analysis'
              to={'/monitoring/analysis/overall-performance'}
            />

            <Redirect to={'/404'} />
          </Switch>
        </Router>
      );
    } else {
      return (
        <Router history={History}>
          <Switch>
            <Route path='/okta/callback' component={LoginCallback} />
            <Route path='/okta/callback-home' component={OktaHomeCallback} />
            <Route path='/okta/login' component={OktaLogin} />

            <Redirect path='/login' to={'/' + tenant + '/login'} />
            <Redirect path='/' exact to={'/' + tenant + '/login'} />

            <Route path='/:tenant/login' component={Login} />
            <Route path='/:tenant/admin' exact component={UsersMgmt} />
            <Route path='/:tenant/cco' exact component={HomeAgent} />

            <Route
              path='/:tenant/cco/session/:customerId'
              exact
              //render={(matchProps) => <CallSession {...matchProps} />}

              render={(matchProps2) => {
                return <CallSession {...matchProps2} />;
              }}
            />

            <Route
              path='/:tenant/cco/game/dashboard'
              exact
              component={GamificationDashboard}
            />
            <Route
              path='/:tenant/cco/game/badges'
              exact
              component={GamificationBadges}
            />
            <Route
              path='/:tenant/cco/game/rules'
              exact
              component={GamificationRules}
            />
            <Route
              path='/:tenant/cco/game/levels'
              exact
              component={GamificationLevels}
            />
            <Route path='/:tenant/404' exact component={NotFound} />
            <Route path='/:tenant/403' exact component={Forbidden} />

            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/:tenant/monitoring/analysis/overall-performance'
              exact
              component={OverallPerformance}
            />
            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/:tenant/monitoring/analysis/time-evolution'
              exact
              component={TimeEvolution}
            />
            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/:tenant/monitoring/analysis/feedback-analysis'
              exact
              component={FeedbackAnalysis}
            />
            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/:tenant/monitoring/analysis/interaction-outcome'
              exact
              component={InteractionOutcome}
            />
            {!anonymMonitoring && (
              <GuardedRoute
                user={this.context.me}
                options={MONITORING_PROFILES}
                path='/:tenant/monitoring/analysis/user-analysis'
                exact
                component={UserAnalysis}
              />
            )}
            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/:tenant/monitoring/notifications/rules'
              exact
              component={NotificationsRules}
            />
            <GuardedRoute
              user={this.context.me}
              options={MONITORING_PROFILES}
              path='/:tenant/monitoring/notifications/rule'
              exact
              component={NotificationsRuleEdit}
            />
            <Redirect
              path='/:tenant/monitoring/analysis'
              to={'/' + tenant + '/monitoring/analysis/overall-performance'}
            />

            <Redirect to={'/' + tenant + '/404'} />
          </Switch>
        </Router>
      );
    }
  }
}
