import buddy_information from './../assets/buddy_information.svg';
import buddy_guide from './../assets/buddy_guide.svg';
import buddy_motivate from './../assets/buddy_motivate.svg';

const BuddiesSrc = {
  information: buddy_information,
  guide: buddy_guide,
  motivate: buddy_motivate
};

function formatTrans(trans) {
  if (trans) {
    let labels = {};
    trans.forEach((lbl) => {
      labels[lbl.lang] = lbl.text;
    });
    return labels;
  } else {
    return null;
  }
}

export function category(data) {
  if (!data) {
    return null;
  }
  let img = new Image();
  img.src = BuddiesSrc[data.code];
  let dataTranslated = {
    ...data,
    buddy: BuddiesSrc[data.code],
    name_trans: formatTrans(data.name_trans?.items)
  };
  return dataTranslated;
}

export function notification(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    category: category(data.category),
    message_trans: formatTrans(data.message_trans?.items)
  };
  return dataTranslated;
}
