import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import History from 'utils/History';
import * as MwHistory from 'utils/MwHistory';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import FilterOptionsList from './../../components/FilterOptionsList';
import FilterCheckList from './../../components/FilterCheckList';
import AutocompleteCheckList from './../../components/AutocompleteCheckList';
import DownloadList from './DownloadList';
import FiltersContext, {
  FILTERS_TIMELINE,
  FILTERS_KPIS,
  FILTERS_STATUS
} from './../contexts/FiltersProvider';

const muiStyles = (theme) => ({
  btRoot: {
    color: '#3A404D',
    textTransform: 'none',
    padding: '0.8rem 2.4rem 0.8rem 1.6rem',
    fontSize: '1.5rem',
    fontWeight: 400,
    lineHeight: '2rem',
    borderRadius: '2.2rem',
    marginRight: '1.6rem',
    boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.19)',
    border: '1px solid #13AE57',
    backgroundColor: '#fff',
    '&:hover': {
      backgroundColor: '#f2f2f2',
      boxShadow: 'inset 0rem 0.1rem 0.3rem 0rem rgba(0,0,0,0.19)'
    }
  },
  filters: {
    position: 'fixed',
    top: '1.4rem',
    right: '1rem',
    zIndex: 1202
  }
});

class Filters extends Component {
  static contextType = FiltersContext;

  constructor(props) {
    super(props);
    this.state = {
      pathname: History.location.pathname
    };
  }

  UNSAFE_componentWillMount() {
    History.listen((location) => {
      this.securSetState({ pathname: location.pathname });
    });
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  handleCreateRule(event) {
    event.stopPropagation();
    MwHistory.push('/monitoring/notifications/rule');
  }

  renderBtCreateRule() {
    const { classes } = this.props;

    return (
      <Button
        variant='outlined'
        type='button'
        disableElevation
        disableRipple
        size='small'
        classes={{
          root: classes.btRoot,
          label: classes.btText
        }}
        onClick={(event) => {
          this.handleCreateRule(event);
        }}
      >
        <FontAwesomeIcon
          icon={faPlusCircle}
          style={{
            color: '#13AE57',
            marginRight: '1rem'
          }}
        />
        Create Rule
      </Button>
    );
  }

  render() {
    const { classes } = this.props;
    const { pathname } = this.state;
    const {
      outcomes,
      products,
      channels,
      campaignTypes,
      multiProductsFilter,
      multiCampaignTypesFilter,
      multiChannelsFilter,
      singleCampaignTypeFilter,
      kpisFilter,
      setOutcomeFilter,
      setMultiProductsFilter,
      setSingleProductFilter,
      setChannelFilter,
      setMultiChannelsFilter,
      setMultiCampaignTypesFilter,
      setSingleCampaignTypeFilter,
      setTimelineFilter,
      setKpisFilter,
      setStatusFilter
    } = this.context;
    let isFilters,
      isTimelineFilter,
      isChannelFilter,
      isOutcomeFilter,
      isKpisFilter,
      isStatusFilter = false;
    let isMultiProductsFilter,
      isMultiChannelFilter,
      isSingleProductFilter = false;
    let isMultiCampaignTypesFilter,
      isSingleCampaignTypesFilter = false;
    let isBtDownload,
      isBtCreateRule = false;

    if (pathname.indexOf('/overall-performance') > -1) {
      isFilters = isTimelineFilter = isMultiChannelFilter = true;
      // isFilters = isTimelineFilter = isChannelFilter = isOutcomeFilter = isMultiCampaignTypesFilter = isBtDownload = true;
    } else if (pathname.indexOf('/feedback-analysis') > -1) {
      // isFilters = isMultiProductsFilter = isTimelineFilter = isChannelFilter = isSingleCampaignTypesFilter = isBtDownload = true;
      isFilters = isTimelineFilter = isMultiChannelFilter = isMultiCampaignTypesFilter = true;
    } else if (pathname.indexOf('/interaction-outcome') > -1) {
      // isFilters = isSingleProductFilter = isTimelineFilter = isChannelFilter = isSingleCampaignTypesFilter = isBtDownload = true;
      isFilters = isMultiProductsFilter = isTimelineFilter = isMultiChannelFilter = isMultiCampaignTypesFilter = true;
    } else if (pathname.indexOf('/user-analysis') > -1) {
      // isFilters = isTimelineFilter = isChannelFilter = isKpisFilter = isBtDownload = true;
      isFilters = isTimelineFilter = isMultiChannelFilter = isKpisFilter = true;
    } else if (pathname.indexOf('/notifications/rules') > -1) {
      isBtCreateRule = true;
      isStatusFilter = true;
    }

    let filtersTimeline = [];
    for (let key in FILTERS_TIMELINE) {
      filtersTimeline.push(FILTERS_TIMELINE[key]);
    }

    let filtersStatus = [];
    for (let key in FILTERS_STATUS) {
      filtersStatus.push(FILTERS_STATUS[key]);
    }

    let isMultiProductsFilterDisabled = false;
    if (
      isSingleCampaignTypesFilter &&
      (singleCampaignTypeFilter?.id === 1 || singleCampaignTypeFilter?.id === 2)
    ) {
      isMultiProductsFilterDisabled = true;
    }

    return (
      <div className={classes.filters}>
        <Grid container spacing={0} wrap='nowrap'>
          {isFilters && (
            <Grid item>
              <div style={{ fontWeight: 600, paddingTop: 9, paddingRight: 14 }}>
                Filter:
              </div>
            </Grid>
          )}
          <Grid item style={{ display: isTimelineFilter ? 'block' : 'none' }}>
            <FilterOptionsList
              items={filtersTimeline.map((f) => ({
                ...f,
                label: this.props.t(f.label)
              }))}
              onSelect={(value) => setTimelineFilter(value)}
            />
          </Grid>
          <Grid item style={{ display: 'none' }}>
            <FilterOptionsList
              items={channels}
              onSelect={(value) => setChannelFilter(value)}
            />
          </Grid>
          <Grid
            item
            style={{ display: 'none' }}
          >
            <AutocompleteCheckList
              title='Channels'
              disabled={false}
              value={multiChannelsFilter}
              items={channels}
              onChange={(values) => setMultiChannelsFilter(values)}
            />
          </Grid>
          <Grid
            item
            style={{ display: isSingleCampaignTypesFilter ? 'block' : 'none' }}
          >
            <FilterOptionsList
              items={campaignTypes}
              onSelect={(value) => setSingleCampaignTypeFilter(value)}
            />
          </Grid>
          <Grid
            item
            style={{ display: isMultiCampaignTypesFilter ? 'block' : 'none' }}
          >
            <FilterCheckList
              title='Camp. Types'
              disabled={false}
              defaultSelectedIds={multiCampaignTypesFilter}
              items={campaignTypes}
              onChange={(values) => setMultiCampaignTypesFilter(values)}
            />
          </Grid>
          <Grid item style={{ display: isOutcomeFilter ? 'block' : 'none' }}>
            <FilterOptionsList
              items={outcomes}
              onSelect={(value) => setOutcomeFilter(value)}
            />
          </Grid>
          <Grid
            item
            style={{
              display:
                isSingleProductFilter && !isMultiProductsFilterDisabled
                  ? 'block'
                  : 'none'
            }}
          >
            <FilterOptionsList
              items={products}
              onSelect={(value) => setSingleProductFilter(value)}
            />
          </Grid>
          <Grid
            item
            style={{
              display:
                isMultiProductsFilter || isMultiProductsFilterDisabled
                  ? 'block'
                  : 'none'
            }}
          >
            <FilterCheckList
              title='Products'
              disabled={isMultiProductsFilterDisabled}
              defaultSelectedIds={multiProductsFilter}
              items={products}
              onChange={(values) => setMultiProductsFilter(values)}
            />
          </Grid>
          <Grid item style={{ display: isKpisFilter ? 'block' : 'none' }}>
            <FilterCheckList
              title='KPIs'
              disabled={false}
              defaultSelectedIds={kpisFilter}
              items={FILTERS_KPIS}
              onChange={(values) => setKpisFilter(values)}
            />
          </Grid>
          <Grid item style={{ display: isStatusFilter ? 'block' : 'none' }}>
            <FilterOptionsList
              items={filtersStatus}
              onSelect={(value) => setStatusFilter(value)}
            />
          </Grid>
          <Grid item>
            {isBtDownload && <DownloadList />}
            {isBtCreateRule && this.renderBtCreateRule()}
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default withTranslation('monitoring')(withStyles(muiStyles)(Filters));
