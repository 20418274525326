import { getRequest, postRequest, patchRequest } from 'deep/api/utils';
import * as FormatData from './formatdata';
import * as MwHistory from 'utils/MwHistory';
import { STORAGE_VARS } from 'utils/Constants';

export function getCustomer(customerId) {
  const endpoint = 'cco/customer/' + customerId;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-404');
            break;
        }
      });
  });
}

export function searchCustomer(keyword, limit = 10) {
  if (keyword.length < 5) return null

  const endpoint = 'cco/customer/search?search=' + keyword + '&limit=' + limit;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        resolve(result.results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-404');
            break;
        }
      });
  });
}

export function getCustomerPersonalAttributes(customerId, campaignTypeId, contractID) {
  let endpoint =
    'cco/customer_value?customer=' +
    customerId +
    '&attribute__category__code=profile&attribute__customerattributevisibility__visible=true';
  if (campaignTypeId) {
    endpoint +=
      '&attribute__customerattributevisibility__campaign_type=' +
      campaignTypeId;
  }
  if(contractID) {
    endpoint +=
      '&contract=' +
      contractID;
  }
  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        let results = [];
        if (result.results.length > 0) {
          results = FormatData.customerAttributes(result.results);
        }
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getCustomerContracts(customerId) {
  const endpoint = 'cco/contract?customer=' + customerId + '&ordering=-started';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.contracts(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getCustomerInteractionHistory(customerId) {
  const endpoint =
    'cco/customer_value?customer=' +
    customerId +
    '&attribute__category__code=history';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        let results = [];
        if (result.results.length > 0) {
          results = FormatData.customerAttributes(result.results);
        }
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getRelationship(customerId) {
  const endpoint =
    'cco/interaction?customer=' + customerId + '&ordering=-date,-id';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.interactions(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getCustomerBill(customerId) {
  const endpoint = 'cco/bill?customer__id=' + customerId;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        resolve(result.results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getCustomerCampaigns(customerId) {
  const now = new Date();
  const nowFormat =
    now.getFullYear() +
    '-' +
    ('0' + (now.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + now.getDate()).slice(-2);
  const endpoint =
    'cco/campaign?opportunity__customer=' +
    customerId +
    '&expiration_date__gte=' +
    nowFormat;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.campaigns(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getCustomerOpportunities(customerId, campaignId) {
  const endpoint =
    'cco/opportunity/customer?customer=' +
    customerId +
    '&campaign=' +
    campaignId;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.opportunities(result);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getOpportunityTips(opportunityId) {
  const endpoint = 'cco/datadriventip?opportunity=' + opportunityId;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.dataDrivenTips(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getOutcomes() {
  let endpoint = 'cco/feedbackoutcome';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.outcomes(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getReasons(campaignTypeId, productId) {
  let endpoint = 'cco/feedbackreason?campaign_type=' + campaignTypeId;
  if (productId) {
    endpoint += '&product=' + productId;
  }

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.reasons(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getProducts() {
  const endpoint = 'cco/product?parent__isnull=true&purchasable=true';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.products(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getProductsFeatures(product1, product2) {
  let endpoint = 'cco/productfeature?products__in=' + product1;
  if (product2) {
    endpoint += ',' + product2;
  }

  return new Promise(function (resolve, reject) {
    //const results = FormatData.productsfeatures(productfeature.results);
    //resolve(results);

    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.productsfeatures(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function postFeedback(
  opportunityId,
  offerId,
  started,
  ended,
  customerId,
  comment,
  outcome,
  reason,
  contractAccountId,
  offerComment
) {
  const endpoint = 'cco/feedback';

  return new Promise(function (resolve, reject) {
    const data = {
      opportunity: opportunityId,
      offer: offerId,
      started: started,
      ended: ended,
      customer: customerId,
      comment: comment,
      outcome: outcome,
      reason: reason,
      contract_account_id: contractAccountId,
      offer_comment: offerId === null ? offerComment: null,
    };
    if (!reason) delete data.reason;
    if (!comment) delete data.comment;

    postRequest(endpoint, data)
      .then((result) => {
        //DEMO PURPOSE - BEGIN
        let key = null;
        switch (outcome) {
          case 0:
            key = STORAGE_VARS.DEMO_CALL_NOTOFFERED;
            break;
          case 1:
            key = STORAGE_VARS.DEMO_CALL_ACCEPTED;
            break;
          case 2:
            key = STORAGE_VARS.DEMO_CALL_REJECTED;
            break;
          default:
        }
        if (key) {
          let list = localStorage.getItem(key)
            ? JSON.parse(localStorage.getItem(key))
            : [];
          list.push(result.id);
          localStorage.setItem(key, JSON.stringify(list));
        }
        //DEMO PURPOSE - END

        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function patchFeedback(feedbackId, ended, comment, outcome, reason, offerId, offerComment) {
  const endpoint = 'cco/feedback/' + feedbackId;

  return new Promise(function (resolve, reject) {
    const data = {
      ended: ended,
      comment: comment ? comment : '',
      outcome: outcome,
      reason: reason,
      offer: offerId,
      offer_comment: offerId === null ? offerComment: null
    };

    patchRequest(endpoint, data)
      .then((result) => {
        //DEMO PURPOSE
        let key = null;
        switch (outcome) {
          case 0:
            key = STORAGE_VARS.DEMO_CALL_NOTOFFERED;
            break;
          case 1:
            key = STORAGE_VARS.DEMO_CALL_ACCEPTED;
            break;
          case 2:
            key = STORAGE_VARS.DEMO_CALL_REJECTED;
            break;
          default:
        }
        if (key) {
          let list0 = localStorage.getItem(STORAGE_VARS.DEMO_CALL_NOTOFFERED)
            ? JSON.parse(
                localStorage.getItem(STORAGE_VARS.DEMO_CALL_NOTOFFERED)
              )
            : [];
          let list1 = localStorage.getItem(STORAGE_VARS.DEMO_CALL_ACCEPTED)
            ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_ACCEPTED))
            : [];
          let list2 = localStorage.getItem(STORAGE_VARS.DEMO_CALL_REJECTED)
            ? JSON.parse(localStorage.getItem(STORAGE_VARS.DEMO_CALL_REJECTED))
            : [];
          const index0 = list0.indexOf(feedbackId);
          const index1 = list1.indexOf(feedbackId);
          const index2 = list2.indexOf(feedbackId);
          if (index0 > -1) {
            list0.splice(index0, 1);
            localStorage.setItem(
              STORAGE_VARS.DEMO_CALL_NOTOFFERED,
              JSON.stringify(list0)
            );
          } else if (index1 > -1) {
            list1.splice(index1, 1);
            localStorage.setItem(
              STORAGE_VARS.DEMO_CALL_ACCEPTED,
              JSON.stringify(list1)
            );
          } else if (index2 > -1) {
            list2.splice(index2, 1);
            localStorage.setItem(
              STORAGE_VARS.DEMO_CALL_REJECTED,
              JSON.stringify(list2)
            );
          }
          let list = localStorage.getItem(key)
            ? JSON.parse(localStorage.getItem(key))
            : [];
          list.push(feedbackId);
          localStorage.setItem(key, JSON.stringify(list));
        }

        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getAgentFeedbacks(personaId) {
  let limit = 3;
  const endpoint =
    'cco/feedback?agent=' + personaId + '&ordering=-started&limit=' + limit;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.feedbacks(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 404:
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getContractAccountFeedback(account_id) {
  const endpoint = 'cco/feedback/' + account_id + '/contract_account';

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const r = FormatData.singleFeedback(result);
        resolve(r);
      })
      .catch((error) => {
        switch (error.status) {
          case 404:
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getProactiveOpportunities(limit = 1000) {
  const endpoint = 'cco/opportunity/proactive?limit=' + limit;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.proactiveOpportunities(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function getCollectionForCustomer(customerId) {
  const endpoint = `cco/customer/${customerId}/data_collection`;
  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.dataCollection(result);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function postCollection(customer, choice) {
  const endpoint = 'cco/customer_collections_value';
  return new Promise(function (resolve, reject) {
    const data = {
      choice: choice,
      customer: customer.id
    };

    postRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getQuarantines(customerId) {
  const endpoint = `cco/quarantine`;
  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.quarantine(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.customer-data-unavailable');
            break;
        }
      });
  });
}

export function postQuarantine(customer, quarantineId) {
  const endpoint = 'cco/customer_quarantine';
  return new Promise(function (resolve, reject) {
    const data = {
      customer: customer.id,
      quarantine: quarantineId
    };

    postRequest(endpoint, data)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getFAQs() {
  const endpoint = `cco/faq`;
  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.faq(result.results);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getDataDiscovery(customerId) {
  const endpoint = `cco/customer/${customerId}/data_discovery`;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.dataDiscovery(result);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getDiscoverySegment(customerId, answeredQuestions) {
  const endpoint = `cco/customer/${customerId}/data_discovery`;
  return new Promise(function (resolve, reject) {
    postRequest(endpoint, answeredQuestions)
      .then((result) => {
        const results = FormatData.dataDiscovery(result);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function getCustomerOffer(customerId, contractId) {
  const endpoint = `cco/customer/${customerId}/offer?contract=${contractId}`;

  return new Promise(function (resolve, reject) {
    getRequest(endpoint)
      .then((result) => {
        const results = FormatData.customerOffer(result);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}

export function postCustomerOffer(customerId, offerId) {
  const endpoint = `cco/customer/${customerId}/offer`;

  return new Promise(function (resolve, reject) {
    const data = {
      offer: offerId
    };

    postRequest(endpoint, data)
      .then((result) => {
        const results = FormatData.customerOffer(result);
        resolve(results);
      })
      .catch((error) => {
        switch (error.status) {
          case 403:
            reject(error.status);
            MwHistory.replace('/403');
            break;
          default:
            reject('error.generic');
            break;
        }
      });
  });
}
