import React, { Component } from 'react';
import clsx from 'clsx';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

const muiStyles = (theme) => ({
  label: {
    fontSize: '1rem',
    textTransform: 'uppercase',
    color: '#3A404D',
    opacity: 0.9
  },
  labelRight: {
    textAlign: 'right'
  },
  bar: {
    width: '100%',
    height: '0.6rem',
    backgroundColor: '#0BDCE9',
    borderRadius: '2px',
    margin: '0.5rem 0.2rem 0.5rem 0rem'
  },
  number: {
    fontWeight: 700,
    position: 'absolute',
    transform: 'translateX(calc(-50% + 0.2rem))'
  },
  ratio: {
    width: '100%',
    backgroundColor: '#0BDCE9',
    position: 'absolute',
    bottom: 0,
    borderRadius: '3px'
  },
  figures: {
    fontSize: '1.2rem'
  }
});

class StackedBar extends Component {
  render() {
    const { classes, data } = this.props;

    return (
      <div style={{ width: '100%' }}>
        {data &&
          data.map((item, index) => (
            <div
              key={index}
              style={{ width: item.ratio * 100 + '%', display: 'inline-block' }}
            >
              <div
                className={clsx(classes.label, {
                  [classes.labelRight]: index > 0
                })}
              >
                {item.label}
              </div>
              <div
                className={classes.bar}
                style={{ backgroundColor: item.color || '#F3F3F3' }}
              ></div>
              <div
                className={clsx(classes.figures, {
                  [classes.labelRight]: index > 0
                })}
              >
                {item.value}&nbsp;&nbsp;
                <span style={{ fontWeight: 700 }}>
                  ({(item.ratio * 100).toFixed()}%)
                </span>
              </div>
            </div>
          ))}
      </div>
    );
  }
}

export default withTranslation('monitoring')(withStyles(muiStyles)(StackedBar));
