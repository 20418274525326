import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import styles from './Ranking.module.css';
import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';
import dots from 'assets/images/game_dots.png';
import Dialog from '@material-ui/core/Dialog';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faDownload } from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import * as GamificationApi from './../api/endpoints';
import GamificationContext from './../contexts/GamificationProvider';

const muiStyles = (theme) => ({
  btRoot: {
    textTransform: 'none',
    backgroundColor: '#fff !important',
    padding: '0.8rem 2.4rem 0.8rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem',
    borderRadius: '2.2rem'
  },
  btPDF: {
    textTransform: 'none',
    backgroundColor: theme.palette.primary.main + ' !important',
    padding: '0.9rem 2.4rem 0.9rem',
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: '2rem',
    borderRadius: '2.2rem',
    marginRight: '1.6rem'
  }
});

class Ranking extends Component {
  static contextType = GamificationContext;

  constructor(props) {
    super(props);
    this.state = {
      allUsers: null
    };
  }

  componentDidMount() {
    this.mounted = true;
    this.loadRanking();
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(data);
    }
  }

  async loadRanking() {
    const levelId = this.context.myKeyInfo.current_level.id;
    const response = await GamificationApi.getRanking(levelId);
    this.securSetState({
      allUsers: response
    });
  }

  handleExtendedClose() {
    this.props.onCloseRankingExtended();
  }

  renderProgressionBar(user, me = true) {
    return (
      <>
        <Grid container alignItems='flex-end' alignContent='space-between'>
          <Grid item className={styles.Name}>
            {user.name}
          </Grid>
          <Grid item className={`${styles.Points} ${me ? styles.Me : ''}`}>
            {user.points}
          </Grid>
        </Grid>
        <div className={styles.Bg}>
          <div
            className={styles.Progress}
            style={{
              backgroundColor: user.bgColor,
              maxWidth: '100%',
              width: user.prc.toString() + '%'
            }}
          ></div>
        </div>
      </>
    );
  }

  formatUsers(users) {
    const dataFormatted = [];
    const nextLevel = this.context.myKeyInfo.next_level;
    const max = nextLevel.points;
    const meId = this.context.me?.id;

    users.forEach((user) => {
      let rank = user.rank?.toString();
      let name = user.name;
      if (user.id === this.props.me?.id) {
        name = '“You” ' + name;
      }
      const prc = (user.points * 100) / max;
      let bgColor = '#cdcdcd';
      if (user.id === meId) {
        bgColor = '#00DE7C';
      } else if (user.rank < 2) {
        bgColor = '#909090';
      }

      dataFormatted.push({
        id: user.id,
        rank: rank,
        avatar: user.avatar,
        name: name,
        points: user.points + ' ' + this.props.t('ranking.pts'),
        prc: prc,
        bgColor: bgColor
      });
    });

    return dataFormatted;
  }

  render() {
    const { classes } = this.props;
    const { myKeyInfo, me } = this.context;
    const { allUsers } = this.state;

    if (!allUsers) {
      return <div />;
    }

    let defaultAvatar = require('assets/images/default_avatar.png');
    const currentLevel = myKeyInfo.current_level;
    const currentLevelColor = currentLevel.color;
    const currentLevelName =
      currentLevel.name_trans[i18next.language] ||
      currentLevel.name_trans[i18next.options.fallbackLng[0]];

    let shortUsersList = [];
    let meIn = false;
    const shortLim = allUsers.length < 5 ? allUsers.length : 5;
    for (let i = 0; i < shortLim; i++) {
      if (allUsers[i].rank !== myKeyInfo.rank) {
        shortUsersList.push({
          id: allUsers[i].id,
          rank: allUsers[i].rank,
          avatar: allUsers[i].avatar,
          name: allUsers[i].nickname,
          points: allUsers[i].points
        });
      } else {
        shortUsersList.push({
          id: me.id,
          rank: myKeyInfo.rank,
          avatar: me.user.avatar,
          name: me.nickname,
          points: myKeyInfo.points
        });
        meIn = true;
      }
    }
    if (!meIn) {
      shortUsersList.pop();
      shortUsersList.push({
        id: me.id,
        rank: myKeyInfo.rank,
        avatar: me.user.avatar,
        name: me.nickname,
        points: myKeyInfo.points
      });
    }

    let fullUsersList = [];
    const fullLim = allUsers.length < 10 ? allUsers.length : 10;
    for (let i = 0; i < fullLim; i++) {
      if (allUsers[i].rank !== myKeyInfo.rank) {
        fullUsersList.push({
          id: allUsers[i].id,
          rank: allUsers[i].rank,
          avatar: allUsers[i].avatar,
          name: allUsers[i].nickname,
          points: allUsers[i].points
        });
      } else {
        fullUsersList.push({
          id: me.id,
          rank: myKeyInfo.rank,
          avatar: me.user.avatar,
          name: me.nickname,
          points: myKeyInfo.points
        });
      }
    }

    let diffPoints = 0;
    if (myKeyInfo) {
      diffPoints = shortUsersList[0].points - myKeyInfo.points;
    }
    shortUsersList = this.formatUsers(shortUsersList);
    fullUsersList = this.formatUsers(fullUsersList);

    const parse = require('html-react-parser');
    let txtIsLeading = this.props.t('ranking.isleading');
    txtIsLeading = txtIsLeading.replace('{leader}', shortUsersList[0].name);
    txtIsLeading = txtIsLeading.replace('{diffPoints}', diffPoints);
    const isLeading = parse(txtIsLeading);

    let txtTitleRanking = this.props.t('ranking.title');
    txtTitleRanking = txtTitleRanking.replace(
      '{currentLevelColor}',
      currentLevelColor
    );
    txtTitleRanking = txtTitleRanking.replace(
      '{currentLevelName}',
      currentLevelName
    );
    const titleRanking = parse(txtTitleRanking);

    return (
      <>
        <Dialog
          onClose={() => this.handleExtendedClose()}
          aria-labelledby='ranking'
          open={this.props.rankingExtendedOpen}
        >
          <div>
            <div className={styles.LDClose}>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => this.handleExtendedClose()}
              />
            </div>
            <div className={styles.LDTitle}>{titleRanking}</div>
            <div className={styles.LDDivider}></div>
            <div className={styles.LDList}>
              <Table
                padding='none'
                className={styles.Table}
                style={{ width: '40rem' }}
              >
                <TableBody>
                  {fullUsersList.slice(0, 10).map((user, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell
                          padding='none'
                          align='right'
                          className={styles.CellRank}
                        >
                          {user.rank}
                        </TableCell>
                        <TableCell
                          padding='none'
                          align='left'
                          className={styles.CellAvatar}
                        >
                          <Avatar src={user?.avatar?.file || defaultAvatar} />
                        </TableCell>
                        <TableCell
                          padding='none'
                          align='left'
                          className={styles.CellProgressBar}
                        >
                          {this.renderProgressionBar(user, user?.id === me?.id)}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
            <div className={styles.LDDivider}></div>
            <div className={styles.LDButtons}>
              <Button
                variant='contained'
                type='button'
                disableElevation
                disableRipple
                size='small'
                color='primary'
                classes={{ root: classes.btPDF }}
              >
                <FontAwesomeIcon
                  icon={faDownload}
                  style={{ marginRight: '1rem' }}
                />
                {this.props.t('ranking.bt-download')}
              </Button>
              <Button
                variant='outlined'
                type='button'
                disableElevation
                disableRipple
                size='small'
                color='primary'
                onClick={() => {
                  this.handleExtendedClose();
                }}
                classes={{ root: classes.btRoot }}
              >
                {this.props.t('ranking.bt-close')}
              </Button>
            </div>
          </div>
        </Dialog>

        <div className={styles.Wrapper}>
          {shortUsersList && (
            <Grid container spacing={6}>
              <Grid item xs={shortUsersList[0]?.id === me?.id ? 10 : 8}>
                <Table padding='none' className={styles.Table}>
                  <TableBody>
                    {shortUsersList.slice(0, 5).map((user, index) => {
                      return (
                        <TableRow key={index}>
                          <TableCell
                            padding='none'
                            align='right'
                            className={`${styles.CellRank} ${
                              user?.id === me?.id ? styles.Me : ''
                            }`}
                          >
                            {user?.rank}
                          </TableCell>
                          <TableCell
                            padding='none'
                            align='left'
                            className={styles.CellAvatar}
                          >
                            <Avatar src={user?.avatar?.file || defaultAvatar} />
                          </TableCell>
                          <TableCell
                            padding='none'
                            align='left'
                            className={styles.CellProgressBar}
                          >
                            {this.renderProgressionBar(
                              user,
                              user?.id === me?.id
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Grid>
              {shortUsersList[0].id !== me.id && (
                <Grid item xs={4}>
                  <div className={styles.Target}>
                    <div className={styles.Message}>{isLeading}</div>
                    <div>
                      <Avatar
                        className={styles.Avatar}
                        src={shortUsersList[0].avatar?.file || defaultAvatar}
                      />
                    </div>
                    <div className={styles.Dots}>
                      <img src={dots} alt='dots' />
                    </div>
                    <div>
                      <Avatar
                        className={styles.Avatar}
                        src={me.user.avatar.file}
                      />
                    </div>
                  </div>
                </Grid>
              )}
            </Grid>
          )}
        </div>
      </>
    );
  }
}

export default withTranslation('gamification')(withStyles(muiStyles)(Ranking));
