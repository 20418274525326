import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function SubmitButton(props) {
  const useStyles = makeStyles({
    root: {
      borderRadius: '2rem',
      textTransform: 'none',
      fontSize: '1.5rem',
      fontWeight: 600,
      padding:
        props.size === 'small'
          ? '0.6rem 4.4rem 0.6rem 1.4rem'
          : '0.9rem 4.5rem 0.9rem 1.5rem'
    },
    disabled: {
      '&:disabled': {
        color: '#ffffff'
      }
    }
  });

  const classes = useStyles(props);

  const progressStyle = {
    marginRight: '1rem',
    color: '#ffffff',
    opacity: props.isLoading ? 1 : 0
  };

  return (
    <Button
      variant='contained'
      color={props.color?props.color:'primary'}
      disableElevation
      disableRipple
      fullWidth={props.fullWidth}
      type={props.type ? props.type : 'submit'}
      disabled={props.disabled || props.isLoading}
      size={props.size ? props.size : 'small'}
      onClick={props.onClick}
      classes={{
        root: classes.root,
        disabled: classes.disabled
      }}
    >
      <CircularProgress size={20} style={progressStyle} />
      {props.label}
    </Button>
  );
}
