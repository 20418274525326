import React, { Component } from 'react';
import isEqual from 'lodash/isEqual'
import * as CcoApi from './../api/endpoints';
import * as MwHistory from 'utils/MwHistory';

const CallSessionContext = React.createContext();

class CallSessionProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: null,
      selectedCampaign: null,
      selectedOpportunity: null,
      selectedProduct: null,
      selectedCollection: null,
      selectedCollectionAnswer: null,
      allProducts: null,
      portfolio: null,
      interactionHistory: null,
      relationship: null,
      bill: null,
      campaigns: null,
      personalAttributes: null,
      products: null,
      allOpportunities: null,
      opportunities: null,
      outcomes: null,
      outcomeAcceptedId: null,
      outcomeNotOfferedId: null,
      outcomeRejectedId: null,
      reasons: null,
      quarantines: null,
      features: null,
      tips: null,
      collections: null,
      faq: null,
      dataDiscovery: null,
      dataDiscoveryAnswers: null,
      discoverySegment: null,
      customerOffers: null,
      selectedOffer: null,
      contractAccountId: null,
      offerComment: null
    };
  }

  clearContext = () => {
    this.setState({ 
      customer: null,
      selectedCampaign: null,
      selectedOpportunity: null,
      selectedProduct: null,
      selectedCollection: null,
      selectedCollectionAnswer: null,
      allProducts: null,
      portfolio: null,
      interactionHistory: null,
      relationship: null,
      bill: null,
      campaigns: null,
      personalAttributes: null,
      products: null,
      allOpportunities: null,
      opportunities: null,
      outcomes: null,
      outcomeAcceptedId: null,
      outcomeNotOfferedId: null,
      outcomeRejectedId: null,
      reasons: null,
      quarantines: null,
      features: null,
      tips: null,
      collections: null,
      faq: null,
      dataDiscovery: null,
      dataDiscoveryAnswers: null,
      discoverySegment: null,
      customerOffers: null,
      selectedOffer: null,
      contractAccountId: null,
      offerComment: null

    });

  }

  async loadCustomer(customerId) {
    try {

      customerId = String(Number(customerId));
      const result = await CcoApi.getCustomer(customerId);
      this.onCustomerSucceed(result, customerId);
    } catch (error) {
      MwHistory.replace('/404/');
    }
  }

  onCustomerSucceed(customer, contractAccountId) {
    this.setState({ customer: customer, contractAccountId });
    this.loadAllProducts(customer.id);
    this.loadPortfolio(customer.id);
    this.loadInteractionHistory(customer.id);
    this.loadRelationship(customer.id);
    this.loadBill(customer.id);
    this.loadCampaigns(customer.id, contractAccountId);
    this.loadOutcomes();
    this.loadCollectionData(customer.id);
    this.loadQuarantines();
    this.loadFAQ();
    this.loadDataDiscoveryQuestions(customer.id)
    this.loadCustomerOffer(customer.id, contractAccountId)
  }

  async loadAllProducts() {
    const result = await CcoApi.getProducts();
    this.setState({ allProducts: result });
  }

  async loadPortfolio(customerId) {
    const result = await CcoApi.getCustomerContracts(customerId);
    this.setState({ portfolio: result });
  }

  async loadInteractionHistory(customerId) {
    const result = await CcoApi.getCustomerInteractionHistory(customerId);
    this.setState({ interactionHistory: result });
  }

  async loadRelationship(customerId) {
    const result = await CcoApi.getRelationship(customerId);
    this.setState({ relationship: result });
  }

  async loadBill(customerId) {
    const result = await CcoApi.getCustomerBill(customerId);
    this.setState({ bill: result });
  }

  async loadCampaigns(customerId, contractId) {
    const result = await CcoApi.getCustomerCampaigns(customerId);
    this.onCampaignsSucceed(result, contractId);
  }

  onCampaignsSucceed(data, contractID) {
    const selectedCampaign = data.length > 0 ? data[0] : null;
    this.setState({
      campaigns: data,
      selectedCampaign: selectedCampaign
    });
    // if (selectedCampaign) {
    //   this.loadOpportunities(this.state.customer.id, selectedCampaign.id);
    // }
    this.loadPersonalAttributes(
      this.state.customer.id,
      selectedCampaign?.type.id,
      contractID
    );
    
    this.loadReasons( selectedCampaign?.type.id);
  }

  async loadOpportunities(customerId, campaignId) {
    const result = await CcoApi.getCustomerOpportunities(
      customerId,
      campaignId
    );
    this.onOpportunitiesSucceed(result);
  }

  onOpportunitiesSucceed(data) {
    this.setState({ allOpportunities: data });
    this.populateProducts(data);
    this.populateOpportunities(data, this.state.selectedProduct?.id);
    this.loadReasons(
      this.state.selectedCampaign?.type.id,
      this.state.selectedProduct?.id
    );
    this.loadProductFeatures(this.state.selectedProduct?.id);
  }

  async loadPersonalAttributes(customerId, campaignTypeId, contractID) {

    const result = await CcoApi.getCustomerPersonalAttributes(
      customerId,
      campaignTypeId,
      contractID
    );
    this.setState({ personalAttributes: result });
  }

  populateProducts(opportunities) {
    let ids = [];
    const products = [];
    opportunities.forEach((opportunity) => {
      if (opportunity.product && !ids.includes(opportunity.product.id)) {
        ids.push(opportunity.product.id);
        products.push({
          id: opportunity.product.id,
          price: opportunity.product.price,
          propensity: opportunity.propensity,
          name: opportunity.product.name_trans
        });
      }
    });

    let selectedProduct = products.length > 0 ? products[0] : null;
    this.setState({
      products: products,
      selectedProduct: selectedProduct
    });
  }
  yieldOthersOpportunities = function* (opportunity) {
    for (const offer of opportunity?.offer?.others) {
      yield { ...opportunity, offer };
    }
  };
  populateOpportunities(allOpportunities, productId) {
    let opportunities = [];

    allOpportunities.forEach((opportunity) => {
      if (opportunity.product?.id === productId || !opportunity.product) {
        opportunities.push(opportunity);
      }
    });

    if (opportunities.length === 1) {
      opportunities = opportunities.concat(
        ...this.yieldOthersOpportunities(opportunities[0])
      );
    }

    let selectedOpportunity = null;
    if (opportunities.length > 0) {
      selectedOpportunity = opportunities[0];
    }
    this.setState({
      opportunities: opportunities,
      selectedOpportunity: selectedOpportunity
    });
    this.loadTips(selectedOpportunity.id);
  }

  async loadOutcomes() {
    const result = await CcoApi.getOutcomes();
    let outcomeAcceptedId = 0;
    let outcomeNotOfferedId = 0;
    let outcomeRejectedId = 0;
    let outcomeUndecidedId = 0;

    result.forEach((item) => {
      if (item.code === 'accepted') {
        outcomeAcceptedId = item.id;
      } else if (item.code === 'not_proposed') {
        outcomeNotOfferedId = item.id;
      } else if (item.code === 'rejected') {
        outcomeRejectedId = item.id;
      } else if (item.code === 'undecided') {
        outcomeUndecidedId = item.id;
      }
    });

    this.setState({
      outcomes: result,
      outcomeAcceptedId: outcomeAcceptedId,
      outcomeNotOfferedId: outcomeNotOfferedId,
      outcomeRejectedId: outcomeRejectedId,
      outcomeUndecidedId: outcomeUndecidedId
    });
  }

  async loadReasons(campaignTypeId, productId) {

    const result = await CcoApi.getReasons(campaignTypeId, productId);
    this.setState({ reasons: result });
  }

  async loadQuarantines(campaignTypeId, productId) {
    const result = await CcoApi.getQuarantines();
    this.setState({ quarantines: result });
  }

  async loadProductFeatures(productId) {
    if (productId) {
      const result = await CcoApi.getProductsFeatures(productId);
      this.setState({ features: result });
    }
  }

  async loadTips(opportunityId) {
    const result = await CcoApi.getOpportunityTips(opportunityId);
    this.setState({ tips: result });
  }

  loadCollectionData = async (customerId) => {
    const result = await CcoApi.getCollectionForCustomer(customerId);
    this.setState({
      collections: result,
      selectedCollection: result.length ? result[0] : null
    });
  };

  async loadFAQ() {
    const result = await CcoApi.getFAQs();
    this.setState({ faq: result });
  }

  setDataDiscoveryState(dataDiscoveryResponse) {

    if (!dataDiscoveryResponse){
      return
    } 
    const dataDiscovery = dataDiscoveryResponse.questions.reduce((acc, data, key) =>
      Object.assign(acc, {[key]: { 
        question: { name_trans: data.name_trans},
        possible_answers: data.possible_answers,
        answer: dataDiscoveryResponse.answers && dataDiscoveryResponse.answers[[key]]
      }}), {})

      this.setState({ dataDiscovery, discoverySegment: dataDiscoveryResponse.segment });
  }

  setCustomerOffer(offerResponse) {
    if (!offerResponse){
      return
    }

    const offerScriptSelection = offerResponse.offerScripts || (this.state.customerOffers && this.state.customerOffers.offerScripts) || []
    let selectedOffer = offerScriptSelection.findIndex(x => x.offer.id === (offerResponse.selectedOffer && offerResponse.selectedOffer.id));
    selectedOffer = selectedOffer === -1 ? offerScriptSelection.length -1  : selectedOffer 
    const that = {
      selectedOffer,
      customerOffers: { 
        ...offerResponse,
        offerScripts: offerScriptSelection
      }
      
    }
    this.setState(that);
  }

  
  setCustomerOfferComment = (comment) => {
    if (!comment){
      this.setState({offerComment: null});
      return
    }

    this.setState({offerComment: comment});
  }
  
  async loadDataDiscoveryQuestions(customerId) {
    const result = await CcoApi.getDataDiscovery(customerId);
    this.setDataDiscoveryState(result);
    this.setUserDiscoveryAnswers()
  }

  async loadCustomerOffer(customerId, contractID) {
    const result = await CcoApi.getCustomerOffer(customerId, contractID);
    this.setCustomerOffer(result);
  }

  load = (customerId) => {
    this.loadCustomer(customerId);
  };

  setSelectedCampaign = (campaign, contractID) => {

    this.setState({
      selectedCampaign: campaign,
      selectedOpportunity: null,
      selectedProduct: null
    });
    this.loadOpportunities(this.state.customer.id, campaign.id);
    this.loadPersonalAttributes(this.state.customer.id, campaign.type.id, contractID);
  };
  setSelectedOpportunity = (opportunity) => {
    this.setState({
      selectedOpportunity: opportunity,
      selectedProduct: null
    });
    this.loadTips(opportunity.id);
  };
  setSelectedProduct = (product) => {
    this.setState({ selectedProduct: product });
    this.populateOpportunities(this.state.allOpportunities, product.id);
    this.loadReasons(this.state.selectedCampaign?.type.id, product.id);
    this.loadProductFeatures(product.id);
  };
  setSelectedCollectionQuestion = (question) => {
    this.setState({
      selectedCollection: question
    });
  };
  setSelectedCollectionAnswer = (answer) => {
    this.setState({
      selectedCollectionAnswer: answer
    });
  };

  setUserDiscoveryAnswers = () => {
    const { dataDiscovery } = this.state;
    if (!dataDiscovery) {
      return
    }

    const customerAnswers = {};
    Object.values(dataDiscovery).map((data, idx) => {
      if (!data.answer) {
        return null
      }
      const answerIndex = data.possible_answers.findIndex(ans => ans.id === data.answer.id)
      customerAnswers[[idx]] = answerIndex;
      return null
    })

    this.setState({
      dataDiscoveryAnswers: customerAnswers
    });
  
  }

  setNewDiscoveryAnswers = async (answers) => {
    this.setState({
      dataDiscoveryAnswers: answers
    });

    const { dataDiscovery, dataDiscoveryAnswers, customer, contractAccountId } = this.state;
    const questions = Object.keys(answers);
    const hasChanged = questions.length === 3 && !isEqual(answers, dataDiscoveryAnswers)

    if (!hasChanged){
      return
    }

    const segmentData = {}
    questions.map(id => {
        const questionID = parseInt(id);
        const { possible_answers } = dataDiscovery[questionID]; 
        segmentData[`answer_${questionID + 1}`] = possible_answers[answers[id]].id
        return null
    });

    let result;
    try {
      result = await CcoApi.getDiscoverySegment(customer.id, segmentData);
    } catch (error) {
      this.setState({ discoverySegment: null });
    } finally {
      this.setDataDiscoveryState(result);
      this.loadCustomerOffer(customer.id, contractAccountId);

    }

  };


  setSelectedOffer = async (offerIndex) => {

    const { customer, customerOffers } = this.state;

    if(!customerOffers || !customerOffers.offerScripts){
      return
    }

    const offerID = customerOffers.offerScripts[offerIndex].offer.id

    let result;
    try {
      result = await CcoApi.postCustomerOffer(customer.id, offerID);
    } catch (error) {
     console.log(error)
    } finally {

      this.setCustomerOffer(result)

    }

  }

  render() {
    const { children } = this.props;
    const {
      customer,
      selectedCampaign,
      selectedOpportunity,
      selectedProduct,
      selectedCollection,
      selectedCollectionAnswer,
      allProducts,
      portfolio,
      interactionHistory,
      relationship,
      bill,
      campaigns,
      personalAttributes,
      products,
      allOpportunities,
      opportunities,
      outcomes,
      outcomeAcceptedId,
      outcomeNotOfferedId,
      outcomeRejectedId,
      outcomeUndecidedId,
      reasons,
      quarantines,
      features,
      tips,
      collections,
      faq,
      dataDiscovery,
      dataDiscoveryAnswers,
      discoverySegment,
      customerOffers,
      selectedOffer,
      contractAccountId,
      offerComment
    } = this.state;

    const gamificationActive = this.props.appContext.gamificationActive;
    const clientSettings = this.props.appContext.clientSettings;
    const me = this.props.appContext.me;

    const {
      load,
      setSelectedCampaign,
      setSelectedOpportunity,
      setSelectedProduct,
      setSelectedCollectionQuestion,
      setSelectedCollectionAnswer,
      loadCollectionData,
      setUserDiscoveryAnswers,
      setSelectedOffer,
      setNewDiscoveryAnswers,
      setCustomerOfferComment,
      clearContext,
    } = this;

    return (
      <CallSessionContext.Provider
        value={{
          load,
          gamificationActive,
          clientSettings,
          me,
          customer,
          selectedCampaign,
          selectedOpportunity,
          selectedProduct,
          selectedCollection,
          selectedCollectionAnswer,
          allProducts,
          portfolio,
          interactionHistory,
          relationship,
          bill,
          campaigns,
          personalAttributes,
          products,
          allOpportunities,
          opportunities,
          outcomes,
          outcomeAcceptedId,
          outcomeNotOfferedId,
          outcomeRejectedId,
          outcomeUndecidedId,
          reasons,
          quarantines,
          features,
          tips,
          collections,
          faq,
          dataDiscovery,
          dataDiscoveryAnswers,
          discoverySegment,
          customerOffers,
          selectedOffer,
          contractAccountId,
          offerComment,
          setSelectedCampaign,
          setSelectedOpportunity,
          setSelectedProduct,
          setSelectedCollectionQuestion,
          setSelectedCollectionAnswer,
          setUserDiscoveryAnswers,
          setNewDiscoveryAnswers,
          setSelectedOffer,
          loadCollectionData,
          setCustomerOfferComment,
          clearContext
        }}
      >
        {children}
      </CallSessionContext.Provider>
    );
  }
}

export default CallSessionContext;

export { CallSessionProvider };
