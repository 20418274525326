import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Box from '@material-ui/core/Box';

export default function EnumerationTable(props) {
  const { data } = props;

  const useStyles = makeStyles((theme) => ({
    tableBorder: {
      border: '1px solid #E6E9EE'
    },
    tableCellLabel: {
      color: '#777777',
      borderBottom: '1px solid #E6E9EE',
      padding: '0.4rem 1.2rem',
      verticalAlign: 'top'
    },
    tableCellValue: {
      borderBottom: '1px solid #E6E9EE',
      fontWeight: 700,
      color: '#777777',
      padding: '0.4rem 1.2rem',
      verticalAlign: 'top',
      '&:last-child': {
        paddingRight: '1.2rem'
      }
    },
    tableRow: {
      '&:last-child th, &:last-child td': {
        borderBottom: 0
      }
    }
  }));

  const classes = useStyles();

  return (
    <>
      {data && data.length > 0 && (
        <Box
          classes={{ root: classes.tableBorder }}
          border={1}
          borderRadius='6px'
        >
          <Table size='small'>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index} classes={{ root: classes.tableRow }}>
                  <TableCell
                    classes={{ root: classes.tableCellLabel }}
                    align={'left'}
                    size='small'
                  >
                    {row.name}
                  </TableCell>
                  <TableCell
                    classes={{ root: classes.tableCellValue }}
                    align={row.numeric ? 'right' : 'left'}
                    size='small'
                  >
                    {row.value}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      )}
    </>
  );
}
