import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import * as MwHistory from 'utils/MwHistory';
import Container from '@material-ui/core/Container';
import picto from 'assets/images/mascot_403.svg';
import SubmitButton from './../components/materials/SubmitButton';

class Forbidden extends Component {
  redirect() {
    MwHistory.push('/login');
  }

  render() {
    const styles = {
      wrapper: {
        alignItems: 'center',
        textAlign: 'center',
        padding: '51px 0rem 0rem',
        backgroundColor: '#f3f6f9',
        height: '100vh',
        width: '100vw'
      },
      activity: {
        height: '0.3rem',
        backgroundColor: '#fff',
        width: 'calc(100vw + 2rem)',
        position: 'fixed',
        top: 0,
        left: '-2rem',
        zIndex: 2000
      },
      wrapperTop: {
        backgroundColor: '#fff',
        width: '100%',
        height: '45%',
        minHeight: '30rem',
        position: 'relative',
        boxShadow: '0rem 0.3rem 0.4rem 0rem rgba(1,0,0,0.02)'
      },
      contentTop: {
        position: 'absolute',
        bottom: '0rem',
        width: '50%',
        minWidth: '50rem',
        left: '50%',
        transform: 'translateX(-50%)',
        textAlign: 'center',
        fontSize: '25rem',
        fontWeight: 600,
        color: '#4C4C4C',
        lineHeight: '0.775',
        whiteSpace: 'nowrap'
      },
      contentBottom: {
        marginTop: '3.6rem',
        title: {
          fontSize: '3.6rem',
          fontWeight: 600,
          lineHeight: '4.4rem',
          color: '#4C4C4C'
        },
        subtitle: {
          fontSize: '1.8rem',
          fontWeight: 400,
          lineHeight: '4.4rem',
          color: '#4C4C4C'
        },
        bt: {
          marginTop: '2.8rem'
        }
      }
    };

    return (
      <Container maxWidth={false} style={styles.wrapper}>
        <div style={styles.activity}></div>
        <div style={styles.wrapperTop}>
          <Container style={styles.contentTop}>
            <div style={{ display: 'inline-block' }}>4</div>
            <div
              style={{
                display: 'inline-block',
                position: 'relative',
                width: '18rem'
              }}
            >
              <img
                src={picto}
                alt='403'
                style={{
                  position: 'absolute',
                  bottom: '-2.6rem',
                  height: '30rem',
                  width: 'auto',
                  transform: 'translateX(-50%)'
                }}
              />
            </div>
            <div style={{ display: 'inline-block' }}>3</div>
          </Container>
        </div>
        <div style={styles.contentBottom}>
          <div style={styles.contentBottom.title}>
            {this.props.t('forbidden.txt-title')}
          </div>
          <div style={styles.contentBottom.subtitle}>
            {this.props.t('forbidden.txt-subtitle')}
          </div>
          <div style={styles.contentBottom.bt}>
            <form onSubmit={this.redirect}>
              <SubmitButton
                disabled={false}
                label={this.props.t('forbidden.bt-login')}
                isLoading={false}
                type='submit'
                size='large'
              />
            </form>
          </div>
        </div>
      </Container>
    );
  }
}

export default withTranslation('common')(Forbidden);
