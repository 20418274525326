function formatTrans(trans) {
  if (trans) {
    let labels = {};
    trans.forEach((lbl) => {
      labels[lbl.lang] = lbl.text;
    });
    return labels;
  } else {
    return null;
  }
}

function product(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items)
  };
  return dataTranslated;
}
function outcome(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    ...(data.reasons && { reasons: data.reasons.map(feedbackReason) }),
    name_trans: formatTrans(data.name_trans?.items)
  };
  return dataTranslated;
}

function channel(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items),
    parent: channel(data.parent)
  };
  return dataTranslated;
}
function campaignType(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    name_trans: formatTrans(data.name_trans?.items)
  };
  return dataTranslated;
}
function feedbackReason(data) {
  if (!data) {
    return null;
  }
  let dataTranslated = {
    ...data,
    description_trans: formatTrans(data.description_trans?.items),
    ...(data.product && { product: product(data.product) })
  };
  return dataTranslated;
}

export function products(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(product(item));
  });
  return dataTranslated;
}
export function outcomes(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(outcome(item));
  });
  return dataTranslated;
}
export function channels(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(channel(item));
  });
  return dataTranslated;
}
export function campaignTypes(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(campaignType(item));
  });
  return dataTranslated;
}
export function FeedbackReasons(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(feedbackReason(item));
  });
  return dataTranslated;
}

function timeEvolution(stat) {
  if (!stat) {
    return null;
  }

  const data = stat.data;
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push({
      label_trans: formatTrans(item.label_trans?.items),
      count: item.count,
      ratio: item.ratio
    });
  });

  let statTranslated = {
    label_trans: formatTrans(stat.label_trans?.items),
    data: dataTranslated
  };
  return statTranslated;
}
export function timeEvolutions(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push(timeEvolution(item));
  });
  return dataTranslated;
}
export function interactionOutcomes(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    let reasons = [];
    if (item.reasons) {
      item.reasons.forEach((item) => {
        reasons.push({
          reason: feedbackReason(item.reason),
          ratio: item.ratio,
          count: item.count
        });
      });
    }

    dataTranslated.push({
      outcome: outcome(item.outcome),
      ratio: item.ratio,
      count: item.count,
      reasons: reasons
    });
  });
  return dataTranslated;
}
export function userAnalysis(data) {
  let dataTranslated = [];
  data.forEach((item) => {
    dataTranslated.push({
      ...item,
      channel: channel(item.channel)
    });
  });
  return dataTranslated;
}

export function customerAnalysis(data) {
  return {
    ...data,
    products: data.products.map((product) => ({
      ...product,
      name_trans: formatTrans(product?.name_trans.items),
      feedback: {
        ...product.feedback,
        outcome: product.feedback.outcome.map((o) => ({
          ...o,
          name_trans: formatTrans(o?.name_trans.items)
        }))
      }
    })),
    total: data.total.map((o) => ({
      ...o,
      name_trans: formatTrans(o?.name_trans.items)
    }))
  };
}
