import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import * as MwHistory from 'utils/MwHistory';
import SubmitButton from 'deep/components/materials/SubmitButton';
import BasicInput from 'deep/components/materials/BasicInput';
import Box from '@material-ui/core/Box';
import * as CcoApi from './../api/endpoints';

class StartCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null,
      inputKeyword: '',
      validKeyword: ''
    };
    this.handleKeywordChange = this.handleKeywordChange.bind(this);
    this.handleSearchSubmit = this.handleSearchSubmit.bind(this);
  }

  componentDidMount() {
    this.mounted = true;
  }
  componentWillUnmount() {
    this.mounted = false;
  }
  securSetState(data) {
    if (this.mounted) {
      this.setState(() => data);
    }
  }

  handleKeywordChange(value) {
    this.securSetState({
      inputKeyword: value,
      error: null
    });
  }
  handleSearchSubmit(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.state.inputKeyword !== '') {
      this.securSetState({
        loading: true,
        validKeyword: this.state.inputKeyword
      });
      if (this.mounted) {
        this.loadData();
      }
    } else {
      this.securSetState({
        customers: [],
        loading: false
      });
    }
  }

  async loadData() {
    const validKeyword = this.state.inputKeyword;

    try {
      const result = await CcoApi.getCustomer(validKeyword);
      this.onLoadDataSucceed(result);
    } catch (error) {
      if (error.status === 403) {
        this.props.onClose();
      } else {
        this.securSetState({
          loading: false,
          error: error
        });
      }
    }
  }

  onLoadDataSucceed = (customer) => {
    this.securSetState({
      loading: false,
      error: false
    });
    this.props.onStart();
    MwHistory.push('/cco/session/' + customer.id);
    const currentRoute = MwHistory.getCurrentRoute();
    if (currentRoute.path.indexOf('/cco/session/') > -1) {
      MwHistory.go();
    }
  };

  render() {
    const styles = {
      error: {
        height: '2rem',
        fontSize: '1.1rem',
        padding: 0,
        textAlign: 'left'
      }
    };

    //manage language
    let errorTrans = null;
    if (this.state.error) {
      errorTrans = this.props.t(this.state.error);
    }

    return (
      <form onSubmit={this.handleSearchSubmit}>
        <div style={{ width: 'max-content', margin: '0rem auto' }}>
          <BasicInput
            defaultValue=''
            autoFocus={true}
            placeholder={this.props.t('startcall.ph-id')}
            error={this.state.error && this.state.error !== ''}
            valid={this.state.inputKeyword !== '' && !this.state.error}
            type='text'
            onChange={(value) => this.handleKeywordChange(value)}
            size={this.props.size}
            maxLength='250'
            style={{
              width: this.props.inputWidth,
              maxWidth: '100%',
              margin: '0rem 1rem 1rem 0rem'
            }}
          />
          <span style={{ margin: '0rem 1rem 0rem 0rem' }}>
            <SubmitButton
              disabled={this.state.inputKeyword === ''}
              label={this.props.t('startcall.bt-start')}
              isLoading={this.state.loading}
              type='submit'
              size={this.props.size}
            />
          </span>
          <Box color='error.main' style={styles.error}>
            <span>{errorTrans}</span>
          </Box>
        </div>
      </form>
    );
  }
}

export default withTranslation('cco')(StartCall);
